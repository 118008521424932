import React from 'react';
import { getDefaultPagingNumberOfItems } from '../../../../helpers/PackageJsonHelper';
import { deleteContact, getContactByID, getContactsByCustomer } from '../../../../networking/NetworkingContacts';
import { getStyle } from '../../../../styles/styles';
import AlertBox from '../../../shared/AlertBox';
import LoadingBox from '../../../shared/LoadingBox';
import NewDynamicTable from '../../../shared/NewDynamicTable';
import ContactMaintenanceScreen from '../contacts/ContactMaintenanceScreen';


var style = getStyle();

export default class ContactsScreen extends React.Component {

    state = {
        contacts: [],
        newContact: false,
        updateContact: false,
        contactUpdateToken: '',
        selectedContact: {},
        currentPage: 1,
        contactID: 0,
        totalPages: 0,
        newSearch: false,
        //---------
        readyToDeleteContact: {},
        showDeleteAlert: false,
        showSuccessDeleteAlert: false,
        showAlert: false,
        alertTitle: '',
        alertMessage: '',
        loading: false
    }

    handleRowClick(obj) {
        console.log(obj);
        let title = `Contact - ${obj.Name} - ${obj['Contact ID']}`;
        this.props.updateContactHandler(title)
        getContactByID(obj["Contact ID"]).then(
            (value) => {
                console.log(value)
                var pep = true;
                if(value["Politically Exposed Person"] === null || value["Politically Exposed Person"] === false) { pep = false;}
                this.setState({
                    updateContact: true,
                    contactID: obj["Contact ID"],
                    contactUpdateToken: value.UpdateToken,
                    selectedContact: { ...value, "Politically Exposed Person": pep }
                });
            }
        );
    }
    handleDeleteClick(obj) {
        console.group("Contact handleDeleteClick")
        console.log(obj);
        console.groupEnd();
        this.setState({ showDeleteAlert: true, readyToDeleteContact: obj })
    }
    closeAlert() {
        this.setState({ showAlert: false, alertTitle: '' });
    }
    closeDeleteAlert() {
        this.setState({ showDeleteAlert: false, readyToDeleteContact: {} });
    }

    openDeleteAlert() {
        this.setState({ showDeleteAlert: true });
    }

    yesDeleteAlert() 
    {
        this.setState({ 
            showDeleteAlert : false, 
            loading         : true
        });

        deleteContact(this.state.readyToDeleteContact['Contact ID']).then(
            (deleteResponse) => {
                ////debugger
                let auxTitle = 'Error';
                let auxMessage = 'Something went wrong. Please try again later.';
                let deleted = false;

                if(deleteResponse !== undefined)
                {
                    switch (deleteResponse.httpStatusCode) 
                    {
                        case 200:
                        {
                            deleted = true;
                            break;
                        }
                        case 503:
                        {
                            /*
                                errorMessage: "Conversion failed when converting from a character string to uniqueidentifier."
                                httpStatusCode: 503
                                result: ""
                            */

                            if(deleteResponse.result !== undefined && deleteResponse.result !== null && deleteResponse.result !== '')
                            {
                                switch (deleteResponse.result) 
                                {
                                    case 'Contact has related activity and cannot be deleted':
                                    {
                                        auxMessage = 'This contact cannot be deleted as it is linked to one or more entities in the system.';
                                        break;
                                    }
                                    default:
                                    {
                                        auxMessage = deleteResponse.result;
                                        break;
                                    }
                                }
                            }else
                            {
                                if(deleteResponse.errorMessage !== undefined && deleteResponse.errorMessage !== null && deleteResponse.errorMessage !== '')
                                {
                                    auxMessage = deleteResponse.errorMessage;
                                }
                            }
                            break;
                        }
                        case 412:
                        {
                            if(deleteResponse.errorMessage !== undefined && deleteResponse.errorMessage !== null && deleteResponse.errorMessage !== '')
                            {
                                auxMessage = deleteResponse.errorMessage;
                            }
                            break;
                        }
                        default:
                        {
                            break;
                        }
                    }
                    if(deleted)
                    {
                        this.setState({
                            showSuccessDeleteAlert  : true,
                            loading                 : false
                        }, () =>{
                            this.updatePage();
                        });
                    }else
                    {
                        this.setState({
                            showAlert   : true,
                            alertTitle  : auxTitle,
                            alertMessage: auxMessage,
                            loading     : false
                        });
                    }
                }else
                {
                    this.setState({
                        showAlert   : true,
                        alertTitle  : auxTitle,
                        alertMessage: auxMessage,
                        loading     : false,
                        email       : ''
                    });
                }
            }
        )
    }
    okSuccessAlert() {
        this.setState({ showSuccessDeleteAlert: false });
    }
    handleAddContactClick = () => {
        const newContact = {
            "Contact ID": 0,
            "Contact Type": '',
            "Contact Status": '',
            "Salutation": '',
            "First Name": '',
            "Name": '',
            "Last Name": '',
            "Mobile Number": '',
            "Phone Number": '',
            "Phone Extension": '',
            "Position": '',
            "Trade Limit": 0.0,
            "Trade Limit Warning or Enforce": null,
            "Daily Trade Limit": 0.0,
            "Daily Trade Limit Warning or Enforce": null,
            "Date of Birth": '',
            "Preferred Name": '',
            "Language": '',
            "Employer Name": '',
            "Main Contact": false,
            "Politically Exposed Person": false,
            "PEP Position": '',
            "Institution Type": '',
            "Appointment Date": '',
            "Authorized Signing Officer": false,
            "ByPass Validation": false,
            "Expiry ByPass Date": '',
            "UpdateToken": '0',

            "Address Type": '',
            "Address Value": '',
            "Address Status": '',
            "Address2": '',
            "City": '',
            "Zip Code": '',
            "State ID": 0,
            "Country ID": '',
            "Other State": '',
            "Default Address": false,
            "Since When": '',
            "District": '',
            "POBox": ''
        };
        this.setState({ newContact: true, updateContact: true, selectedContact: newContact });
        this.props.createContactHandler();
    }

    getCreateContactDone() {
        this.setState({ newContact: false });
    }

    getCreateContact(contactId) {
        console.log(contactId)
        getContactByID(contactId).then(
            (value) => {
                console.log(value)
                var pep = true;
                if(value["Politically Exposed Person"] === null || value["Politically Exposed Person"] === false) { pep = false;}
                this.setState({
                    newContact: false,
                    updateContact: true,
                    contactID: contactId,
                    contactUpdateToken: value.UpdateToken,
                    selectedContact: { ...value, "Politically Exposed Person": pep }
                });
            }
        );
    }

    handleBackLink() {
        this.setState({ updateContact: false });
    }

    clearData() {
        this.setState({
            contacts: [],
        });
    }

    componentDidMount() {
        this.setState({ loading: true }, () => {
            getContactsByCustomer(this.props.data, 1, 100).then(
                (value) => {
                    var helper = [];
                    if (value.contacts !== undefined) {
                        console.log(value);
                        this.setState({ contacts: value.contacts });
                    } else {
                        this.setState({ contacts: helper });
                    }
                    var totalCount = value.totalCount;
                    var pages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
                    console.log(pages);
                    this.setState({ totalPages: pages, newSearch: true, loading: false });
                }
            );
        })
    }

    getContacts(){
        getContactsByCustomer(this.props.data, 1, 100).then(
            (value) => {
                var helper = [];
                if (value.contacts !== undefined) {
                    console.log(value);
                    this.setState({ contacts: value.contacts });
                } else {
                    this.setState({ contacts: helper });
                }
                var totalCount = value.totalCount;
                var pages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
                console.log(pages);
                this.setState({ totalPages: pages, newSearch: true });
            }
        );
    }
    changePage(value) {
        this.setState({ currentPage: value })
    }

    updatePage() {
        getContactsByCustomer(this.props.data, this.state.currentPage, 100).then(
            (json) => {

                /*
                    httpErrorMessage: undefined
                    httpStatusCode: 200
                    totalCount: 0
                */

                if(json.totalCount !== undefined)
                {
                    if(json.totalCount > 0)
                    {
                        this.setState({ contacts: json.contacts });
                        var totalCount = json.totalCount;
                        var pages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
                        // console.log(pages);
                        this.setState({ totalPages: pages, newSearch: false });
                    }else
                    {
                        this.setState({ contacts: [] });
                        var totalCount = json.totalCount;
                        var pages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
                        console.log(pages);
                        this.setState({ totalPages: pages, newSearch: true, loading: false });
                    }
                }
            }
        );
    }
    getAllContacts = async () => {
        var json = await getContactsByCustomer(this.props.data, 0, 0);
        var helper = json.contacts;
        return helper;
    }

    makeNewBeneficiary = () => {
        this.handleAddContactClick();
        this.setState({
            contactID: 0,
            contactUpdateToken: '',
        })
    }

    render() {
        if (this.state.updateContact) {
            return (
                <ContactMaintenanceScreen 
                    newContact              = {this.state.newContact} 
                    backFromCreateContact   = {this.getCreateContactDone.bind(this)}
                    backFromCreation        = {this.getCreateContact.bind(this)}
                    updateFunctionOrigin    = {this.updatePage.bind(this)} 
                    backLink                = {this.handleBackLink.bind(this)} 
                    contactID               = {this.state.contactID}
                    customerID              = {this.props.data} 
                    selectedContact         = {this.state.selectedContact} 
                    contactUpdateToken      = {this.state.contactUpdateToken} 
                    makeNewBeneficiary      = {this.makeNewBeneficiary}
                    customerType            = {this.props.customerType}
                    addBreadcrumb={this.props.addBreadcrumb} 
                removeBreadcrumb ={this.props.removeBreadcrumb}
                hideDetail={this.props.hideDetail}
                />
            )
        }

        return (
            <div>
                <div>
                    <h3 className="uk-heading-divider component-title">
                    Contacts &nbsp;
                    <button className="uk-button uk-button-green" onClick={this.handleAddContactClick} type="button">Add Contact</button>
                    </h3>
                    <NewDynamicTable 
                        key="contact-screen"
                        data={this.state.contacts}
                        enableClick={true}
                        clickFunction={this.handleRowClick.bind(this)}
                        newSearch={this.state.newSearch}
                        useDeleteButton={true}
                        deleteFunction={this.handleDeleteClick.bind(this)}
                        enableSortByHeader={true}
                        usePaginator={true}
                        changePage={this.changePage.bind(this)}
                        numberOfPages={this.state.totalPages}
                        pageNumber={this.state.currentPage}
                        numberPerPage={getDefaultPagingNumberOfItems()}
                        useFilter={true}
                        filterFunction={this.getAllContacts}
                        useExportButton={true}
                        tableName="contacts"
                        hiddenHeaders={['Online Confirmation Email']}
                    />
                </div>
                <AlertBox open={this.state.showDeleteAlert} onClose={this.closeDeleteAlert.bind(this)} title="Warning"
                    message="Are you sure you want to delete this Contact? This action can not be undone." type="Yes/No" yesClick={this.yesDeleteAlert.bind(this)} noClick={this.closeDeleteAlert.bind(this)} />
                <AlertBox open={this.state.showSuccessDeleteAlert} onClose={this.closeDeleteAlert.bind(this)} title='Success' message='Contact successfully removed' type="Ok" okClick={this.okSuccessAlert.bind(this)} />
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert.bind(this)} />
                <LoadingBox loading={this.state.loading} />
            </div>
        );
    }
}