import { Breadcrumb } from 'antd';
import React from 'react';
import {GlobalSystemParametersNames} from '../../../../constants/GlobalSystemParametersNames';
import { validate } from '../../../../helpers/ValidateHelper';
import { saveContact, SendOnlineEmailAccess, ValidatePasswordForOnlineAccessEmail, ValidateSendOnlineAccessEmail, ChangeContactStatus, verifyContact } from '../../../../networking/NetworkingContacts';
import { searchInGlobalWatchLists } from '../../../../networking/NetworkingGlobalWatchListSearch';
import { resetAnswers } from '../../../../networking/NetworkingUsers';
import { requestFacematchVerification } from '../../../../networking/NetworkingLemVerify';
import AlertBox from '../../../shared/AlertBox';
import AttachmentsDialog from '../../../shared/Dialogs/AttachmentsDialog';
import LoadingBox from '../../../shared/LoadingBox';
import AddressForm from '../../addresses/AddressForm';
import AuditContactScreen from '../auditEntity/AuditContactScreen';
import ComplianceScreen from '../updateCustomer/ComplianceScreen';
import AccountInformationForm from './AccountInformationForm';
import ContactInformationForm from './ContactInformationForm';
import ContactPropertiesForm from './ContactPropertiesForm';
import ContactPropertiesScreen from './ContactPropertiesScreen';
import ContactTasksForm from './ContactTasksForm';
import AppContext from '../../../../AppContext';
import { getParametersByName } from '../../../../networking/NetworkingGlobalSystemParameters';


const enableContactImpersonation = (window.REACT_APP_ENABLE_CONTACT_IMPERSONATION) ? window.REACT_APP_ENABLE_CONTACT_IMPERSONATION : false;
const setOnlineContactDefaultPassword = (window.REACT_APP_SET_ONLINE_CONTACT_DEFAULT_PASSWORD_RANDOMLY) ? window.REACT_APP_SET_ONLINE_CONTACT_DEFAULT_PASSWORD_RANDOMLY : false;
const customerName = (window.REACT_APP_CUSTOMER_NAME) ? window.REACT_APP_CUSTOMER_NAME:'';
let globalFunctions = {};
export default class ContactMaintenanceScreen extends React.Component {
    static contextType = AppContext;
    state = {
        contactInfo             : {},
        accountInfo             : {},
        addressInfo             : null,
        isValidatedContact      : true,
        isValidatedAddress      : true,
        isValidatedPEP          : true,
        isValidatedAccount      : true,
        contactInfoErrors       : [],
        addressErrors           : [],
        pepErrors               : [],
        accountInfoErrors       : [],
        saveProperties          : false,
        saveTasks               : false,
        contactID               : 0,
        selectedContact         : null,
        newSearch               : false,
        contactUpdateToken      : '',
        notFirstLoad            : true,
        notFirstLoadAdd         : true,
        notFirstLoadPEP         : true,
        notFirstLoadAcc         : true,
        showAlertError          : false,
        showAlertYesNo          : false,
        showAlertOK             : false,
        showAlertSaveSuspicious: false,
        alertTitle              : '',
        alertMessage            : '',
        viewHistory             : false,
        loading                 : false,
        selectContact: {
            PersonID    : this.props.selectedContact.PersonID,
            CustomerID  : this.props.selectedContact['Contact ID'],
            DealStatus  : null
        },
        clearContact            : false,
        clearAddress            : false,
        clearAccountInformation : false,
        yesNoType               : 0, //1: ResetAnswers, 2: SendOnlineAccessEmail

        //Attachments
        showAttachmentsDialog   : false,
        selectedEntityTaskID      :'',
        toggleReloadGlobalSearch: false,
        fromAudit: false,
        showContactVerificationButton: false
    }

    componentDidMount() {
        globalFunctions = this.context;
        this.setState({ selectedContact: this.props.selectedContact, contactUpdateToken: this.props.contactUpdateToken });
        var contact = this.props.selectedContact;
        var pep = true;
        if(contact["Politically Exposed Person"] === null || contact["Politically Exposed Person"] === false) { pep = false;}
        var stateId = 0;
        if(contact["State ID"] !== undefined && contact["State ID"] !== null) { stateId = contact["State ID"];}
        this.setState({
            contactInfo: {
                contactID: this.props.newContact ? this.props.contactID : contact["Contact ID"],
                contactType: contact["Contact Type"],
                status: contact["Contact Status"],
                salutation: contact["Salutation"],
                firstName: contact["First Name"],
                middleName: contact["Name"],
                lastName: contact["Last Name"],
                phoneNumber: contact["Phone Number"],
                extension: contact["Phone Extension"],
                cellNumber: contact["Mobile Number"],
                position1: contact["Position"],
                tradeLimit: contact["Trade Limit"],
                tLWE: contact["Trade Limit Warning or Enforce"],
                dailyTradeLimit: contact["Daily Trade Limit"],
                dTLWE: contact["Daily Trade Limit Warning or Enforce"],
                dateOfBirth: contact["Date of Birth"],
                preferredName: contact["Preferred Name"],
                language: contact["Language"],
                employerName: contact["Employer Name"],
                isMainContact: contact["Main Contact"],
                PEP: pep,
                pepPosition: contact["PEP Position"],
                institutionType: contact["Institution Type"],
                appointmentDate: contact["Appointment Date"],
                isAuthorized: contact["Authorized Signing Officer"],
                bypassValidation: contact["ByPass Validation"],
                expiryDate: contact["Expiry ByPass Date"],
                updateToken: contact["UpdateToken"]
            },
            addressInfo: {
                AddressType: contact["Address Type"],
                AddressValue: contact["Address Value"],
                Status: contact["Address Status"],
                Address2: contact["Address2"],
                City: contact["City"],
                ZipCode: contact["Zip Code"],
                StateId: stateId,
                CountryId: contact["Country ID"],
                OtherState: contact["Other State"],
                //DefaultAddress: contact["Default Address"],
                DefaultAddress: true,
                SinceWhen: contact["Since When"],
                District: contact["District"],
                POBox: contact["POBox"]
            }
        });
        getParametersByName(GlobalSystemParametersNames.EnableContactVerificationInterface).then(
            (response) => {
                let _showContactVerificationButton = false;
                if (response?.parameters?.length > 0 && response?.parameters[0]?.Value !== '' && response?.parameters[0]?.Value) {
                    _showContactVerificationButton = response?.parameters[0]?.Value?.toLowerCase() === 'true' ?? false;
                }
                this.setState({ showContactVerificationButton: _showContactVerificationButton });
            }
        );
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedContact && prevProps.contactUpdateToken !== this.props.contactUpdateToken) {
            this.setState({ selectedContact: this.props.selectedContact, contactUpdateToken: this.props.contactUpdateToken });
        }

        if (prevProps.hideDetail !== this.props.hideDetail && !this.props.hideDetail) {          
            this.setState({ viewHistory: false});
            if(this.props.removeBreadcrumb)
            {
                this.props.removeBreadcrumb();
            }
        }
    }

    transformContactInfoToSelectedContact(contactInfo, addressInfo) {
        var selectedContact =
        {
            "Contact ID": contactInfo.contactID,
            "Contact Type": contactInfo.contactType,
            "Contact Status": contactInfo.status,
            "Salutation": contactInfo.salutation,
            "First Name": contactInfo.firstName,
            "Name": contactInfo.middleName,
            "Last Name": contactInfo.lastName,
            "Mobile Number": contactInfo.cellNumber,
            "Phone Number": contactInfo.phoneNumber,
            "Phone Extension": contactInfo.extension,
            "Position": contactInfo.position1,
            "Trade Limit": contactInfo.tradeLimit,
            "Trade Limit Warning or Enforce": contactInfo.tLWE,
            "Daily Trade Limit": contactInfo.dailyTradeLimit,
            "Daily Trade Limit Warning or Enforce": contactInfo.dTLWE,
            "Date of Birth": contactInfo.dateOfBirth,
            "Preferred Name": contactInfo.preferredName,
            "Language": contactInfo.language,
            "Employer Name": contactInfo.employerName,
            "Main Contact": contactInfo.isMainContact,
            "Politically Exposed Person": contactInfo.PEP,
            "PEP Position": contactInfo.pepPosition,
            "Institution Type": contactInfo.institutionType,
            "Appointment Date": contactInfo.appointmentDate,
            "Authorized Signing Officer": contactInfo.isAuthorized,
            "ByPass Validation": contactInfo.bypassValidation,
            "Expiry ByPass Date": contactInfo.expiryDate,
            "UpdateToken": contactInfo.updateToken,

            "Address Type": addressInfo.type,
            "Address Value": addressInfo.address1,
            "Address Status": addressInfo.status,
            "Address2": addressInfo.address2,
            "City": addressInfo.city,
            "Zip Code": addressInfo.postalCode,
            "State ID": addressInfo.stateCode,
            "Country ID": addressInfo.countryId,
            "Other State": addressInfo.other,
            "Default Address": addressInfo.defaultAddress,
            "Since When": addressInfo.sinceWhen,
            "District": addressInfo.district,
            "POBox": addressInfo.poBox
        };
        return selectedContact;
    }

    getContactInfoModel(model) {
        this.setState({ contactInfo: model });
    }

    getAddressInfoModel(model) {
        this.setState({ addressInfo: model });
    }

    getAccountInfo(value) {
        this.setState({ accountInfo: value });
    }

    getSaveProperties(value) {
        this.setState({ saveProperties: value });
    }

    getSaveTasks(value) {
        this.setState({ saveTasks: value });
    }

    handleBackLink() {
        this.props.updateFunctionOrigin();
        this.props.backFromCreateContact();
        this.props.backLink();
    }

    handleClearFieldsClick() {
        this.setState({
            clearContact: true,
            clearAddress: true,
            clearAccountInformation: true,
            yesNoType: 0
        }, () => {
            this.setState({
                clearContact: false,
                clearAddress: false,
                clearAccountInformation: false, 
                yesNoType: 0
            });
        });
        this.props.makeNewBeneficiary();
    }

    showAlertAfterPropertiesValidation = () => {
        this.setState({ loading: false });
    }


    handleButtonSaveClick = async () => {
        globalFunctions.startLoading();
        if(this.state.contactInfo.bypassValidation){
            this.saveAllTabs(false)
        } else {
            const globalListsResponse = await searchInGlobalWatchLists(this.state.contactInfo.lastName + ', ' + this.state.contactInfo.firstName, 'Contact', this.state.contactInfo.contactID);
            if (globalListsResponse != null && globalListsResponse.MatchedWords.length === 0 && globalListsResponse.SourceNames.length === 0) {
                this.saveAllTabs(false)
            } else {
                this.setState({ showAlertSaveSuspicious: true, alertTitle: 'Warning', alertMessage: 'Found Contact in Watch Lists. The Contact will be saved with status "Suspicious"' });
            }
        }
        globalFunctions.finishLoading();
    }

    handleViewHistoryClick() {
        this.setState({ viewHistory: true, fromAudit: true });
        if(this.props.addBreadcrumb)
        {
            this.props.addBreadcrumb(<Breadcrumb.Item className="breadcrumb-item-bold">Audit Contact</Breadcrumb.Item>, 'hideContactDetail')
        }
    }

    handleResetAnswersClick(){
        this.setState({ showAlertYesNo: true, alertMessage: 'Are you sure you want to reset this users Challenge Question answers?', alertTitle: 'Confirm', yesNoType: 1});
    }

    internalResetAnswers(){
        var onlineAccess = this.state.accountInfo.onlineAccess != null && this.state.accountInfo.onlineAccess !== '' ? this.state.accountInfo.onlineAccess : false
        if(onlineAccess){
            resetAnswers(this.state.accountInfo.emailAddress).then(
                (json) => {
                    console.log(json);
                    if (json.httpStatusCode !== undefined && json.httpStatusCode !== 200){
                        this.setState({showAlertYesNo: false, showAlertOK: true, alertMessage: json.Message, alertTitle: 'Error', yesNoType: 0}); 
                    }
                    else {
                        this.setState({showAlertYesNo: false, showAlertOK: true, alertMessage: 'Answers have been reset.', alertTitle: 'Success', yesNoType: 0});
                    }
                }
            );
        }
        else{            
            this.setState({showAlertYesNo: false, showAlertOK: true, alertMessage: 'Online Access must be checked.', alertTitle: 'Warning', yesNoType: 0});
        }
    }

    handleSendOAEmailClick(){
        this.setState({yesNoType: 2});
        if(this.state.accountInfo.onlineAccess){
            if(this.state.contactInfo.contactID > 0 && this.state.accountInfo.emailAddress != null && this.state.accountInfo.emailAddress !== ''
                && this.state.accountInfo.password != null && this.state.accountInfo.password !== ''){
                var validateOnlineAccessEmailRequest = {
                    ContactID: this.state.contactInfo.contactID !== '' ? this.state.contactInfo.contactID : 0,
                    ApplicationName: 'ForeignExchange',
                    ContactEmail: this.state.accountInfo.emailAddress != null ? this.state.accountInfo.emailAddress : '',
                    ReportingOnly: false,
                    OnlineAccess: this.state.accountInfo.onlineAccess != null && this.state.accountInfo.onlineAccess !== '' ? this.state.accountInfo.onlineAccess : false,
                    OnlineLanguage: this.state.contactInfo.language,
                    UserIsLock : this.state.accountInfo.previousPassword != null ? this.state.accountInfo.accountLocked : false,
                    NoDaysPasswordExpires: this.state.accountInfo.passwordExpire != null ? this.state.accountInfo.passwordExpire : 0,
                    NoPreviousPwds: this.state.accountInfo.previousPassword != null ? this.state.accountInfo.previousPassword : 0,
                    ResetPassword: this.state.accountInfo.resetPassword != null && this.state.accountInfo.resetPassword !== '' ? this.state.accountInfo.resetPassword : false
                };
                ValidateSendOnlineAccessEmail(validateOnlineAccessEmailRequest).then(
                    (json) => {
                        console.log(json);
                        if (json.httpStatusCode !== undefined && json.httpStatusCode !== 200){
                            this.setState({showAlertYesNo: false, showAlertOK: true, alertMessage: json.Message, alertTitle: 'Error'}); 
                        }
                        else {
                            if(json.result){
                                var validatePasswordForOnlineAccessEmail = {
                                    ApplicationName: 'ForeignExchange',
                                    ContactID: this.state.contactInfo.contactID !== '' ? this.state.contactInfo.contactID : 0,
                                    Password: this.state.accountInfo.password
                                }
                                ValidatePasswordForOnlineAccessEmail(validatePasswordForOnlineAccessEmail).then(
                                    (json) => {
                                        console.log(json);
                                        if (json.httpStatusCode !== undefined && json.httpStatusCode !== 200){
                                            this.setState({showAlertYesNo: false, showAlertOK: true, alertMessage: json.Message, alertTitle: 'Error', yesNoType: 0}); 
                                        }
                                        else {
                                            if(json.result){
                                                this.SendOAEmail();
                                            }
                                            else{
                                                this.setState({showAlertYesNo: true, showAlertOK: false, alertMessage: 'In order to continue, we must reset the customer contact password to its default value. Are you sure you want to reset it?', alertTitle: 'Confirm', yesNoType: 2});
                                            }
                                        }
                                    }
                                )
                            }
                            else{
                                this.setState({showAlertOK: true, alertMessage: 'Some fields do not match with the ones we have in the database. In order to continue you must save this contact and then send the emails.', alertTitle: 'Warning'});
                            }
                        }
                    }
                );
            }
            else {
                this.setState({showAlertOK: true, alertMessage: 'Please make sure the Email and Password fields are not empty.', alertTitle: 'Warning'});
            }
        }
        else {
            this.setState({showAlertOK: true, alertMessage: 'Online Access must be checked.', alertTitle: 'Warning'});
        }
    }

    
    handleSendVerifySMSClick(){
        let contactInfo = this.state.contactInfo;

        let contactID = contactInfo.contactID;
        let phoneNumber = contactInfo.cellNumber;
        if(phoneNumber !== undefined && phoneNumber !== ''){
            var model = {
                contactID: contactID,
                contactPersonID: null,
                movileNumber: phoneNumber
            }
            requestFacematchVerification(model).then((json) => {
                switch (Number(json.httpStatusCode)) {
                    case 200:
                        this.setState({
                            showAlertOK: true,
                            alertTitle: 'Success',
                            alertMessage: 'Send SMS'
                        });
                        break;
                    default:
                        var alertMessage = 'Error Send Verify SMS'
                        this.setState({
                            showAlertError: true,
                            alertMessage: alertMessage,
                            alertTitle: 'Error'
                        });
                        break;
                }
            });
        }
        else{
            var alertMessage = 'Required Cell Number'
                        this.setState({
                            showAlertError: true,
                            alertMessage: alertMessage,
                            alertTitle: 'Error'
                        });
        }
    }

    handleVerifyContact = async () => {
        let _contactVerificationInterface = '';
        const response = await getParametersByName(GlobalSystemParametersNames.ContactVerificationInterface);
        if (response?.parameters?.length > 0 && response?.parameters[0]?.Value !== '') {
            _contactVerificationInterface = response?.parameters[0]?.Value;
        }
        const contactID = this.state.contactInfo.contactID;
        const cellNumber = this.state.contactInfo.cellNumber;

        if (_contactVerificationInterface != null && _contactVerificationInterface !== '') {
            if (contactID != null && contactID !== 0) {
                if (cellNumber != null && cellNumber !== '') {
                    const model = {
                        'contactID': contactID,
                        'phoneNumber': cellNumber,
                        'typeVerification': _contactVerificationInterface
                    }
                    const json = await verifyContact(model);
                    if (json.httpStatusCode === 200) {
                        this.setState({
                            showAlertOK: true,
                            alertTitle: 'Success',
                            alertMessage: 'An SMS was sent to your cell number to continue your validation.'
                        })
                    } else {
                        this.setState({
                            showAlertError: true,
                            alertTitle: 'Error',
                            alertMessage: 'There was an error when validating your contact information.\n' + (json?.Message ?? '')
                        });
                    }
                } else {
                    this.setState({
                        showAlertError: true,
                        alertTitle: 'Error',
                        alertMessage: 'A cell number is required to verify your contact information.'
                    });
                }
            } else {
                this.setState({
                    showAlertError: true,
                    alertMessage: 'Please select a valid contact.',
                    alertTitle: 'Error'
                });
            }
        } else {
            this.setState({
                showAlertError: true,
                alertMessage: 'Please set up a valid Contact Verification Interface.',
                alertTitle: 'Error'
            });
        }
    }

    SendOAEmail(){
        var setDefaultPassword = false;
        if (setOnlineContactDefaultPassword) { setDefaultPassword = true }
        var installationID = 'Default';
        if (customerName !== undefined) { installationID = customerName }

        var sendOnlineEmailAccessRequest = {
            InstallationID: installationID,
            ResetPwd: true,
            Password: this.state.accountInfo.password,
            SetOnlineContactDefaultPasswordRandomly: setDefaultPassword,
            ApplicationName: 'ForeignExchange',
            ContactID: this.state.contactInfo.contactID !== '' ? this.state.contactInfo.contactID : 0
        }
        SendOnlineEmailAccess(sendOnlineEmailAccessRequest).then(
            (json) => {
                console.log(json);
                if (json !== undefined && json.error === ""){
                    this.setState({showAlertYesNo: false, showAlertOK: true, alertMessage: "Email sent", alertTitle: 'Success'}); 
                }
                else {
                    this.setState({showAlertYesNo: false, showAlertOK: true, alertMessage: 'Email sent.', alertTitle: 'Success', yesNoType: 0});
                }
            }
        )
    }

    transformListRoles(list) {
        var helper = [];
        for (var i = 0; i < list.length; i++) {
            helper.push(list[i].RoleName);
        }
        return helper;
    }

    saveAllTabs = async (isSuspicious) => {
        var date = new Date();
        let userID = localStorage.getItem('UserID');
        var roles = this.transformListRoles(this.state.accountInfo.listWant);
        var isContactImpersonation = false;
        if (enableContactImpersonation === "true") { isContactImpersonation = true }

        let contactUserID = this.props.selectedContact.PersonID
        //for new contact the updates does not have the value yet
        if ((this.props.selectedContact.PersonID === undefined || this.props.selectedContact.PersonID === null)
            && this.state.contactInfo.contactPersonID !== undefined && this.state.contactInfo.contactPersonID !== null) {
            // the next line from the assigment
            // const updatedContactInfo = { ...this.state.contactInfo, updateToken: json.updateToken, contactID: json.contactId, contactPersonID: json.contactPersonID };
            contactUserID = this.state.contactInfo.contactPersonID
        }
       

        var model = {
            contactRequest: {
                ContactID: this.state.contactInfo.contactID !== '' ? this.state.contactInfo.contactID : 0,
                CustomerID: this.props.customerID,
                MainContact: this.state.contactInfo.isMainContact,
                Position: this.state.contactInfo.position1,
                TradeLimit: this.state.contactInfo.tradeLimit,
                DailyTradeLimit: this.state.contactInfo.dailyTradeLimit,
                PersonID: contactUserID !== null ? contactUserID : null, //this.props.selectedContact.PersonID != null ? this.props.selectedContact.PersonID : null,
                BirthdayDate: this.state.contactInfo.dateOfBirth,
                PoliticallyExposedPerson: this.state.contactInfo.PEP,
                AuthorizedSigningOfficer: this.state.contactInfo.isAuthorized != null ? this.state.contactInfo.isAuthorized : false,
                PreferredName: this.state.contactInfo.preferredName,
                CreatedBy: userID,
                //CreatedOn: date.toISOString(),
                ContactType: this.state.contactInfo.contactType,
                OnlineLanguage: this.state.contactInfo.language,
                PEPPosition: this.state.contactInfo.pepPosition,
                EmployerName: this.state.contactInfo.employerName,
                AppointmentDate: this.state.contactInfo.appointmentDate,
                InstitutionType: this.state.contactInfo.institutionType,
                Status: isSuspicious ? 'Suspicious' : this.state.contactInfo.status,
                ExpiryByPassDate: this.state.contactInfo.expiryDate,
                ByPassValidation: this.state.contactInfo.bypassValidation,
                TradeLimitWarningOrEnforce: this.state.contactInfo.tLWE,
                DailyTradeLimitWarningOrEnforce: this.state.contactInfo.dTLWE,
                UpdateToken: this.state.contactInfo.updateToken,
                Name: this.state.contactInfo.lastName + ', ' + this.state.contactInfo.firstName,
                FirstName: this.state.contactInfo.firstName,
                LastName: this.state.contactInfo.lastName,
                Salutation: this.state.contactInfo.salutation,
                MobilePhone: this.state.contactInfo.cellNumber,
                PhoneExt: this.state.contactInfo.extension,
                PhoneNumber: this.state.contactInfo.phoneNumber,
                //Account Information
                OnlineAccess: this.state.accountInfo.onlineAccess != null && this.state.accountInfo.onlineAccess !== '' ? this.state.accountInfo.onlineAccess : false,
                Admin: false,
                AllowForwards: false,
                Email: this.state.accountInfo.emailAddress != null ? this.state.accountInfo.emailAddress : '',
                OneClickBuyAllowed: false,
                OnlineConfirmationEmail: this.state.accountInfo.onlineConfirmEmail !== null ? this.state.accountInfo.onlineConfirmEmail : '',
                ShowChallengeQuestions: this.state.accountInfo.showChallenge != null && this.state.accountInfo.showChallenge !== '' ? this.state.accountInfo.showChallenge : false,
                UserCanApprove: false,
                MaxOpenForwardDays: this.state.accountInfo.forwardDays != null ? this.state.accountInfo.forwardDays : 0,
                ReportingOnly: false,
                OnlineQuoteOnly: false
            },
            addressRequest: {
                AddressType: this.state.addressInfo.AddressType,
                AddressValue: this.state.addressInfo.AddressValue,
                CountryID: this.state.addressInfo.CountryId,
                StateID: this.state.addressInfo.StateId,
                City: this.state.addressInfo.City != null ? this.state.addressInfo.City : '',
                Address2: this.state.addressInfo.Address2,
                ZipCode: this.state.addressInfo.ZipCode != null ? this.state.addressInfo.ZipCode : '',
                OtherState: this.state.addressInfo.OtherState != null ? this.state.addressInfo.OtherState : '',
                DefaultAddress: this.state.addressInfo.DefaultAddress,
                SinceWhen: this.state.addressInfo.SinceWhen != null ? this.state.addressInfo.SinceWhen : '',
                Status: this.state.addressInfo.Status != null ? this.state.addressInfo.Status : '',
                District: this.state.addressInfo.District != null ? this.state.addressInfo.District : '',
                POBox: this.state.addressInfo.POBox != null ? this.state.addressInfo.POBox : '',
                UpdatedBy: userID,
                UpdateToken: this.state.contactInfo.updateToken
            },
            roles,
            contactUser: {
                ApplicationName: "ForeignExchange",
                UserID: contactUserID,//this.props.selectedContact.PersonID,
                UserName: this.state.accountInfo.emailAddress != null ? this.state.accountInfo.emailAddress : '',
                MobileName: "",
                Email: this.state.accountInfo.emailAddress,
                resetPassword: this.state.accountInfo.resetPassword != null && this.state.accountInfo.resetPassword !== '' ? this.state.accountInfo.resetPassword : false,
                noDaysPasswordExpires: this.state.accountInfo.passwordExpire != null ? this.state.accountInfo.passwordExpire : 0,
                noPreviousPwds: this.state.accountInfo.previousPassword != null ? this.state.accountInfo.previousPassword : 0,
                Islock: this.state.accountInfo.accountLocked != null ? this.state.accountInfo.accountLocked : false,
                UserCommissionPercentage: 10.0,
                CommissionOverride: false,
                CountryID: this.state.addressInfo.CountryId,
                BusinessUnitID: 1,
                ReportsToUserID: null,
                FullName: this.state.contactInfo.middleName,
                ShowLocked: null,
                FirstName: this.state.contactInfo.firstName,
                LastName: this.state.contactInfo.lastName,
                PhoneNumber: this.state.contactInfo.phoneNumber,
                TellerBranchID: this.props.selectedContact['Branch ID'] !== undefined ? this.props.selectedContact['Branch ID'] : null,
                DealsLimit: 1.0,
                RequireApproval: false,
                AllowPreviousDayVoid: false,
                CommissionPercentage: 1.0,
                AllowSameDayVoid: false,
                AllowSingleDealRoll: false,
                AllowTradeUpdates: false,
                RestrictCustomerAccess: false,
                CreatedBy: userID,
                //CreatedOn: date.toISOString(),
                UpdatedBy: userID,
                //UpdatedOn: date.toISOString()
            },
            contactClaim: {
                Questions: null,
                Password: this.state.accountInfo.password,
                NewPassword: this.state.accountInfo.confirmPassword,
                SecurityToken: "4e95799e-7e1f-4f0e-9ad3-d8fa12f1e4f4"
            },
            isContactImpersonation: isContactImpersonation,
            relatedCustomerId: this.state.accountInfo.relatedCustomerId
        };
        if (this.state.isValidatedContact && this.state.isValidatedAddress && this.state.isValidatedAccount) {
            /*if (this.state.accountInfo.emailAddress !== '' && this.state.accountInfo.onlineConfirmEmail !== '') {
                if(this.state.accountInfo.password !== '' && this.state.accountInfo.confirmPassword !== ''){
                    if (this.state.accountInfo.doublePassword && this.state.accountInfo.doubleEmail) {
                        if (this.state.accountInfo.isEmailValidated && this.state.accountInfo.isConfirmEmailValidated) {
                            this.doSaveContact(model);
                        } else {
                            this.setState({ showAlertError: true, alertMessage: 'Your email is incorrect. Please try again.', alertTitle: 'Error' });
                        }
                    } else {
                        this.setState({ showAlertError: true, alertMessage: 'Your email or password do not match. Please try again.', alertTitle: 'Error' });
    
                    }                   
                }else{
                    this.setState({ showAlertError: true, alertMessage: 'You need to provide a password. Please try again.', alertTitle: 'Error' });
                }                
            } else {
                if(this.state.accountInfo.onlineAccess){
                    this.setState({ showAlertError: true, alertMessage: 'Need to register e-mail and password to have Online Access.', alertTitle: 'Error' });
                }else{
                    this.doSaveContact(model);
                }
            }*/
            await this.doSaveContact(model);
        } else {
            this.setState({
                showAlertError: true,
                alertMessage: 'Please fill all mandatory fields',
                alertTitle: 'Error',
                notFirstLoad: false,
                notFirstLoadAdd: false,
                notFirstLoadAcc: false
            });
        }
    }

    doSaveContact = (model) => {
        var save = true;
        if(model.contactRequest.PoliticallyExposedPerson){
            if(model.contactRequest.PEPPosition === undefined || model.contactRequest.PEPPosition === ''
                || model.contactRequest.EmployerName === undefined || model.contactRequest.EmployerName === ''
                || model.contactRequest.AppointmentDate === undefined || model.contactRequest.AppointmentDate === null || model.contactRequest.AppointmentDate === ''
                || model.contactRequest.InstitutionType === undefined || model.contactRequest.InstitutionType === ''){
                    save = false;
            }
        }
        /*this.isValidatedPEPInfo(this.validateRequiredInputPEP(model.contactRequest.EmployerName, model.contactRequest.PEPPosition, 
                            model.contactRequest.InstitutionType, model.contactRequest.AppointmentDate));*/
        var tempContactId = this.state.contactInfo.contactID;
        if(save){
        /*if(this.isValidatedPEPInfo(this.validateRequiredInputPEP(model.contactRequest.EmployerName, model.contactRequest.PEPPosition, 
            model.contactRequest.InstitutionType, model.contactRequest.AppointmentDate))){*/
            this.setState({ loading: true });
            saveContact(model).then((json) => {
                if (json.httpStatusCode !== undefined && json.httpStatusCode !== 200 && json.httpStatusCode !== 409 && json.httpStatusCode !== 300) {
                    this.setState({ showAlertError: true, alertMessage: 'An error occurred while trying to save the contact. Please try again.', alertTitle: 'Error', loading: false });
                } else if (json.httpStatusCode !== undefined && (json.httpStatusCode === 409 || json.httpStatusCode === 300)){
                    this.setState({ showAlertError: true, alertMessage: json.httpErrorMessage, alertTitle: 'Error', loading: false });
                }
                else {
                    let newContact = true;
                    if(model.contactRequest.ContactID !== 0){
                        newContact = false;
                    }
                    const updatedContactInfo = { ...this.state.contactInfo, updateToken: json.updateToken, contactID: json.contactId, contactPersonID: json.contactPersonID  };
                    const newSelectedContact = this.transformContactInfoToSelectedContact(updatedContactInfo, this.state.addressInfo);
                    if(newContact){
                        this.setState({
                            showAlertOK: true, alertTitle: 'Success', alertMessage: 'Contact successfully saved.',
                            contactInfo: updatedContactInfo,
                            contactUpdateToken: json.updateToken,
                            selectedContact: { ...newSelectedContact, "UpdateToken": json.updateToken },
                            loading: false
                        });
                    } else {
                        this.setState({
                            showAlertOK: true, alertTitle: 'Success', alertMessage: 'Contact successfully saved.',
                            contactInfo: updatedContactInfo,
                            contactUpdateToken: json.updateToken,
                            selectedContact: { ...newSelectedContact, "UpdateToken": json.updateToken }
                        }, () => this.setState({ saveProperties: !this.props.newContact, saveTasks: !this.props.newContact }));
                    }
                    debugger;
                    if(tempContactId == 0 && !this.state.contactInfo.bypassValidation){
                        if(this.state.contactInfo.expiryDate <  localStorage.getItem(GlobalSystemParametersNames.SystemDate)){
                            this.changeContactStatus(json.contactId);
                        }                        
                    }
                    this.props.backFromCreation(json.contactId);
                }
            });
        } else{      
            this.isValidatedPEPInfo(this.validateRequiredInputPEP(model.contactRequest.EmployerName, model.contactRequest.PEPPosition, 
                model.contactRequest.InstitutionType, model.contactRequest.AppointmentDate));      
            this.setState({
                showAlertError: true,
                alertMessage: 'Please fill all mandatory fields for a Politically Exposed Person on Employer Information section.',
                alertTitle: 'Error',
                notFirstLoadPEP: false
            });
        }
    }

    changeContactStatus= async (contactId) => {
        const globalListsResponse = await searchInGlobalWatchLists(this.state.contactInfo.lastName + ', ' + this.state.contactInfo.firstName, 'Contact', contactId)
        if (globalListsResponse != null && globalListsResponse.MatchedWords.length === 0 && globalListsResponse.SourceNames.length === 0) {
            
        } else {        
            var model = { 'ContactId' : contactId, 'Status': 'Suspicious' }
            ChangeContactStatus(model).then((json) => {
                debugger;
                if (json == undefined || (json.httpStatusCode !== undefined && json.httpStatusCode !== 200)) {
                    this.setState({ showAlertOK: true, alertMessage: 'An error occurred while trying to save the contact. Please try again.', alertTitle: 'Error', loading: false });
                } else {
                    
                }
            });
            //this.setState({ showAlertSaveSuspicious: true, alertTitle: 'Warning', alertMessage: 'Found Contact in Watch Lists. The Contact will be saved with status "Suspicious"' });
        }
    }
    
    validateRequiredInputPEP(employerName, pepPosition, institutionType, appointmentDate){ 
        const fields = [
            { displayName: 'Employer Name', validateName: 'employerName', value: employerName, type: 'string' },
            { displayName: 'Position', validateName: 'pepPosition', value: pepPosition, type: 'string' },
            { displayName: 'Institution Type', validateName: 'institutionType', value: institutionType, type: 'string' },
            { displayName: 'Appointment Date', validateName: 'appointmentDate', value: appointmentDate, type: 'string' }
        ]

        const [valid, validations, errors] = validate(fields);

        return { valid, errors };
    }

    isValidatedContactInfo = (validator) => {
        if (validator.valid) {
            this.setState({
                isValidatedContact: true,
                notFirstLoad: true,
                contactInfoErrors: []
            });
        } else {
            this.setState({
                isValidatedContact: false,
                contactInfoErrors: { tab: "Contact Information", errors: validator.errors }
            });
        }
    }

    isValidatedAddressInfo = (validator) => {
        if (validator.valid) {
            this.setState({
                isValidatedAddress: true,
                notFirstLoadAdd: true,
                addressErrors: []
            });
        } else {
            this.setState({
                isValidatedAddress: false,
                addressErrors: { tab: "Address", errors: validator.errors }
            });
        }
    }
    
    isValidatedPEPInfo = (validator) => {
        if (validator.valid) {
            this.setState({
                isValidatedPEP: true,
                notFirstLoadPEP: true,
                pepErrors: []
            });
        } else {
            this.setState({
                isValidatedPEP: false,
                pepErrors: { tab: "Contact Information / Compliance", errors: validator.errors }
            });
        }
    }

    isValidatedAccountInfo = (validator) => {
        if (validator.valid) {
            this.setState({
                isValidatedAccount: true,
                notFirstLoadAcc: true,
                accountInfoErrors: []
            });
        } else {
            this.setState({
                isValidatedAccount: false,
                accountInfoErrors: { tab: "Account Information", errors: validator.errors }
            });
        }
    }

    closeAlertError() {
        this.setState({ showAlertError: false, notFirstLoad: true, notFirstLoadAdd: true, notFirstLoadPEP: true, notFirstLoadAcc: true });
    }

    closeAlertOK()
    {
        this.setState({ showAlertOK: false});
    }

    closeAlertYesNo()
    {
        this.setState({ showAlertYesNo: false, yesNoType: 0});
    }

    closeAlertSaveSuspicious = () => {
        this.setState({ showAlertSaveSuspicious: false, contactInfo: { ...this.state.contactInfo, status: 'Suspicious' } });
        this.saveAllTabs(true);
    }

    yesAlertYesNo()
    {
        if(this.state.yesNoType === 1)
        {
            this.internalResetAnswers();
        } else if(this.state.yesNoType === 2)
        {
            this.SendOAEmail();
        }
    }

    handleAttachmentsClick()
    {
        this.setState({
            showAttachmentsDialog: true
        });  
    }

    onCloseAttachmentsDialog = ()=>
    {
        this.setState({
            showAttachmentsDialog: false,
        })
    }

    activateTab = (tab) => this.setState({ activatePersonIdentifications:false,
        [tab]: true,selectedEntityTaskID:'' });

    handleActiveTab = (tab) => {
        
        return this.state[tab] ? 'uk-active': '';
    }

    refreshContact = async () => {
        this.setState({ toggleReloadGlobalSearch: !this.state.toggleReloadGlobalSearch });
    };

    updateFromAuditProp = () =>{
        this.setState({fromAudit: false});
    }

    render() {
        var maintenanceScreen = (
            <div>
                <div>
                    {/*<h3 className="uk-heading-divider component-title" >
                        <button className="uk-button uk-button-text uk-margin-right min-width-0" onClick={this.handleBackLink.bind(this)}>
                            <i className="fa fa-arrow-left back-arrow" ></i>
                        </button>
                        {this.props.newContact ? 'Create Contact' : `Contact - ${this.props.selectedContact.Name} - ${this.props.contactID}`}
                    </h3>*/}
                    <ul uk-tab="">
                        <li>
                            <a href="#" id="contact-information">Contact Information</a>
                        </li>
                        <li>
                            <a href="#" id="address-information">Address Information</a>
                        </li>
                        <li style={{ display: this.props.newContact ? "none" : "" }}>
                            <a href="#" id="account-information">Account Information</a>
                        </li>
                        <li style={{ display: this.props.newContact ? "none" : "" }}>
                            <a href="#" id="contact-properties">Properties</a>
                        </li>
                        <li style={{ display: this.props.newContact ? "none" : "" }}>
                            <a href="#" id="compliance">Compliance</a>
                        </li>
                        <li style={{ display: this.props.newContact ? "none" : "" }}>
                            <a href="#" id="contact-tasks">Contact Tasks</a>
                        </li>
                        {/* <li style={{ display: this.props.newContact ? "none" : "" }}>
                            <a href="#" id="extended-properties">Extended Properties</a>
                        </li> */}
                    </ul>
                    <ul className="uk-switcher uk-margin">
                        <li>
                            <ContactInformationForm isValid={this.isValidatedContactInfo.bind(this)} getContactInfo={this.getContactInfoModel.bind(this)} customerID={this.props.customerID} selectedContact={this.state.selectedContact} contactUpdateToken={this.state.contactUpdateToken} notFirstLoad={this.state.notFirstLoad} clearFields={this.state.clearContact} refreshContact={this.refreshContact} fromAudit={this.state.fromAudit} updateFromAudit={this.updateFromAuditProp} />
                        </li>
                        <li>
                            <AddressForm selectedAddress={this.state.addressInfo} getAddressInfo={this.getAddressInfoModel.bind(this)} isValid={this.isValidatedAddressInfo.bind(this)} validations={this.state.notFirstLoadAdd} clearFields={this.state.clearAddress} isCustomer={false} />
                            {/* <AddressInformationForm getAddressInfo={this.getAddressInfoModel.bind(this)} selectedContact={this.state.selectedContact} contactUpdateToken={this.state.contactUpdateToken}/> */}
                        </li>
                        <li style={{ display: this.props.newContact ? "none" : "" }}>
                            <AccountInformationForm isValid={this.isValidatedAccountInfo.bind(this)} selectedContact={this.state.selectedContact} customerId={this.props.customerID} getAccountInfo={this.getAccountInfo.bind(this)} clearFields={this.state.clearAccountInformation} validations={this.state.notFirstLoadAcc} />
                        </li>
                        <li style={{ display: this.props.newContact ? "none" : "" }}>
                            <ContactPropertiesScreen contactID={this.props.contactID} personID={this.props.selectedContact.PersonID} customerType={this.props.customerType}>
                                <ContactPropertiesForm getSaveProperties={this.getSaveProperties.bind(this)} contactID={this.props.contactID} saveProperties={this.state.saveProperties} showFinalAlert={this.showAlertAfterPropertiesValidation} />
                            </ContactPropertiesScreen>
                        </li>
                        <li style={{ display: this.props.newContact ? "none" : "" }}>
                            <ComplianceScreen customer={this.state.selectContact} fatherScreen={'Contact'} reloadGlobalSearch={this.state.toggleReloadGlobalSearch} />
                        </li>
                        <li style={{ display: this.props.newContact ? "none" : "" }}>
                            <ContactTasksForm getSaveTasks={this.getSaveTasks.bind(this)} contactID={this.props.contactID} personID={this.state.selectContact.PersonID} saveTasks={this.state.saveTasks} />
                        </li>
                        {/* <li style={{ display: this.props.newContact ? "none" : "" }}>
                            <ExtendedOnlinePropertiesScreen entityID={this.props.contactID} entityType="Contact" />
                        </li> */}
                    </ul>
                </div>
                <div>
                    {/*<button className="uk-button uk-button-green uk-margin-right" onClick={this.handleClearFieldsClick.bind(this)} type="button">Clear Fields</button>*/}
                    <button className="uk-button uk-button-green uk-margin-right" onClick={this.handleButtonSaveClick} type="button">Save</button>
                    <button style={{ display: this.props.newContact ? "none" : "" }} className="uk-button uk-button-green uk-margin-right" onClick={this.handleViewHistoryClick.bind(this)} type="button">View History</button>
                    <button style={{ display: this.props.newContact ? "none" : "" }} className="uk-button uk-button-green uk-margin-right" onClick={this.handleAttachmentsClick.bind(this)} type="button">Attachments</button>
                    {/*<button className="uk-button uk-button-green uk-margin-right" onClick={this.handleExitClick.bind(this)} type="button">Exit</button>*/}
                    <button style={{ display: this.props.newContact ? "none" : "" }} className="uk-button uk-button-green uk-margin-right" onClick={this.handleResetAnswersClick.bind(this)} type="button">Reset Answers</button>
                    <button style={{ display: this.props.newContact ? "none" : "" }} className="uk-button uk-button-green uk-margin-right" onClick={this.handleSendOAEmailClick.bind(this)} type="button">Send Online Access Email</button>
                    <button style={{ display: this.props.newContact ? "none" : "" }} className="uk-button uk-button-green uk-margin-right" onClick={this.handleSendVerifySMSClick.bind(this)} type="button">Send Verify SMS</button>
                    {this.state.showContactVerificationButton && <button style={{ display: this.props.newContact ? "none" : "" }} className="uk-button uk-button-green uk-margin-right" onClick={this.handleVerifyContact} type="button">Verify Contact</button>}
                </div>
                <div>
                </div>
                <AlertBox
                    open={this.state.showAlertError}
                    onClose={this.closeAlertError.bind(this)}
                    title={this.state.alertTitle}
                    message={this.state.alertMessage}
                    messageBoldList={[this.state.contactInfoErrors, this.state.addressErrors, this.state.pepErrors, this.state.accountInfoErrors]}
                    type="Ok" okClick={this.closeAlertError.bind(this)}
                />
                <AlertBox
                    open={this.state.showAlertSaveSuspicious}
                    onClose={this.closeAlertSaveSuspicious}
                    title={this.state.alertTitle}
                    message={this.state.alertMessage} type="Ok" okClick={this.closeAlertSaveSuspicious} />
                <AlertBox
                    open={this.state.showAlertOK}
                    onClose={this.closeAlertOK.bind(this)}
                    title={this.state.alertTitle}
                    message={this.state.alertMessage}
                    type="Ok"
                    okClick={this.closeAlertOK.bind(this)}
                />
                <AlertBox
                    id="yes-no-alert"
                    open={this.state.showAlertYesNo}
                    onClose={this.closeAlertYesNo.bind(this)}
                    title={this.state.alertTitle}
                    message={this.state.alertMessage}
                    type="Yes/No"
                    yesClick={this.yesAlertYesNo.bind(this)}
                    noClick={this.closeAlertYesNo.bind(this)} />
                <AttachmentsDialog
                    open={this.state.showAttachmentsDialog}
                    onClose={this.onCloseAttachmentsDialog}
                    entityID={this.props.contactID}
                    entityType='Contact'
                />
                <LoadingBox loading={this.state.loading} />
            </div>
        )
        if (this.state.viewHistory) {
            maintenanceScreen = (
                <div>
                    <AuditContactScreen isTitle={true} contactID={this.props.contactID} />
                </div >
            )
        }
        return (
            <div>
                {maintenanceScreen}
            </div>
        );
    }
}