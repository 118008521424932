import React from 'react';
import { getStyle} from '../../../styles/styles';
import DatePicker from '../../shared/DatePicker';
import NumberInput from '../../shared/NumberInput';
import AlertBox from '../../shared/AlertBox';
import LoadingBox from '../../shared/LoadingBox';
import {getNostroBalances, UpdateApiBalances, saveActualEndingBalance, SaveNostroBalances, getNostroBankAccounts} from '../../../networking/NetworkingBankAccounts'
import { formatDate} from '../../../helpers/FormatHelper';
import { getLookUpTable } from '../../../networking/Networking';
import DynamicSelect from '../../shared/DynamicSelect';

var style = getStyle();

export default class NostroBalancesScreen extends React.Component {

    state = {
        date: new Date(),
        endDate: new Date(),
        balances:[],
        balancesToSave:[],
        loading: false,
        showAlert: false,
        showSaveAlert: false,
        alertTitle:'',
        alertMessage:'',
        banks:[],
        bankID:0,
        nostroAcountID:''
    }

    componentDidMount()
    {        
        getNostroBankAccounts(0).then(
            (json) => {
                if (json != null && json.banks != null) {
                    let banks = json.banks;
                    banks.forEach(element => {
                        element.value = element.BankID;
                        element.name = element.BankName;
                    });
                    this.setState({ banks: banks }, ()=>{
                        if(banks.length > 0)
                        {
                            this.setState({ bank: banks[0].BankID }, ()=>{
                                this.onSearch();
                            });

                        }
                    });
                }
            }
        )
    }

    handleUpdateValueDate = (date) =>
    {
        this.setState({ date: date });
    }

    onSearch = () =>
    {
        this.setState({ loading: true });
        var date = formatDate(this.state.date);
        getNostroBalances(date, this.state.bankID).then((response) => {
            
            if(response != null)
            {
            this.setState({balances: response.balances,balancesToSave:[],
             loading: false});
            }
            else
            {
                this.setState({balances: [], balancesToSave:[],
                    loading: false});
            }
          });
    }

    handleBalance(data, i, event) 
    {
        //alert(JSON.stringify(data));
        let tempToSave     = this.state.balancesToSave;
        let newBalance     = event.target.value;
        var balanceChanged  = this.state.balances.filter(item => item['BankAccountID'] === data.recordID)[0]
        var valid           = true;
        //let auxValdiation   = this.state.validations.inputAmountList;

        /*if (amountToUse < 0) {
            this.setState({ showAlert: true, alertMessage: 'Amount to Use should be a positive decimal', alertTitle: 'Error' });
            paymentChanged['AmountToUse'] = 0;
            valid = false;
        }
*/
        //remove if exist
        if (tempToSave.length > 0) {
            tempToSave = tempToSave.filter(item => item.BankAccountId !== data.recordID)
        }

        if (valid) 
        {
            tempToSave.push({
                    BankAccountId: data.recordID,
                    BalanceDate: data.date,
                    AccountBalance: newBalance
                });
            
            balanceChanged['AccountBalance']   = newBalance;
            //auxValdiation[i]                = true;
        }
        //this.getCustomerFundOnHoldBalanceByCurrencyJson(this.state.customerID, this.state.currencyID);


        this.setState({
            balancesToSave: tempToSave,
            //validations: {...this.state.validations, inputAmountList: auxValdiation}
        });
        
    }

    yesSave = () => 
    {
        this.setState({loading: true, showSaveAlert:false});
        let userID = localStorage.getItem('UserID');
        let balances = this.state.balances;

        var model = {
            balances: balances,
            UserId: userID
        };
        SaveNostroBalances(model).then((json) => {
            if (json.httpStatusCode !== 200) {       
                let alertMessage = 'An error occurred while trying to apply the payments. Please try again.';
                if(json.errorMessage !== undefined && json.errorMessage=='concurrency'){                    
                    alertMessage = 'Please reload the information and try again. Otherwise contact your supervisor';
                }
                this.setState({
                    showAlert: true,
                    alertMessage: alertMessage,
                    alertTitle: 'Error',
                    loading:false
                });
            }
            else
            {
                this.setState({
                    showAlert: true,
                    alertMessage: 'The balances have been saved.',
                    alertTitle: 'Success',
                    loading:false
                });
            }                             
        });
    }

    closeAlert = () =>
    {
        this.setState({showAlert:false});
    }

    onSave = () =>
    {
        this.setState({showSaveAlert:true});
    }

    noSave = () =>
    {
        this.setState({showSaveAlert:false});
    }

    /*
    onReflesh = () =>
    {
        let nostroAcountID = this.state.nostroAcountID;
        let date = formatDate(this.state.date);
        let userId = localStorage.getItem('UserID');
        if(nostroAcountID!==undefined && nostroAcountID!==''){
            let model_UpdateApiBalances={
                bankAccountID:nostroAcountID,
                balanceDate:date,
                userID:userId
            };
            UpdateApiBalances(model_UpdateApiBalances).then((json) => {
                if (json.httpStatusCode == 200) {
                    this.setState({ loading: true });
                    getNostroBalances(date).then((response) => {                    
                        if(response != null)
                        {
                            this.setState({balances: response.balances,
                            loading: false});
                        }
                    });                
                }else{
                    let errorMessage='';
                    if(json.errorMessage!==undefined && json.errorMessage!==''){
                        errorMessage=json.errorMessage;
                    }else{
                        errorMessage=json.interfaceErrorMessage;
                    }
                    this.setState({
                        showAlert: true,
                        alertMessage: errorMessage,
                        alertTitle: 'Error',
                        loading:false
                    });
                }
            });
        }
    }
*/
    handleBanks = (obj) => {
        if (obj.value) {            
            this.setState({ bankID : obj.value});
        }
    }

    render(){
         //new dynamic table with editable cell
         var headers;
         var rows = [];
 
         headers = (
             <tr style={{textAlignLast: 'center'}}>

                 <th >Currency</th>
                 <th >Account Number</th>
                 <th >Actual Balance</th>
             </tr>
         );

         var index = 0;
        if (this.state.balances !== undefined) 
        {
            this.state.balances.forEach((element,i) => {
                index++;
                var balanceControl;

                balanceControl = (
                    <NumberInput id={'balance-' + index}
                        type        = "Currency"
                        negative={true}
                        value       = {element['AccountBalance']}
                        //className   = "uk-input"
                        className= 'uk-input' //{this.state.validations.inputAmountList[i] ? 'uk-input' : ' uk-input uk-form-danger'}
                        onChange    = {this.handleBalance.bind(this,
                            {
                                recordID: element['BankAccountID'],
                                date: element['BalanceDate']
                            }, i)}
                    />
                );

                rows.push(
                    <tr key={index}  >
                        <td style={{textAlign: 'center'}}>{element['AccountCurrencyID']}</td>
                        <td style={{textAlign: 'center'}}>{element['BankAccount']}</td>                        
                        <td style={{textAlign: 'center'}}>
                            {balanceControl}
                        </td> 
                    </tr>
                );
            });
        }

        return(<div>
            <h3 className="uk-heading-divider" style={style.titleStyle}>Nostro Balances</h3>
            <div className="uk-form-stacked">
                <div className='uk-margin uk-grid'>
                    <div className="uk-width-1-4">
                        <label className="uk-form-label" htmlFor="form-deal-FromDate">Date</label>
                                <DatePicker id="dp-value-date"
                                className= 'uk-input'
                                    onDayChange={this.handleUpdateValueDate}
                                    endDate={this.state.endDate}
                                    value={this.state.date} 
                                    modeCustomArturo={true}/>
                    </div>
                    <div className="uk-width-1-4">
                        <label className="uk-form-label">Banks:</label>
                        <DynamicSelect  id="select-banks" data={this.state.banks} 
                            objValue={{value: this.state.bankID}} getValue={this.handleBanks} />
                    </div>
                    <div className="uk-width-1-4"></div>
                    <div className="uk-width-1-4"></div>
                </div>
                <div className='uk-margin uk-grid'>
                    <div className="uk-width-1-1">
                        <button className="uk-button uk-button-green uk-margin-right" onClick={this.onSearch}>Refresh</button>
                        <button className="uk-button uk-button-green uk-margin-right" onClick={this.onSave}>Save</button>
                    </div>
                </div>
            </div>
            <div className='uk-grid'>
                <div className="uk-width-1-1 border-table">
                    <div style={{width:'100%', alignItems:'center', display:'flex', flexDirection:'column'}}>
                        <table className="uk-table uk-table-striped uk-table-hover uk-table-divider" style={{width:'50%'}}>
                            <thead>
                                {headers}
                            </thead>
                            <tbody>
                                {rows}
                            </tbody>
                        </table>
                        </div>
                </div>
            </div>
            <AlertBox id="global-Alert"
                    type="Ok"
                    open={this.state.showAlert}
                    title={this.state.alertTitle}
                    message={this.state.alertMessage}
                    okClick={this.closeAlert}
                    onClose={this.closeAlert} />

                <AlertBox id="savee-alert"
                    type="Yes/No"
                    open={this.state.showSaveAlert}
                    title="Confirmation"
                    message="Are you sure you want to save this balances?"
                    yesClick={this.yesSave}
                    noClick={this.noSave} />

                <LoadingBox loading={this.state.loading} />
        </div>);
    }
}