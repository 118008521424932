import Badge from '@material-ui/core/Badge';
import InfoIcon from '@material-ui/icons/Info';
import React from 'react';
import { CustomerType, OutgoingFundType, BeneficiaryType } from '../../constants/EntityTypeNames';
import { formatDate, formatDateToDDMMYYYY, formatToCurrency, formatToCurrencyWithScale, formatToMT103, getScaleByCurrency } from '../../helpers/FormatHelper';
import { getLookUpTable, getLookUpTableByFilter } from '../../networking/Networking';
import { getNostroBankAccountsForFunds } from '../../networking/NetworkingBankAccounts';
import { getBeneficiariesByID, getBeneficiaryForDisbursementsScreen, validateCountryRiskScore } from '../../networking/NetworkingBeneficiaries';
import { getCustomerByID, getRiskScoreRanges } from '../../networking/NetworkingCustomers';
import { getDealHeaderInfo, sendOutgoingFundReportByEmail, validateEntityForReferral } from '../../networking/NetworkingDeals';
import { getEntityProperties } from '../../networking/NetworkingEntityProperties';
import { deleteWorkAssignments, updateProcessStatus } from '../../networking/NetworkingCompliance';
import { getProcessStatusesByRole, getStatusesForEntity, getUsersByRole } from '../../networking/NetworkingLookUpTables';
import {
    getAmountInBaseCurrency, getBalanceDueByDealID, updateOutgoingFundStatus,
    getOutgoingFundsRiskScoreDetails, getOutgoingInformationByID, getPurposeOfPayments, rejectOutgoingFund, saveOutgoingToBankInterface,
    swiftCreateCustomerCreditTransfer, swiftPaymentVerification, swiftGetOutgoingPaymentStatus, updateOutgoingFund, updateRejectedFeeAmount,
    getApayloBalance
} from '../../networking/NetworkingFunds';
import { getInstrumentsByCustomer } from '../../networking/NetworkingInstruments';
import { getStyle, notFilledInputClass, notFilledSelectClass, regularInputClass, regularSelectClass } from '../../styles/styles';
import OutgoingFundsConfirmation from '../outgoingFunds/OutgoingFundsConfirmation';
import AuditOutgoingFundScreen from '../pre_trade/customers/auditEntity/AuditOutgoingFundScreen';
import RiskCalculationDialog from '../pre_trade/customers/riskCalculation/RiskCalculationDialog';
import AlertBox from '../shared/AlertBox';
import DatePicker from '../shared/DatePicker';
import RejectedFeeAmountDialog from '../shared/Dialogs/RejectedFeeAmountDialog';
import DynamicSelect from '../shared/DynamicSelect';
import EntityProperties from '../shared/EntityProperties';
import LoadingBox from '../shared/LoadingBox';
import NumberInput from '../shared/NumberInput';
import OutgoingFundVersionsScreen from './OutgoingFundVersionsScreen';
var style = getStyle();
const deliveryMethodBranch = 'At the branch';

const DocumentTypes = {
    WIRETRANSFER:'WireTransfer',
    CONFIRMATION: 'Confirmation'
}
export default class OutgoingFundsForm extends React.Component {
    state={
        outgoing: {
            OutgoingFundsID: 0,
            DealHeaderID: 0,
            CustomerID: 0,
            BeneficiaryBankAccountID: 0,
            BeneficiaryBankAccountName: '',
            BeneficiaryID: 0,
            BeneficiaryName: '',
            NostroAccountID: 0,
            NostroAccountName: '',
            PaymentInstrument: '',
            PaymentAmount: 0,
            DeliveryRefNo: '',
            PaymentReference: '',
            Status: '',
            VersionNo: 0,
            BeneficiaryType: 0,
            Comments: '',
            ValueDate: '',
            DeliveryMethod: '',
            WireItemFee: 0,
            WireItemFeeCurrencyID: '',
            DetailsOfPayment: '',
            CurrencyID: '',
            ContractBalance: '',
            CreatedBy: '',
            UpdateToken: '',
            OutgoingFundExt: null,
            IsRefund: false,
            InterfaceSource:'',
            InterfaceID:'',
            PaymentPurposeCode:'',
            AssignTo: '',
            ProcessStatus: ''
        },
        beneAccounts:{
            BeneficiaryID: 0,
            BankAccountID: 0,
            Name: '',
            AccountCurrencyID: '',
            ReceiveLimit: 0
        },
        beneficiaryAccountList: [],
        validations: {
            beneficiaryaccount: true,
            instrumenttodeliver: true,
            deliverymethod: true,
            valuedate: true,
            paymentamount: true,
            status: true,
            nostroaccount: true
        },
        dropdowns: {
            "Payment Delivery Method": [],
            "Outgoing Fund Status": [],
            "Outgoing Instruments": [],
            nostroBankAccounts: [],
            bankAccounts: [],
        },
        lookUpTables: [
            "Payment Delivery Method",
            "Outgoing Fund Status",
            "Outgoing Instruments",
            "BankInterfaces"
        ],
        ProcessStatuses: [],
        Users: [],
        listDeliveryMethods: [],
        paymentBankInterfaces: [],
        accountCcyID: '',
        alertMessage: '',
        alertTitle: '',
        amountInBaseCurrency: 0,
        balanceDue: 0,
        beneAccountID: 0,
        beneAccountName: '',
        beneID: 0,
        beneName: '',
        chosenWireAlertMessage: '',
        customerID: 0,
        dealId: 0,
        dealHeaderInfo: {},
        dealLookUpTables: [],
        dealValueDate: '',
        deliveryMethod: '',
        deliveryMethodList: {},
        disable: true,
        disableControls: false,
        disableStatus: false,
        entityPropertiesSaveProcess: false,
        exec: false,
        isFirstLoad: true,
        outgoingInstrumentList: [],
        tbaOutgoingInstrumentList: [],
        allOutgoingInstrumentList: [],
        riskScoreRanges: [],
        riskScoreDetails: [],
        outgoingRiskScore: {},        
        receiveLimit: 0,
        sellCcy: '',
        showAlert: false,
        showExceedsLimitAlert: false,
        showChosenWireAlert: false,
        showOutgoingConfirmation: false,
        showOutgoingEmailLog: false,
        showReleasePaymentAlert: false,
        showSendingFundsAlert: false,
        showRiskCalculationDetails: false,
        showRejectOutgoing: false,
        previewStatus: '',
        loading: false,
        loadingNostros: false,
        readOnly: false,
        startDate: new Date(),
        externalPaymentInterface: '',
        //Refunds        
        fromRefunds: false,
        refundPersonID:'',
        refundBranchID: 0,
        forceLoad: false,
        bankInterfacesDetails:[],
        //Reject Outgoing
        showRejectOutgoingAlert: false,
        showRejectFeeScreen: false,
        rejectOutgoingMessage: '',
        reloadScreen: false,
        rejectedFee: 0,
        showAlertYesNo: false,
        createNewOutgoingMessage: '',
        purposeOfPayments: [],
        purposeOfPayment_value: '',
        entityPropertiesBlanks : 0,
        entityPropertiesBlanksWarning : 0,
        showPropertiesAlert: false,
        showPropertiesAlertWarning: false,

        fontWeight                          : 'bolder', //riskscore
        backgroundColorRiskScore            : '#1d2b1d',

        systemDate: '',
        canEditStatus: true,
        //13 Jan 2022
        beneficiaryHighRiskMessage: '',
        fundsForReferralMessage: '',
        showBeneficiaryHighRiskAlert: false,
        showFundsForReferralAlert: false,
        validateViolation: true,
        saveOutgoingToBankInterface: false,
        loadedProperties: false,
        saveInComplete: false,
        //
        referalFromCompliance: false,
        showStatusConfirmation: false,
        messageStatusConfirmation: '',
        showConfirm : false,
        releaseOfFunds: false,

        preferredLanguage: 'English',
        sendToBene: 'no',
        email:'',
        showConfirmationSendCustomerEmail: false,
        showConfirmationSendBeneEmail: false,
        saveSuccess: false,
        checkForBene: false,
        enableCompliance: false,
        disableAssignToButton: true,

        actualBalance: 0,
        availableBalance: 0,
        actualBalanceLabel: 'Actual balance: ',
        availableBalanceLabel: 'Available balance: ',
        showBalances: false,
        openVersions: false
    }

    componentDidMount = async () => {
        let systemDate = localStorage.getItem('SystemDate');
        let screenPermisions = JSON.parse(localStorage.getItem('ScreenShowPermissions'));
  
        var enableProcessStatus = false;

        if(screenPermisions != undefined && screenPermisions.ReleaseOfFunds != undefined){
            this.setState({releaseOfFunds: screenPermisions.ReleaseOfFunds})
        }

        var outgoingID = this.props.id;
        var customerID = this.props.customerID;
        var sellCcy = this.props.sellCcy;
        var dealId = this.props.dealId;
        let readOnly = this.props.readOnly != null ? this.props.readOnly : false;

        var startDate = new Date(systemDate);//this.state.startDate;

        var backgroundColorRiskScore =  localStorage.getItem('BackgroundColorRiskScore');
        if(backgroundColorRiskScore != null && backgroundColorRiskScore != '')
        {
            this.setState({backgroundColorRiskScore: backgroundColorRiskScore});
        }

        startDate.setDate(startDate.getDate() -31);

        this.setState({loading: true,
            customerID: customerID, 
            dealId: dealId,
            sellCcy: sellCcy,
            readOnly : readOnly,
            startDate: startDate,
            systemDate: systemDate
        });
        if(sellCcy != null && sellCcy !== ''){
            this.setState({loadingNostros: true, outgoing: {...this.state.outgoing, CurrencyID: sellCcy}});       
            await getNostroBankAccountsForFunds(sellCcy, this.props.customerID, false).then(
                (json) => {
                    if(json != undefined && json.nostroBankAccounts != undefined)
                    {
                    for (var i = 0; i < json.nostroBankAccounts.length; i++){
                        json.nostroBankAccounts[i].name = json.nostroBankAccounts[i].BankName;
                        json.nostroBankAccounts[i].value = json.nostroBankAccounts[i].BankAccountID;
                    }
                    //Set default nostro account
                    let defaultNostroAccount = json.nostroBankAccounts.find((account) => { return Number(account.BankID) === Number(this.props.defaultOutgoingNostroBankID); });
                    var nostroAccountID = 0;
                    if(defaultNostroAccount !== undefined){
                        nostroAccountID = defaultNostroAccount.BankAccountID;
                    }
                    this.setState({loadingNostros: false, 
                        dropdowns: {...this.state.dropdowns, nostroBankAccounts: json.nostroBankAccounts},
                        outgoing: {...this.state.outgoing, NostroAccountID: nostroAccountID}
                    });
                }
                else
                {  
                    this.setState({loadingNostros: false,
                        alertTitle: 'Warning',
                        alertMessage:"Nostro Account doesn't exist for the currency",
                        showAlert: true });
                }
                }
            )

            if(customerID != null && customerID > 0){              
                this.setState({outgoing: {...this.state.outgoing, CustomerID: customerID}})   
                this.loadInstrument(customerID);  
                getBeneficiaryForDisbursementsScreen(customerID, sellCcy).then(
                    (json) => {
                        var helper = [];
                        let beneficiaries = json.beneficiaries;
                        if(beneficiaries !== undefined){
                            var tbaRecord = {value:'-2|0', name:'TBA', BeneficiaryID:-2, BeneficiaryBankAccountID:0};
                            helper.push(tbaRecord);
        
                            //var customerRecord = {value:'-1|0', name:this.props.objCustomer['Customer Name'], BeneficiaryID:-1, BeneficiaryBankAccountID:0}
                            //helper.push(customerRecord);
        
                            for(var i=0; i<beneficiaries.length; i++){
                                beneficiaries[i].value = beneficiaries[i].Value;
                                beneficiaries[i].name = beneficiaries[i].Description;
                                helper.push(beneficiaries[i]);
                            }
                        }

                        this.setState({beneficiaryAccountList: helper});
                    }
                );
            }
        }
        getProcessStatusesByRole().then(
            (json) => {
                if (json !== undefined) {
                    if (json.processStatuses !== undefined) {
                        if (json.processStatuses.length > 0) {
                            enableProcessStatus = true;
                        }
                    }
                }
                if (enableProcessStatus === true) {
                    for (var i = 0; i < json.processStatuses.length; i++) {
                        json.processStatuses[i].value = json.processStatuses[i].LookUpTableDetail;
                        json.processStatuses[i].name = json.processStatuses[i].LookUpTableDetail;
                    }
                    this.setState({ ProcessStatuses: json.processStatuses, enableCompliance: enableProcessStatus });
                }
            });

        //Load outgoing fund
        let outgoingValueDate = '';
        if(outgoingID != null && outgoingID > 0){
            getOutgoingInformationByID(outgoingID).then(
                (value) => {
                    if(this.state.fromRefunds)
                    {
                        value.DeliveryMethod = deliveryMethodBranch;
                    }
                    outgoingValueDate = value.ValueDate
                    var externalPaymentInterface = '';
                    var paymentBankInterfaces = this.state.bankInterfacesDetails.filter(x => x.Field1 === 'Payments' || x.DisplayOrder === -1) || [];
                    if(value.CurrencyID !== 'CAD'){
                        paymentBankInterfaces = paymentBankInterfaces.filter(x => x.LookUpTableDetail !== 'Apaylo - Payments')
                    }
                    if(value.InterfaceSource != undefined && value.InterfaceSource != ''
                    && value.InterfaceID != undefined && value.InterfaceID != '')
                    {
                        paymentBankInterfaces = this.state.bankInterfacesDetails;
                        if(value.CurrencyID !== 'CAD'){
                            paymentBankInterfaces = paymentBankInterfaces.filter(x => x.LookUpTableDetail !== 'Apaylo - Payments')
                        }
                        externalPaymentInterface = value.InterfaceSource;
                    }
                    let disableControls = false;
                    if(value.Status === 'Completed' || value.Status === 'Void' || value.Status === 'Rejected'){
                        disableControls = true;
                    } else {
                        disableControls = false;                         
                    }
                    if(value.Status === 'Void' || value.Status === 'Rejected' || value.Status === 'Completed'){
                        if(value.Status === 'Completed' /*&& value.ValueDate >= this.state.systemDate*/)
                        {
                            this.setState({disableStatus: false});
                        }
                        else
                        {
                            this.setState({disableStatus: true});
                        }
                    } else {
                        this.setState({disableStatus: false});
                    }

                    console.log("externalPaymentInterface 1")
                    console.log(externalPaymentInterface);
                    this.setState({disableControls: disableControls, outgoing: value, previewStatus: value.Status,
                        paymentBankInterfaces: paymentBankInterfaces}, 
                         () => {this.setState({externalPaymentInterface: externalPaymentInterface});
                        
                         getAmountInBaseCurrency(this.state.outgoing.DealHeaderID, this.state.outgoing.PaymentAmount).then(
                            (getamountInBaseCcy) => 
                            {
                                this.setState({amountInBaseCurrency: getamountInBaseCcy.response});
                            }
                        )
                    });
                }
            );
            this.loadRiskDetails()
        }
        

        if(this.props.fromRefunds !== undefined)
        {
            this.setState({fromRefunds: this.props.fromRefunds,
                refundBranchID: this.props.refundBranchID,
                refundPersonID: this.props.refundPersonID});
        }

        if(this.props.dealId != null && this.props.dealId > 0)
        {
            //alert(this.props.dealId);
            this.setState({dealId: this.props.dealId});
            getDealHeaderInfo(this.props.dealId).then(
                (json) => {
                    for(var i= 0; i < json.dealHeaderInfo.length; i++){
                        console.log(json);
                        this.setState({dealValueDate: json.dealHeaderInfo[i].ValueDate, dealHeaderInfo: json.dealHeaderInfo[0], dealLookUpTables: json.lookUpTables})
                        this.setState({outgoing: {...this.state.outgoing, WireItemFeeCurrencyID: json.dealHeaderInfo[i].BCur, CurrencyID: sellCcy, DealHeaderID: this.props.dealId, CustomerID: customerID}})
                    }
                }
            );

            getAmountInBaseCurrency(this.props.dealId, this.state.outgoing.PaymentAmount).then(
                (getamountInBaseCcy) => 
                {
                    this.setState({amountInBaseCurrency: getamountInBaseCcy.response});
                }
            )
            
        }
        
        getLookUpTable(this.state.lookUpTables.toString()).then(
            (json) => {
                for (var i = 0; i < this.state.lookUpTables.length; i++) {
                    let index = json.lookUpTables.map(({LookUpTableDescription}) => LookUpTableDescription).indexOf(this.state.lookUpTables[i]);
                    if (index > -1){
                        var table = json.lookUpTables[index].lookUpTableDetails;
                        for (var j = 0; j < table.length; j++) {
                            table[j].value = table[j].LookUpTableDetail;
                            table[j].name = table[j].LookUpTableDetail;
                        }
                        if (json.lookUpTables[index].LookUpTableDescription === 'Outgoing Fund Status') {
                            if(enableProcessStatus){
                                let tempTable = [];
                                let tempProcessStatuses = table;
                                let processStatuses = [];
                                getStatusesForEntity('OutgoingFund', '', this.state.outgoing.OutgoingFundsID, 0, '', this.state.outgoing.Status).then(
                                    (json) => {
                                        if (json !== undefined && json.complianceFlow !== undefined && json.complianceFlow.length > 0) {
                                            for (let i = 0; i < json.complianceFlow.length; i++) {
                                                json.complianceFlow[i].value = json.complianceFlow[i].LookUpTableDetail;
                                                json.complianceFlow[i].name = json.complianceFlow[i].LookUpTableDetail;
                                            }
                                            processStatuses = json.complianceFlow;
                                            if (processStatuses !== undefined && processStatuses.length > 0) {
                                                processStatuses.forEach((LookUpTableDetail) => {
                                                    for (let k = 0; k < tempProcessStatuses.length; k++) {
                                                        if (tempProcessStatuses[k].LookUpTableDetail === LookUpTableDetail.LookUpTableDetail) {
                                                            tempTable.push(tempProcessStatuses[k]);
                                                        }
                                                    }
                                                });
                                                this.setState({ [this.state.lookUpTables[0]]: tempTable });
                                            }
                                        } else {
                                            this.setState({ [this.state.lookUpTables[0]]: tempProcessStatuses });
                                        }
                                    }
                                );
                            }
                            if (this.state.outgoing != null && this.state.outgoing.Status !== 'Rejected') {
                                //table = table.filter((t) => t.value != 'Rejected');
                                if(this.state.outgoing.Status === 'Completed' /*&& outgoingValueDate >= this.state.systemDate*/){
                                    table = table.filter((t) => t.LookUpTableDetailTranslationLabel === 'Void' || t.LookUpTableDetailTranslationLabel === 'Completed' || t.DisplayOrder === -1 || t.LookUpTableDetailTranslationLabel === 'Rejected');
                                } else if(this.state.outgoing.Status === 'Sent to Partner' )
                                { 
                                    table = table.filter((t) => t.LookUpTableDetailTranslationLabel === 'Void'  || t.LookUpTableDetailTranslationLabel === 'Completed' || t.LookUpTableDetailTranslationLabel === 'Sent to Partner' || t.DisplayOrder === -1);
                                } else {
                                    table = table.filter((t) => t.value !== 'Rejected' && t.value !== 'Sent to Partner');
                                }
                                this.setState({ loading: false, dropdowns: {...this.state.dropdowns, [this.state.lookUpTables[i]]: table}});
                            }                                                       
                        }
                        else
                        {
                            this.setState({ loading: false, dropdowns: {...this.state.dropdowns, [this.state.lookUpTables[i]]: table}});
                        }

                        
                    }
                }
                                
                let bankInterfaces = json.lookUpTables.filter(x => x.LookUpTableDescription === 'BankInterfaces')[0] || {}
                let bankInterfacesDetails = bankInterfaces.lookUpTableDetails || []
                let paymentBankInterfaces = bankInterfacesDetails.filter(x => x.Field1 === 'Payments' || x.DisplayOrder === -1) || []        
                if(this.state.sellCcy !== 'CAD'){
                    paymentBankInterfaces = paymentBankInterfaces.filter(x => x.LookUpTableDetail !== 'Apaylo - Payments')
                }
                this.setState({ paymentBankInterfaces: paymentBankInterfaces,
                    bankInterfacesDetails: bankInterfacesDetails })        

            }
            
        );

    }

    yesExceedsLimitAlert = () => {
        var hasEntered = false;
        if(this.state.outgoing.Status === 'Completed' && this.state.balanceDue > 0){
            this.setState({showSendingFundsAlert: true});
            hasEntered = true;
        }
        if(this.state.dealValueDate > this.state.outgoing.ValueDate){
            this.setState({showReleasePaymentAlert: true});
            hasEntered = true;
        }

        if(!hasEntered){
            this.saveOutgoing();
        }
    }

    closeExceedsLimitAlert = () =>{
        this.setState({showExceedsLimitAlert: false, exec: false});
    }
    
    yesSendingFundsAlert = () => {
        var hasEntered = false;
           
            if(this.state.dealValueDate > this.state.outgoing.ValueDate){
                this.setState({showReleasePaymentAlert: true});
                hasEntered = true;
            }

        if(!hasEntered){
            this.saveOutgoing();
        }
    }

    closeSendingFundsAlert = () =>{
        this.setState({showSendingFundsAlert: false, showExceedsLimitAlert: false, exec: false});
    }
    
    yesReleasePaymentAlert = () => {
            this.saveOutgoing();
    }

    closeReleasePaymentAlert = () =>{
        this.setState({showReleasePaymentAlert: false, showSendingFundsAlert: false, showExceedsLimitAlert: false, exec: false});
    }
    
    yesChosenWireAlert = () => {
        this.updateOutgoingIntern();
    }

    closeChosenWireAlert = () =>{
        this.setState({showChosenWireAlert: false, showReleasePaymentAlert: false, showSendingFundsAlert: false, showExceedsLimitAlert: false, exec: false});
    }
    
    yesRejectOutgoingAlert = () => {
        this.setState({showRejectFeeScreen: true});
    }

    closeRejectOutgoingAlert = () =>{
        this.setState({showRejectOutgoingAlert: false});
    }
    
    closeAlertYesNo(){
        this.setState({showAlertYesNo: false});
        this.rejectOutgoing(true);
    }

    yesAlertYesNo(){
        this.setState({showAlertYesNo: false});
        this.rejectOutgoing(false);
    }

    componentDidUpdate(prevProps, prevState){
        var customerID = 0;
        var sellCcy = '';
        var buyccy = '';
        var dealId = 0;
        if(prevProps.sellCcy !== this.props.sellCcy)
        {
            sellCcy = this.props.sellCcy;
            this.setState({sellCcy: this.props.sellCcy, saveInComplete:false, outgoing: {...this.state.outgoing, CurrencyID: sellCcy}})
            //this.setState({outgoing: {...this.state.outgoing, CurrencyID: sellCcy}});       
            
            if(sellCcy != null && sellCcy !== ''){    
                this.setState({loadingNostros: true});
                getNostroBankAccountsForFunds(sellCcy, this.props.customerID, false).then(
                    (json) => {
                        var nostroAccounts = [];
                        if (json.nostroBankAccounts != null) {
                            for (var i = 0; i < json.nostroBankAccounts.length; i++){
                                json.nostroBankAccounts[i].name = json.nostroBankAccounts[i].BankName;
                                json.nostroBankAccounts[i].value = json.nostroBankAccounts[i].BankAccountID;
                            }
                            nostroAccounts = json.nostroBankAccounts;
                        }
                        this.setState({loadingNostros: false, dropdowns: {...this.state.dropdowns, nostroBankAccounts: nostroAccounts}});
                    }
                )
            }
        }
        if(prevProps.fromRefunds !== this.props.fromRefunds){
            this.setState({fromRefunds: this.props.fromRefunds, saveInComplete: false,
                refundPersonID: this.props.refundPersonID,
                refundBranchID: this.props.refundBranchID});  
        }
        if(prevProps.dealId !== this.props.dealId)
        {       
            dealId = this.props.dealId;
            this.setState({dealId: this.props.dealId, saveInComplete: false});	
            getDealHeaderInfo(this.props.dealId).then(
                (json) => {
                    for(var i= 0; i < json.dealHeaderInfo.length; i++){
                        this.setState({dealValueDate: json.dealHeaderInfo[i].ValueDate, dealHeaderInfo: json.dealHeaderInfo[0], dealLookUpTables: json.lookUpTables});
                        buyccy = json.dealHeaderInfo[i].BCur;
                        this.setState({
                            outgoing: {...this.state.outgoing, WireItemFeeCurrencyID: buyccy, CurrencyID: json.dealHeaderInfo[i].SCur, DealHeaderID: dealId}
                        });
                    }
                }
            );

            getAmountInBaseCurrency(this.props.dealId, this.state.outgoing.PaymentAmount).then(
                (getamountInBaseCcy) => 
                {
                    this.setState({amountInBaseCurrency: getamountInBaseCcy.response});
                }
            )
        }
        if(prevProps.customerID !== this.props.customerID)
        {
            customerID = this.props.customerID;
            this.setState({customerID: this.props.customerID,saveInComplete: false})		
            this.setState({
                outgoing: {...this.state.outgoing, CustomerID: customerID}
            });
            /*getBeneAccountByCustomerID(customerID, sellCcy).then(
                (json) => {
                    this.setState({beneAccounts: json.bankAccounts});
                    for(var i = 0; i < json.bankAccounts.length; i++){
                        json.bankAccounts[i].name = json.bankAccounts[i].Name;
                        if(json.bankAccounts[i].BankAccountID > 0){
                            json.bankAccounts[i].value = json.bankAccounts[i].BankAccountID;
                        } else{                        
                            json.bankAccounts[i].value = json.bankAccounts[i].BeneficiaryID;
                        }
                    }
                    this.setState({dropdowns: {...this.state.dropdowns, bankAccounts: json.bankAccounts}});
                }
            )*/             
            this.loadInstrument(customerID);  
            getBeneficiaryForDisbursementsScreen(customerID, sellCcy).then(
                (json) => {
                    var helper = [];
                    let beneficiaries = json.beneficiaries;
                    if(beneficiaries !== undefined){
                        var tbaRecord = {value:'-2|0', name:'TBA', BeneficiaryID:-2, BeneficiaryBankAccountID:0};
                        helper.push(tbaRecord);
    
                        //var customerRecord = {value:'-1|0', name:this.props.objCustomer['Customer Name'], BeneficiaryID:-1, BeneficiaryBankAccountID:0}
                        //helper.push(customerRecord);
    
                        for(var i=0; i<beneficiaries.length; i++){
                            beneficiaries[i].value = beneficiaries[i].Value;
                            beneficiaries[i].name = beneficiaries[i].Description;
                            helper.push(beneficiaries[i]);
                        }
                    }

                    this.setState({beneficiaryAccountList: helper});
                }
            );
        }

        if(prevProps.id !== this.props.id || (this.props.id && this.state.reloadScreen))
        {
            var outgoingID = this.props.id;
            let outgoingValueDate = '';
            if(outgoingID != null && outgoingID > 0){
                this.setState({loading: true, entityPropertiesBlanks:0, entityPropertiesBlanksWarning: 0, reloadScreen: false
                    , outgoing: {...this.state.outgoing, OutgoingFundsID: outgoingID}});                
                getOutgoingInformationByID(outgoingID).then(
                    (value) => {
                        outgoingValueDate = value.ValueDate
                        this.setState({previewStatus: value.Status})
                        getAmountInBaseCurrency(value.DealHeaderID, value.PaymentAmount).then(
                            (getamountInBaseCcy) => 
                            {
                                this.setState({amountInBaseCurrency: getamountInBaseCcy.response});
                            }
                        )
                        //alert(JSON.stringify(value));
                        if(this.state.fromRefunds)
                        {
                            value.DeliveryMethod = deliveryMethodBranch;
                        }
                        var externalPaymentInterface = '';
                        var paymentBankInterfaces = this.state.bankInterfacesDetails.filter(x => x.Field1 === 'Payments' || x.DisplayOrder === -1) || [];
                        console.log("paymentBankInterfaces 2")
                        console.log(this.state.bankInterfacesDetails);
                        if(value.CurrencyID !== 'CAD'){
                            paymentBankInterfaces = paymentBankInterfaces.filter(x => x.LookUpTableDetail !== 'Apaylo - Payments')
                        }
                        console.log(paymentBankInterfaces);

                    if(value.InterfaceSource != undefined && value.InterfaceSource != ''
                    && value.InterfaceID != undefined && value.InterfaceID != '')
                    {
                        paymentBankInterfaces = this.state.bankInterfacesDetails;
                        if(value.CurrencyID !== 'CAD'){
                            paymentBankInterfaces = paymentBankInterfaces.filter(x => x.LookUpTableDetail !== 'Apaylo - Payments')
                        }
                        externalPaymentInterface = value.InterfaceSource;
                    }
                        this.setState({
                            outgoing     : value,
                            previewStatus: value.Status,
                            paymentBankInterfaces: paymentBankInterfaces,                       
                            validations: {
                                beneficiaryaccount: true,
                                instrumenttodeliver: true,
                                deliverymethod: true,
                                valuedate: true,
                                paymentamount: true,
                                status: true,
                                nostroaccount: true
                            }
                        },
                            () => {
                                let instrumentObject = this.state.dropdowns["Outgoing Instruments"].filter(x => x.name === this.state.outgoing.PaymentInstrument)[0];
                                this.loadLookUpTableByFilter(instrumentObject);
                                this.setState({externalPaymentInterface: externalPaymentInterface});                                
                        });

                        if(value.Status === 'Completed' || value.Status === 'Void' || value.Status === 'Rejected'){
                            this.setState({disableControls: true});
                        } else {
                            this.setState({disableControls: false});                            
                        }
                        if(value.Status === 'Void' || value.Status === 'Rejected' || value.Status === 'Completed'){
                            if(value.Status === 'Completed' /*&& value.ValueDate >= this.state.systemDate*/)
                            {
                                this.setState({disableStatus: false});
                            }
                            else
                            {
                                this.setState({disableStatus: true});
                            }
                        } else {
                            this.setState({disableStatus: false});
                        }
                        for(var i = 0; i < this.state.beneAccounts.length; i++){
                            if(this.state.beneAccounts[i].BankAccountID > 0){
                                this.setState({beneAccountID: this.state.beneAccounts[i].BankAccountID, beneAccountName: this.state.beneAccounts[i].Name, beneID: this.state.beneAccounts[i].BeneficiaryID});
                            } else{                        
                                this.setState({beneAccountID: this.state.beneAccounts[i].BeneficiaryID, beneAccountName: this.state.beneAccounts[i].Name});
                            }
                        }

                        if (this.props.onUpdateStatus != null) {
                            this.props.onUpdateStatus(value.Status)
                        }

                        getLookUpTable(this.state.lookUpTables.toString()).then(
                            (json) => {
                                for (let i = 0; i < this.state.lookUpTables.length; i++) {
                                    let index = json.lookUpTables.map(({LookUpTableDescription}) => LookUpTableDescription).indexOf(this.state.lookUpTables[i]);
                                    if (index > -1){
                                        var table = json.lookUpTables[index].lookUpTableDetails;
                                        for (var j = 0; j < table.length; j++) {
                                            table[j].value = table[j].LookUpTableDetail;
                                            table[j].name = table[j].LookUpTableDetail;
                                        }
                                        if (json.lookUpTables[index].LookUpTableDescription === 'Outgoing Fund Status') {

                                            if (this.state.outgoing != null && this.state.outgoing.Status !== 'Rejected') {
                                                //table = table.filter((t) => t.value != 'Rejected');
                                                if(this.state.outgoing.Status === 'Completed' /*&& outgoingValueDate >= this.state.systemDate*/){
                                                    table = table.filter((t) => t.LookUpTableDetailTranslationLabel === 'Void' || t.LookUpTableDetailTranslationLabel === 'Completed' || t.DisplayOrder === -1 || t.LookUpTableDetailTranslationLabel === 'Rejected');
                                                } else if(this.state.outgoing.Status === 'Sent to Partner' )
                                                { 
                                                    table = table.filter((t) => t.LookUpTableDetailTranslationLabel === 'Void' || t.LookUpTableDetailTranslationLabel === 'Completed' || t.LookUpTableDetailTranslationLabel === 'Sent to Partner' || t.DisplayOrder === -1);
                                                } else {
                                                    table = table.filter((t) => t.value !== 'Rejected' && t.value !== 'Sent to Partner');
                                                }
                                                this.setState({ loading: false, dropdowns: {...this.state.dropdowns, [this.state.lookUpTables[i]]: table}});
                                            }
                                        }
                                        else
                                        {
                                        this.setState({ dropdowns: {...this.state.dropdowns, [this.state.lookUpTables[i]]: table}});
                                        }
                                    }
                                }
                        }
                        )
                    }                    
                );
                this.loadRiskDetails();
                
                this.setState({disable: false, loading: false});
                  
            }
            else{
                this.setState({outgoing: {...this.state.outgoing, UpdateToken: '637269570688200000'}, saveInComplete: false})    
                getLookUpTable(this.state.lookUpTables.toString()).then(
                    (json) => {
                        for (let i = 0; i < this.state.lookUpTables.length; i++) {
                            let index = json.lookUpTables.map(({LookUpTableDescription}) => LookUpTableDescription).indexOf(this.state.lookUpTables[i]);
                            if (index > -1){
                                var table = json.lookUpTables[index].lookUpTableDetails;
                                for (var j = 0; j < table.length; j++) {
                                    table[j].value = table[j].LookUpTableDetail;
                                    table[j].name = table[j].LookUpTableDetail;
                                }
                                if (json.lookUpTables[index].LookUpTableDescription === 'Outgoing Fund Status') {
                                    if (this.state.outgoing != null && this.state.outgoing.Status !== 'Rejected') {
                                        //table = table.filter((t) => t.value !== 'Rejected');
                                        if(this.state.outgoing.Status === 'Completed' /*&& outgoingValueDate >= this.state.systemDate*/){
                                            table = table.filter((t) => t.LookUpTableDetailTranslationLabel === 'Void' || t.LookUpTableDetailTranslationLabel === 'Completed' || t.DisplayOrder === -1 || t.LookUpTableDetailTranslationLabel === 'Rejected');
                                        } else if(this.state.outgoing.Status === 'Sent to Partner' )
                                        { 
                                            table = table.filter((t) => t.LookUpTableDetailTranslationLabel === 'Void' || t.LookUpTableDetailTranslationLabel === 'Completed' || t.LookUpTableDetailTranslationLabel === 'Sent to Partner' || t.DisplayOrder === -1);
                                        } else
                                        {
                                            table = table.filter((t) => t.value !== 'Rejected' && t.value !== 'Sent to Partner');
                                        }
                                    }
                                }
                                this.setState({ dropdowns: {...this.state.dropdowns, [this.state.lookUpTables[i]]: table}
                                , entityPropertiesBlanks:0, entityPropertiesBlanksWarning:0});
                            }
                        }
                }
                );     
            }                        
        }

        if(prevState.loadedProperties != this.state.loadedProperties || prevState.saveInComplete != this.state.saveInComplete)
        {
            if(this.state.outgoing.OutgoingFundsID > 0 && this.state.outgoing.Status == 'Pending' && this.state.loadedProperties && this.state.saveInComplete)
            {
                        this.setState({outgoing: {...this.state.outgoing, Status: 'Completed'}, saveInComplete: false, loadedProperties: false}
                        , () => this.saveComplete());
            }
        }

        if (prevProps.readOnly !== this.props.readOnly && this.props.readOnly) {
            this.setState({ readOnly: this.props.readOnly });
        }
        if (prevProps.forceLoad !== this.props.forceLoad) {
            this.setState({ forceLoad: this.props.forceLoad });
        }
        if (prevProps.CloseAuditOutGoingFundScreen !== this.props.CloseAuditOutGoingFundScreen) {
            this.setState({ viewHistory: this.props.CloseAuditOutGoingFundScreen });
        }
    }
    
    //Get customer instruments
    loadInstrument(customerId){    
        getInstrumentsByCustomer(customerId).then(
            (json) => {
                let jsonInstruments = json.instruments;
                if(jsonInstruments !== undefined){
                    var incomingHelper = [];
                    var outgoingHelper = [];
                    for(var i=0; i<jsonInstruments.length; i++)
                    {
                        var instrumentElement = {
                            value   :jsonInstruments[i].Instrument,
                            name    :jsonInstruments[i].Instrument
                        };
                        if(jsonInstruments[i].InstrumentType === 'Incoming'){
                            incomingHelper.push(instrumentElement);
                        }
                        else{
                            instrumentElement.deliveryMethods = jsonInstruments[i].DeliveryMethods;
                            var addItem = true;
                            if(this.state.fromRefunds)
                            {
                                var methodsList = jsonInstruments[i].DeliveryMethods.split('§');
                                if(!methodsList.find(method => method == deliveryMethodBranch))
                                {
                                    addItem = false;
                                }
                            }
                            if(addItem)
                            {
                                outgoingHelper.push(instrumentElement);
                            }
                            
                        }
                    }

                    let tbahelper = outgoingHelper.filter((outg) => {
                        return outg.value === "Hold fund in Client's account";
                    });

                    this.setState({
                        incomingInstrumentList      : incomingHelper,
                        outgoingInstrumentList      : outgoingHelper,
                        tbaOutgoingInstrumentList   : tbahelper,
                        allOutgoingInstrumentList   : outgoingHelper
                    });   
                    //Load delivery methods 
                    let instruments = outgoingHelper;
                    var deliveryMethodList = {};
                    for(var k=0; k<instruments.length; k++){
                        var list = [];
                        if(instruments[k].deliveryMethods !== ''){
                            if(this.state.fromRefunds)
                            {
                                var itemBranch = { value: deliveryMethodBranch, name: deliveryMethodBranch };
                                list.push(itemBranch);
                            }
                            else
                            {
                                var methodsList = instruments[k].deliveryMethods.split('§');
                                for(var j=0; j<methodsList.length; j++){
                                    var item = {value:methodsList[j], name:methodsList[j]};
                                    list.push(item);
                                }
                            }
                        }
                        deliveryMethodList[instruments[k].value] = list;
                    }
                    this.setState({deliveryMethodList: deliveryMethodList});
                }
            }
        );
    }
    
    loadLookUpTableByFilter(value) {
        if (typeof value != "undefined") {
            getLookUpTableByFilter("Settlement Delivery Method", value.Field4).then((response) => {
                if (typeof response !== "undefined") {
                    let deliverMethodsResponse = response.lookUpTables[0].lookUpTableDetails;
                    deliverMethodsResponse.forEach(element => {
                        element.value = element.LookUpTableDetail;
                        element.name = element.LookUpTableDetail;
                    });
                    this.setState({
                        listDeliveryMethods: deliverMethodsResponse
                    });
                } else {
                    this.setState({listDeliveryMethods: []});
                }
            });
        }
    }

    loadRiskDetails = () => {
        let outgoingId = this.props.id;
        let customerId = this.props.customerID;
        let riskScoreRanges = this.state.riskScoreRanges || []
        if (riskScoreRanges.length === 0) {
            getRiskScoreRanges().then(
                (response) => {
                    console.log(response)
                    console.log('CustomerId: ' + customerId + ' - OutgoingId: ' + outgoingId)
                    if (response !== undefined && (response.riskScores || []).length > 0) {
                        this.setState({ riskScoreRanges: response.riskScores }, () => {
                            this.calculateOutgoingRiskScore(customerId, outgoingId)
                        })
                    }
                }
            )
        } else {
            console.log('CustomerId: ' + customerId + ' - OutgoingId: ' + outgoingId)
            this.calculateOutgoingRiskScore(customerId, outgoingId)
        }
    }

    calculateOutgoingRiskScore(customerId, outgoingId) {
        let riskScoreRanges = this.state.riskScoreRanges || []
        getOutgoingFundsRiskScoreDetails(customerId, outgoingId, false, false).then(response => {
            console.log(response);
            if (response !== undefined && response.riskScoreDetails !== undefined) {
                let riskDetails = response.riskScoreDetails || [];
                if (riskDetails.length > 0) {
                    let total = riskDetails.map(x => Number(x.Total || 0)).reduce((acc, currentValue) => { return acc + currentValue; }, 0);
                    let importanceTotal = riskDetails.map(x => Number(x["Risk Importance"] || 0)).reduce((acc, currentValue) => { return acc + currentValue; }, 0);
                    importanceTotal = importanceTotal === 0 ? 1 : importanceTotal;
                    let riskScore = total / importanceTotal;
                    var outgoingRiskScoreRange = riskScoreRanges.find((range) => range["Range From"] <= riskScore && riskScore <= range["Range To"] && range["Entity Type"] === "OutgoingFund");
                    this.setState({ riskScoreDetails: riskDetails, outgoingRiskScore: outgoingRiskScoreRange });
                }
                else {
                    this.setState({ riskScoreDetails: [], outgoingRiskScore: 0 });
                }
            }
        });
    }

    validateRequiredFields(){
        var valid = true;
        console.log(this.state.outgoing)

        let bankDeal = this.state.dealHeaderInfo !== undefined && this.state.dealHeaderInfo !== null && this.state.dealHeaderInfo.CustomerType === 'Bank'

        var beneficiaryaccount = true, instrumenttodeliver = true, deliverymethod = true, valuedate = true, paymentamount = true, status= true, nostroaccount = true;
        //if(this.state.beneAccountID === "select" || this.state.beneAccountID === 0){
        //-1 is a valid option for customer bene
        if (!bankDeal &&
            (this.state.outgoing.BeneficiaryID === "select" || this.state.outgoing.BeneficiaryID === '' || this.state.outgoing.BeneficiaryID === undefined
                || this.state.outgoing.BeneficiaryID === 0 /*|| this.state.outgoing.BeneficiaryID === -1*/)) {
            valid = false;
            beneficiaryaccount = false;
        }
        //if(this.state.outgoing.PaymentInstrument === "select" || this.state.dropdowns["Outgoing Instruments"].find(e => e.value === this.state.outgoing.PaymentInstrument) === undefined){
        if(this.state.outgoing.PaymentInstrument === "select" || this.state.outgoing.PaymentInstrument === undefined || this.state.outgoing.PaymentInstrument === ''){
            valid = false;
            instrumenttodeliver = false;
        }
        //if(this.state.outgoing.DeliveryMethod === "select" || this.state.outgoing.DeliveryMethod === undefined || this.state.outgoing.DeliveryMethod === ''){
        if(this.state.outgoing.DeliveryMethod === "select" || this.state.outgoing.DeliveryMethod === undefined){
            valid = true;
            deliverymethod = false;
        }
        if (this.state.outgoing.ValueDate === '') {
            valid = false;
            valuedate = false;
        }
        if (this.state.outgoing.PaymentAmount === "select" || this.state.outgoing.PaymentAmount === '' || this.state.outgoing.PaymentAmount === undefined || this.state.outgoing.PaymentAmount === 0) {
            valid = false;
            paymentamount = false;
        } 
        if(this.state.outgoing.Status === "select" || this.state.dropdowns["Outgoing Fund Status"].find(e => e.value === this.state.outgoing.Status) === undefined){
            valid = false;
            status = false;
        }
        if( //this.state.outgoing.Status === "Completed" && 
        (this.state.outgoing.NostroAccountID === "select" || this.state.outgoing.NostroAccountID === null || this.state.outgoing.NostroAccountID === undefined || this.state.outgoing.NostroAccountID === 0)){
            valid = false;
            nostroaccount = false;
        }

        this.setState({
            validations: {
                ...this.state.validations,
                beneficiaryaccount: beneficiaryaccount,
                instrumenttodeliver: instrumenttodeliver,
                deliverymethod: deliverymethod,
                valuedate: valuedate,
                paymentamount: paymentamount,
                status: status,
                nostroaccount: nostroaccount
            }
        })
        return valid;
    }

    onFinishEntityPropertiesSaveProcess(){
        let outgoingId = this.props.id;
        let customerId = this.props.customerID;
        console.log('CustomerId: ' + customerId + ' - OutgoingId: ' + outgoingId)
        this.setState({ entityPropertiesSaveProcess: false, forceLoad:true }, () => 
            {this.calculateOutgoingRiskScore(customerId, outgoingId);
            this.props.onSaveSuccess()});
    }

    save = async() =>{
        if(this.state.outgoing.Status === 'Void'){
            if(this.state.outgoing.UpdateToken === undefined)
            {
                this.setState({outgoing: {...this.state.outgoing, UpdateToken: '637269570688200000'}});
            }
            
            updateOutgoingFund(this.state.outgoing).then(
                (json) => {
                    switch(Number(json.httpStatusCode)){
                        case 200:
                        {
                            this.setState({
                                outgoing: {
                                    ...this.state.outgoing, 
                                    UpdateToken: json.updateToken, 
                                    OutgoingFundsID: json.outgoingID,
                                },
                                showAlert: true,
                                alertMessage: 'OutgoingFund successfully saved.', 
                                alertTitle: 'Success', 
                                entityPropertiesSaveProcess: true
                            });
                            if(this.state.outgoing.Status !== 'Pending'){
                                this.setState({disableControls: true});
                            } else{
                                this.setState({disableControls: false});                        
                            }
                            if (this.state.outgoing.Status === 'Void'){
                                this.setState({disableStatus: true});
                            } else {
                                this.setState({disableStatus: false});
                            }
                            this.props.onSaveSuccess();
                            this.loadRiskDetails();
                            break;
                        }
                        default:
                        {
                            var alertMessage = 'Outgoing Fund not saved. Please try again or contact the administrator.';
                            
                            if(json.httpStatusMessage !== undefined)
                            {
                                alertMessage = json.httpStatusMessage;
                            }

                            if(json.Message !== undefined)
                            {
                                if(json.Message.indexOf('concurrency') !== -1)
                                {
                                    alertMessage = 'Please reload this screen. Another user has posted changes before your submit.';
                                }else{
                                    alertMessage = alertMessage + ' (' + json.Message + ')';
                                }
                            }

                            this.setState({
                                showAlert   : true,
                                alertTitle  : 'Error',
                                alertMessage: alertMessage,
                            });
                            break;
                        }
                    }
                }
            );
        }//if(this.state.outgoing.Status === 'Void'){
        else{
            var getamountInBaseCcy = await getAmountInBaseCurrency(this.state.dealId, this.state.outgoing.PaymentAmount);
            this.setState({amountInBaseCurrency: getamountInBaseCcy.response})
            /*getAmountInBaseCurrency(this.state.dealId, this.state.outgoing.PaymentAmount).then(
                (value) => {
                    this.setState({amountInBaseCurrency: value.response})
                }
            );*/

            var getBalanceDue = await getBalanceDueByDealID(this.state.dealId);
            this.setState({balanceDue: getBalanceDue.response});
            /*getBalanceDueByDealID(this.state.dealId).then(
                (value) => {
                    this.setState({balanceDue: value.response});
                }
            );*/
            
            for (var i = 0; i < this.state.beneAccounts.length; i++){
                if(this.state.beneAccounts[i].Name === this.state.beneAccountName){
                    this.setState({receiveLimit: this.state.beneAccounts[i].ReceiveLimit,
                                    accountCcyID: this.state.beneAccounts[i].AccountCurrencyID,
                                    beneName: this.state.beneAccounts[i].Name});
                }
                
            }
            var hasEntered = false;
            //if(this.state.outgoing.Status !== 'Void'){
            if(this.state.outgoing.Status === 'Completed'){
                //Warning Limit Bene
                if (this.state.receiveLimit > 0) {
                    if (this.state.outgoing.PaymentInstrument === 'Electronic Funds Transfer' || this.state.outgoing.PaymentInstrument === 'EFT-Direct Credit') {
                        if (this.state.receiveLimit != null && this.state.amountInBaseCurrency > this.state.receiveLimit) {
                            hasEntered = true;
                            this.setState({ showExceedsLimitAlert: true });
                        }
                    } else {
                        if (this.state.receiveLimit != null && this.state.amountInBaseCurrency > this.state.receiveLimit) {
                            hasEntered = true;
                            this.setState({ showExceedsLimitAlert: true });
                        }
                    }
                }

                if(!hasEntered){
                    if(this.state.outgoing.Status === 'Completed' && this.state.balanceDue > 0){
                        hasEntered = true;
                        this.setState({showSendingFundsAlert: true});
                    }
                }
                
                if(!hasEntered){
                    if(this.state.dealValueDate > this.state.outgoing.ValueDate){
                        this.setState({showReleasePaymentAlert: true});
                        hasEntered = true;
                    }
                }
            }

            //if(!hasEntered && this.state.exec){
            if(!hasEntered){
                if(this.state.outgoing.OutgoingFundsID === 0)
                {
                    getDealHeaderInfo(this.state.dealId).then(
                        (json) => {
                            for(var i= 0; i < json.dealHeaderInfo.length; i++){
                                this.setState({
                                    outgoing: {...this.state.outgoing, CurrencyID: json.dealHeaderInfo[i].SCur, BeneficiaryType: 0, VersionNo: 0, UpdateToken: '637269570688200000'}
                                });
                            }
                        }
                    );
                }
                this.saveOutgoing();
            }
        }//else if(this.state.outgoing.Status === 'Void'){
    }

    ValidateEntityForReferral(alertMessage, alertTitle, disableControls, entityPropertiesSaveProcess, outgoing, previewStatus, saveSuccess){
        validateEntityForReferral(this.state.outgoing.DealHeaderID, outgoing.OutgoingFundsID, localStorage.getItem('UserID')).then(
            (json) => {
                var showReferralAlert = false
                var showRegularAlert = true
                var saveSuccessTemp = saveSuccess;
                if(!json.Result && this.state.outgoing.Status !== 'Void'){
                    showReferralAlert = true;
                    showRegularAlert = false;
                    saveSuccessTemp = false;
                }
                else
                {
                    if (this.state.saveOutgoingToBankInterface) {
                        this.setState({ saveOutgoingToBankInterface: false }, () => {
                            this.saveToInterface();
                        })
                    } 
                }
                let tempMessage = 'The Profile Limit has been violated. ' + json.Reasons
                let questionMessage = (this.state.saveOutgoingToBankInterface) ?'Do you want to send the payment to the '+this.state.externalPaymentInterface+'?':
                'Do you want to save the Outgoing in Completed status?';

                var newFundForReferralMessage = <span> {tempMessage} <br /> {questionMessage} </span>
                this.setState({
                    alertMessage: alertMessage,
                    alertTitle: alertTitle,
                    disableControls: disableControls,
                    entityPropertiesSaveProcess: entityPropertiesSaveProcess,
                    loading: false,
                    outgoing: outgoing,
                    previewStatus: previewStatus,
                    //fundsForReferralMessage: 'The limit has been violated. ' + json.Reasons + '. Do you want to save the Outgoing in Completed status?',
                    fundsForReferralMessage: newFundForReferralMessage,
                    showFundsForReferralAlert: showReferralAlert,
                    showAlert: showRegularAlert,
                    saveSuccess: saveSuccessTemp
                });
            }
        )
    }
     
    
    saveOutgoing(){
        /*if(this.state.saveOutgoingToBankInterface)
        {
            this.saveOutgoingToBankInterface();
        }
        else
        {*/
            this.updateOutgoingIntern();
        //}
        // if(this.state.outgoing.PaymentInstrument === 'Electronic Funds Transfer' || this.state.outgoing.PaymentInstrument === 'EFT-Direct Credit'){
        //     if(this.state.outgoing.CurrencyID !== this.state.accountCcyID 
        //         && this.state.beneName !== 'TBA'
        //         && this.state.status !== 'Void' ){
        //         let chosenWireAlertMessage = 'You have chosen to wire ' + this.state.outgoing.CurrencyID + " to a " + this.state.accountCcyID + " beneficiary account. Are you sure this is correct?"
        //         this.setState({showChosenWireAlert: true,
        //             chosenWireAlertMessage: chosenWireAlertMessage,
        //         });
        //     } else {
        //         this.updateOutgoingIntern();                     
        //     }
        // } else {
        //     this.updateOutgoingIntern();
        // }
    }

    updateOutgoingIntern(){
        this.setState({loading: true,
            showChosenWireAlert: false,
            showExceedsLimitAlert: false, 
            showReleasePaymentAlert: false, 
            showSendingFundsAlert: false, 
            showPropertiesAlert: false,
            showPropertiesAlertWarning: false,
            showBeneficiaryHighRiskAlert: false,
            showFundsForReferralAlert: false
        });
        console.log(this.state.outgoing)
        updateOutgoingFund(this.state.outgoing).then(
            (json) => {
                console.log(json);
                var disableControls = false;
                var alertMessage = '';
                var alertTitle = '';
                var previewStatus = this.state.previewStatus;
                var entityPropertiesSaveProcess = this.state.entityPropertiesSaveProcess;
                var outgoing = this.state.outgoing;
                var showAlert = true;
                let saveSuccess = false;

                outgoing.ComplianceSoftRulesAcceptedByUser = false;

                if(json.httpStatusCode !== undefined && json.httpStatusCode !== 200)
                {
                    alertTitle = 'Error';
                    //alertMessage = json.Message;

                    var alertMessage = 'Outgoing Fund not saved. Please try again or contact the administrator.';
                                
                    if(json.httpStatusMessage !== undefined)
                    {
                        alertMessage = json.httpStatusMessage;
                    }

                    if(json.Message !== undefined)
                    {
                        if(json.Message.indexOf('concurrency') !== -1)
                        {
                            alertMessage = 'Please reload this screen. Another user has posted changes before your submit.';
                        }else{
                            alertMessage = alertMessage + ' (' + json.Message + ')';
                        }
                    }
                }else 
                {
                    alertTitle = 'Success';
                    alertMessage = 'OutgoingFund successfully saved.';
                    
                    previewStatus = this.state.outgoing.Status;
                    entityPropertiesSaveProcess = true;
                    outgoing.UpdateToken = json.updateToken;
                    outgoing.OutgoingFundsID = json.outgoingID;
                    saveSuccess = true;
                    if(this.state.outgoing.Status === 'Void' || this.state.outgoing.Status === 'Completed')
                    {
                        disableControls = true;
                    }

                    this.props.onSaveSuccess();
                    if (this.props.onUpdateID != null) {
                        this.props.onUpdateID(json.outgoingID)
                    }
                    if (this.props.onUpdateStatus != null) {
                        this.props.onUpdateStatus(this.state.outgoing.Status)
                    }
                    this.loadRiskDetails();                    
                }
                if (json != undefined && json.response != undefined && json.response != null && json.response.ComplianceMessagePending != undefined && json.response.ComplianceMessagePending != null
                    && Array.isArray(json.response.ComplianceRules) && json.response.ComplianceRules.length > 0) {
                    
                    outgoing.Status = 'Pending'

                    let newMessage = json.response.ComplianceMessagePending + ", reasons: " + json.response.ComplianceRules.join()

                    this.setState({
                        alertMessage: newMessage,
                        alertTitle: alertTitle,
                        disableControls: disableControls,
                        entityPropertiesSaveProcess: entityPropertiesSaveProcess,
                        loading: false,
                        outgoing: outgoing,
                        previewStatus: previewStatus,
                        showAlert: showAlert,
                        saveSuccess: saveSuccess
                    });
                }
                else if (json != undefined && json.response != undefined && json.response != null && json.response.ComplianceSoftRules && Array.isArray(json.response.ComplianceSoftRules) && json.response.ComplianceSoftRules.length > 0) {
                    
                    outgoing.Status = 'Pending'

                    let tempMessage = 'The Outgoing needs an additional revision from Compliance.' + ", reasons: " + json.response.ComplianceSoftRules.join()
                    let questionMessage = 'Do you want to save the Outgoing in Completed status?'                    
                    var newFundForReferralMessage = <span> {tempMessage} <br /> {questionMessage} </span>
                    this.setState({
                        alertMessage: alertMessage,
                        alertTitle: alertTitle,
                        disableControls: disableControls,
                        entityPropertiesSaveProcess: entityPropertiesSaveProcess,
                        loading: false,
                        outgoing: outgoing,
                        previewStatus: previewStatus,
                        fundsForReferralMessage: newFundForReferralMessage,
                        showFundsForReferralAlert: true,
                        showAlert: false,
                        referalFromCompliance: true,
                        saveSuccess: saveSuccess
                    });
                }
                else if(this.state.validateViolation && this.state.outgoing.Status === 'Completed'){                    
                    this.ValidateEntityForReferral(alertMessage, alertTitle, disableControls, entityPropertiesSaveProcess, outgoing, previewStatus, saveSuccess);
                } else {
                    if (this.state.saveOutgoingToBankInterface) {
                        this.setState({ saveOutgoingToBankInterface: false }, () => {
                            this.saveToInterface();
                        })
                    } 
                    this.setState({
                        alertMessage: alertMessage,
                        alertTitle: alertTitle,
                        disableControls: disableControls,
                        entityPropertiesSaveProcess: entityPropertiesSaveProcess,
                        loading: false,
                        outgoing: outgoing,
                        previewStatus: previewStatus,
                        showAlert: showAlert,
                        saveSuccess: saveSuccess
                    });
                }                
                /*this.setState({
                    alertMessage: alertMessage,
                    alertTitle: alertTitle,
                    disableControls: disableControls,
                    entityPropertiesSaveProcess: entityPropertiesSaveProcess,
                    loading: false,
                    outgoing: outgoing,
                    previewStatus: previewStatus,
                    showAlert: true,
                });*/
            }
        )        
    }

    saveOutgoingToBankInterface = async () => {        
        this.setState({saveOutgoingToBankInterface: true});

        var update = await this.UpdateOutgoingFundClick();
        if(update){
            validateCountryRiskScore('Beneficiary', this.state.outgoing.BeneficiaryID).then(
                (json) => {
                    if(json.Result !== undefined){
                        if(!json.Result && this.state.outgoing.Status !== 'Void'){
                            this.setState({ validateViolation: false, showBeneficiaryHighRiskAlert: true, beneficiaryHighRiskMessage: 'The Beneficiary country has a high risk level. Do you want to save the outgoing on Pending status?' })
                        } else {
                            this.setState({ validateViolation: true });
                            this.save();                        
                        }
                    } else {
                        var alertMessage = 'Outgoing Fund not saved. Please try again or contact the administrator.';
                                    
                        if(json.httpStatusMessage !== undefined)
                        {
                            alertMessage = json.httpStatusMessage;
                        }
                        if(json.Message !== undefined)
                        {
                            alertMessage = alertMessage + ' (' + json.Message + ')';
                        }
                        this.setState({
                            alertMessage: alertMessage,
                            alertTitle: 'Error',
                            showAlert: true,
                        });
                    }
                }
            );
        }
    
        /*if (allowToSaveToInterfaces) 
        { 
            
            validateEntityForReferral(this.props.dealId, this.state.outgoing.OutgoingFundsID, localStorage.getItem('UserID')).then(
                (json) => {
                    console.log(json)

                    if (json.Result) {
                        this.saveToInterface();
                    }
                    else {
                        let tempMessage = 'The Profile Limit has been violated. ' + json.Reasons
                        let questionMessage = 'Do you want to send the Outgoing to the Provider?'
                        var newFundForReferralMessage = <span> {tempMessage} <br /> {questionMessage} </span>

                        this.setState({
                            alertMessage: newFundForReferralMessage,
                            alertTitle: 'Warning',
                            disableControls: this.state.outgoing.Status === 'Void' || this.state.outgoing.Status === 'Completed',
                            entityPropertiesSaveProcess: false,
                            loading: false,
                            fundsForReferralMessage: newFundForReferralMessage,
                            showFundsForReferralAlert: true,
                            showAlert: false,
                            saveOutgoingToBankInterface: true
                        });
                    }
                }
            )
        }
*/
    }
    

    saveToInterface = () =>
    {
        let userId = localStorage.getItem('UserID');

        let outgoingFund = { ...this.state.outgoing, ForceSaveToInterface: this.state.externalPaymentInterface, UpdatedBy: userId }
        this.setState({ loading: true }, () => {
            saveOutgoingToBankInterface(outgoingFund).then((response) => {
                console.log(response)
                switch (Number(response.httpStatusCode)) {
                    case 200:
                        this.setState({
                            showAlert: true,
                            alertTitle: 'Success',
                            alertMessage: 'Payment saved in ' + this.state.externalPaymentInterface,
                            loading: false,
                        })
                        break
                    case 409:
                        this.setState({
                            showAlert: true,
                            alertTitle: 'Error',
                            alertMessage: 'Current record has been updated by other user. Please refresh the information and try again.',
                            loading: false
                        })
                        break
                    default:
                        console.log(response.errorMessage)
                        this.setState({
                            showAlert: true,
                            alertTitle: 'Error',
                            alertMessage: response.errorMessage || response.interfaceErrorMessage || 'Error when sending. Please try again.',
                            loading: false
                        })
                        break
                }
            }).finally(() => { 
                this.setState({ loading: false, reloadScreen: true , forceLoad: !this.state.forceLoad}) ;
                if (this.props.onUpdateID != null) {
                    this.props.onUpdateID(this.state.outgoing.OutgoingFundsID);
                }
                if (this.props.onUpdateStatus != null) {
                    this.props.onUpdateStatus(this.state.outgoing.Status)
                }
                if (this.props.onSaveSuccess != null) {
                    this.props.onSaveSuccess();
                }
            })
        })
    }

    handleCreateMT103Click = async () => {
        const model = {
            dealID: this.props.dealId,
            outgoingFund: this.state.outgoing.OutgoingFundsID,
            currencyID: this.state.outgoing.CurrencyID,
            paymentAmount: this.state.outgoing.PaymentAmount,
            nostroAccountName: this.state.outgoing.NostroAccountName,
            beneficiaryName: this.state.outgoing.BeneficiaryName,
            beneficiaryAddress: this.state.outgoing.BeneAddress,
            countryID: this.state.outgoing.CountryId,
            beneficiaryState: this.state.outgoing.BeneState,
            beneficiaryBankAccountID: this.state.outgoing.BeneficiaryBankAccountID,
            interBank: this.state.outgoing.InterBank
        }

        const text = await formatToMT103(model);
        const date = new Date();
        const [month, day] = formatDateToDDMMYYYY(date);
        const file = new Blob([ text ], { type: 'text/plain' });
        const element = document.createElement('a');
        element.href = URL.createObjectURL(file);
        element.download = `MT103-${month}${day}${date.getFullYear()}-${this.props.dealId}.txt`;
        document.body.appendChild(element);
        element.click();
    }

    handleClearClick(){
        //alert(this.props.defaultOutgoingNostroBankID);

        //Set default nostro account
        let nostroAccounts = this.state.dropdowns.nostroBankAccounts;
        let defaultNostroAccount = nostroAccounts.find((account) => { return Number(account.BankID) === Number(this.props.defaultOutgoingNostroBankID); });
        var nostroAccountID = 0;
        if(defaultNostroAccount !== undefined){
            nostroAccountID = defaultNostroAccount.BankAccountID;
        }
        var paymentBankInterfaces = this.state.bankInterfacesDetails.filter(x => x.Field1 === 'Payments' || x.DisplayOrder === -1) || [];

        if(this.state.outgoing.CurrencyID !== 'CAD'){
            paymentBankInterfaces = paymentBankInterfaces.filter(x => x.LookUpTableDetail !== 'Apaylo - Payments')
        }
        const statusDropdown = this.state.dropdowns['Outgoing Fund Status'].filter((d) => d.value !== 'Rejected');
        
        this.setState({
            outgoing: {...this.state.outgoing, 
                BeneficiaryBankAccountID: 0, 
                BeneficiaryID: '', 
                BeneficiaryName: '',
                NostroAccountID: nostroAccountID,
                NostroAccountName: '',
                PaymentInstrument: '',
                PaymentAmount: this.props.sellAmount - this.props.totalAmountOutgoings,
                OutgoingFundsID: 0,
                DeliveryRefNo: '',
                PaymentReference: '',
                Status: 'Pending',
                VersionNo: '',
                BeneficiaryType: '',
                Comments: '',
                ValueDate: '',
                DeliveryMethod: '',
                WireItemFee: 0,
                WireItemFeeCurrencyID: '',
                DetailsOfPayment: '',
                ContractBalance: '',
                CreatedBy: '',
                UpdateToken: ''
            }, 
            beneAccountID: 0, 
            beneAccountName: '',
            beneID: 0,
            disable: true,
            disableControls: false,
            disableStatus: false,
            listDeliveryMethods: [],
            receiveLimit: 0,
            previewStatus: '',
            paymentBankInterfaces: paymentBankInterfaces,
            externalPaymentInterface: '',
            entityPropertiesBlanks:0,
            entityPropertiesBlanksWarning: 0,
            loadedProperties: false,
            saveInComplete: false,
            dropdowns: { ...this.state.dropdowns, 'Outgoing Fund Status': statusDropdown }
        });
        if (this.props.onUpdateID != null) {
            this.props.onUpdateID(0);
        }
        if (this.props.onUpdateStatus != null) {
            this.props.onUpdateStatus('')
        }
    }

    handleReturnToOutgoingFund() {
        this.setState({ viewHistory: false});
    }

    handleViewHistoryClick() {
        this.props.ViewAuditOutGoingFundScreen();        
        this.setState({ viewHistory: true});
    }

    formatValidSelect(valid) {
        return valid ? regularSelectClass : notFilledSelectClass;
    }

    formatValidInput(valid) {
        return valid ? regularInputClass : notFilledInputClass;
    }

    closeAlert(){
        this.setState({showAlert: false});
        
        if(this.state.saveSuccess)
        {
            if(this.state.outgoing.Status === 'Completed'){
                this.setState({saveSuccess: false});
                this.checkForSendConfirmation();
            }
        }
        if(this.state.checkForBene)
        {
            if(this.state.outgoing.Status === 'Completed'){
                this.setState({checkForBene: false});
                this.closeSendCustomerConfirmation();
            }
        }
    }
    
    handleGetDeliveryMethod (value) {
        if(value !== undefined && value.value !== undefined){
            this.setState({
                outgoing: {...this.state.outgoing, DeliveryMethod: value.value},
                validations: {...this.state.validations, deliverymethod: true}
            });
        }
    }
    /*handleGetDeliveryMethod = (obj) => {
        if(obj.value !== undefined){
            var value = obj.value;
            if(value === 'select'){
                value = '';
            }
            var outgoingTemp = this.state.outgoing;
            //let controlID = obj.controlID;
            //let index = controlID.substring(controlID.indexOf('-') + 1);
            outgoingTemp.DeliveryMethod = value;
            this.setState({outgoingFundList: outgoingTemp});
        }
    }*/
    
    handleGetStatus (value) {
        if(value !== undefined && value.value !== undefined && value.value !== 'select'){
            this.setState({
                outgoing: {...this.state.outgoing, Status: value.value},
                validations: {...this.state.validations, status: true}
            });
            if(value.value !== 'Completed' || value.value !== 'Void'){            
                this.setState({disableControls: false, disableStatus: false});
            }
        }
    }

    handlePaymentBankInterfaces = (value) => {
        if (value !== undefined && value.value !== undefined && value.value !== 'select') {
            if(value.value === "Apaylo - Payments") {
                this.getBalanceForInterface()
            } else {
                this.setState({ externalPaymentInterface: value.value, showBalances: false })
            }
        }
        this.GetPurposeOfPayments(value);
    }

    handlePurposeOfPayments = (value) => {
        if (value !== undefined && value.value !== undefined && value.value !== 'Select') {
            this.setState({ purposeOfPayment_value: value.value, outgoing: { ...this.state.outgoing, PaymentPurposeCode: value.value } });
        }else{
            this.setState({ purposeOfPayment_value: value.value, outgoing: { ...this.state.outgoing, PaymentPurposeCode: null } });
        }
    }

    GetPurposeOfPayments = (value) => {
        let purposeOfPayments = [];
        if (value !== undefined && value.value !== undefined && value.value !== '') {
            let interfaceSource = value.value;
            let customerID = this.state.customerID;
            let bankAccountId = this.state.outgoing.BeneficiaryBankAccountID;
            let currencyID = this.state.outgoing.CurrencyID;
            getPurposeOfPayments(interfaceSource, customerID, currencyID, bankAccountId).then((json) => {
                purposeOfPayments.push( {value:'Select', name:'Select'} );
                for (var j = 0; j < json.data.length; j++) {
                    let data ={
                        value: json.data[j].Code,
                        name: json.data[j].Description
                    }
                    purposeOfPayments.push(data);
                }
                this.setState({ purposeOfPayments: purposeOfPayments, purposeOfPayment_value: 'Select', outgoing: { ...this.state.outgoing, PaymentPurposeCode: null} })      
            });
        }else{ 
            this.setState({ purposeOfPayments: purposeOfPayments, purposeOfPayment_value: 'Select', outgoing: { ...this.state.outgoing, PaymentPurposeCode: null} })
        }
    }
     
    handleGetInstrumentToDeliver = (obj) => {
        if(obj !== undefined && obj.value !== undefined){
            var outgoingTemp = this.state.outgoing;
            //let controlID = obj.controlID;
            //let index = controlID.substring(controlID.indexOf('-') + 1);
            outgoingTemp.PaymentInstrument = obj.value;
            outgoingTemp.DeliveryMethod = '';
            if(this.state.fromRefunds)
                {
                    outgoingTemp.DeliveryMethod = deliveryMethodBranch;
                }
            //this.calculateWireItemFee(outgoingTemp, index, obj.value, outgoingTemp[index].PaymentAmount);
            this.setState({outgoing: outgoingTemp,
                validations: {...this.state.validations, instrumenttodeliver: true}
            });
        }
    }

    handleGetNostroAccount (value) {
        if(value !== null && value !== undefined && value.value !== undefined && value.value !== 'select' && value!== 0){
            this.setState({
                outgoing: {
                    ...this.state.outgoing,
                    NostroAccountID: value.value !== '' ? parseInt(value.value) : 0,
                    NostroAccountName: value.BankAccount != null ? value.BankAccount : ''
                },
                validations: {...this.state.validations, nostroaccount: true}
            });
        }
    }
 
    handleGetBeneficiaryBankAccount = (obj) => {
        if(obj.value !== undefined){
            var outgoingTemp = this.state.outgoing;
            //let controlID = obj.controlID;
            //let index = controlID.substring(controlID.indexOf('-') + 1);
            let entityID = obj.value;
            outgoingTemp.EntityID = entityID;
            let ids = entityID.split('|');

            outgoingTemp.BeneficiaryBankAccountID = parseInt(ids[1]);
            outgoingTemp.BeneficiaryID = parseInt(ids[0]);
            outgoingTemp.CountryName = obj.CountryName;
            outgoingTemp.Name = obj.BeneficiaryName;
            outgoingTemp.BeneficiaryBankAccountName = obj.BeneficiaryBankAccountName;

            if(obj.PreferredMethodOfPayment !== '' &&
                obj.PreferredMethodOfPayment !== undefined){
                outgoingTemp.PaymentInstrument = obj.PreferredMethodOfPayment;
            }
            else{
                outgoingTemp.PaymentInstrument = '';
            }

            if(obj.PreferredDeliveryMethod !== '' &&
                obj.PreferredDeliveryMethod !== undefined){
                outgoingTemp.DeliveryMethod = obj.PreferredDeliveryMethod;
            }
            else{
                outgoingTemp.DeliveryMethod = '';
            }
            outgoingTemp.BeneficiaryType = 0;
            switch(Number(outgoingTemp.BeneficiaryID)){
                case -1:
                    outgoingTemp.BeneficiaryType = 1;
                    break;
                case -2:
                    outgoingTemp.BeneficiaryType = 2;
                    break;
                default:
                    break;
            }

            if(entityID === 'select' || entityID === ''){
                outgoingTemp.BeneficiaryID = 0;
                outgoingTemp.BeneficiaryBankAccountID = 0;
            }

            if(obj.name == "TBA")
            {
                this.setState({
                    outgoing                : outgoingTemp,
                    outgoingInstrumentList  : this.state.tbaOutgoingInstrumentList,
                    validations             : {...this.state.validations, deliverymethod: true}
                }, () =>{
                    this.handleGetInstrumentToDeliver(this.state.tbaOutgoingInstrumentList[0])
                });
            }else
            {
                this.setState({
                    outgoing                : outgoingTemp,
                    outgoingInstrumentList  : this.state.allOutgoingInstrumentList,
                    validations             : {...this.state.validations, deliverymethod: true}});
            }
            
            
        }
    }

    handleUpdatePaymentAmount (event){
        this.setState({
            outgoing: {...this.state.outgoing, PaymentAmount: event.target.value},
            validations: {...this.state.validations, paymentamount: true}
        }, () =>
        {
            getAmountInBaseCurrency(this.state.dealId, this.state.outgoing.PaymentAmount).then(
                (getamountInBaseCcy) => 
                {
                    this.setState({amountInBaseCurrency: getamountInBaseCcy.response});
                }
            )
        });
    }

    handleUpdateDeliveryRefNo (event){
        this.setState({outgoing: {...this.state.outgoing, DeliveryRefNo: event.target.value}});
    }

    handleUpdateDetailsOfPayment (event){
        this.setState({outgoing: {...this.state.outgoing, DetailsOfPayment: event.target.value}});
    }

    handleUpdatePaymentReference (event){
        this.setState({outgoing: {...this.state.outgoing, PaymentReference: event.target.value}});
    }

    handleUpdateWireItemFee (event){
        this.setState({outgoing: {...this.state.outgoing, WireItemFee: event.target.value}});
    }

    handleUpdateComments (event){
        this.setState({outgoing: {...this.state.outgoing, Comments: event.target.value}});
    }

    handlePrintSendPaymentConfirmation() {
        this.setState({ showOutgoingConfirmation: true });
    }

    handleBackLink() {
        this.setState({
            showOutgoingConfirmation: false,
            showOutgoingEmailLog: false
        });
    }

    handleUpdateValueDate(date){
        if(date!==undefined){
            this.setState({outgoing:{...this.state.outgoing, ValueDate: formatDate(date)}});
        }else{
            this.setState({outgoing:{...this.state.outgoing, ValueDate: ''}});
        }
    }
    
    handleRejectClick() {
        this.setState({ showRejectOutgoingAlert: true, rejectOutgoingMessage: 'Are you sure you want to mark this outgoing fund as rejected?' });
    }

    onShowRiskCalculationDetails = () => {
        if (this.state.outgoing.OutgoingFundsID > 0) {
            this.setState({ showRiskCalculationDetails: !this.state.showRiskCalculationDetails });
        }
    }

    updateRejectedFeeAmount = (feeAmount, rejectOutgoing) => {
        this.setState({rejectedFee: feeAmount, showRejectFeeScreen: false, showRejectOutgoingAlert: false});
        if(rejectOutgoing)
        {
            if(feeAmount >= 0){
                this.setState({ showAlertYesNo: true, createNewOutgoingMessage: 'Do you want to create the new outgoing with the full amount (' + this.state.outgoing.PaymentAmount + ')?'});
            }
        }
    }

    rejectOutgoing(createRejectedFee){

        var rejectedOutgoing = this.state.outgoing;            
        var disableControls = false;
        var alertMessage = '';
        var alertTitle = '';
        var previewStatus = this.state.previewStatus;
        var entityPropertiesSaveProcess = this.state.entityPropertiesSaveProcess;

        rejectOutgoingFund(this.state.outgoing, this.state.rejectedFee, createRejectedFee).then(
            (json) => {

            if(json.httpStatusCode !== undefined && json.httpStatusCode !== 200)
            {
                alertTitle = 'Error';
                var alertMessage = 'Outgoing Fund not saved. Please try again or contact the administrator.';
                            
                if(json.httpStatusMessage !== undefined)
                {
                    alertMessage = json.httpStatusMessage;
                }

                if(json.Message !== undefined)
                {
                    if(json.Message.indexOf('concurrency') !== -1)
                    {
                        alertMessage = 'Please reload this screen. Another user has posted changes before your submit.';
                    }else{
                        alertMessage = alertMessage + ' (' + json.Message + ')';
                    }
                }
            }else 
            {
                alertTitle = 'Success';
                alertMessage = 'Outgoing fund rejected! The new nsf outgoing fund was loaded in the screen.';
                previewStatus = this.state.outgoing.Status;
                entityPropertiesSaveProcess = true;
                rejectedOutgoing.UpdateToken = json.UpdateToken;
                rejectedOutgoing.OutgoingFundsID = json.OutgoingFundsID;
                rejectedOutgoing.Status = json.Status;
                
                this.setState({
                    disableControls: disableControls,
                    entityPropertiesSaveProcess: entityPropertiesSaveProcess,
                    outgoing: rejectedOutgoing,
                    previewStatus: previewStatus
                }, () => {
                    if (this.props.onUpdateID != null) {
                        this.props.onUpdateID(json.OutgoingFundsID)
                    }
                    if (this.props.onUpdateStatus != null) {
                        this.props.onUpdateStatus(json.Status)
                    }
                    this.props.onSaveSuccess();
                    this.loadRiskDetails();
                });
            }
            this.setState({
                alertMessage: alertMessage,
                alertTitle: alertTitle,
                showAlert: true,
            });
        });
    }

    handleSwiftValidatePayment = async (event) => {
        let userId = localStorage.getItem('UserID');
        const model = {
            PaymentOutgoingID: this.state.outgoing.OutgoingFundsID,
            UserID: userId
        }
        this.setState({ loading: true }, () => {
            swiftPaymentVerification(model).then((response) => {
                console.log(response)
                switch (Number(response.httpStatusCode)) {
                    case 200:
                        if (response.interfaceProviderMessage != undefined && response.interfaceProviderMessage != null && response.interfaceProviderMessage != '') {
                            this.setState({
                                showAlert: true,
                                alertTitle: 'Warning',
                                alertMessage: response.interfaceProviderMessage,
                                loading: false,
                            })
                        } else {
                            this.setState({
                                showAlert: true,
                                alertTitle: 'Success',
                                alertMessage: response.result,
                                loading: false,
                            })
                        }
                        break
                    default:
                        console.log(response.errorMessage)
                        this.setState({
                            showAlert: true,
                            alertTitle: 'Error',
                            alertMessage: response.Message || response.interfaceErrorMessage || 'Error when sending. Please try again.',
                            loading: false
                        })
                        break
                }
            }).finally(() => { this.setState({ loading: false }); this.props.onSaveSuccess(); })
        })
    }

    handleSwiftSendPayment = async (event) => {
        let userId = localStorage.getItem('UserID');
        const model = {
            PaymentOutgoingID: this.state.outgoing.OutgoingFundsID,
            UserID: userId
        }
        this.setState({ loading: true }, () => {
            swiftCreateCustomerCreditTransfer(model).then((response) => { 
                console.log(response)
                switch (Number(response.httpStatusCode)) {
                    case 200:
                        if (response.interfaceProviderMessage != undefined && response.interfaceProviderMessage != null && response.interfaceProviderMessage != '') {
                            this.setState({
                                showAlert: true,
                                alertTitle: 'Warning',
                                alertMessage: response.interfaceProviderMessage,
                                loading: false,
                            })
                        } else {
                            this.setState({
                                showAlert: true,
                                alertTitle: 'Success',
                                alertMessage: response.result,
                                loading: false,
                            })
                        }
                        break
                    default:
                        console.log(response.errorMessage)
                        this.setState({
                            showAlert: true,
                            alertTitle: 'Error',
                            alertMessage: response.Message || response.interfaceErrorMessage || 'Error when sending. Please try again.',
                            loading: false
                        })
                        break
                }
            }).finally(() => { this.setState({ loading: false }) ; this.props.onSaveSuccess(); })
        })
    }
    handleSwiftGetOutgoingPaymentStatus = async (event) => {
        let userId = localStorage.getItem('UserID');
        const model = {
            PaymentOutgoingID: this.state.outgoing.OutgoingFundsID,
            UserID: userId
        }
        this.setState({ loading: true }, () => {
            swiftGetOutgoingPaymentStatus(model).then((response) => { 
                console.log(response)
                switch (Number(response.httpStatusCode)) {
                    case 200:
                        if (response.interfaceProviderMessage != undefined && response.interfaceProviderMessage != null && response.interfaceProviderMessage != '') {
                            this.setState({
                                showAlert: true,
                                alertTitle: 'Warning',
                                alertMessage: response.interfaceProviderMessage,
                                loading: false,
                            })
                        } else {
                            this.setState({
                                showAlert: true,
                                alertTitle: 'Success',
                                alertMessage: response.result,
                                loading: false,
                            })
                        }
                        break
                    default:
                        console.log(response.errorMessage)
                        this.setState({
                            showAlert: true,
                            alertTitle: 'Error',
                            alertMessage: response.Message || response.interfaceErrorMessage || 'Error when sending. Please try again.',
                            loading: false
                        })
                        break
                }
            }).finally(() => { this.setState({ loading: false }); this.props.onSaveSuccess();  })
        })
    }    

    onValidMandatory = async(properties) =>
    {
        if(properties != null)
        {
            var invalidProperties = 0;
            var invalidPropertiesWarning = 0;
            properties.map(prop =>
                {
                    if(prop.Mandatory)
                    { 
                        if((prop.Type == 'Text' && (prop.TextValue == null || prop.TextValue == ''))
                        || (prop.Type == 'Numeric' && (prop.NumValue == null || prop.NumValue == '' || prop.NumValue.value == 0))
                        || (prop.Type == 'DateTime' && (prop.DateValue === null || prop.DateValue === '') ) ) 
                        {
                            invalidProperties++;
                        }
                        if(prop.AttachmentsRequired && prop.Attachments == 0)
                            {
                                invalidProperties++;
                            }                        
                    }
                    else
                    {
                        if(prop.AmountLimit > 0 && this.state.amountInBaseCurrency > prop.AmountLimit)
                        {
                            if ((prop.Type == 'Text' && (prop.TextValue == null || prop.TextValue == ''))
                            || (prop.Type == 'Numeric' && (prop.NumValue == null || prop.NumValue == '' || prop.NumValue.value == 0))
                            || (prop.Type == 'DateTime' && (prop.DateValue === null || prop.DateValue === '') ) )
                            {
                                invalidPropertiesWarning++;
                            }
                            if(prop.AttachmentsRequired && prop.Attachments == 0)
                            {
                                invalidPropertiesWarning++;
                            }
                        }
                    }
                }
            );

            this.setState({entityPropertiesBlanks: invalidProperties,
                entityPropertiesBlanksWarning: invalidPropertiesWarning,
                loadedProperties: true
            });
        }
        else
        {
            this.setState({entityPropertiesBlanks: 0,
                entityPropertiesBlanksWarning: 0,
                loadedProperties: true
            });
        }
    }

    closePropertiesAlert = () => {
        this.setState({showPropertiesAlert: false, showPropertiesAlertWarning: false});
    }

    yesPropertiesAlert = () => {
        this.setState({showPropertiesAlertWarning: false}, () => {
            validateCountryRiskScore('Beneficiary', this.state.outgoing.BeneficiaryID).then(
                (json) => {
                    if(json.Result !== undefined){
                        if(!json.Result && this.state.outgoing.Status !== 'Void'){
                            this.setState({ validateViolation: false, showBeneficiaryHighRiskAlert: true, beneficiaryHighRiskMessage: 'The Beneficiary country has a high risk level. Do you want to save the outgoing on Pending status?' })
                        } else {
                            this.setState({ validateViolation: true });
                            this.save();                        
                        }
                    } else {
                        var alertMessage = 'Outgoing Fund not saved. Please try again or contact the administrator.';
                                    
                        if(json.httpStatusMessage !== undefined)
                        {
                            alertMessage = json.httpStatusMessage;
                        }
                        if(json.Message !== undefined)
                        {
                            alertMessage = alertMessage + ' (' + json.Message + ')';
                        }
                        this.setState({
                            alertMessage: alertMessage,
                            alertTitle: 'Error',
                            showAlert: true,
                        });
                    }
                }
            );
        });
    }
    
    yesBeneficiaryHighRiskAlert = () => {
        this.setState({showBeneficiaryHighRiskAlert: false, outgoing: {...this.state.outgoing, Status: 'Pending'}});
        //this.UpdateOutgoingFundClick();
        //this.saveOutgoing();
        this.save();
    }

    closeBeneficiaryHighRiskAlert = () =>{
        this.setState({showBeneficiaryHighRiskAlert: false, validateViolation: true, outgoing: {...this.state.outgoing, Status: 'Completed'}});
        //this.UpdateOutgoingFundClick();
        //this.saveOutgoing();
        this.save();
    }    
    
    yesFundsForReferralAlert = () => {
        let referalFromCompliance = this.state.referalFromCompliance;
        this.setState({ showFundsForReferralAlert: false, referalFromCompliance: false });
        if (this.state.saveOutgoingToBankInterface) {
            this.setState({ saveOutgoingToBankInterface: false }, () => {
                this.saveToInterface();
            })
        } else {
            if (referalFromCompliance) {
                this.setState({ outgoing: { ...this.state.outgoing, Status: 'Completed', ComplianceSoftRulesAcceptedByUser: true } },
                    () => { this.updateOutgoingIntern() });
            } else {
                this.UpdateOutgoingFundStatus('Completed');
            }
        }
    }

    closeFundsForReferralAlert = () =>{
        if(this.state.outgoing.Status != 'Pending')
        {
            this.UpdateOutgoingFundStatus('Pending');
        }
        this.setState({showFundsForReferralAlert: false, externalPaymentInterface:''});
    }

    saveComplete = async () =>
    {
        var update = await this.UpdateOutgoingFundClick();
        if(update){
            this.setState({saveOutgoingToBankInterface: false, saveInComplete: false});
            validateCountryRiskScore('Beneficiary', this.state.outgoing.BeneficiaryID).then(
                (json) => {
                    if(json.Result !== undefined){
                        if(!json.Result && this.state.outgoing.Status !== 'Void'){
                            this.setState({ validateViolation: false, showBeneficiaryHighRiskAlert: true, beneficiaryHighRiskMessage: 'The Beneficiary country has a high risk level. Do you want to save the outgoing on Pending status?' })
                        } else {
                            this.setState({ validateViolation: true });
                            this.save();                        
                        }
                    } else {
                        var alertMessage = 'Outgoing Fund not saved. Please try again or contact the administrator.';
                                    
                        if(json.httpStatusMessage !== undefined)
                        {
                            alertMessage = json.httpStatusMessage;
                        }
                        if(json.Message !== undefined)
                        {
                            alertMessage = alertMessage + ' (' + json.Message + ')';
                        }
                        this.setState({
                            alertMessage: alertMessage,
                            alertTitle: 'Error',
                            showAlert: true,
                        });
                    }
                }
            );
        }
    }

    saveInPending = async() =>{

        var outgoingID = 0;
        var outgoing = this.state.outgoing;
        outgoing.Status = 'Pending';

        var json = await updateOutgoingFund(outgoing);
                       
        var alertTitle = '';
        var alertMessage = '';

        if(json.httpStatusCode !== undefined && json.httpStatusCode !== 200)
        {
            alertTitle = 'Error';
                    //alertMessage = json.Message;

            var alertMessage = 'Outgoing Fund not saved. Please try again or contact the administrator.';
                                
                    if(json.httpStatusMessage !== undefined)
                    {
                        alertMessage = json.httpStatusMessage;
                    }

                    if(json.Message !== undefined)
                    {
                        if(json.Message.indexOf('concurrency') !== -1)
                        {
                            alertMessage = 'Please reload this screen. Another user has posted changes before your submit.';
                        }else{
                            alertMessage = alertMessage + ' (' + json.Message + ')';
                        }
                    }
                    this.setState({alertTitle: alertTitle, alertMessage: alertMessage, showAlert: true});
        }else 
        {
                    outgoing.UpdateToken = json.updateToken;
                    outgoing.OutgoingFundsID = json.outgoingID;
                    outgoingID = json.outgoingID;

                    if (this.props.onUpdateID != null) {
                        this.props.onUpdateID(json.outgoingID)
                    }
                    if (this.props.onUpdateStatus != null) {
                        this.props.onUpdateStatus(outgoing.Status)
                    }

                    this.props.onSaveSuccess();
                    this.setState({outgoing: outgoing});
        }

        return outgoingID;
    }

    saveInPendingCheck = async() =>{ // save, then validate is exists mandatories properties, if there isn't save in Complete
        if (this.validateRequiredFields()) {
            var outgoingID = await this.saveInPending();
            this.setState({saveInComplete: true});
        }
    }

    setLoadedProperties = () => {
        this.setState({loadedProperties: true});
    }

    validateStatus = (event)=>{
        event.preventDefault();
        if(this.state.previewStatus ==='Completed' && (this.state.outgoing.Status === 'Pending' || this.state.outgoing.Status === 'Void'))
        {
            if(this.state.dealHeaderInfo !== undefined /*&& this.state.dealHeaderInfo.ValueDate < this.state.systemDate*/)
            {
                this.setState({messageStatusConfirmation: "Are you sure you want to unapply the payment to the deal with Value Date "+this.state.dealHeaderInfo.ValueDate+"?"
                ,showStatusConfirmation: true})
            }
        }
        else if(this.state.previewStatus === 'Sent to Partner' && (this.state.outgoing.Status === 'Completed' || this.state.outgoing.Status === 'Void')){
            this.setState({messageStatusConfirmation: "This outgoing has been sent to a partner. Are you sure you want to proceed?"
            ,showStatusConfirmation: true})
        }else {
            this.validateBeneficiaryRiskScore()        
        }
    }

    closeStatusConfirmation = () => {
        this.setState({showStatusConfirmation: false})
    }

    yesStatusConfirmation = () => {
        this.setState({showStatusConfirmation: false})
        this.validateBeneficiaryRiskScore()
    }

    validateBeneficiaryRiskScore = async() =>{
        if(this.state.outgoing.Status == 'Completed' && this.state.outgoing.OutgoingFundsID == 0)
        {
            this.setState({outgoing: {...this.state.outgoing, Status: 'Pending'}});
            var saveInPendingCheck = await this.saveInPendingCheck();
        }
        else
        {
            this.saveComplete();
        }
    }
    
    UpdateOutgoingFundClick= async()=>{
        var result = false;
        if(this.state.beneID > 0)
        {
            this.setState({outgoing: {...this.state.outgoing, BeneficiaryID: this.state.beneID}})
        }
        
        if(this.state.outgoing.Status === 'Rejected'){
            var feeAmount = await updateRejectedFeeAmount(this.state.outgoing);
        }

        var outgoingFundExt = null;

                if(this.state.fromRefunds)
                {
                    outgoingFundExt = {
                        OutgoingFundsID: this.state.outgoing.OutgoingFundsID,
                        RefundBranchID: this.state.refundBranchID,
                        RefundPersonID: this.state.refundPersonID,
                        UpdatedBy: localStorage.getItem('UserID')
                    }
                    this.setState({outgoing: {...this.state.outgoing, OutgoingFundExt: outgoingFundExt
                        ,IsRefund : this.state.fromRefunds}});
                }

        if (this.validateRequiredFields()) {
            if(this.state.outgoing.Status != 'Completed')
            {
                if(this.state.entityPropertiesBlanks > 0 || this.state.entityPropertiesBlanksWarning > 0)
                {
                this.setState({showPropertiesAlertWarning: true});
                }
                else
                {
                    //this.save();
                    result = true;
                }
            }
            else
            {
                if(this.state.entityPropertiesBlanks > 0 || this.state.entityPropertiesBlanksWarning > 0)
                {
                    this.setState({outgoing: {...this.state.outgoing, Status: 'Pending'}}, () =>
                    this.setState({showPropertiesAlert: true}) );
                }
                else
                {
                    //this.save();
                    result = true
                }
            }
        }

        return result;
    }

    UpdateOutgoingFundStatus = (newStatus) => {
        updateOutgoingFundStatus(this.state.outgoing.OutgoingFundsID, newStatus, this.state.outgoing.CreatedBy).then(
            (json) => {
                if(json.Result){
                    let saveSuccess = false;
                    if(newStatus === 'Completed')
                    {
                        saveSuccess = true;
                    }
                    this.setState({ alertMessage: this.state.alertMessage + ' The outgoing fund has been referred for further review.', showAlert: true, saveSuccess: saveSuccess});
                }
            }
        )
    }

    //#region Message Confirm
    showConfirmMessageSendInterface(){
        let interfaceName = this.state.externalPaymentInterface;        
        if (interfaceName === '') {
            this.setState({
                showAlert: true,
                alertTitle: 'Warning',
                alertMessage: 'Please select a payment interface'
            })
            return
        }
        this.openConfirm('Send Interface','Are you sure you want to send this payment to ' + interfaceName + '?');
    }

    openConfirm(title, message){
        this.setState({showConfirm: true, alertTitle: title, alertMessage: message});
    }

    yesConfirm(){
        let saveToInterface = true
        if(this.state.externalPaymentInterface === "Apaylo - Payments") {
           if(this.state.availableBalance >= this.state.outgoing.PaymentAmount){
                this.saveOutgoingToBankInterface();
                this.setState({showConfirm: false});
           } else {
                this.setState({
                    alertMessage: "Your account does not have enough balance. Please try again.",
                    alertTitle: 'Warning',
                    showAlert: true,
                    showConfirm: false
                });
           }
        } else {
            this.saveOutgoingToBankInterface();
            this.setState({showConfirm: false, showBalances: false});
        }
    }

    getBalanceForInterface = async () => { 
        getApayloBalance().then(
            (json) => {
                if(json !== undefined && json.availableBalance !== undefined) {
                    this.setState({availableBalance: json.availableBalance, actualBalance: json.actualBalance, showBalances: true, externalPaymentInterface: 'Apaylo - Payments'});
                } else {        
                    let alertMessage = "";                        
                    if(json.httpStatusMessage !== undefined)
                    {
                        alertMessage = json.httpStatusMessage;
                    }
                    if(json.Message !== undefined)
                    {
                        alertMessage = alertMessage + ' (' + json.Message + ')';
                    }
                    this.setState({
                        alertMessage: alertMessage,
                        alertTitle: 'Error',
                        showAlert: true,
                        showConfirm: false
                    });
                }
            }
        )
    }
    
    closeConfirm(){
        this.setState({showConfirm: false});
    }
    //#endregion

    sendConfirmation = async(email) =>{
        
         let sendOutgoingFundReportByEmailRequest = {
                DealHeaderID: this.state.outgoing.OutgoingFundsID,
                Language: this.state.preferredLanguage
            }             


            let DealMailInfo = []

            DealMailInfo.push({MainInfo: email, DocType: DocumentTypes.WIRETRANSFER})
            sendOutgoingFundReportByEmailRequest.DealMailInfo = DealMailInfo;   

            return sendOutgoingFundReportByEmail(sendOutgoingFundReportByEmailRequest);
        
    }

    checkForSendConfirmation = async() => {
        this.setState({loading: true});
        let propertiesC = await getEntityProperties(this.state.customerID, CustomerType);
        let propertiesB = await getEntityProperties(this.state.outgoing.BeneficiaryID, BeneficiaryType);
        if(propertiesC && propertiesB)
        {
        let sendToCustomer = propertiesC.entityProperties.find(x => x.Description === 'Receive Automatic Payment Confirmation');
        let sendToBeneficiary = propertiesB.entityProperties.find(x => x.Description === 'Receive Automatic Payment Confirmation');
        let customer = await getCustomerByID(this.state.customerID);

        if(sendToCustomer !== undefined && sendToCustomer.TextValue !== null && sendToCustomer.TextValue.toLowerCase() === 'yes' && customer.Email !== undefined && customer.Email !== '')
        {
            let sendToBene = sendToBeneficiary !== undefined && sendToBeneficiary.TextValue !== null ? sendToBeneficiary.TextValue: 'no';
      
            this.setState({preferredLanguage: customer.PreferredLanguage,
                email: customer.Email,
                sendToBene: sendToBene,
                showConfirmationSendCustomerEmail: true,
                loading: false});
        }
        else{
            if(sendToBeneficiary !== undefined && sendToBeneficiary.TextValue !== null && sendToBeneficiary.TextValue.toLowerCase() === 'yes')
            {
                let beneficiary = await getBeneficiariesByID(this.state.outgoing.BeneficiaryID);
                if(beneficiary !== undefined && beneficiary.length > 0 && beneficiary[0].ContactEmail !== '')
                {
                    this.setState({
                        email: beneficiary[0].ContactEmail,
                        showConfirmationSendBeneEmail: true,
                        loading: false});
                }
            }
            else
            {
                this.setState({
                    loading: false});
            }
        }
    }
    }

    yesSendCustomerConfirmation = async() =>{
        let reponseSend = await this.sendConfirmation(this.state.email);
        if (reponseSend.httpStatusCode !== 200) {
            this.setState({
                showAlert: true,
                alertMessage: 'An error occurred while sending the report. Please try again.',
                alertTitle: 'Error',
                saveSuccess: false
            });
        } else {
            if (typeof reponseSend.error !== undefined && reponseSend.error !== '') {
                this.setState({
                    showAlert: true,
                    alertMessage: reponseSend.error,
                    alertTitle: 'Error',
                    saveSuccess: false
                })
            } else {
                this.setState({
                    showAlert: true,
                    alertTitle: 'Success',
                    alertMessage: 'Email confirmation sent.',
                    saveSuccess: false,
                    checkForBene: true
                });
            }                    
        }

        this.setState({
            showConfirmationSendCustomerEmail: false});
    }

    closeSendCustomerConfirmation = async() =>{
        this.setState({
            showConfirmationSendCustomerEmail: false});
        if(this.state.sendToBene.toLowerCase() === 'yes')
        {
            let response = await getBeneficiariesByID(this.state.outgoing.BeneficiaryID);
            if(response !== undefined)
            {
                let beneficiary = response.beneficiary;
                if(beneficiary !== undefined && beneficiary.length > 0 && beneficiary[0].ContactEmail !== '')
                {
                    this.setState({
                        email: beneficiary[0].ContactEmail,
                        showConfirmationSendBeneEmail: true});
                }
            }
        }
    }

    yesSendBeneConfirmation = async() =>{
        let reponseSend = await this.sendConfirmation(this.state.email);
        if (reponseSend.httpStatusCode !== 200) {
            this.setState({
                showAlert: true,
                alertMessage: 'An error occurred while sending the report. Please try again.',
                alertTitle: 'Error',
                checkForBene: false
            });
        } else {
            if (typeof reponseSend.error !== undefined && reponseSend.error !== '') {
                this.setState({
                    showAlert: true,
                    alertMessage: reponseSend.error,
                    alertTitle: 'Error',
                    checkForBene: false
                })
            } else {
                this.setState({
                    showAlert: true,
                    alertTitle: 'Success',
                    alertMessage: 'Email confirmation sent.',
                    checkForBene: false
                });
            }                    
        }
        this.setState({
            showConfirmationSendBeneEmail: false});
    }

    closeSendBeneConfirmation = () =>{
        this.setState({
            showConfirmationSendBeneEmail: false});
    }
    
    formatComplianceVisibility() {
        if (this.state.enableCompliance !== undefined) {
            if (this.state.enableCompliance) {
                return "flex";
            }
            else {
                return "none";
            }
        } else { return "none"; }
    }

    handleUpdateProcessStatus() {
        if(this.state.outgoing.ProcessStatus !== 'Sent for First Review' 
        && this.state.outgoing.ProcessStatus !== 'Sent for Second Review'
        && this.state.outgoing.ProcessStatus !== 'Approved'){
            this.setState({ customer: { ...this.state.customer, AssignTo: localStorage.getItem('UserID') } });
        }

        this.updateProcessStatus();
        if (this.state.outgoing.Status === 'Registered Online') {
            this.deleteWorkAssignments();
        }
    }

    updateProcessStatus() {
        updateProcessStatus(this.state.outgoing.OutgoingFundsID, 'OutgoingFund', this.state.outgoing.ProcessStatus, this.state.outgoing.AssignTo).then(
            (json) => {
                console.log(json)
                if (json.httpStatusCode !== undefined && json.httpStatusCode !== 200) {
                    this.setState({ showAlert: true, alertMessage: json.Message, alertTitle: 'Error' });
                } else {
                    this.setState({
                        showAlert: true, alertMessage: 'Process Status updated.', alertTitle: 'Success'
                    });
                }
            }
        )
    }

    deleteWorkAssignments() {
        deleteWorkAssignments(this.state.outgoing.OutgoingFundsID, 'OutgoingFund').then(
            (json) => {
            }
        )
    }
    
    handleProcessStatus = (value) => {
        if (value != null && value !== "select") {
            let roleName = ''
            if (value.value === 'Sent for First Review') {
                roleName = 'FirstReview';
            }
            else if (value.value === 'Sent for Second Review') {
                roleName = 'SecondReview'
            }
            else if (value.value === 'Approved') {
                roleName = 'AMLCountryCompliance'
            }
            if (roleName !== '') {
                let disableAssignTo = true;
                getUsersByRole(roleName).then(
                    (json) => {
                        if (json !== undefined) {
                            if (json.users !== undefined) {
                                if (json.users.length > 0) {
                                    disableAssignTo = false;
                                }
                            }
                        }
                        if (disableAssignTo === false) {
                            for (var i = 0; i < json.users.length; i++) {
                                json.users[i].value = json.users[i].UserId;
                                json.users[i].name = json.users[i].UserName;
                            }
                            this.setState({
                                outgoing: { ...this.state.outgoing, ProcessStatus: value.value },
                                Users: json.users, disableAssignToButton: disableAssignTo
                            });
                        }
                    });
            }
            else {
                this.setState({ outgoing: { ...this.state.outgoing, ProcessStatus: value.value } });
            }
        }
    }

    handleAssignTo = (value) => {
        if (value != null && value !== "select") {
            this.setState({ outgoing: { ...this.state.outgoing, AssignTo: value.value } });
        }
    }

    render() {        
        let list = this.state.outgoing;
        //Get delivery methods
        var deliveryMethods = [];
        if(list.PaymentInstrument !== undefined &&
            list.PaymentInstrument !== '' &&
            list.PaymentInstrument !== 'select'){
            deliveryMethods = this.state.deliveryMethodList[list.PaymentInstrument];
        }

        let headerMessage = [];
        if (this.state.outgoing.OutgoingFundsID > 0) {
            /*headerMessage.push('Outgoing Fund ID: ' + this.state.outgoing.OutgoingFundsID) 
            headerMessage.push(<br />)*/
            headerMessage.push('Status: ' + this.state.outgoing.Status)
        }

        if (this.state.viewHistory) {
            return (<AuditOutgoingFundScreen backLink={this.handleReturnToOutgoingFund.bind(this)} outgoingFundID={this.state.outgoing.OutgoingFundsID} />);
        }
        
        //Save button
        var saveButton;
        if(this.state.previewStatus !== 'Void'){
            saveButton = (<button className="uk-button uk-button-green" type="button" onClick={this.validateStatus.bind(this)} disabled={this.state.outgoing != null && this.state.outgoing.Status === 'Rejected'} >Save</button>);
        }

        var disableControlsIntrument = this.state.disableControls;
        var disableStatus = this.state.disableStatus;
        if(this.state.fromRefunds)
        {
            disableControlsIntrument = !this.state.fromRefunds;
            disableStatus = this.state.fromRefunds;
        }

        var disableInterface = false;
        if(this.state.outgoing.InterfaceSource != undefined && this.state.outgoing.InterfaceSource != ''
         && this.state.outgoing.InterfaceID != undefined && this.state.outgoing.InterfaceID != '')
        {
            disableInterface = true;
        }

        let showSwiftSend = this.state.outgoing.BeneficiaryBankAccountID > 0 && this.state.outgoing.NostroAccountID > 0
            && this.state.outgoing.Status === "Completed" && this.state.outgoing.SwiftUETR === ""
        let showSwiftGetStatus = this.state.outgoing.SwiftUETR !== undefined && this.state.outgoing.SwiftUETR !== ""

        var openProperties = "";
        if(this.state.entityPropertiesBlanks > 0)
        {
            openProperties = "uk-open";
        }

        return(
            <div>
                <form className="">
                    {/*<div className="uk-heading-divider" uk-grid="true" style={{verticalAlign: "middle"}}>
                        <div className="uk-width-expand" style={{textAlign: "right"}}>
                            <h3  style={{paddingLeft: "0x",paddingTop: "5px",paddingRight: "25px", paddingBottom: "10px"}}>
                                {headerMessage}
                            </h3>
                        </div>
                        <div className="" style={{textAlign: "right",paddingTop: "10px", marginBottom: 10}}>
                            <label className="uk-margin-right" style={{fontSize: '1.10em', fontWeight:700}} hidden={this.state.outgoing.OutgoingFundsID === 0}>Outgoing Fund Status</label>
                            <label hidden={this.state.outgoing.OutgoingFundsID === 0}>{this.state.outgoing.Status}</label>                
                        </div> 
                    </div>*/}
                    <div className="uk-grid uk-margin" style={{ display: this.formatComplianceVisibility() }}>
                        <div className="uk-width-1-6" style={{ visibility: "hidden" }}>
                            <label className="uk-form-label" ></label>
                        </div>
                        <div className="uk-width-1-6">
                            <label className="uk-form-label" >Process Status</label>
                        </div>
                        <div className="uk-width-1-6">
                            <DynamicSelect objValue={{ value: this.state.outgoing.ProcessStatus }} getValue={this.handleProcessStatus} listHasPlaceholder={true} data={this.state.ProcessStatuses} id="form-process-status" />
                        </div>
                        <div className="uk-width-1-6">
                            <button className="uk-button uk-button-green" onClick={this.handleUpdateProcessStatus.bind(this)} type="button">Update process Status</button>
                        </div>
                    </div>
                    {/*<div style={{ textAlign: "right" , marginBottom: "5px"}}>
                        <h4 style={{marginBottom: 0, lineHeight: 1}}>{headerMessage}</h4>
                </div> */}

                    <div className="uk-grid uk-margin">
                        <div className="uk-width-1-2">
                            <label className="uk-form-label">Beneficiary</label>
                            {!this.state.readOnly ?
                                <DynamicSelect 
                                    id                  = "form-beneficiary" 
                                    className           = {this.formatValidSelect(this.state.validations.beneficiaryaccount)} 
                                    disabled            = {this.state.disableControls} 
                                    getValue            = {this.handleGetBeneficiaryBankAccount} 
                                    objValue            = {{value:this.state.outgoing.BeneficiaryID + '|' + this.state.outgoing.BeneficiaryBankAccountID}} 
                                    listHasPlaceholder  = {false} 
                                    placeholder         = {"Select an Item..."} 
                                    data                = {this.state.beneficiaryAccountList} />
                                :
                                <p id="label-beneficiary" style={{marginTop: '5px'}}>
                                    {this.state.outgoing.BeneficiaryID > 0 && this.state.beneficiaryAccountList.length > 0 ?
                                        this.state.beneficiaryAccountList.find(n => n.BeneficiaryID === this.state.outgoing.BeneficiaryID).name : "-"}
                                </p>
                            }
                        </div>
                        
                        <div className="uk-width-1-2">
                            <label className="uk-form-label" htmlFor="form-deal-valueDateFrom" style={style.boldLabel}>Value Date(*)</label>
                            {!this.state.readOnly ?
                                <DatePicker 
                                    disabledDays = {[0,6]} 
                                    className    = {this.formatValidInput(this.state.validations.valuedate)} 
                                    disabled     = {this.state.disableControls} 
                                    value        = {this.state.outgoing.ValueDate} 
                                    startDate    = {this.state.startDate}
                                    onDayChange  = {this.handleUpdateValueDate.bind(this)}
                                /> :
                                <p id="label-incomingpaymentform-dateReceived" style={{marginTop: '5px'}}>
                                    {this.state.outgoing.ValueDate !== "" ? formatDate(this.state.outgoing.ValueDate) : "-"}
                                </p>
                            }
                        </div>
                        
                        <div className="uk-width-1-2">
                            <div className='uk-grid' style={{display:'flex', flexDirection:'row', paddingTop: 5}}>
                                <label className="uk-width-1-2 uk-form-label" style={style.boldLabel} >Payment Amount (*)</label>
                                <label className="uk-width-1-2 uk-form-label" style={style.boldLabelRight} >Remaining amount ({formatToCurrencyWithScale(this.props.sellAmount-this.props.totalAmountOutgoings-(this.state.outgoing.OutgoingFundsID  !== 0 ? 0:this.state.outgoing.PaymentAmount),this.state.sellCcy)})</label>
                            </div>
                            {!this.state.readOnly ?
                                <NumberInput className={this.formatValidInput(this.state.validations.paymentamount)} id="form-outgoing-funds-payment-amount" disabled={this.state.disableControls} placeholder="0,00" type="Currency"
                                scale={getScaleByCurrency(this.state.sellCcy)}
                                value={this.state.outgoing.PaymentAmount} onChange={this.handleUpdatePaymentAmount.bind(this)}/>
                                :
                                <p id="label-outgoing-funds-payment-amount" style={{marginTop: '5px'}}>
                                    {formatToCurrencyWithScale(this.state.outgoing.PaymentAmount,this.props.sellCcy)}
                                </p>
                            }
                        </div>

                        <div className="uk-width-1-2">
                            <label className="uk-form-label" style={style.boldLabel} >Delivery Method</label>
                            {!this.state.readOnly ?
                                <DynamicSelect id="form-delivery-method" className="uk-select" disabled={this.state.disableControls} getValue={this.handleGetDeliveryMethod.bind(this)} value={this.state.outgoing.DeliveryMethod} objValue={{value: this.state.outgoing.DeliveryMethod}} 
                                listHasPlaceholder={false} placeholder="Select a Method..." data={deliveryMethods || []} />
                                :
                                <p id="label-delivery-method" style={{marginTop: '5px'}}>
                                    {this.state.outgoing.DeliveryMethod != null && this.state.outgoing.DeliveryMethod !== "" ?this.state.outgoing.DeliveryMethod : "-"}
                                </p>
                            }
                        </div>
                        
                        <div className="uk-width-1-2">
                            <label className="uk-form-label" style={style.boldLabel} >Instrument to deliver(*)</label>
                            {!this.state.readOnly ?
                                <DynamicSelect 
                                    id="form-payment-instrument" 
                                    className={this.formatValidSelect(this.state.validations.instrumenttodeliver)} 
                                    disabled={disableControlsIntrument || this.state.outgoing.BeneficiaryID === -2} 
                                    getValue={this.handleGetInstrumentToDeliver} 
                                    objValue={{value: this.state.outgoing.PaymentInstrument}} 
                                    listHasPlaceholder={false} 
                                    placeholder={"Select an Item..."} 
                                    data={this.state.outgoing.BeneficiaryID !== -2 ?this.state.outgoingInstrumentList: this.state.tbaOutgoingInstrumentList} />
                                :
                                <p id="label-payment-instrument" style={{marginTop: '5px'}}>
                                    {this.state.outgoing.PaymentInstrument != null && this.state.outgoing.PaymentInstrument !== "" ?this.state.outgoing.PaymentInstrument : "-"}
                                </p>
                            }
                        </div>

                        <div className="uk-width-1-2">
                            <label className="uk-form-label">Delivery reference Number</label>
                            {!this.state.readOnly ?
                                <input id="form-delivery-reference-number" className="uk-input" disabled={this.state.disableControls} type="text" value={this.state.outgoing.DeliveryRefNo} onChange={this.handleUpdateDeliveryRefNo.bind(this)} />
                                :
                                <p id="label-delivery-reference-number" style={{marginTop: '5px'}}>
                                    {this.state.outgoing.DeliveryRefNo != null && this.state.outgoing.DeliveryRefNo !== "" ?this.state.outgoing.DeliveryRefNo : "-"}
                                </p>
                            }
                        </div>
                        
                        <div className="uk-width-1-2">
                            <label className="uk-form-label" style={style.boldLabel}>Nostro Bank Account(*)</label>
                            {!this.state.readOnly ?
                                <DynamicSelect className={this.formatValidSelect(this.state.validations.nostroaccount)} objValue={{value: this.state.outgoing.NostroAccountID}} disabled={disableControlsIntrument} getValue={this.handleGetNostroAccount.bind(this)} listHasPlaceholder={false} placeholder={"Select an Item..."} data={this.state.dropdowns.nostroBankAccounts} id="form-nostro-account" />
                                :
                                <p id="label-outgoingpaymentform-nostroaccounts" style={{marginTop: '5px'}}>
                                    {this.state.outgoing.NostroAccountID > 0 && this.state.dropdowns.nostroBankAccounts.length > 0 ?
                                        this.state.dropdowns.nostroBankAccounts.find(n => n.value === this.state.outgoing.NostroAccountID).name : "-"}
                                </p>
                            }
                        </div>

                        <div className="uk-width-1-2">

                        </div>

                        <div className="uk-width-1-2">
                            <label className="uk-form-label">Details of Payment</label>
                            {!this.state.readOnly ?
                                <textarea className="uk-textarea" disabled={this.state.disableControls}
                                    value={this.state.outgoing.DetailsOfPayment} onChange={this.handleUpdateDetailsOfPayment.bind(this)}></textarea>
                                :
                                <p id="label-delivery-reference-number" style={{marginTop: '5px'}}>
                                    {this.state.outgoing.DetailsOfPayment != null && this.state.outgoing.DetailsOfPayment !== "" ?this.state.outgoing.DetailsOfPayment : "-"}
                                </p>
                            }
                        </div>

                        <div className="uk-width-1-2">
                            <label className="uk-form-label">Payment Reference</label>
                            {!this.state.readOnly ?
                                <textarea className="uk-textarea" disabled={this.state.disableControls}
                                    value={this.state.outgoing.PaymentReference} onChange={this.handleUpdatePaymentReference.bind(this)}></textarea>
                                :
                                <p id="label-delivery-reference-number" style={{marginTop: '5px'}}>
                                    {this.state.outgoing.PaymentReference != null && this.state.outgoing.PaymentReference !== "" ?this.state.outgoing.PaymentReference : "-"}
                                </p>
                            }
                        </div>

                        <div className="uk-width-1-2">
                            <label className="uk-form-label">Comments</label>
                            {!this.state.readOnly ?
                                <textarea className="uk-textarea" disabled={this.state.disableControls}
                                    value={this.state.outgoing.Comments} onChange={this.handleUpdateComments.bind(this)}></textarea>
                                :
                                <p id="label-delivery-reference-number" style={{marginTop: '5px'}}>
                                    {this.state.outgoing.Comments != null && this.state.outgoing.Comments !== "" ?this.state.outgoing.Comments : "-"}
                                </p>
                            }
                        </div>

                        <div className="uk-width-1-2">
                            <label className="uk-form-label" style={style.boldLabel}>Outgoing Risk Score(*)</label>
                            <div style={{paddingTop: "3px"}}>
                                <Badge
                                    anchorOrigin={{
                                        vertical    : 'top',
                                        horizontal  : 'right',
                                    }}
                                    badgeContent={
                                        <InfoIcon 
                                            uk-tooltip  = {'Risk Calculation Outgoing Fund'}
                                            style       = {{ color: '#6E9C3A' }}
                                        />
                                    }
                                    disabled={true}
                                >
                                    <div 
                                        className   = "" 
                                        type        = "button" 
                                        style       = {{
                                            borderRadius    : 10,
                                            border          : '1px solid #e5e5e5',
                                            height          : 30,
                                            justifyContent  : 'center',
                                            display         : 'flex',
                                            alignItems      : 'center',
                                            paddingLeft      : 10,
                                            paddingRight     : 10,
                                            backgroundColor : this.state.backgroundColorRiskScore
                                        }}
                                    >
                                        <label 
                                            onClick     = {this.onShowRiskCalculationDetails}
                                            className   =""
                                            style       = {{ color: this.state.outgoingRiskScore !== undefined ? this.state.outgoingRiskScore['Color Hex'] : '', fontWeight: this.state.fontWeight, cursor:'pointer'}}
                                            id          = "outGoingRiskScore"
                                        >{this.state.outgoingRiskScore !== undefined && ((this.state.outgoingRiskScore.Description || '') !== '') ?
                                        this.state.outgoingRiskScore.Description :
                                        'N/A'}
                                        </label>
                                    </div>
                                </Badge>
                            </div>                            
                        </div>
                        
                        <div className="uk-width-1-2">
                            <label className="uk-form-label" style={style.boldLabel} >Status(*)</label>
                            {!this.state.readOnly ?
                                <DynamicSelect className={this.formatValidSelect(this.state.validations.status)} disabled={disableStatus}// || this.state.disableControls}
                                    objValue={{value: this.state.outgoing.Status}} getValue={this.handleGetStatus.bind(this)} listHasPlaceholder={true} data={this.state.dropdowns["Outgoing Fund Status"]} id="form-outgoing-fund-status" />
                                :
                                <p id="label-outgoing-fund-status" style={{marginTop: '5px'}}>
                                    {this.state.outgoing.Status != null && this.state.outgoing.Status !== "" ?this.state.outgoing.Status : "-"}
                                </p>
                            }
                        </div>
                        <div className="uk-width-1-4">
                            <label className="uk-form-label">Wire Fee (Buy side)</label>
                            {/*<input className="uk-input" type="number" placeholder="0.00" value={this.state.outgoing.WireItemFee} onChange={this.handleUpdateWireItemFee.bind(this)}/>*/}
                            {!this.state.readOnly ?
                                <NumberInput className="uk-input" id="form-outgoing-funds-wire-fee" disabled={this.state.disableControls} placeholder="0,00" type="Currency" 
                                scale={getScaleByCurrency(this.state.outgoing.WireItemFeeCurrencyID)} 
                                value={this.state.outgoing.WireItemFee} onChange={this.handleUpdateWireItemFee.bind(this)}/>
                                :
                                <p id="label-outgoing-funds-wire-fee" style={{marginTop: '5px'}}>
                                    {formatToCurrencyWithScale(this.state.outgoing.WireItemFee,this.state.outgoing.WireItemFeeCurrencyID)}
                                </p>
                            }
                        </div>
                        <div className="uk-width-1-4">
                            <div className="uk-margin" style={{marginTop: 26, paddingBottom:"5px", textAlign:'left'}}>
                                <label>{this.state.outgoing.WireItemFeeCurrencyID}</label>
                            </div>
                        </div>

                        {/* row n */}                       
                        
                        <div className="uk-width-1-2">
                            <div className="uk-grid uk-margin">
                                <div className="uk-width-2-3">
                                    <label className="uk-form-label">Payment Interface</label>
                                </div>
                                <div className="uk-width-1-3">
                                </div>
                                <div className="uk-width-2-3">
                                    <DynamicSelect listHasPlaceholder={true} data={this.state.paymentBankInterfaces} id="form-paymentBankInterfaces"
                                        objValue={{ value: this.state.externalPaymentInterface }}
                                        getValue={this.handlePaymentBankInterfaces}
                                        disabled={((this.state.outgoing.OutgoingFundsID || 0) === 0 && this.state.outgoing.BeneficiaryBankAccountID === 0)
                                            || this.state.fromRefunds || disableInterface || this.state.disableControls || !this.state.releaseOfFunds} />
                                </div>
                                <div className="uk-width-1-3">
                                    <button className="uk-button uk-button-green" type="button" onClick={this.showConfirmMessageSendInterface.bind(this)}
                                        disabled={((this.state.outgoing.OutgoingFundsID || 0) === 0 && this.state.outgoing.BeneficiaryBankAccountID === 0)
                                            || this.state.fromRefunds || disableInterface || this.state.disableControls || this.state.readOnly || !this.state.releaseOfFunds}>Send and Save</button>
                                </div>
                                <div className="uk-width-1-3">
                                    {this.state.showBalances && <label className="uk-form-label">{this.state.availableBalanceLabel}{formatToCurrencyWithScale(this.state.availableBalance, 'CAD')} CAD</label>}
                                </div>
                                <div className="uk-width-1-3">
                                    {this.state.showBalances && <label className="uk-form-label">{this.state.actualBalanceLabel}{formatToCurrencyWithScale(this.state.actualBalance, 'CAD')} CAD</label>}
                                </div>
                            </div>
                        </div>

                        <div className="uk-width-1-2">
                            { (this.state.purposeOfPayments.length > 1) &&
                            <div className="uk-grid uk-margin">
                                <div className="uk-width-2-3">
                                    <label className="uk-form-label">Purpose of Payments</label>
                                </div>
                                <div className="uk-width-1-3">
                                </div>
                                <div className="uk-width-2-3">
                                    <DynamicSelect listHasPlaceholder={true}  data={this.state.purposeOfPayments} id="form-purpose of Payments" 
                                        objValue={{value: this.state.purposeOfPayment_value}} getValue={this.handlePurposeOfPayments} />
                                </div>
                            </div>
                            }
                        </div>
                        <div className="uk-width-1-2">
                        </div>

                    </div>
                    
                    <div className="uk-width-1-1 uk-margin">
                        <hr class=".uk-divider-icon" style={{ height: 0.5, backgroundColor: '#E5DBD8' }}></hr>
                    </div>
                        
                        <div>
                            {!this.state.readOnly && saveButton}
                            {!this.state.readOnly && !this.state.fromRefunds && <button className="uk-button uk-button-green" type="button" onClick={this.handleClearClick.bind(this)}>Clear Fields</button>}
                            {!this.state.fromRefunds && <button className="uk-button uk-button-green" type="button" onClick={this.handleViewHistoryClick.bind(this)} disabled={this.state.disable}> View History</button>}
                            {
                                this.state.outgoing.DeliveryMethod === "Wire Transfer" &&
                                this.state.outgoing.BeneficiaryBankAccountID != null &&
                                this.state.outgoing.NostroAccountID != null &&
                                this.state.outgoing.BeneficiaryBankAccountID > 0 &&
                                this.state.outgoing.NostroAccountID > 0 &&
                                <button className="uk-button uk-button-green" type="button" onClick={this.handleCreateMT103Click} disabled={this.state.disable}>Export MT103</button>
                            }
                            {
                                this.state.outgoing.Status === "Completed" && !this.state.fromRefunds &&
                                <button className="uk-button uk-margin-right uk-button-green" type="button" onClick={this.handlePrintSendPaymentConfirmation.bind(this)} disabled={this.state.readOnly} >Print/Send Payment Conf.</button>
                            }
                            {this.state.previewStatus ==="Completed" && <button className="uk-button uk-button-green" type="button" onClick={this.handleRejectClick.bind(this)} > Reject</button>}
                        {showSwiftSend &&
                            <button className="uk-button uk-button-green" type="button" onClick={this.handleSwiftValidatePayment}  > SWIFT Validate Payment</button>}
                        {showSwiftSend &&
                            <button className="uk-button uk-button-green" type="button" onClick={this.handleSwiftSendPayment}  > SWIFT Send Payment</button>}
                        {showSwiftGetStatus &&
                            <button className="uk-button uk-button-green" type="button" onClick={this.handleSwiftGetOutgoingPaymentStatus}  > SWIFT Get Status</button>}
                            {!this.state.fromRefunds && this.state.outgoing.OutgoingFundsID > 0 && <button className="uk-button uk-button-green" type="button" onClick={() => this.setState({openVersions: true})}> View Versions</button>}

                        </div>
                        <div className="uk-width-1-1 uk-margin">
                        {!this.state.fromRefunds && <hr class=".uk-divider-icon" style={{height: 0.5, backgroundColor: '#E5DBD8'}}></hr>
                         }
                        </div> 
                    {!this.state.fromRefunds && this.state.outgoing.OutgoingFundsID > 0 && <ul id="accordion-outgoing-funds-additional-properties" uk-accordion="multiple: true" style={{marginBottom: "0px"}} >
                        <li className="">
                            <h5 className="uk-accordion-title">Additional Properties</h5>
                            <div className="uk-accordion-content" style={{ backgroundColor: '#f8f8f8' }} >
                                <div id="outgoing-funds-additional-properties">
                                    <EntityProperties id="outgoing-form" entityType={OutgoingFundType} entityID={this.state.outgoing.OutgoingFundsID} fullScreen={true} runSaveProcess={this.state.entityPropertiesSaveProcess} finishSaveProcess={this.onFinishEntityPropertiesSaveProcess.bind(this)} forceLoad={this.state.forceLoad} 
                                    outgoingFunds={true} onValidMandatory = {this.onValidMandatory}
                                    amount={this.state.amountInBaseCurrency} />
                                </div>
                            </div>
                        </li>
                    </ul>}
                </form>

                <RiskCalculationDialog riskScoreDetails={this.state.riskScoreDetails}
                    open={this.state.showRiskCalculationDetails}
                    close={this.onShowRiskCalculationDetails} 
                    entityType={"Outgoing Fund"}/>	

                <OutgoingFundsConfirmation 
                    backLink        = {this.handleBackLink.bind(this)} 
                    outgoingFundId  = {this.props.id} 
                    dealHeaderInfo  = {this.state.dealHeaderInfo} 
                    dealHeaderId    = {this.state.outgoing.DealHeaderID}
                    lookUpTables    = {this.state.dealLookUpTables} 
                    open            = {this.state.showOutgoingConfirmation}
                />

                <RejectedFeeAmountDialog
                    open={this.state.showRejectFeeScreen} onClose={false} updateRejectedFeeAmount={this.updateRejectedFeeAmount}
                />

<OutgoingFundVersionsScreen
                    open={this.state.openVersions}
                    close={() => this.setState({openVersions: false})}
                    entityID={this.state.outgoing.OutgoingFundsID}
                />

                <AlertBox 
                            open={this.state.showConfirm} 
                            onClose={this.closeConfirm.bind(this)} 
                            title={this.state.alertTitle}
                            message={this.state.alertMessage}
                            type="Yes/No" 
                            yesClick={this.yesConfirm.bind(this)} 
                            noClick={this.closeConfirm.bind(this)}/>

                <AlertBox open={this.state.showAlert} onClose={this.closeAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert.bind(this)}/>
                <AlertBox id="exceeds-limit" open={this.state.showExceedsLimitAlert} onClose={this.closeExceedsLimitAlert} title="Confirm" message={`The amount you have entered exceeds the limit defined for this beneficiary. Are you sure the amount is correct? (${formatToCurrencyWithScale(this.state.receiveLimit,this.state.sellCcy)} ${this.state.sellCcy})`} type="Yes/No" yesClick={this.yesExceedsLimitAlert} noClick={this.closeExceedsLimitAlert}/>
                <AlertBox id="warning-save" open={this.state.showPropertiesAlertWarning} onClose={this.closePropertiesAlert} title="Warning" message={"There are properties that haven't been filled. Do you want to continue?"} type="Yes/No" yesClick={this.yesPropertiesAlert} noClick={this.closePropertiesAlert}/>
                <AlertBox id="alert-save" open={this.state.showPropertiesAlert} onClose={this.closePropertiesAlert} title="Error" message={"There are mandatory properties that need to be filled out. This outgoing fund will be saved in Pending status."} type="Ok" okClick={this.closePropertiesAlert}/>
                <AlertBox id="sending-funds" open={this.state.showSendingFundsAlert} onClose={this.closeSendingFundsAlert} title="Confirm" message="You are sending out funds before all incoming funds have been received. Are you sure?" type="Yes/No" yesClick={this.yesSendingFundsAlert} noClick={this.closeSendingFundsAlert}/>
                <AlertBox id="release-payment" open={this.state.showReleasePaymentAlert} onClose={this.closeReleasePaymentAlert} title="Confirm" message="You are releasing a payment before the deal's value date. Are you sure?" type="Yes/No" yesClick={this.yesReleasePaymentAlert} noClick={this.closeReleasePaymentAlert}/>
                <AlertBox id="chosen-wire" open={this.state.showChosenWireAlert} onClose={this.closeChosenWireAlert} title="Confirm" message={this.state.chosenWireAlertMessage} type="Yes/No" yesClick={this.yesChosenWireAlert} noClick={this.closeChosenWireAlert}/>
                <AlertBox id="reject-outgoing" open={this.state.showRejectOutgoingAlert} onClose={this.closeRejectOutgoingAlert} title="Confirm" message={this.state.rejectOutgoingMessage} type="Yes/No" yesClick={this.yesRejectOutgoingAlert} noClick={this.closeRejectOutgoingAlert}/>
                <AlertBox id="yes-no-alert" open={this.state.showAlertYesNo} onClose={this.closeAlertYesNo.bind(this)} title="Confirm" message={this.state.createNewOutgoingMessage} 
                            type="Yes/No" yesClick={this.yesAlertYesNo.bind(this)} noClick={this.closeAlertYesNo.bind(this)}/>
                <LoadingBox loading={this.state.loading || this.state.loadingNostros}/>
                {/** 13 Jan 2022 **/}
                <AlertBox open={this.state.showBeneficiaryHighRiskAlert} onClose={this.closeBeneficiaryHighRiskAlert.bind(this)} title={"Confirm"} message={this.state.beneficiaryHighRiskMessage} type="Yes/No" yesClick={this.yesBeneficiaryHighRiskAlert} noClick={this.closeBeneficiaryHighRiskAlert.bind(this)}/>
                <AlertBox open={this.state.showFundsForReferralAlert} onClose={this.closeFundsForReferralAlert.bind(this)} title={"Confirm"} message={this.state.fundsForReferralMessage} type="Yes/No" yesClick={this.yesFundsForReferralAlert} noClick={this.closeFundsForReferralAlert.bind(this)}/>
                {/** 20220715 */}
                <AlertBox id="status-confirmation" open={this.state.showStatusConfirmation} onClose={this.closeStatusConfirmation} title="Confirm" message={this.state.messageStatusConfirmation} type="Yes/No" yesClick={this.yesStatusConfirmation} noClick={this.closeStatusConfirmation}/>
                
                <AlertBox id="send-c-confirmation" open={this.state.showConfirmationSendCustomerEmail} onClose={this.closeSendCustomerConfirmation} title="Confirm" message={"Customer is set to receive automatic Payment Confirmation, Do you want to send it now?"} type="Yes/No" yesClick={this.yesSendCustomerConfirmation} noClick={this.closeSendCustomerConfirmation}/>
                <AlertBox id="send-b-confirmation" open={this.state.showConfirmationSendBeneEmail} onClose={this.closeSendBeneConfirmation} title="Confirm" message={"Beneficiary is set to receive automatic Payment Confirmation, Do you want to send it now?"} type="Yes/No" yesClick={this.yesSendBeneConfirmation} noClick={this.closeSendBeneConfirmation}/>

            </div>
        );
    }
}