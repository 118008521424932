import React from 'react';
import AppContext from '../../AppContext';
import { GetGlobalSystemParameters, updateParameter } from '../../networking/NetworkingGlobalSystemParameters';
import { getStyle } from '../../styles/styles';
import AlertBox from '../shared/AlertBox';
import NumberInput from '../shared/NumberInput';

const style = getStyle();
let globalFunction = {};

export default class BeneficiaryHandlingScreen extends React.Component {
    static contextType = AppContext;
    state ={
        alertTitle : '',
        alertMessage : '',
        disableScoringRangeControls : true,
        disableStandardControls : false,
        globalSystemParameters : [],
        isPendingChecked : false,
        isAuthorizedChecked : false,
        isStandardChecked : true,
        isScoreRangeChecked : false,
        lessThanZeroLabel : 'Score <= 0, Status=Accepted',
        moreThanZeroLabel : 'Score > 0, Status=Suspicious',
        loading : false,
        parameterName : 'BeneficiaryStandardMethodStatus',
        parameterValue : 'Standard',
        parameterBeneStatusName : '',
        parameterBeneStatusValue : '',
        scoreLimit : 0,
        showAlert : false
    }

    closeAlert() {
        this.setState({ showAlert: false });
    }
    
    componentDidMount() {
        globalFunction = this.context;
        globalFunction.startLoading();
        GetGlobalSystemParameters(1, 100).then(
            (value) => {
                //this.setState({globalSystemParameters: value.globalParameters})
                if (value != null && value.globalParameters != null) {
                    for (let i = 0; i < value.globalParameters.length; i++) {
                        let isStandard = this.state.isStandardChecked;
                        let isScoreRange = this.state.isScoreRangeChecked;
                        let isPending = this.state.isPendingChecked;
                        let isAuthorized = this.state.isAuthorizedChecked;
                        let newLessThanZeroLabel = this.state.lessThanZeroLabel;
                        let newMoreThanZeroLabel = this.state.moreThanZeroLabel;
                        const parameterValue = value.globalParameters[i]["Parameter Value"];
                        if (value.globalParameters[i]["Parameter Name"] === 'BeneficiaryHandlingMethod') {
                            if (parameterValue === 'Standard') {
                                isStandard = true;
                                isScoreRange = false;
                            } else {
                                isStandard = false;
                                isScoreRange = true;
                            }
                            this.setState({
                                isStandardChecked: isStandard,
                                isScoreRangeChecked: isScoreRange,
                                parameterName: 'BeneficiaryHandlingMethod',
                                parameterValue: parameterValue
                            });
                        }
                        if (value.globalParameters[i]["Parameter Name"] === 'BeneficiaryStandardMethodStatus') {
                            if (parameterValue === 'Pending activation') {
                                isPending = true;
                                isAuthorized = false;
                            } else {
                                isPending = false;
                                isAuthorized = true;
                            }
                            if (isScoreRange) {//When Score Range is selected, keep Standard radio buttons unselected
                                isPending = false;
                                isAuthorized = false;
                            }
                            this.setState({
                                isPendingChecked: isPending,
                                isAuthorizedChecked: isAuthorized,
                                parameterBeneStatusName: 'BeneficiaryStandardMethodStatus',
                                parameterBeneStatusValue: parameterValue,
                            })
                        }
                        if (value.globalParameters[i]["Parameter Name"] === 'BeneficiaryScoreMethodLimit') {
                            newLessThanZeroLabel = `Score <= ${parameterValue}, Status = Accepted`;
                            newMoreThanZeroLabel = `Score > ${parameterValue}, Status = Suspicious`;
                            this.setState({
                                scoreLimit: parameterValue,
                                lessThanZeroLabel: newLessThanZeroLabel,
                                moreThanZeroLabel: newMoreThanZeroLabel
                            })
                        }
                    }
                }
                globalFunction.finishLoading();
            }
        );
    }

    saveParameters = () => {
        globalFunction.startLoading();
        var objParameter = {
            'ParameterName' : 'BeneficiaryHandlingMethod',
            'ParameterValue' : this.state.parameterValue,
            'ParameterValueDataType' : 'T'
        }
        //To save Standard or Scoring Range.
        updateParameter(objParameter).then((json) => {
            if (json.httpStatusCode !== undefined && json.httpStatusCode !== 200) {
                this.setState({ showAlert: true, alertMessage: 'An error occurred while trying to save the contact. Please try again.', alertTitle: 'Error', loading: false });
            } else {
                //To save beneficiary Status.
                var objParameter = {
                    'ParameterName' : 'BeneficiaryStandardMethodStatus',
                    'ParameterValue' : this.state.parameterBeneStatusValue,
                    'ParameterValueDataType' : 'T'
                }
                updateParameter(objParameter).then((json) => {
                    if (json.httpStatusCode !== undefined && json.httpStatusCode !== 200) {
                        this.setState({ showAlert: true, alertMessage: 'An error occurred while trying to save the contact. Please try again.', alertTitle: 'Error', loading: false });
                    } else {
                        //To save beneficiary Status.
                        var objParameter = {
                            'ParameterName' : 'BeneficiaryScoreMethodLimit',
                            'ParameterValue' : '' + this.state.scoreLimit,
                            'ParameterValueDataType' : 'I'
                        }
                        updateParameter(objParameter).then((json) => {
                            if (json.httpStatusCode !== undefined && json.httpStatusCode !== 200) {
                                this.setState({ showAlert: true, alertMessage: 'An error occurred while trying to save the contact. Please try again.', alertTitle: 'Error', loading: false });
                            } else {
                                var newLessThanZeroLabel = 'Score <= ' + this.state.scoreLimit + ', Status=Accepted';
                                var newMoreThanZeroLabel = 'Score > ' + this.state.scoreLimit + ', Status=Suspicious'
                                this.setState({lessThanZeroLabel: newLessThanZeroLabel, moreThanZeroLabel: newMoreThanZeroLabel,
                                    showAlert: true, alertMessage: 'Settings Saved!', alertTitle: 'Success', loading: false
                                });
                                globalFunction.finishLoading();
                            }
                        });
                    }
                });
            }
            
        });
    }

    handlerSaveClick(event) {
        event.preventDefault();
        this.saveParameters();
    }

    handleStandardMethod(event){
        this.setState({
            parameterValue: event.target.value,
            parameterName: 'BeneficiaryHandlingMethodStatus',
            isStandardChecked: event.target.checked,
            isScoreRangeChecked: false,
            disableScoringRangeControls: true,
            disableStandardControls: false,
            isPendingChecked: true,//When Standar is selected, select Pending as default
            isAuthorizedChecked: false
        });
    }

    handleSoringRangedMethod(event){
        this.setState({
            parameterValue: event.target.value,
            parameterName: 'BeneficiaryHandlingMethodStatus',
            isScoreRangeChecked: event.target.checked,
            isStandardChecked: false,
            disableScoringRangeControls: false,
            disableStandardControls: true,
            isPendingChecked: false,//When Score Range is selected, keep Standard radio buttons unselected
            isAuthorizedChecked: false
        });
    }

    handlePendingStatus(event){
        this.setState({parameterBeneStatusValue: event.target.value, parameterBeneStatusName: 'BeneficiaryStandardMethodStatus', isPendingChecked: event.target.checked, 
                isAuthorizedChecked: false
        });
    }
    
    handleAuthorizedStatus(event){
        this.setState({parameterBeneStatusValue: event.target.value, parameterBeneStatusName: 'BeneficiaryStandardMethodStatus', isAuthorizedChecked: event.target.checked, 
                isPendingChecked: false});
    }

    handleUpdateScoreLimit(event){
        this.setState({scoreLimit: event.target.value});
    }

    render(){
        return(
            <div>
                <form className="uk-form-stacked uk-grid">
                    <div className="uk-width-1-1">
                        <div className="">
                            <h3 className="uk-heading-divider" style={style.titleStyle}>
                                    Beneficiary Handling
                            </h3>
                        </div>
                    </div>
                    <div className="uk-width-1-1">
                        <div className="uk-width-1-2 uk-form-stacked">
                            <label className="uk-form-label" style={style.boldLabel}>Handling Method:</label>
                            <div className="uk-form-controls" style={{marginTop: '10px'}}>
                                <label className="uk-form-label" style={{ marginLeft: "10px"}}>Please choose your preferred method for handling Beneficiaries entered via the online system. The 'Standard' method allows you to control the Status that a beneficiary will be saved with when entered in the online system. 'Authorized' will make the beneficiary immediately available for use in a deal. Saving the Beneficiaries in a 'Pending' status will prevent them from being used in a deal until your staff reviews and approves the beneficiary data.</label>
                                <label className="uk-form-label" style={{ marginLeft: "10px", marginTop: '15px'}}>The Scoring Range method allows you to specify a risk score threshold. When a beneficiary is saved online, it will run through the Compliance Scoring process. If the score is lower than the threshold you have defined, the beneficiary will be saved in 'Authorized' status. If the beneficiary scores higher (beneficiary is deemed by the system to be high-risk), the beneficiary is saved with a 'Suspicious' status, until your staff can review and clear the beneficiary for use.</label>
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked" style={{ marginLeft: "10px"}}>
                            <div className="uk-form-controls" style={{marginTop: '10px'}}>
                                <label className="uk-form-label" ><input value="Standard" className="uk-radio" type="radio" name="radioStandard" onChange={this.handleStandardMethod.bind(this)} checked={this.state.isStandardChecked} /> Standard</label>
                                <label className="uk-form-label" style={{ marginLeft: "110px"}}><input value="Pending activation" className="uk-radio" type="radio" name="radioPending" disabled={this.state.disableStandardControls} onChange={this.handlePendingStatus.bind(this)} checked={this.state.isPendingChecked} /> Save beneficiaries entered online in Pending status</label>
                                <label className="uk-form-label" style={{ marginLeft: "110px"}}><input value="Authorized" className="uk-radio" type="radio" name="radioAuthorized" disabled={this.state.disableStandardControls} onChange={this.handleAuthorizedStatus.bind(this)} checked={this.state.isAuthorizedChecked} /> Save beneficiaries entered online in Authorized status</label>
                            </div>
                            <div className="uk-form-controls" style={{marginTop: '10px'}}>
                                <label className="uk-form-label" ><input value="Scoring Range" className="uk-radio" type="radio" name="radioScoringRange" onChange={this.handleSoringRangedMethod.bind(this)} checked={this.state.isScoreRangeChecked} /> Scoring Range</label>
                                <label className="uk-form-label" style={{ marginLeft: "110px"}}>Score Method Limit </label>
                                <div className=""  style={{ marginLeft: "110px"}}>
                                    <NumberInput className="uk-input" style={{ marginLeft: "110px"}} id="form-bene-handling-score-limit" type="Integer" placeholder="0" disabled={this.state.disableScoringRangeControls} value={this.state.scoreLimit} onChange={this.handleUpdateScoreLimit.bind(this)} />
                                </div>
                                <label className="uk-form-label" style={{ marginLeft: "110px"}}>{this.state.lessThanZeroLabel}</label>
                                <label className="uk-form-label" style={{ marginLeft: "110px"}}>{this.state.moreThanZeroLabel}</label>
                            </div>                         
                        </div>

                    </div>
                    <div className="uk-width-1-1 uk-margin">
                        <button className="uk-button uk-button-green" type="button" onClick={this.handlerSaveClick.bind(this)}>Save</button>
                    </div>
                </form>
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert.bind(this)} />
            </div>
        );
    }
}