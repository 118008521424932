import { Breadcrumb } from 'antd';
import React, { useContext } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import AppContext from '../../../AppContext';
import { GetEmailById, GetEmails, UpdateEmail } from '../../../networking/NetworkingEmails';
import AlertBox from '../../shared/AlertBox';
import EmailDetailDialog from './EmailDetailDialog';
import NewEditableGrid from '../../shared/NewEditableGrid';
import { SearchOutlined } from '@ant-design/icons';

const NewEmailsScreen = () => {
    const context = useRef(useContext(AppContext));
    const [emails, setEmails] = useState([]);
    const [emailDetail, setEmailDetail] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const [reloadEmails, setReloadEmails] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');


    const [columnDetail, setColumnDetail] = useState(null);

    useEffect(() => {
        loadEmails();
    }, [reloadEmails]);

    const loadEmails = async () => {
        const json = await GetEmails();
        if (json != null && json.result != null) {
            const _emails = json.result.map((e, index) => ({ ...e, key: `Emails-${index}` }));
            setEmails(_emails);
            if (json.columnDetail != null) {
                const _columnDetail = JSON.parse(json.columnDetail);
                setColumnDetail(_columnDetail);
            }
        } else {
            setEmails([]);
        }
    };

    const save = async (record, row) => {
        try {
            const model = {
                EmailId: record['EmailId'],
                Status: row['Status'],
                EmailReceiver: row['EmailReceiver']
            };
            console.log(model)
            context.current.startLoading();
            const json = await UpdateEmail(model);
            context.current.finishLoading();
            if (json != null) {
                if (json.httpStatusCode !== 200) {
                    setShowAlert(true);
                    setAlertTitle('Error');
                    setAlertMessage(json.httpErrorMessage);
                    setReloadEmails(!reloadEmails);
                } else {
                    setShowAlert(true);
                    setAlertTitle('Success');
                    setAlertMessage('Email successfully saved.');
                    setReloadEmails(!reloadEmails);
                    //onAddOrDeleteSpotPricing();
                }
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const onRowSelected = async (record) => {
        context.current.startLoading();
        const json = await GetEmailById(record['EmailId']);
        if (json != null && json.httpStatusCode === 200) {
            setEmailDetail(json);
            setShowModal(true);
        } else {
            setEmailDetail(null);
            setShowModal(false);
        }
        context.current.finishLoading();
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const closeAlert = () => {
        setShowAlert(false);
        setAlertTitle('');
        setAlertMessage('');
    };

    const onRefresh = () => {
        loadEmails();
    }

    return (
        <div>
            <div className="breadcrumb-div">
                <Breadcrumb separator=">">
                    <Breadcrumb.Item className="breadcrumb-item-bold">Emails</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div>
                <div className="uk-width-1-1" style={{ marginTop: '5px' , marginBottom: '15px', marginRight: '20px', textAlign: 'right'}}>
                    <button Name="btnRefresh" className="uk-button uk-button-default uk-button-green" onClick={onRefresh}>Refresh</button>
                </div>
                <NewEditableGrid
                    data={emails}
                    columnDetails={columnDetail}
                    isEditable={true}
                    saveFunction={save}
                    useIcons={true}
                    dynamicActions={[{name: 'View', function: onRowSelected, isIcon: true, icon: <SearchOutlined /> }]} />
            </div>
            {showModal && <EmailDetailDialog showModal={showModal} emailDetail={emailDetail} closeModal={closeModal} />}
            <AlertBox open={showAlert} onClose={closeAlert} title={alertTitle} message={alertMessage} type="Ok" okClick={closeAlert} />
        </div>
    );
};

export default NewEmailsScreen;
