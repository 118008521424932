import React from 'react';
import { BankType } from '../../../constants/EntityTypeNames';
import MinusIcon from '../../../css/custom-icons/minus.svg';
import PlusIcon from '../../../css/custom-icons/plus.svg';
import { formatDate } from '../../../helpers/FormatHelper';
import { getLookUpTable } from '../../../networking/Networking';
import { saveBank, searchBankCustomers } from '../../../networking/NetworkingBanks';
import { getCountries, getCountryById, searchCountries } from '../../../networking/NetworkingCountries';
import { getCustomerByID } from '../../../networking/NetworkingCustomers';
import { searchInGlobalWatchLists } from '../../../networking/NetworkingGlobalWatchListSearch';
import { getStyle, notFilledInputClass, notFilledSelectClass, regularInputClass, regularSelectClass } from '../../../styles/styles';
import AlertBox from '../../shared/AlertBox';
import DatePicker from '../../shared/DatePicker';
import DynamicSearchSelect from '../../shared/DynamicSearchSelect';
import DynamicSelect from '../../shared/DynamicSelect';
import EntityProperties from '../../shared/EntityProperties';
import NumberInput from '../../shared/NumberInput';
import {Colors} from '../../../constants/Colors'

export default class BanksForm extends React.Component {
   
    state = {
        notFirstLoad: false,
        showAlert: false,
        entityPropertiesSaveProcess: false,
        listStatus: [],
        listCountries: [],
        listCustomers: [],
        statusPlaceHolder: '',
        bankName: '', //required
        bankStatus: '', //required
        bankSwift: '',
        bankABA: '',
        bankCountryID: '',  //required
        bankCountryName:'', //required
        bankRiskScore: 0, //required
        bankCustomerID: 0,
        bankCustomerName: '',
        bankID: 0,
        bankUpdateToken: 0,
        bypassValidation: false,
        expiryBypassDate: '',
        alertTitle: '',
        alertMessage: '',
        showAlertSaveSuspicious: false
    }
    
    componentDidMount() {
        console.log(regularInputClass);
        getLookUpTable('Bank Status').then(
            (json) => {
                var lookUpTableDetails = json.lookUpTables[0].lookUpTableDetails;
                var helper = [];
                for(var i=0; i<lookUpTableDetails.length; i++){
                    lookUpTableDetails[i].value = lookUpTableDetails[i].LookUpTableDetail
                    lookUpTableDetails[i].name = lookUpTableDetails[i].LookUpTableDetail
                    helper.push(lookUpTableDetails[i]);
                    //helper.push({value: lookUpTableDetails[i].LookUpTableDetail, name: lookUpTableDetails[i].LookUpTableDetail})
                }
                this.setState({listStatus: helper});
            }
        );
        getCountries(1, 250).then(
            (json) => {
                if (json != null && json.countries != null) {
                    const countries = json.countries.map(c => ({ ...c, name: c["Country Name"], value: c.Country }));
                    this.setState({ listCountries: countries });
                }
            }
        );
    }

    componentDidUpdate(prevProps){
        if(this.props.selectedBank !== {} && prevProps.selectedBank !== this.props.selectedBank){
            var bank = this.props.selectedBank;
            var customerID = 0;
            if(bank.CustomerID !== null){
                this.searchCustomers(bank.CustomerID);
                customerID = bank.CustomerID;
            }
            // this.getCountry(bank.CountryID);
            this.getCustomer(bank.CustomerID);
            
            this.setState({
                bankID: bank.BankID,
                bankName: bank.BankName,
                bankSwift: bank.SWIFT,
                bankABA: bank.ABA,
                bankStatus: bank.Status,
                bankCountryID: bank.CountryID,
                bankRiskScore: bank.RiskScore,
                bankCustomerID: customerID,
                bankUpdateToken: bank.UpdateToken,
                bypassValidation: bank.BypassValidation,
                expiryBypassDate: bank.ExpiryBypassDate
            });
        };
    }

    getCountry(id){
        getCountryById(id).then(
            (json) => {
                console.log(json);
                var helper = [];
                var country = json.country[0];
                country.value = country['Country Id'];
                var name = country['Country Id'] + ' - ' + country['Country Name'];
                country.name = name;
                helper.push(country);
                this.setState({bankCountryID: country['Country Id'], bankCountryName: name, listCountries: helper});
            }
        );
    }

    getCustomer(id){
        if(id !== null){
            getCustomerByID(id).then(
                (json) => {
                    console.log(json);
                    var helper = [];
                    var customer = json;
                    customer.value = customer.CustomerID;
                    var name = customer.CustomerID + ' - ' + customer.Name;
                    customer.name = name;
                    helper.push(customer);
                    this.setState({bankCustomerID: customer.CustomerID, bankCustomerName: name, listCustomers: helper});
                }
            );
        }
    }

    searchCountry(query){ 
        searchCountries(query).then(
            (json) => {
                var helper = [];
                if(query !== ''){
                    console.group('Search Countries');
                    console.log('Query');
                    console.table(query);
                    console.log('Response');
                    console.table(json);
                    console.groupEnd();
                for(var i=0; i<json.length; i++){
                    json[i].value = json[i].CountryId;
                    json[i].name = json[i].CountryIdName;
                    helper.push(json[i]);
                    //helper.push({value: json[i].CountryId, name: json[i].CountryIdName})
                }
                console.log(helper);
                this.setState({listCountries: helper});
                if(helper.length === 1){
                    var value = helper[0];
                    this.setState({bankCountryID: value.CountryId, bankCountryName: value.CountryIdName});
                }
            }}
        );
    }

    searchCustomers(query){
        searchBankCustomers(query).then(
            (response) => {
                var helper = [];
                var json = response.customers;
                if(query !== ''){
                    for(var i=0; i<json.length; i++){
                        json[i].value = json[i].CustomerId;
                        json[i].name = json[i].CustomerIDName;
                        helper.push(json[i]);
                    }
                }
                this.setState({listCustomers: helper});
                if(helper.length === 1){
                    var value = helper[0];
                    this.setState({bankCustomerID: value.CustomerId, bankCustomerName: value.CustomerIDName});
                }
            }
        );
    }

    getStatusSelect(value){
        if (value != null && value !== 'select' && value.value !== undefined) {
            this.setState({ bankStatus: value.value !== "select" ? value.value : "" });
        }
    }

    getCountryValue(value){
        if (value != null && value !== 'select' && value.value !== undefined) {
            this.setState({ bankCountryID: value.value !== "select" ? value.value : "", bankCountryName: value.name != null ? value.name : "" });
        }
    }

    getCustomerValue(value){
        if(value.CustomerId === undefined){
            this.setState({bankCustomerID: 0, bankCustomerName: ''});
        } else {
            this.setState({bankCustomerID: value.CustomerId, bankCustomerName: value.CustomerIDName});
        }
    }

    getBankNameValue(event){
        this.setState({bankName: event.target.value});
    }

    getSwiftValue(event){
        this.setState({bankSwift: event.target.value});
    }

    getABAValue(event){
        this.setState({bankABA: event.target.value});
    }

    getRiskScoreValue(event){
        this.setState({bankRiskScore: event.target.value});
    }

    handleUpdateBypassValidation = (event) => {
        const bypass = event.target.checked;
        if (bypass) {
            this.setState( { bypassValidation: bypass });
        } else {
            this.setState( { bypassValidation: bypass, expiryBypassDate: '' });
        }
    }

    handleUpdateExpiryBypassDate = (date) => {
        if (date !== undefined) {
            this.setState({ expiryBypassDate: formatDate(date) });
        }
    }

    handleClearClick(){
        this.setState({
            bankName: '', //required
            bankStatus: '', //required
            bankSwift: '',
            bankABA: '',
            bankCountryID: '',  //required
            bankCountryName:'', //required
            bankRiskScore: 0, //required
            bankCustomerID: 0,
            bankCustomerName: '',
            bankID: 0,
            bankUpdateToken: 0,
            bypassValidation: false,
            expiryBypassDate: ''
        });
    }
    trySave = async () => {
        const textToSearch = this.state.bankName;
        const globalListsResponse = await searchInGlobalWatchLists(textToSearch, BankType, this.state.bankID);
        if (this.state.bypassValidation || (globalListsResponse != null && globalListsResponse.MatchedWords.length === 0 && globalListsResponse.SourceNames.length === 0)) {
            await this.handleSaveClick(false)
        } else {
            this.setState({ showAlertSaveSuspicious: true, alertTitle: 'Warning', alertMessage: 'Found Bank in Watch Lists. The Bank will be saved with status "Suspicious"' });
        }
    };

    handleSaveClick = async (isSuspicious) => {
        const date = new Date();
        const userID = localStorage.getItem('UserID');
        const model = {
            BankID: this.state.bankID,
            BankName: this.state.bankName,
            SWIFT: this.state.bankSwift,
            ABA: this.state.bankABA,
            Status: isSuspicious ? 'Suspicious' : this.state.bankStatus,
            CountryID: this.state.bankCountryID,
            RiskScore: this.state.bankRiskScore,
            UpdatedOn: date.toISOString(),
            UpdateToken: this.state.bankUpdateToken,
            UserID: userID,
            CustomerID: this.state.bankCustomerID,
            BypassValidation: this.state.bypassValidation != null ? this.state.bypassValidation : false,
            ExpiryBypassDate: this.state.expiryBypassDate
        };
        if (this.validateRequiredInputs()) {
            const json = await saveBank(model);
            if (json.httpStatusCode !== 200) {
                let alertMsg = 'An error occurred while trying to save the bank. Please try again.'
                if(json.httpErrorMessage !== undefined && json.httpErrorMessage !== ''){
                    alertMsg = json.httpErrorMessage;
                }
                this.setState({showAlert: true, alertMessage: alertMsg, alertTitle: 'Error'});
            } else {
                this.setState({bankUpdateToken: json.updateToken, bankID: json.bankId, showAlert: true, alertTitle: 'Success', alertMessage: 'Bank successfully saved.', entityPropertiesSaveProcess: true});
                this.props.updateFunction();
            }
        } else {
            this.setState({notFirstLoad: true});
        }
    }

    closeAlert(){
        this.setState({showAlert: false});
    }

    closeAlertSaveSuspicious = async () => {
        this.setState({ showAlertSaveSuspicious: false, bankStatus: 'Suspicious' });
        await this.handleSaveClick(true);
    }

    validateRequiredInputs(){
        if(this.state.bankName === ''){
            return false;
        }
        if(this.state.bankStatus === undefined){
            return false;
        }
        if(this.state.bankRiskScore === ''){
            return false;
        }
        if(this.state.bankCountryID === undefined){
            return false;
        }
        if (this.state.bypassValidation) {
            if (this.state.expiryBypassDate == null || this.state.expiryBypassDate === '') {
                return false;
            }
        }
        return true;
    }

    onFinishEntityPropertiesSaveProcess =()=>{
        this.setState({entityPropertiesSaveProcess: false});
    }

    render(){
        //for required inputs
        var classNameInputName = regularInputClass;
        var classNameInputStatus = regularSelectClass; 
        var classNameInputCountry = regularInputClass;
        var classNameInputRiskScore = regularInputClass;
        var classNameInputExpiryBypassDate = regularSelectClass;
        if(this.state.bankName === '' && this.state.notFirstLoad){
            classNameInputName=notFilledInputClass;
        }
        if(this.state.bankStatus === undefined && this.state.notFirstLoad){
            classNameInputStatus = notFilledSelectClass;
        }
        if(this.state.bankRiskScore === '' && this.state.notFirstLoad){
            classNameInputRiskScore = notFilledInputClass;
        }
        if(this.state.bankCountryID === undefined && this.state.notFirstLoad){
            classNameInputCountry = notFilledInputClass;
        }
        if((this.state.expiryBypassDate == null || this.state.expiryBypassDate === '') && this.state.notFirstLoad){
            classNameInputExpiryBypassDate = notFilledSelectClass;
        }

        var bankPropertyId = 'bank-property-'+this.props.selectedBank.BankID;

        return(
            <div>
                <form className="uk-form-stacked uk-grid">
                    <div className="uk-width-1-2" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-bank-name">Name</label>
                            <div className="uk-form-controls">
                                <input className={classNameInputName} id="form-bank-name" value={this.state.bankName} type="text" placeholder="Bank Name..." onChange={this.getBankNameValue.bind(this)}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2">
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-bank-status">Status</label>
                            <div className="uk-form-controls">
                            <DynamicSelect className={classNameInputStatus} getValue={this.getStatusSelect.bind(this)} listHasPlaceholder={true} data={this.state.listStatus} id="form-bank-status" objValue={{value: this.state.bankStatus, LookUpTableDetail: this.state.bankStatus}}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-bank-swift">SWIFT</label>
                            <div className="uk-form-controls">
                                <input className="uk-input" id="form-bank-swift" type="text" placeholder="SWIFT" value={this.state.bankSwift} onChange={this.getSwiftValue.bind(this)}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-bank-aba">ABA</label>
                            <div className="uk-form-controls">
                                <input className="uk-input" id="form-bank-aba" type="text" placeholder="ABA" value={this.state.bankABA} onChange={this.getABAValue.bind(this)}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2">
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-bank-country">Country</label>
                            <div className="uk-form-controls">
                                <DynamicSelect className={classNameInputCountry} id="form-bank-country" placeholder="Select a Country..." getValue={this.getCountryValue.bind(this)} data={this.state.listCountries} objValue={{value: this.state.bankCountryID, name: this.state.bankCountryName, CountryId: this.state.bankCountryID, CountryIdName: this.state.bankCountryName}}/> 
                                {/* <DynamicSearchSelect className={classNameInputCountry} id="form-bank-country" placeholder="Select a Country..." searchFunction={this.searchCountry.bind(this)} getValue={this.getCountryValue.bind(this)} data={this.state.listCountries} objValue={{value: this.state.bankCountryID, name: this.state.bankCountryName, CountryId: this.state.bankCountryID, CountryIdName: this.state.bankCountryName}}/>  */}
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2">
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-bank-risk-score">Risk Score</label>
                            <div className="uk-form-controls">
                                <NumberInput className={classNameInputRiskScore} placeholder="0" id="form-bank-risk-score" type="Integer" value={this.state.bankRiskScore} onChange={this.getRiskScoreValue.bind(this)}/>
                                {/*<input className={classNameInputRiskScore} placeholder="0.00" id="form-bank-risk-score" type="number"  value={this.state.bankRiskScore} onChange={this.getRiskScoreValue.bind(this)}/>*/}
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2" >
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-bank-customer">Customer</label>
                            <div className="uk-form-controls">
                                <DynamicSearchSelect id="customer-list" placeholder="Customer Name" searchFunction={this.searchCustomers.bind(this)} getValue={this.getCustomerValue.bind(this)} data={this.state.listCustomers} objValue={{value: this.state.bankCustomerID, name: this.state.bankCustomerName, CustomerId: this.state.bankCustomerID, CustomerIDName: this.state.bankCustomerName}}/>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-2">
                        <label className="uk-form-label">Bypass Validation (*)</label>
                        <div className="uk-grid" >
                            <div className="uk-width-1-3" style={{ paddingTop: '5px' }}>
                                <label><input className="uk-checkbox" value={this.state.bypassValidation} checked={this.state.bypassValidation} onChange={this.handleUpdateBypassValidation} type="checkbox" /> Expiry bypass date</label>
                            </div>
                            <div className="uk-width-1-2">
                                <DatePicker readOnly={true} disabled={!this.state.bypassValidation}
                                    className={classNameInputExpiryBypassDate}
                                    value={this.state.expiryBypassDate}
                                    onDayChange={this.handleUpdateExpiryBypassDate} />
                            </div>
                        </div>
                    </div>

                    <div className="uk-width-1-1 uk-margin">
                        <h5 
                            className="uk-heading-divider properties-tasks-category-title-new1" 
                            uk-toggle="target : #propertiesSection;"
                            style={{...getStyle().titleStyle, cursor: 'pointer', backgroundColor: Colors.primaryColor, backgroundImage: 'none'}}
                        >Additional Properties
                            <img 
                                id="propertiesSection"
                                alt="Plus-icon"
                                src={PlusIcon}
                                //className="fa fa-fw fa-plus" 
                                style={{position: 'absolute',
                                paddingTop: '16px',
                                right: '30px', color: 'black'}}
                            />
                            <img 
                                id="propertiesSection" 
                                alt="Minus-icon"
                                src={MinusIcon}
                                //className="fa fa-fw fa-minus" 
                                style={{position: 'absolute',
                                paddingTop: '16px',
                                right: '30px', color: 'black'}} 
                                hidden
                            />
                        </h5>
                        <div id="propertiesSection" hidden>
                            <div className="uk-width-1-1 uk-margin">
                                <EntityProperties
                                    id                  = {bankPropertyId}
                                    entityType          = {BankType}
                                    entityID            = {this.state.bankID}
                                    fullScreen          = {true}
                                    runSaveProcess      = {this.state.entityPropertiesSaveProcess}
                                    finishSaveProcess   = {this.onFinishEntityPropertiesSaveProcess}
                                />
                            </div>
                        </div>  
                    </div>
                    {/*<div className="uk-width-1-1 uk-margin">
                        <EntityProperties 
                            id={bankPropertyId}
                            entityType={BankType} 
                            entityID={this.state.bankID} 
                            runSaveProcess={this.state.entityPropertiesSaveProcess} 
                            finishSaveProcess={this.onFinishEntityPropertiesSaveProcess.bind(this)}
                        />
                    </div>*/}
                    <div className="uk-width-1-1 uk-margin">
                        <button className="uk-button uk-button-green" type="button" onClick={this.trySave}>Save</button>
                        <button className="uk-button uk-button-green" type="button" onClick={this.handleClearClick.bind(this)}>Clear Fields</button>
                    </div>
                </form>
                <AlertBox id="save-bank" open={this.state.showAlert} onClose={this.closeAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert.bind(this)}/>
                <AlertBox open={this.state.showAlertSaveSuspicious} onClose={this.closeAlertSaveSuspicious} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlertSaveSuspicious} />
            </div>
        );
    }
}