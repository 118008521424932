/* eslint-disable jsx-a11y/anchor-is-valid */
import Badge from '@material-ui/core/Badge';
import InfoIcon from '@material-ui/icons/Info';
import React from 'react';
import { DealTypesNames } from '../../constants/DealTypesNames';
import { DealType } from '../../constants/EntityTypeNames';
import MinusIcon from '../../css/custom-icons/minus.svg';
import PlusIcon from '../../css/custom-icons/plus.svg';
import { formatToCurrencyWithScale, getScaleByCurrency, roundAmountByCurrencyPair } from '../../helpers/FormatHelper';
import { getDealFees, updateDealFee } from '../../networking/NetworkingDealFees';
import { getDrawdownsByForward, updateDealHeader } from '../../networking/NetworkingDeals';
import { completedIncomingOutgoingStatus, getIncomingFundsByDeal, getOutgoingFundsByDeal } from '../../networking/NetworkingFunds';
import { getParametersByName } from '../../networking/NetworkingGlobalSystemParameters';
import { applyPaymentsToDeal, confirmDealSettlements } from '../../networking/NetworkingPayments';
import { getRiskScoreDetailsForDeals, getRiskScoreRanges } from '../../networking/NetworkingRiskScoreRange';
import { getStyle } from '../../styles/styles';
import AlertBox from '../shared/AlertBox';
import RiskScoreDetailDialog from '../shared/Dialogs/RiskScoreDetailDialog';
import EntityFunding from '../shared/EntityFunding';
import EntityProperties from '../shared/EntityProperties';
import LoadingBox from '../shared/LoadingBox';
import NewDynamicTable from '../shared/NewDynamicTable';
import NumberInput from '../shared/NumberInput';
import DealConfirmationFormDialog from './DealConfirmationFormDialog';
import DealEmailLogForm from './DealEmailLogForm';

var style = getStyle();
export default class DealInformationForm extends React.Component {
    constructor(props) {
        super(props);
        this.handlePaymentAmountToUse = this.handlePaymentAmountToUse.bind(this);
    }

    state = {
        addressValue: '',
        address2: '',
        dealHeaderInfoProp: {},
        dealHeaderId: 0,
        customerName: "",
        customerID: 0,
        valueDate: "",
        buyAmount: 0,
        sellAmount: 0,
        customerRate: 0,
        BCur: "",
        SCur: "",
        type: "",
        status: "",
        dealOrigin: "",
        createdBy: "",
        createdOn: "",
        customerType: "",
        customerRefNo: "",
        dealPaidStatus: "",
        totalPaidByCustomer: 0,
        totaSentToBeneficiaries: 0,
        totalFees: 0,
        updateToken: 0,

        listDealPayments: [],
        listDealPaymentsView: [], // the list with the filter applied
        dealFeeList: [],
        listPaymentsToApply: [],

        showAppliedPayments: false,
        showAvailablePayments: false,

        feesSectionExpanded: false,

        //alerts
        alertTitle: '',
        alertMessage: '',
        showAlert: false,
        showApplyPaymentsAlert: false,
        showAmountExceedsBalanceAlert: false,
        showAmountToApplyExceedsBuyBalance: false,
        showConfirmDealAlert: false,

        //loading
        loading: false,

        //navigation
        showDealConfirmation: false,
        showDealEmailLog: false,
        showIncludeInwardsConfirmation: false,

        //
        entityPropertiesSaveProcess: false,
        updateFeesOnBlur: false,

        //Read Only
        readOnly: false,

        //drawdowns
        listDrawdowns: [],

        //
        incomingPaymentsTab: false,

        //RiskScore
        showModalViewRiskScoreCalculation   : false,
        listRiskScoreDetails                : [],
        riskValue                           : 0,
        dealRiskScoreText                   : 'N/A',
        colorRiskText                       : 'white',
        fontWeight                          : 'normal',

        coverDeals: '',
        coveredDeals:''
    }

    componentDidMount() {
        console.log(this.props)
        if (typeof this.props !== "undefined" && this.props !== null && typeof this.props.dealHeaderId != "undefined") 
        {
            this.setState({
                address: this.props.dealHeaderInfo.AddressValue,
                dealHeaderId: this.props.dealHeaderId,
                dealHeaderInfoProp: this.props.dealHeaderInfo,
                listDealPayments: this.props.dealPayments,
                listDealPaymentsView: this.props.dealPayments,
                //
                buyAmount: this.props.dealHeaderInfo.BuyAmount,
                BCur: this.props.dealHeaderInfo.BCur,
                sellAmount: this.props.dealHeaderInfo.SellAmount,
                SCur: this.props.dealHeaderInfo.SCur,
                status: this.props.dealHeaderInfo.Status,
                dealOrigin: this.props.dealHeaderInfo.DealOrigin,
                createdBy: this.props.dealHeaderInfo["Created By"],
                createdOn: this.props.dealHeaderInfo["Created On"],
                valueDate: this.props.dealHeaderInfo.ValueDate,
                customerRate: this.props.dealHeaderInfo.CustomerRate,
                customerRefNo: this.props.dealHeaderInfo.CustomerRefNo,
                totalPaidByCustomer: Number(this.props.dealHeaderInfo.TotalPaidByCustomer),
                //totaSentToBeneficiaries: dealHeaderInfo.TotaSentToBeneficiaries,
                totaSentToBeneficiaries: Number(this.props.dealHeaderInfo.TotalDisbursed),
                totalFees: Number(this.props.dealHeaderInfo.TotalFees),
                dealPaidStatus: this.props.dealHeaderInfo.DealPaidStatus,
                updateToken: this.props.dealHeaderInfo.UpdateToken,
                customerName: this.props.dealHeaderInfo.Name,
                customerID: this.props.dealHeaderInfo["Customer ID"],
                //
                type: this.props.dealHeaderInfo.type,
                customerType: this.props.dealHeaderInfo.CustomerType,
                coverDeals: this.props.dealHeaderInfo.CoverDeals,
                coveredDeals: this.props.dealHeaderInfo.CoveredDeals
            },
                () => {
                    this.getDealFees()// this.handleLoadClick()
                    this.getGlobalParameters();
                    
                    //only Forwards

                    if (this.props.dealHeaderInfo.Type === 'Forward') {
                        getDrawdownsByForward(this.props.dealHeaderInfo.DealHeaderID,0,0).then(drawdownsValue => {
                            if (drawdownsValue.totalCount > 0) {
                                this.setState({listDrawdowns: drawdownsValue.deals});
                            }
                        })
                    }

                  //  this.clickButtonViewRiskScoreCalculation();
                }
            );
        }
        if (this.props.readOnly !== undefined) {
            this.setState({ readOnly: this.props.readOnly })
        }
        if (this.props.fromSettlements !== undefined) {
            this.setState({ readOnly: this.props.fromSettlements })
        }

        if (this.props.fromRefunds !== undefined) {
            this.setState({ readOnly: this.props.fromRefunds })
        }
        if (typeof this.props !== "undefined" && this.props !== null && typeof this.props.dealHeaderId != "undefined" 
            && this.props.dealHeaderInfo.Status === 'Voided')
        {
            this.setState({ readOnly: true })
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.dealHeaderInfo !== this.props.dealHeaderInfo) {
            this.setState({
                dealHeaderId: this.props.dealHeaderId,
                dealHeaderInfoProp: this.props.dealHeaderInfo,
                listDealPayments: this.props.dealPayments,
                listDealPaymentsView: this.props.dealPayments,
                //
                buyAmount: this.props.dealHeaderInfo.BuyAmount,
                BCur: this.props.dealHeaderInfo.BCur,
                sellAmount: this.props.dealHeaderInfo.SellAmount,
                SCur: this.props.dealHeaderInfo.SCur,
                status: this.props.dealHeaderInfo.Status,
                dealOrigin: this.props.dealHeaderInfo.DealOrigin,
                createdBy: this.props.dealHeaderInfo["Created By"],
                createdOn: this.props.dealHeaderInfo["Created On"],
                valueDate: this.props.dealHeaderInfo.ValueDate,
                customerRate: this.props.dealHeaderInfo.CustomerRate,
                customerRefNo: this.props.dealHeaderInfo.CustomerRefNo,
                totalPaidByCustomer: this.props.dealHeaderInfo.TotalPaidByCustomer,
                //totaSentToBeneficiaries: dealHeaderInfo.TotaSentToBeneficiaries,
                totaSentToBeneficiaries: Number(this.props.dealHeaderInfo.TotalDisbursed),
                totalFees: this.props.dealHeaderInfo.TotalFees,
                dealPaidStatus: this.props.dealHeaderInfo.DealPaidStatus,
                updateToken: this.props.dealHeaderInfo.UpdateToken,
                customerName: this.props.dealHeaderInfo.Name,
                customerID: this.props.dealHeaderInfo["Customer ID"],
                //
                type: this.props.dealHeaderInfo.type,
                customerType: this.props.dealHeaderInfo.CustomerType,
                coverDeals: this.props.dealHeaderInfo.CoverDeals,
                coveredDeals: this.props.dealHeaderInfo.CoveredDeals
            }, () => {
                this.getDealFees();
                //only Forwards
                this.getGlobalParameters();

                if (this.props.dealHeaderInfo.Type === 'Forward') {
                    getDrawdownsByForward(this.props.dealHeaderInfo.DealHeaderID,0,0).then(drawdownsValue => {
                        if (drawdownsValue.totalCount > 0) {
                            this.setState({listDrawdowns: drawdownsValue.deals});
                        }
                    })
                }
            })
        }
        if (prevProps.dealPayments !== this.props.dealPayments) {
            this.setState({
                listDealPayments: this.props.dealPayments,
                listDealPaymentsView: this.props.dealPayments
            })
        }
    }

    getGlobalParameters = () => {
        getParametersByName('TradeScreenAllowAdditionalSettlements').then((json)=>{
            this.setState({showIncludeInwardsConfirmation: json.parameters[0].Value === 'true'})
        });
    }

    

    /* handleLoadClick() {
        this.setState({loading: true});

        console.group("handleLoadClick")
        console.groupEnd();
        getDealHeaderInfo(this.state.dealHeaderId).then(
            (value) => {
                if (typeof value !== "undefined" && typeof value.dealHeaderInfo !== "undefined" && value.dealHeaderInfo != null) {
                    var dealHeaderInfo = value.dealHeaderInfo[0]
                    console.table(dealHeaderInfo);
                    this.setState({
                        DealHeaderID: dealHeaderInfo.DealHeaderID,
                        buyAmount: dealHeaderInfo.BuyAmount,
                        BCur: dealHeaderInfo.BCur,
                        sellAmount: dealHeaderInfo.SellAmount,
                        SCur: dealHeaderInfo.SCur,
                        Status: dealHeaderInfo.Status,
                        ValueDate: dealHeaderInfo.ValueDate,
                        CustomerRate: dealHeaderInfo.CustomerRate,
                        customerRefNo: dealHeaderInfo.CustomerRefNo,
                        totalPaidByCustomer: dealHeaderInfo.TotalPaidByCustomer,
                        //totaSentToBeneficiaries: dealHeaderInfo.TotaSentToBeneficiaries,
                        totaSentToBeneficiaries: dealHeaderInfo.TotalDisbursed,
                        totalFees: dealHeaderInfo.TotalFees,
                        dealPaidStatus: dealHeaderInfo.DealPaidStatus,
                        updateToken: dealHeaderInfo.UpdateToken,
                        customerName: dealHeaderInfo.Name,
                        customerID: dealHeaderInfo["Customer ID"]
                    });
                }

                if (typeof value.dealPayments !== undefined && value.dealPayments != null) {
                    this.setState({
                        listDealPayments: value.dealPayments,
                        listDealPaymentsView: value.dealPayments
                    });
                } else {
                    this.setState({
                        listDealPayments: [],
                        listDealPaymentsView: []
                    });
                }
            }
        );

        //Get deal fees
        this.getDealFees();
        
        this.setState({
            loading: false
        });
    } */

    clickButtonViewRiskScoreCalculation()
    {

        var objDeal     = this.state.dealHeaderInfoProp;
        let listInco    = []
        let listOutg    = []

        getIncomingFundsByDeal(objDeal.DealHeaderID,0,0).then(
            (jsonResponse)=>{
                console.log("getIncomingFundsByDeal: "+ JSON.stringify(jsonResponse));

                if(jsonResponse !== undefined)
                {
                    switch (jsonResponse.httpStatusCode) 
                    {
                        case 200:
                        {
                            if(jsonResponse.totalCount !== 0)
                            {
                                listInco = jsonResponse.incomingFunds
                            }
                            break;
                        }
                        default:
                        {
                            break;
                        }
                    }
                }

                getOutgoingFundsByDeal(objDeal.DealHeaderID,0,0).then(
                    (jsonResponse)=>{

                        console.log("getOutgoingFundsByDeal: "+ JSON.stringify(jsonResponse));
        
                        if(jsonResponse !== undefined)
                        {
                           switch (jsonResponse.httpStatusCode) 
                           {
                               case 200:
                               {
                                   if(jsonResponse.totalCount !== 0)
                                   {
                                        listOutg = jsonResponse.outgoingFunds
                                   }
                                   break;
                               }
                               default:
                               {
                                   break;
                               }
                           }
                        }

                        var modelRquest = 
                        {
                            "dealType"      : DealTypesNames[objDeal.dealTypeName],
                            "customerID"    : objDeal['Customer ID'],
                            "buyCurrency"   : objDeal.BCur,
                            "sellCurrency"  : objDeal.SCur,
                            "oOutgoingFunds": listOutg,
                            "oIncomingFunds": listInco,
                            dealID          : objDeal.DealHeaderID
                        }

                        let subtotalA = 0;
                        let subtotalB = 0;
                        let listRiskScoreDetails = [];

                        getRiskScoreDetailsForDeals(modelRquest).then(
                            (jsonResponse)=>{
                                console.log("getRiskScoreDetailsForDeals: "+ JSON.stringify(jsonResponse));

                                /*
                                    {"riskScoreDetails":[
                                        {
                                            "Risk Factor":"Customer (Willy Corp 1000 (WO))",
                                            "Risk Score":26,
                                            "Risk Importance":50,
                                            "Total":1300,
                                            "Entity ID":9679,
                                            "Entity Type":"C",
                                            "Order":1,
                                            "Risk Score Text":"26",
                                            "Score Calculated":1,
                                            "Parent Risk Score Text":""
                                        },...]}
                                */

                                if(jsonResponse.riskScoreDetails !== undefined)
                                {
                                    listRiskScoreDetails = jsonResponse.riskScoreDetails;
                                    listRiskScoreDetails.forEach(risk => {
                                        subtotalA += risk['Risk Importance'];
                                        subtotalB += risk['Total'];
                                    });

                                    let color   = this.state.colorRiskText;
                                    let text    = this.state.dealRiskScoreText;

                                    getRiskScoreRanges().then(
                                        (jsonResponse) => {
                                            console.log("getRiskScoreRanges: "+ JSON.stringify(jsonResponse))
                                            if(jsonResponse.riskScores !== undefined)
                                            {
                                                /*{
                                                    "Score Range ID":10,
                                                    "Range From":0,
                                                    "Range To":25,
                                                    "Description":"Low risk",
                                                    "Color":"#FF80FF00",
                                                    "Status":"Authorized to deal",
                                                    "User Editable":false,
                                                    "Entity Type":"Customer"
                                                }*/
                            
                                                var listRSRAll = []
                                                
                                                listRSRAll = jsonResponse.riskScores;

                                                listRSRAll.forEach(riskScoreRange => {
                                                    if( riskScoreRange['Entity Type'] === 'Trade' && 
                                                        riskScoreRange['Range From'] <= Math.round(subtotalB/subtotalA) && 
                                                        Math.round(subtotalB/subtotalA) <= riskScoreRange['Range To'])
                                                    {
                                                        color = riskScoreRange['Color Hex'];
                                                        text  = riskScoreRange.Description;
                                                    }
                                                });
                                            }

                                            this.setState({
                                                listRiskScoreDetails    : listRiskScoreDetails,
                                                riskValue               : Math.round(subtotalB/subtotalA),
                                                dealRiskScoreText       : text === this.state.dealRiskScoreText ? text + Math.round(subtotalB/subtotalA) : text,
                                                colorRiskText           : color,
                                                fontWeight              : 'bolder'
                                            })
                                        }
                                    );
                                }
                            }
                        );
                    }
                );
            }
        );
    }

    hideConfirmationButton() {
        let hideButton = true
        if (this.state.customerType === 'Bank') {
            hideButton = false
        }
        return hideButton
    }

    getDealFees() {
        var dealFees;
        var dealFeesCalc = 0;
        if (dealFees !== undefined) {
            this.setState({
                dealFeeList: dealFees
            });
        }
        else {
            getDealFees(this.state.dealHeaderId, '', 'Backoffice').then((json) => {
                if (typeof json !== "undefined" && typeof json.dealFees !== "undefined") {
                    let jsonDealFees = json.dealFees;
                    var helper = [];
                    for (var i = 0; i < jsonDealFees.length; i++) {
                        jsonDealFees[i].FeeDescription = jsonDealFees[i].Description;
                        helper.push(jsonDealFees[i]);
                        //
                        dealFeesCalc += jsonDealFees[i].FeeAmount;
                    }
                    this.setState({
                        dealFeeList: helper, totalFees: dealFeesCalc
                    });
                }
            });
        }
    }

    handleDeleteClick(obj) {
    }

    getBuyAmountValue(event) {
        this.setState({
            buyAmount: event.target.value
        });
    }

    getSellAmountValue(event) {
        this.setState({
            sellAmount: event.target.value
        });
    }

    //delete
    getDealTestIdValue(event) {
        this.setState({
            //dealTestId: event.target.value,
            dealHeaderId: event.target.value
        });
    }

    handleOnCheckApplied(event) {
        let checked = event.target.checked
        this.setState({
            showAppliedPayments: checked
        })
        if (checked) {
            this.setState({
                showAvailablePayments: false,
                listDealPaymentsView: this.state.listDealPayments.filter(function (element) {
                    return element['Applied'] === 1;
                })
            })
        } else {
            this.setState({
                listDealPaymentsView: this.state.listDealPayments
            })
        }
    }

    handleOnCheckAvailable(event) {
        let checked = event.target.checked
        this.setState({
            showAvailablePayments: checked
        })
        if (checked) {
            this.setState({
                showAppliedPayments: false,
                listDealPaymentsView: this.state.listDealPayments.filter(function (element) {
                    return (element['Balance Available'] > 0 || element['Applied'] === 0);
                })
            })
        } else {
            this.setState({
                listDealPaymentsView: this.state.listDealPayments
            })
        }
    }

    handleOnChangeDealFee = (event) => {
        let dealFeeTemp = this.state.dealFeeList;
        let controlID = event.target.id;
        let index = controlID.substring(controlID.indexOf('-') + 1);
        let amount = event.target.value;

        if (dealFeeTemp[index].FeeAmount === amount) {
            return;
        } else {
            dealFeeTemp[index].FeeAmount = amount;

            var totalFeesCalc = 0;
            for (var i = 0; i < dealFeeTemp.length; i++) {
                totalFeesCalc += Number(dealFeeTemp[i].FeeAmount);
            }

            this.setState({
                dealFeeList: dealFeeTemp,
                totalFees: totalFeesCalc,
                updateFeesOnBlur: true
            });
        }
    }

    handleUpdateDealFee = (index) => {

        var dealFeeTemp = this.state.dealFeeList;

        if (this.state.updateFeesOnBlur) {
            let sessionUserID = localStorage.getItem('UserID');
            var model = {
                FeeAmount: dealFeeTemp[index].FeeAmount,
                FeeDescription: dealFeeTemp[index].Description,
                FeeStructureID: dealFeeTemp[index].FeeStructureID,
                UserID: sessionUserID,
                SourceID: this.state.dealHeaderId,
                SourceTable: "E",
                UpdateToken: dealFeeTemp[index].UpdateToken == null ? 0 : dealFeeTemp[index].UpdateToken
            }

            updateDealFee(model).then((json) => {
                if (json.httpStatusCode === 200) {
                    this.props.reloadDealHeaderInfo();

                } else {
                    this.setState({
                        showAlert: true,
                        alertMessage: 'An error occurred while trying to update de fees. Please try again.',
                        alertTitle: 'Error'
                    });
                }
                this.setState({ updateFeesOnBlur: false });
            })
        }
    }

    handlePaymentAmountToUse(data, event) {

        let tempPaymentsToApply = this.state.listPaymentsToApply;
        let amountToUse = event.target.value;
        let balanceAvailable = data.balanceAvailable;

        if (amountToUse > balanceAvailable) {
            this.setState({ showAmountExceedsBalanceAlert: true });
            return
        }

        //remove if exist
        if (tempPaymentsToApply.length > 0) {
            tempPaymentsToApply = tempPaymentsToApply.filter(item => item.PaymentId !== data.paymentId)
        }

        //add if amount > 0
        if (amountToUse > 0) {
            tempPaymentsToApply.push({
                PaymentId: data.paymentId,
                AmountToUse: amountToUse
            })
        }

        //traveler.map(item => item.Amount).reduce((prev, next) => prev + next);    
        if (tempPaymentsToApply.length > 0) {
            var amountTotalToApply = tempPaymentsToApply.map(item => item.AmountToUse).reduce((prev, next) => prev + next);
            if (amountTotalToApply + this.state.totalPaidByCustomer > this.state.buyAmount + this.state.totalFees) {
                this.setState({
                    showAmountToApplyExceedsBuyBalance: true
                });
                return
            }
        }

        var paymentChanged = this.state.listDealPaymentsView.filter(item => item['Payment ID'] === data.paymentId)[0]
        paymentChanged['Amount To Use'] = amountToUse

        this.setState({
            listPaymentsToApply: tempPaymentsToApply
        });
    }

    handleApplyPayments() {
        this.setState({ showApplyPaymentsAlert: true });
    }

    //alerts

    yesApplyPaymentsAlert() {
        this.setState({ showApplyPaymentsAlert: false });
        let userID = localStorage.getItem('UserID');
        var model = {
            DealHeaderId: this.state.dealHeaderId,
            DealUpdateToken: this.state.updateToken,
            UserId: userID,
            SaveInPendingStatus: false,
            payments: this.state.listPaymentsToApply
        }
        applyPaymentsToDeal(model).then((json) => {
            if (json.httpStatusCode !== 200) {
                this.setState({
                    showAlert: true,
                    alertMessage: 'An error occurred while trying to apply the payments. Please try again.',
                    alertTitle: 'Error'
                });
            } else {
                this.setState({
                    listPaymentsToApply: [],
                    showAlert: true,
                    alertTitle: 'Payments applied successfully.'
                }, () => this.props.reloadDealHeaderInfo());

            }
        });
    }

    noApplyPaymentsAlert() {
        this.setState({ showApplyPaymentsAlert: false });
    }

    okAmountExceedsBalanceAlert() {
        this.setState({ showAmountExceedsBalanceAlert: false });
    }

    okAmountToApplyExceedsBuyBalance() {
        this.setState({ showAmountToApplyExceedsBuyBalance: false });
    }

    closeAlert() {
        if (this.state.incomingPaymentsTab) {
            //This not reload deal but open the incoming payments tab
            this.props.openIncomingTab(this.state.incomingPaymentsTab)
            this.setState({ showAlert: false, alertMessage: '', alertTitle: '', incomingPaymentsTab: false });
        } else {
            this.setState({ showAlert: false, alertMessage: '', alertTitle: '' });
        }
    }

    noConfirmDealAlert() {
        this.setState({ showConfirmDealAlert: false });
    }

    yesConfirmDealAlert() {
        this.setState({ showConfirmDealAlert: false });
        let userID = localStorage.getItem('UserID');
        var model = {
            DealId: this.state.dealHeaderId,
            CreatedBy: userID,
            UpdateToken: this.state.updateToken
        }
        completedIncomingOutgoingStatus(model).then((json) => {
            if (json.httpStatusCode !== 200) {
                this.setState({
                    showAlert: true,
                    alertMessage: 'An error occurred while trying to confirm. Please try again.',
                    alertTitle: 'Error'
                });
            } else {
                this.setState({
                    showAlert: true,
                    alertTitle: 'Confirmation completed successfully.'
                }, () => this.props.reloadDealHeaderInfo());

            }
        });
    }
    //alerts end

    //navigation

    handleConfirmDeal() {
        this.setState({ showConfirmDealAlert: true });
    }

    handlePrintSendDealConfirmation() {
        this.setState({ showDealConfirmation: true });
    }

    handleViewSentEmails() {
        this.setState({ showDealEmailLog: true });
    }

    handleConfirmDealSettlements = () => {
        let userId = localStorage.getItem('UserID')
        this.setState({ loading: true }, () => {
            confirmDealSettlements(this.state.dealHeaderId, userId, true).then((response) => {
                console.log(response)
                if (response.httpStatusCode !== 200) {
                    let incomingPaymentsTab = false
                    if (response.errorMessage != null && response.errorMessage !== '') {
                        if (response.errorMessage.includes('go to the Incoming Payments tab')) {
                            incomingPaymentsTab = true;
                        }
                    }
                    this.setState({
                        incomingPaymentsTab,
                        showAlert: true,
                        alertMessage: response.errorMessage || 'An error occurred while trying to confirm the settlements. Please try again.',
                        alertTitle: 'Error',
                        loading: false
                    })
                } else {
                    let message = 'Deal Settlements Confirmed Successfully.'
                    let alertTitle = 'Success'
                    if (response.dealConfirmationMessage !== null && response.dealConfirmationMessage !== "") {
                        alertTitle = 'Warning'
                        message = response.dealConfirmationMessage
                        message = message.split("\n").map(function(item, idx) {
                            return (
                                <span key={idx}>
                                    {item}
                                    <br/>
                                </span>
                             )
                        })
                    }
                    this.setState({
                        showAlert: true,
                        alertMessage: message,
                        alertTitle: alertTitle,
                        loading: false
                    }, () => this.props.reloadDealHeaderInfo())
                }
            })
        })
    }

    handleBackLink() {
        this.setState({
            showDealConfirmation: false,
            showDealEmailLog: false
        });
    }

    //navigation end

    //addtional properties
    handleSaveProperties() {
        this.setState({ entityPropertiesSaveProcess: true });
    }

    onFinishEntityPropertiesSaveProcess(status, message, pendingMandatoryList) {
        if (status) {
            this.setState({ loading: false, entityPropertiesSaveProcess: false, showAlert: true, alertTitle: 'Success.', alertMessage: 'Deal properties saved.', errors: pendingMandatoryList });
        } else {
            this.setState({ loading: false, entityPropertiesSaveProcess: false, showAlert: true, alertTitle: 'Error', alertMessage: message, errors: pendingMandatoryList });
        }
    }
    //
    handlefeesSectionClick = (event) => {
        this.setState({ feesSectionExpanded: !this.state.feesSectionExpanded });
    }

    changeDealInformation = () => {
        this.props.clickDeal(this.state.dealHeaderInfoProp.ParentDealHeaderId);
    }

    //Additional Properties
    handleUpdateInternalComments = (event) => {
        this.setState({ dealHeaderInfoProp: { ...this.state.dealHeaderInfoProp, InternalComments: event.target.value } })
    }
    handleUpdateExternalComments = (event) => {
        this.setState({ dealHeaderInfoProp: { ...this.state.dealHeaderInfoProp, ExternalComments: event.target.value } })
    }
    handleSaveDealInfo = () => {
        let model = {
            DealHeaderId: this.state.dealHeaderInfoProp.DealHeaderID,
            InternalComment: this.state.dealHeaderInfoProp.InternalComments,
            ExternalComment: this.state.dealHeaderInfoProp.ExternalComments,
            UpdateToken: this.state.dealHeaderInfoProp.UpdateToken,
            CustomerRefNo: this.state.dealHeaderInfoProp.CustomerRefNo
        }
        updateDealHeader(model).then(
            (json) => {
                var alertMessage = '', alertTitle = '';
                switch (json.httpStatusCode) {
                    case 200:
                        {
                            alertTitle = 'Success';
                            alertMessage = 'Deal ' + this.state.dealHeaderInfoProp.DealHeaderID + ' has been saved';
                            this.setState({
                                dealHeaderInfoProp: {
                                    ...this.state.dealHeaderInfoProp,
                                    UpdateToken: json.updateToken
                                },
                                alertTitle: alertTitle,
                                alertMessage: alertMessage,
                                showAlert: true,
                                loading: false,
                            }, () => {
                                this.props.updateDealObj(this.state.dealHeaderInfoProp)
                            });
                            break;
                        }
                    default:
                        {
                            alertMessage = 'Deal not saved! Please try again or contact administrator.';
                            alertTitle = 'Error';

                             if (json.Message !== undefined) {
                                 switch (json.Message) {
                                     case 'concurrency':
                                         {
                                             alertMessage = 'Please reload the information and try again. Otherwise contact your supervisor';
                                             break;
                                         }
                                     default:
                                         {
                                             alertMessage = alertMessage + ' (' + json.Message + ')';
                                             break;
                                         }
                                 }
                             } else {
                                 if (json.httpErrorMessage !== undefined) {
                                     alertTitle = 'Error';
                                     alertMessage = json.httpErrorMessage;
                                 }
                             }
                             this.setState({
                                alertTitle: alertTitle,
                                alertMessage: alertMessage,
                                showAlert: true,
                                loading: false,
                            });
                             break;
                         }
                 }
            }
        );
    }
    render() {
        //fees section
        var dealFeeRows = [];

        for (var i = 0; i < this.state.dealFeeList.length; i++) {
            let index = i;
            var disabled = '';
            if (this.state.dealFeeList[i].ReadOnly === true || this.state.readOnly) {
                disabled = 'disabled';
            }
            dealFeeRows.push(
                <tr key={i}>
                    <td>
                        {this.state.dealFeeList[i].Description}
                    </td>
                    <td>
                        <NumberInput id={"dealfee-" + i} type="Currency" className="uk-input"
                            value={this.state.dealFeeList[i].FeeAmount}
                            onChange={this.handleOnChangeDealFee}
                            scale={getScaleByCurrency(this.state.BCur)}
                            onBlur={() => this.handleUpdateDealFee(index)}
                            disabled={disabled} />
                    </td>
                </tr>
            );
        }

        var parentDealHeaderId = '';

        if (this.state.dealHeaderInfoProp.ParentDealHeaderId !== null) {
            parentDealHeaderId = '(' + this.state.dealHeaderInfoProp.ParentDealHeaderId + ')';
        }


        //new dynamic table with editable cell
        /*       var headers = [];
             var rows = [];
     
             headers.push(<th key='1'>Payment ID</th>);
             headers.push(<th key='2'>Payment Amount</th>);
             headers.push(<th key='3'>Incoming Payment Status</th>);
             headers.push(<th key='4'>Amount Applied</th>);
             headers.push(<th key='5'>Balance Available</th>);
             headers.push(<th key='6'>Amount To Use</th>); 
     
             var index=0;
           this.state.listDealPaymentsView.forEach(element => {
                 index++;
                 rows.push(<tr key={index}>
                     <td>{element['Payment ID']}</td>
                     <td>{formatToCurrency(element['Payment Amount'])}</td>
                     <td>{element['Incoming Payment Status']}</td>
                     <td>{formatToCurrency(element['Amount Applied'])}</td>
                     <td>{formatToCurrency(element['Balance Available'])}</td>
                     <td><NumberInput id={'paymentamounttouse-' + index} type="Currency" value={element['Amount To Use']} className="uk-input"
                     onChange={this.handlePaymentAmountToUse.bind(this, 
                         {
                             paymentId: element['Payment ID'],
                             balanceAvailable: element['Balance Available']
                         }
                     )}/>
                     </td>                   
                 </tr>);
             });
     */
        //list of payments to be applied
        var amountTotalToApply = 0;
        var numberOfPaymentsToApply = 0;
        this.state.listPaymentsToApply.forEach(element => {
            amountTotalToApply += element.AmountToUse;
            numberOfPaymentsToApply++;
        });
        amountTotalToApply += this.state.BCur;

        //Deal fees sections
        let dealFeesSection = (
            <div className="uk-width-2-3 uk-margin-left uk-padding-small uk-padding-remove-top uk-padding-remove-bottom">
                <h5 className="uk-heading-divider properties-tasks-category-title-new"
                    uk-toggle="target : #feeSection;"
                    style={{ paddingLeft: "0x", paddingTop: "5px", paddingRight: "10px", cursor: 'pointer' }}>Total Fees &nbsp; {formatToCurrencyWithScale(this.state.totalFees, this.state.BCur)}
                    <img
                        alt="Plus icon"
                        className="properties-tasks-category-image"
                        id="feeSection"
                        src={PlusIcon}
                    //className="fa fa-fw fa-plus" 

                    />
                    <img
                        alt="Minus icon"
                        className="properties-tasks-category-image"
                        id="feeSection"
                        src={MinusIcon}
                        //className="fa fa-fw fa-minus" 

                        hidden
                    />
                </h5>
                <div id="feeSection" hidden className="uk-accordion-content border-table">
                    <table className="uk-table uk-table-divider uk-table-hover">
                        <thead>
                            <tr>
                                <th>Fee</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dealFeeRows}
                        </tbody>
                    </table>
                </div>
            </div>
        );
        //Additioinal Information section
        let addInformationSection = (
            <div className="uk-width-2-3 uk-margin-left uk-padding-small uk-padding-remove-top uk-padding-remove-bottom">
                <h5 className="uk-heading-divider properties-tasks-category-title-new"
                    uk-toggle="target : #addInfoSection;"
                    style={{ paddingLeft: "0x", paddingTop: "5px", paddingRight: "10px", cursor: 'pointer' }}>Additional Information
                    <img
                        alt="Plus icon"
                        className="properties-tasks-category-image"
                        id="addInfoSection"
                        src={MinusIcon}
                    //className="fa fa-fw fa-plus" 

                    />
                    <img
                        alt="Minus icon"
                        className="properties-tasks-category-image"
                        id="addInfoSection"
                        src={PlusIcon}
                        //className="fa fa-fw fa-minus" 
                        hidden
                    />
                </h5>
                <div id="addInfoSection" className="uk-accordion-content border-table">
                    <div className="uk-width-1-1 uk-form-stacked">
                        <label className="uk-form-label" htmlFor="form-beneficiary-shortName" style={style.boldLabel}>Customer Ref No</label>
                        <div className="" style={style.inputStyle}>
                            <input
                                disabled
                                className="uk-input"
                                id="internalComments"
                                type="text"
                                value={this.state.dealHeaderInfoProp.CustomerRefNo}/>
                        </div>
                    </div>
                    <div className="uk-width-1-1 uk-form-stacked">
                        <label className="uk-form-label" htmlFor="form-beneficiary-shortName" style={style.boldLabel}>Internal Comments</label>
                        <div className="" style={style.inputStyle}>
                            <textarea
                                className="uk-textarea"
                                id="internalComments"
                                type="text"
                                value={this.state.dealHeaderInfoProp.InternalComments}
                                onChange={this.handleUpdateInternalComments} />
                        </div>
                    </div>
                    <div className="uk-width-1-1 uk-form-stacked">
                        <label className="uk-form-label" htmlFor="form-beneficiary-shortName" style={style.boldLabel}>External Comments</label>
                        <div className="" style={style.inputStyle}>
                            <textarea
                                className="uk-textarea"
                                id="externalComments"
                                type="text"
                                value={this.state.dealHeaderInfoProp.ExternalComments}
                                onChange={this.handleUpdateExternalComments} />
                        </div>
                    </div>
                    <div className="uk-width-1-1 uk-form-stacked">
                        <button className="uk-button uk-button-green" type="button" onClick={this.handleSaveDealInfo}
                            disabled={this.state.readOnly}>Save</button>
                    </div>
                </div>
            </div>
        );
        
        //Address section
        let addressSection = (
            <div className="uk-width-2-3 uk-margin-left uk-padding-small uk-padding-remove-top uk-padding-remove-bottom">
                <h5 className="uk-heading-divider properties-tasks-category-title-new"
                    uk-toggle="target : #addressSection;"
                    style={{ paddingLeft: "0x", paddingTop: "5px", paddingRight: "10px", cursor: 'pointer' }}>Address
                    <img
                        alt="Plus icon"
                        className="properties-tasks-category-image"
                        id="addressSection"
                        src={PlusIcon}
                    //className="fa fa-fw fa-plus" 

                    />
                    <img
                        alt="Minus icon"
                        className="properties-tasks-category-image"
                        id="addressSection"
                        src={MinusIcon}
                        //className="fa fa-fw fa-minus" 
                        hidden
                    />
                </h5>
                <div id="addressSection" hidden className="uk-accordion-content border-table">
                    <div className="uk-form-controls">
                        <label className="">{this.state.dealHeaderInfoProp.AddressValue}</label>
                    </div>
                    <div>
                        <label className="">{this.state.dealHeaderInfoProp.Address2}</label>
                    </div>
                    <div>
                        <label className="">{this.state.dealHeaderInfoProp.City}, {this.state.dealHeaderInfoProp.StateName}</label>
                    </div>
                    <div>
                        <label className="">{this.state.dealHeaderInfoProp.CountryName}</label>
                    </div>
                    <div>
                        <label className="">{this.state.dealHeaderInfoProp.Phone}</label>
                    </div>
                    <div>
                        <label className="">Mobile #: {this.state.dealHeaderInfoProp.MobilePhone}</label>
                    </div>
                </div>
            </div>
        );

        var dealEntityFundingControl;
        if(this.state.dealHeaderInfoProp.dealTypeName === 'Spot'
            || ( this.state.dealHeaderInfoProp.dealTypeName === 'Forward' && this.state.dealHeaderInfoProp.ForwardType === 'Closed' )){
            var additionalWarningVoidMessage;
            if(this.state.totaSentToBeneficiaries > 0){
                additionalWarningVoidMessage = ' Please notice that some or all the funds were already disbursed.';
            }

            dealEntityFundingControl = (
                <li>
                    <h5 className="uk-accordion-title">Deal Funding</h5>
                    <div className="uk-accordion-content" style={{ backgroundColor: '#f8f8f8' }}>
                        <div className="uk-margin">
                            <EntityFunding 
                                additionalWarningVoidMessage={additionalWarningVoidMessage}
                                balance={this.state.buyAmount + this.state.totalFees - this.state.totalPaidByCustomer}
                                currencyID={this.state.BCur}
                                customerID={this.state.customerID}
                                entityID={this.state.dealHeaderId}
                                entityType='Deal' 
                                entityUpdateToken={this.state.updateToken}
                                reloadEntity={this.props.reloadDealHeaderInfo}/>
                        </div>
                    </div>
                </li> 
            );
        }

        //Confirmation Button
        let disableConfirmationButton = this.state.dealHeaderId === 0;
        if (!disableConfirmationButton &&
            this.state.dealHeaderInfoProp &&
            this.state.dealHeaderInfoProp.DealPaidStatus === 'Fully Paid' &&
            Number(this.state.dealHeaderInfoProp.SellAmount - this.state.dealHeaderInfoProp.TotalDisbursed) === 0) {
            disableConfirmationButton = true;
        }

        return (
            <div>
                <form>
                    <div className="uk-grid uk-margin">
                        <div className="uk-width-1-2">
                            <div className="uk-grid uk-margin">
                                <div className="uk-width-1-1 uk-grid mg-t-5 ">
                                    <div className="uk-width-1-1"><label className="uk-form-label">Customer Name</label></div>
                                    <div className="uk-width-1-1" style={{ textAlign: 'left' }}><h4 className="h4-deal">{this.state.customerName} ({this.state.customerID})</h4></div>
                                </div>
                                {/* 
                                <div className="uk-width-1-1 uk-margin-bottom">
                                    <div className="uk-grid uk-margin">
                                        <div className="uk-width-1-4"><label className="uk-form-label" >Customer Name</label></div>
                                        <div className="uk-width-expand" style={{ textAlign: 'left' }}>{this.state.customerName} ({this.state.customerID})</div>
                                    </div>
                                </div> */}

                                {/*Line1*/}
                                {/* <div className="uk-width-1-2 uk-margin-bottom">
                                    <div className="uk-grid uk-margin">
                                        <div className="uk-width-1-2">
                                            <label className="uk-form-label">We Buy</label>
                                        </div>
                                        <div className="uk-width-1-2" style={{ textAlign: 'left' }}>
                                            {formatToCurrency(this.state.buyAmount)}  {this.state.BCur}
                                        </div>

                                    </div>
                                </div> */}
                                <div className="uk-width-1-2 uk-grid mg-t-5">
                                    <div className="uk-width-1-1"><label className="uk-form-label">We Buy</label></div>
                                    <div className="uk-width-1-1" style={{ textAlign: 'left' }}><h4 className="h4-deal">{formatToCurrencyWithScale(this.state.buyAmount, this.state.BCur)}  {this.state.BCur}</h4></div>
                                </div>

                                {/* <div className="uk-width-1-2 uk-margin-bottom">
                                    <div className="uk-grid uk-margin">
                                        <div className="uk-width-1-2">
                                            <label className="uk-form-label">Total paid by customer</label>
                                        </div>
                                        <div className="uk-width-1-2" style={{ textAlign: 'middle' }}>
                                            {formatToCurrency(this.state.totalPaidByCustomer)}  {this.state.BCur}
                                        </div>
                                    </div>
                                </div> */}
                                <div className="uk-width-1-2 uk-grid mg-t-5">
                                    <div className="uk-width-1-1"><label className="uk-form-label">Total paid by customer</label></div>
                                    <div className="uk-width-1-1" style={{ textAlign: 'left' }}><h4 className="h4-deal">{formatToCurrencyWithScale(this.state.totalPaidByCustomer, this.state.BCur)}  {this.state.BCur}</h4></div>
                                </div>

                                {/*Line2*/}
                                {/* <div className="uk-width-1-2 uk-margin-bottom">
                                    <div className="uk-grid uk-margin">
                                        <div className="uk-width-1-2">
                                            <label className="uk-form-label" >We Sell</label>
                                        </div>
                                        <div className="uk-width-1-2" style={{ textAlign: 'left' }}>
                                            {formatToCurrency(this.state.sellAmount)}  {this.state.SCur}
                                        </div>
                                    </div>
                                </div> */}

                                <div className="uk-width-1-2 uk-grid mg-t-5">
                                    <div className="uk-width-1-1"><label className="uk-form-label">We Sell</label></div>
                                    <div className="uk-width-1-1" style={{ textAlign: 'left' }}><h4 className="h4-deal">{formatToCurrencyWithScale(this.state.sellAmount, this.state.SCur)}  {this.state.SCur}</h4></div>
                                </div>

                                {/* <div className="uk-width-1-2 uk-margin-bottom">
                                    <div className="uk-grid uk-margin">
                                        <div className="uk-width-1-2">
                                            <label className="uk-form-label" >Total sent to beneficiaries</label>
                                        </div>
                                        <div className="uk-width-1-2" style={{ textAlign: 'middle' }}>
                                            {formatToCurrency(this.state.totaSentToBeneficiaries)}  {this.state.SCur}
                                        </div>
                                    </div>
                                </div> */}

                                <div className="uk-width-1-2 uk-grid mg-t-5">
                                    <div className="uk-width-1-1"><label className="uk-form-label">Total sent to beneficiaries</label></div>
                                    <div className="uk-width-1-1" style={{ textAlign: 'left' }}><h4 className="h4-deal">{formatToCurrencyWithScale(this.state.totaSentToBeneficiaries, this.state.SCur)}  {this.state.SCur}</h4></div>
                                </div>

                                {/*Line3*/}
                                {/* <div className="uk-width-1-2 uk-margin-bottom">
                                    <div className="uk-grid uk-margin">
                                        <div className="uk-width-1-2"><label className="uk-form-label" >Customer Rate</label></div>
                                        <div className="uk-width-1-2" style={{ textAlign: 'left' }}>{parseFloat(this.state.customerRate).toFixed(8).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</div>
                                    </div>
                                </div>
                                <div className="uk-width-1-2 uk-margin-bottom">
                                    <div className="uk-grid uk-margin">
                                        <div className="uk-width-1-2"><label className="uk-form-label" >Deal Type</label></div>
                                        <div className="uk-width-1-2" style={{ textAlign: 'middle' }}>
                                            {this.state.dealHeaderInfoProp.ForwardType != null ? `${this.state.dealHeaderInfoProp.ForwardType} ` : '' }
                                            {`${this.state.dealHeaderInfoProp.dealTypeName} `}
                                            <a onClick={this.changeDealInformation}>{parentDealHeaderId}</a>
                                        </div>
                                    </div>
                                </div> */}

                                <div className="uk-width-1-2 uk-grid mg-t-5">
                                    <div className="uk-width-1-1"><label className="uk-form-label">Customer Rate</label></div>
                                    <div className="uk-width-1-1" style={{ textAlign: 'left' }}><h4 className="h4-deal">{roundAmountByCurrencyPair(this.state.customerRate, this.state.BCur, this.state.SCur)}</h4></div> {/* parseFloat(this.state.customerRate).toFixed(8).replace(/\d(?=(\d{3})+\.)/g, '$&,') */}
                                </div>
                                <div className="uk-width-1-2 uk-grid mg-t-5">
                                    <div className="uk-width-1-1"><label className="uk-form-label">Deal Type</label></div>
                                    <div className="uk-width-1-1" style={{ textAlign: 'left' }}><h4 className="h4-deal">{this.state.dealHeaderInfoProp.ForwardType != null ? `${this.state.dealHeaderInfoProp.ForwardType} ` : ''}
                                        {`${this.state.dealHeaderInfoProp.dealTypeName} `}
                                        <a onClick={this.changeDealInformation}>{parentDealHeaderId}</a></h4></div>
                                </div>

                                {/*Line4*/}
                                {/* <div className="uk-width-1-2 uk-margin-bottom">
                                    <div className="uk-grid uk-margin">
                                        <div className="uk-width-1-2"><label className="uk-form-label" >Value Date</label></div>
                                        <div className="uk-width-1-2" style={{ textAlign: 'left' }}>{this.state.valueDate}</div>
                                    </div>
                                </div>
                                <div className="uk-width-1-2 uk-margin-bottom">
                                    <div className="uk-grid uk-margin">
                                        <div className="uk-width-1-2"><label className="uk-form-label" >Deal Origin</label></div>
                                        <div className="uk-width-1-2" style={{ textAlign: 'left' }}>{this.state.dealOrigin}</div>
                                    </div>
                                </div> */}

                                <div className="uk-width-1-2 uk-grid mg-t-5">
                                    <div className="uk-width-1-1"><label className="uk-form-label">Value Date</label></div>
                                    <div className="uk-width-1-1" style={{ textAlign: 'left' }}><h4 className="h4-deal">{this.state.valueDate}</h4></div>
                                </div>
                                <div className="uk-width-1-2 uk-grid mg-t-5">
                                    <div className="uk-width-1-1"><label className="uk-form-label">Deal Origin</label></div>
                                    <div className="uk-width-1-1" style={{ textAlign: 'left' }}><h4 className="h4-deal">{this.state.dealOrigin}</h4></div>
                                </div>

                                {/*Line5
                                <div className="uk-width-1-2 uk-margin-bottom">
                                    <div className="uk-grid uk-margin">
                                        <div className="uk-width-1-2"><label className="uk-form-label" >Customer Ref No</label></div>
                                        <div className="uk-width-1-2" style={{ textAlign: 'left' }}>
                                            {this.state.customerRefNo}
                                            //---<input className="uk-input" type="text" id="form-dealinformation-customerRefNo" value={this.state.customerRefNo} readOnly/>
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-width-1-2 uk-margin-bottom">
                                    <div className="uk-grid uk-margin">
                                        <div className="uk-width-1-2"><label className="uk-form-label" >Created By</label></div>
                                        <div className="uk-width-1-2" style={{ textAlign: 'left' }}>{this.state.createdBy} on {this.state.createdOn}</div>
                                    </div>
                                </div>*/}
                                <div className="uk-width-1-1 uk-grid mg-t-5">
                                    <div className="uk-width-1-1"><label className="uk-form-label">Created By</label></div>
                                    <div className="uk-width-1-1" style={{ textAlign: 'left' }}><h4 className="h4-deal">{this.state.createdBy} on {this.state.createdOn}</h4></div>
                                </div>

                                {this.state.coverDeals && 
                                    <div className="uk-width-1-1 uk-grid mg-t-5">
                                        <div className="uk-width-1-1"><label className="uk-form-label">Covered By</label></div>
                                        <div className="uk-width-1-1" style={{ textAlign: 'left' }}><h4 className="h4-deal">{this.state.coverDeals}</h4></div>
                                    </div>
                                }
                                {this.state.coveredDeals && 
                                    <div className="uk-width-1-1 uk-grid mg-t-5">
                                        <div className="uk-width-1-1"><label className="uk-form-label">Cover Deal</label></div>
                                        <div className="uk-width-1-1" style={{ textAlign: 'left' }}><h4 className="h4-deal">{this.state.coveredDeals}</h4></div>
                                    </div>
                                }
                            </div>
                        </div>

                        <div className="uk-width-1-2">
                            {addInformationSection}
                            {addressSection}
                            {dealFeesSection}
                        </div>
                        {/*<div className="uk-width-1-4">
                        <br></br>
                    </div>*/}


                        {/*<div className="uk-width-1-4" style={{alignSelf: 'center'}}>
                            <Badge
                                anchorOrigin={{
                                    vertical    : 'top',
                                    horizontal  : 'right',
                                }}
                                badgeContent={
                                    <InfoIcon 
                                        uk-tooltip  = {'Deal Risk Score'}
                                        style       = {{ color: '#6E9C3A' }}
                                    />
                                }
                                disabled={true}
                            >
                                <div 
                                    className   = "" 
                                    type        = "button" 
                                    style       = {{
                                        borderRadius    : 10,
                                        border          : '1px solid #e5e5e5',
                                        height          : 30,
                                        justifyContent  : 'center',
                                        display         : 'flex',
                                        alignItems      : 'center',
                                        paddingLeft      : 10,
                                        paddingRight     : 10,
                                        backgroundColor : '#1d2b1d'
                                    }}
                                >
                                    <label 
                                        onClick     = { () =>{
                                            this.setState({showModalViewRiskScoreCalculation: true});
                                        }}
                                        className   =""
                                        style       = {{ color: this.state.colorRiskText, fontWeight: this.state.fontWeight, cursor:'pointer'}}
                                        id          = "DealRiskScore"
                                    >{this.state.dealRiskScoreText}
                                    </label>
                                </div>
                            </Badge>
                                    </div>*/}
                    </div>

                    <div className="uk-grid uk-margin">

                    </div>

                    {/*row*/}
                    {!this.state.readOnly &&
                        <div>
                            <button className="uk-button uk-button-default uk-button-green" type="button" onClick={this.handleConfirmDeal.bind(this)}
                                disabled={disableConfirmationButton} hidden={this.hideConfirmationButton() || disableConfirmationButton}>Confirm</button>
                            <button className="uk-button uk-button-default uk-button-green" type="button" onClick={this.handlePrintSendDealConfirmation.bind(this)}
                                disabled={this.state.readOnly}>Print/Send Deal Conf.</button>
                            <button className="uk-button uk-button-green" type="button" onClick={this.handleViewSentEmails.bind(this)}>View Sent Emails</button>
                            <button className="uk-button uk-button-green" type="button" onClick={this.handleConfirmDealSettlements}
                                disabled={this.state.readOnly} hidden={this.state.dealPaidStatus === 'Fully Paid'}>Confirm Settlements</button>
                        </div>}
                    {this.state.status === 'Voided' &&
                    <div>
                        <button className="uk-button uk-button-green" type="button" onClick={this.handleViewSentEmails.bind(this)}>View Sent Emails</button>
                    </div>}

                    <hr className="uk-hr"></hr>              

                    <ul id="accordion-compliance" uk-accordion="multiple: true" >
                        {this.state.listDrawdowns.length > 0 &&
                        <li className="uk-open">
                            <h5 className="uk-accordion-title">Drawdowns</h5>
                            <div className="uk-accordion-content" style={{ backgroundColor: '#f8f8f8' }}>
                                <div className="uk-margin">
                                    <NewDynamicTable data={this.state.listDrawdowns} enableClick={true} clickFunction={(value) => {this.props.clickDeal(value["Deal Header ID"]); this.setState({listDrawdowns: []});}}/>
                                </div>
                            </div>
                        </li> 
                        }
                        { dealEntityFundingControl }
                        {/*Additional Properties*/}
                        <li className="uk-open">
                            <h5 className="uk-accordion-title">Additional Properties</h5>
                            <div className="uk-accordion-content" style={{ backgroundColor: '#f8f8f8' }}>
                                <div className="uk-margin">
                                    <EntityProperties
                                        entityType={DealType}
                                        entityID={this.state.dealHeaderId}
                                        fullScreen={true}
                                        runSaveProcess={this.state.entityPropertiesSaveProcess}
                                        finishSaveProcess={this.onFinishEntityPropertiesSaveProcess.bind(this)}
                                    />
                                </div>
                            </div>
                        </li>
                    </ul>

                    {!this.state.readOnly &&
                        <div className="uk-width-1-1 uk-margin">
                            <button className="uk-button uk-button-green" type="button" onClick={this.handleSaveProperties.bind(this)}>Save Properties</button>
                        </div>
                    }

                    <AlertBox id="close-alert" 
                    open={this.state.showAlert} 
                    onClose={this.closeAlert.bind(this)} 
                    title={this.state.alertTitle} 
                    message={this.state.alertMessage} 
                    type="Ok" 
                    okClick={this.closeAlert.bind(this)}
                    messageBoldList={this.state.errors}
                    />

                    <AlertBox id="apply-payments-alert"
                        open={this.state.showApplyPaymentsAlert}
                        title="Please confirm"
                        message={"You will apply: " + amountTotalToApply + " from " + numberOfPaymentsToApply + " payments"}
                        type="Yes/No"
                        yesClick={this.yesApplyPaymentsAlert.bind(this)}
                        noClick={this.noApplyPaymentsAlert.bind(this)}
                    />

                    <AlertBox id="apply-payments-amountexceedsbalance-alert"
                        open={this.state.showAmountExceedsBalanceAlert}
                        title="Error"
                        message={"The amount exceeds the balance"}
                        type="Ok"
                        okClick={this.okAmountExceedsBalanceAlert.bind(this)}
                    />

                    <AlertBox id="apply-payments-amountexceedsbuyamountplusfees-alert"
                        open={this.state.showAmountToApplyExceedsBuyBalance}
                        title="Error"
                        message={"The amount to apply exceeds the buy amount plus fees"}
                        type="Ok"
                        okClick={this.okAmountToApplyExceedsBuyBalance.bind(this)}
                    />

                    <AlertBox id="deal-confirmation-alert"
                        open={this.state.showConfirmDealAlert}
                        type="Yes/No"
                        title="Please confirm"
                        message={"The funds in and funds out statuses will be set to completed. Are you sure you wish to continue?"}
                        yesClick={this.yesConfirmDealAlert.bind(this)}
                        noClick={this.noConfirmDealAlert.bind(this)}
                    />

                    <LoadingBox loading={this.state.loading} />

                </form>
                <DealConfirmationFormDialog 
                    open                            = {this.state.showDealConfirmation}
                    onClose                         = {this.handleBackLink.bind(this)} 
                    dealHeaderInfo                  = {this.state.dealHeaderInfoProp} 
                    dealHeaderId                    = {this.state.dealHeaderId}
                    lookUpTables                    = {this.props.lookUpTables}
                    showIncludeInwardConfirmation   = {this.state.showIncludeInwardsConfirmation}
                />
                <DealEmailLogForm 
                    open         = {this.state.showDealEmailLog}
                    backLink     = {this.handleBackLink.bind(this)} 
                    dealHeaderId = {this.state.dealHeaderId}
                />
                {/*View Risk Score Calculation*/}
                <RiskScoreDetailDialog
                    entityType                   = {'Trader'}
                    dealId                       = {this.state.dealHeaderId}
                    riskScoreDetails             = {this.state.listRiskScoreDetails}
                    open                         = {this.state.showModalViewRiskScoreCalculation}
                    onCloseRiskScoreDetailDialog = {() =>{this.setState({showModalViewRiskScoreCalculation: false})}}
                />
            </div>
        );
    }
}