import React from 'react';
import GenericBoard from '../../shared/GenericBoard';
import {BoardName} from '../../../constants/BoardNames';
import { getStyle } from '../../../styles/styles';

var style = getStyle();
export default class BeneficiaryBoardsScreen extends React.Component {

    state = {
        showTitle: true
    }
    componentDidMount() {

    }

    isShowTitle = (value) => {
        this.setState({ showTitle: value })
    }
    render(){
        return (
            <div>
                {this.state.showTitle && <h3 className="uk-heading-divider" style={style.titleStyle}>Beneficiary Boards</h3>}
                <GenericBoard board={BoardName.BeneficiaryGenericBoard} mainTitle={"Beneficiary Boards"} isShowTitle={this.isShowTitle} selectSection beneficiaryBody />
            </div>
        );
    }

}