import { Breadcrumb } from 'antd';
import React, { useState } from 'react';
import AuditEntityPropertyScreen from '../auditEntity/AuditEntityPropertyScreen';
import ExtendedOnlinePropertiesScreen from '../updateCustomer/ExtendedOnlinePropertiesScreen';

const ContactPropertiesScreen = ({ contactID, personID, customerType, children }) => {
    const [showBreadCrumb, setShowBreadCrumb] = useState(false);

    const showHistoryScreen = () => {
        setShowBreadCrumb(true);
    }

    const handleBackLink = () => {
        setShowBreadCrumb(false);
    }

    if (showBreadCrumb) {
        return (
            <div>
                <div className="breadcrumb-div">
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item className="breadcrumb-item" onClick={handleBackLink}>Additional Properties</Breadcrumb.Item>
                        <Breadcrumb.Item className="breadcrumb-item-bold">Audit History</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <AuditEntityPropertyScreen entityID={contactID} entityType="Contact" isTitle />;
            </div>
        );
    }

    return <div>
        <ul uk-tab="">
            <li><a>Additional Properties</a></li>
            <li><a>Extended Properties</a></li>
        </ul>
        <ul className="uk-switcher uk-margin">
            <li>{React.cloneElement(children, {showHistoryScreen: showHistoryScreen})}</li>
            <li><ExtendedOnlinePropertiesScreen entityID={contactID} personID={personID} entityType="Contact" customerType={customerType} noTitle={true} /></li>
        </ul>
    </div>;
}

export default ContactPropertiesScreen;
