import React from 'react';
import { formatDate, formatToCurrencyWithScale } from '../../../helpers/FormatHelper';
import { getDefaultPagingNumberOfItems } from '../../../helpers/PackageJsonHelper';
import { getOutgoingFundsInquiries } from '../../../networking/NetworkingCustomerLimitProfile';
import { getStyle } from '../../../styles/styles';
import DatePicker from '../../shared/DatePicker';
import NewDynamicTable from '../../shared/NewDynamicTable';

var style = getStyle();

export default class OutgoingFundsInquiriesForm extends React.Component {
    state = {
        outgoingFundsInquiries: [],
        referredDealId: 0,
        referredDealNo: 0,
        referredCustomer: '',
        referredValueDate: '',
        referredOutgoingValueDate: '',
        referredAmount: 0,
        referredCurrency: 0,
        referredCountry: '',
        referredTraderName: '',
        referredReason: '',
        outgoingFundComment: '',
        referredReviewedBy: '',
        fromDate: '',
        toDate: ''
    }

    changePage(value){
        getOutgoingFundsInquiries(value,getDefaultPagingNumberOfItems(),this.state.fromDate,this.state.toDate).then(
            (value) => {
                this.setState({outgoingFundsInquiries: value.customers});
                var totalCount = value.totalCount;
                var pages = Math.ceil(totalCount/getDefaultPagingNumberOfItems());
                // console.log(pages);
                this.setState({totalPages: pages, newSearch: false});
            }
        );
    }

    handleSearchClick(){
        if(this.state.fromDate !== '' && this.state.fromDate !== undefined && this.state.fromDate !== null && this.state.toDate !== '' && this.state.toDate !== undefined && this.state.toDate !== null){
            getOutgoingFundsInquiries(1,getDefaultPagingNumberOfItems(),this.state.fromDate,this.state.toDate).then(
                (value) => {
                    if (value != null && value.referreadDeals != null) {
                        this.setState({ outgoingFundsInquiries: value.referreadDeals });
                        var totalCount = value.totalCount;
                        var pages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
                        // console.log(pages);
                        this.setState({ totalPages: pages, newSearch: false });
                    }
                }
            );
        }
    }

    handleRowClick(obj){
        this.setState({
            referredDealId: obj["Deal ID"],
            referredCustomer: obj["Customer Name"],
            referredOutgoingValueDate: obj["Outgoing Value Date"],
            referredValueDate: obj["Value Date"],
            referredAmount: obj["Amount"],
            referredCurrency: obj["Currency"],
            referredTraderName: obj["Trader Name"],
            referredReason: obj["Reasons"],
            outgoingFundComment: obj["Comment"],
            referredReviewedBy: obj["Reviewed by"]
        });
    }

    handleUpdateFromDate(date){
        this.setState({fromDate: formatDate(date)});
    }

    handleUpdateToDate(date){
        this.setState({toDate: formatDate(date)});
    }
    
    handleUpdateReferredDealId(value){
        this.setState({referredDealId: value.target.value});
    }

    handleUpdateReferredDealNo(value){
        this.setState({referredDealNo: value.target.value});
    }

    handleUpdateReferredCustomer(value){
        this.setState({referredCustomer: value.target.value});
    }

    handleUpdateReferredValueDate(value){
        this.setState({referredValueDate: value.target.value});
    }

    handleUpdateReferredOutgoingValueDate(value){
        this.setState({referredOutgoingValueDate: value.target.value});
    }

    handleUpdateReferredAmount(value){
        this.setState({referredAmount: value.target.value});
    }

    handleUpdateReferredCurrency(value){
        this.setState({referredCurrency: value.target.value});
    }

    handleUpdateReferredCountry(value){
        this.setState({referredCountry: value.target.value});
    }

    handleUpdateReferredTraderName(value){
        this.setState({referredTraderName: value.target.value});
    }

    handleUpdateReferredReason(value){
        this.setState({referredReason: value.target.value});
    }

    handleUpdateOutgoingFundComment(value){
        this.setState({outgoingFundComment: value.target.value});
    }

    handleUpdateReferredReviewedBy(value){
        this.setState({referredReviewedBy: value.target.value});
    }

    render(){

        return(
            <div>
                <form className="uk-form-horizontal uk-margin-large">
                    <h3 className="uk-heading-divider" style={style.titleStyle}>Selected Outgoing Fund Information</h3>
                    <div className="uk-grid uk-margin">
                        <div className="uk-width-1-2 uk-form-stacked">
                            <div className="uk-margin-right">
                                <label className="uk-form-label" htmlFor="form-bank-status">Deal ID :</label>
                                <div className="uk-margin-right">
                                    <input className="uk-input" disabled={true} id="referredDealId" type="text" placeholder="" value={this.state.referredDealId} onChange={this.handleUpdateReferredDealId.bind(this)}/>
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <div className="uk-margin-right">
                                <label className="uk-form-label" htmlFor="form-bank-status">Customer :</label>
                                <div className="uk-margin-right">
                                    <input className="uk-input" disabled={true} id="referredCustomer" type="text" placeholder="" value={this.state.referredCustomer} onChange={this.handleUpdateReferredCustomer.bind(this)}/>
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <div className="uk-margin-right">
                                <label className="uk-form-label" htmlFor="form-bank-status">Deal Value Date :</label>
                                <div className="uk-margin-right">
                                    <input className="uk-input" disabled={true} id="referredValueDate" type="text" placeholder="" value={this.state.referredValueDate} onChange={this.handleUpdateReferredValueDate.bind(this)}/>
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <div className="uk-margin-right">
                                <label className="uk-form-label" htmlFor="form-bank-status">Outgoing Value Date :</label>
                                <div className="uk-margin-right">
                                    <input className="uk-input" disabled={true} id="referredOutgoingValueDate" type="text" placeholder="" value={this.state.referredOutgoingValueDate} onChange={this.handleUpdateReferredOutgoingValueDate.bind(this)}/>
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <div className="uk-margin-right">
                                <label className="uk-form-label" htmlFor="form-bank-status">Amount :</label>
                                <div className="uk-margin-right">
                                    <input className="uk-input" disabled={true} id="referredAmount" type="text" placeholder="" value={this.state.referredAmount} onChange={this.handleUpdateReferredAmount.bind(this)}/>
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <div className="uk-margin-right">
                                <label className="uk-form-label" htmlFor="form-bank-status">Currency :</label>
                                <div className="uk-margin-right">
                                    <input className="uk-input" disabled={true} id="referredBuyCurrency" type="text" placeholder="" value={this.state.referredCurrency} onChange={this.handleUpdateReferredCurrency.bind(this)}/>
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <div className="uk-margin-right">
                                <label className="uk-form-label" htmlFor="form-bank-status">Trader Name :</label>
                                <div className="uk-margin-right">
                                    <input className="uk-input" disabled={true} id="referredTraderName" type="text" placeholder="" value={this.state.referredTraderName} onChange={this.handleUpdateReferredTraderName.bind(this)}/>
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <div className="uk-margin-right">
                                <label className="uk-form-label" htmlFor="form-bank-status">Reason :</label>
                                <div className="uk-margin-right">
                                    <input className="uk-input" disabled={true} id="referredReason" type="text" placeholder="" value={this.state.referredReason} onChange={this.handleUpdateReferredReason.bind(this)}/>
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <div className="uk-margin-right">
                                <label className="uk-form-label" htmlFor="form-bank-status">Comment :</label>
                                <div className="uk-margin-right">
                                    <input className="uk-input" disabled={true} id="outgoingFundComment" type="text" placeholder="" value={this.state.outgoingFundComment} onChange={this.handleUpdateOutgoingFundComment.bind(this)}/>
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-1-2 uk-form-stacked">
                            <div className="uk-margin-right">
                                <label className="uk-form-label" htmlFor="form-bank-status">Reviewed By :</label>
                                <div className="uk-margin-right">
                                    <input className="uk-input" disabled={true} id="referredReviewedBy" type="text" placeholder="" value={this.state.referredReviewedBy} onChange={this.handleUpdateReferredReviewedBy.bind(this)}/>
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-1"> 
                            <div className="uk-margin" style={style.inputStyle}>
                                <br></br>
                            </div>
                        </div>
                        <div className="uk-width-1 uk-margin">                      
                            <div className="uk-heading-divider" style={style.titleStyle}>
                                <h4>Search Criteria</h4>
                            </div>
                        </div>
                        <div className="uk-width-1-3">
                            <label className="uk-form-label" >From (Outgoing) Date</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <DatePicker className="uk-input" value={this.state.fromDate} onDayChange={this.handleUpdateFromDate.bind(this)}/>
                            </div>
                        </div>
                        <div className="uk-width-1-3">
                            <label className="uk-form-label" >To (Outgoing) Date</label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <DatePicker className="uk-input" value={this.state.toDate} onDayChange={this.handleUpdateToDate.bind(this)}/>
                            </div>
                        </div>
                        <div className="uk-width-1-3">
                            <label className="uk-form-label" > </label>
                            <div className="uk-margin" style={style.inputStyle}>
                                <button className="uk-button uk-button-green uk-margin-right" onClick={this.handleSearchClick.bind(this)} type="button">Search</button>
                            </div>
                        </div>                        
                    </div>
                </form>
                <NewDynamicTable formats={[{header: 'Amount', formatFunction: formatToCurrencyWithScale, formatCurrency: 'Currency'}]} hiddenHeaders={['ID','Deal Value Date','Outgoing Value Date','Trade Name']} data={this.state.outgoingFundsInquiries} enableClick={true} clickFunction={this.handleRowClick.bind(this)} newSearch={this.state.newSearch} useDeleteButton={false}/>
            </div>
        );
    }
}