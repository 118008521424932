import React from 'react';
import { BeneficiaryBankAccountType } from '../../../../constants/EntityTypeNames';
import { getStyle } from '../../../../styles/styles';
import BankAccountsScreen from '../updateCustomer/BankAccountsScreen';
var style = getStyle();

export default class BeneficiaryBankAccount extends React.Component {
    
    updatePage = () => {
        this.props.updatePage();
    };

    render() {
        var section = (
            <BankAccountsScreen data={this.props.data} isBeneficiary={this.props.isBeneficiary} customerID={this.props.customerID} subEntityType={BeneficiaryBankAccountType} updatePage={this.updatePage} updateBankAccount={this.props.updateBankAccount} createBankAccount={this.props.createBankAccount} />
        )
        if (this.props.data === undefined) {
            section = (
                <div>
                    <h5 style={style.alignCenter}>Must create beneficiary first.</h5>
                </div>
            )
        }
        return (
            <div>
                {section}
            </div>
        );
    }
}