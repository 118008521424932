import React from 'react';

import DealForm from '../../components/deal/DealForm'
import { getStyle } from '../../styles/styles';
import { searchDeals } from '../../networking/NetworkingDeals';
import NewDynamicTable from '../shared/NewDynamicTable';
import { formatToCurrency ,formatToCurrencyWithScale} from '../../helpers/FormatHelper';
import { getScreen } from './../shared/ScreenHelper';
import { ActionName } from '../../constants/ActionNames';
import AlertBox from '../shared/AlertBox';
import { getDefaultPagingNumberOfItems } from '../../helpers/PackageJsonHelper'
import DealVersionsScreen from './DealVersionsScreen';

var style = getStyle();

export default class DealSearchScreen extends React.Component {

    constructor(props) {
        super(props);
        this.handleBackLink = this.handleBackLink.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
    }

    state = {
        addCustomer: false,
        alertMessage: '',
        alertTitle: '',
        clickedRow: false,
        customerID: 0,
        customers: [],
        dealHeaderId: 0,
        objDealNo: 0,
        checkDealVersions: false,
        isVisible: 'visible',
        newSearch: false,
        returningFromAnotherWindow: false,
        searchedCustomerName: '',
        showAlert: false,
        totalCriteria: {Page:1},
        totalPages: 0,
        currentPage:1,
        updateCustomer: false,
    }

    componentDidMount() {
        ////debugger
        if(this.props.listSearchDeal !== undefined)
        {
            this.setState({
                customers: this.props.listSearchDeal
            });
        }
    }
    clearGrid() {
        this.setState({ searchedCustomerName: '', customers: [], isVisible: 'hidden' }, () =>{
            if(this.props.onListSearchChange !== undefined)
            {
                this.props.onListSearchChange([]);
            }
        });
    }
    searchDealsGrid(obj) {
        //obj.Page = 1;
        searchDeals(obj).then(
            (json) => {
                //alert(JSON.stringify(json));
                if(Number(json.httpStatusCode === 200)){
                    var totalCount = json.totalCount;
                    //var pages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
                    let objCustomer = json.deals !== undefined ? json.deals : []
                    this.setState({
                        totalPages: totalCount, newSearch: true, customers: objCustomer, isVisible: 'visible',
                        totalCriteria: { ...this.state.totalCriteria, Page: obj.Page }
                    }, () => {
                        if (this.props.onListSearchChange !== undefined) {
                            this.props.onListSearchChange(this.state.customers);
                        }
                    });
                }
                else {
                    var alertMessage;
                    if(json.Message !== undefined){
                        alertMessage = json.Message;
                    }
                    else{
                        alertMessage = 'Search failed. Please try again or contact the administrator';
                    }
                    this.setState({alertMessage: alertMessage,
                        alertTitle: 'Error',
                        showAlert: true,
                    });
                }            
            }
        );
    }
    
    getAllDealsGrid = async () =>{
        var json = await searchDeals(this.state.totalCriteria);
        let deals = json.deals !== undefined ? json.deals : []
        return deals;
    }
    handleRowClick(obj) {
        console.group("Deal Search handleRowClick")
        console.log(obj);
        console.groupEnd();
        //  
        if(this.props.fromBackOffice)
        {
            if(obj.Status !== 'Accepted')
            {
                    this.setState({alertMessage: `This deal is in ${obj.Status} status and cannot be processed`,
                        alertTitle: 'Error',
                        showAlert: true,
                    });
                return;
            }
        }
        if (this.props.onDealClick !== undefined) {
            this.props.onDealClick(obj,true);
        }
        else {
            this.setState({
                clickedRow: true, dealHeaderId: obj.DealNo
            });
        }
    }

    handleBackLink() {
        this.setState({
            clickedRow: false,
            returningFromAnotherWindow: true
        });
    }

    changePage(value) {
        this.setState({ totalCriteria: { ...this.state.totalCriteria, Page: value } });
        //this.state.totalCriteria.Page = value;
        /* searchDeals(this.state.totalCriteria).then(
            (json) => {
                console.log(json);
                var totalCount = json.totalCount;
                //var pages = Math.ceil(totalCount / getDefaultPagingNumberOfItems());
                this.setState({ totalPages: totalCount, newSearch: true, customers: json.deals, isVisible: 'visible' }, () =>{
                    if(this.props.onListSearchChange !== undefined)
                    {
                        this.props.onListSearchChange(this.state.customers);
                    }
                });
            }
        ); */
    }

    handleSelectClick = (obj) =>
    {
        if(obj["DealNo"] !== undefined)
        {            
            this.setState({objDealNo: obj["DealNo"]});
        }
    }

    handleFromDealVersions() {
        this.setState({checkDealVersions: false, objDealNo: 0});
    }

    handleDealVersions(){
        this.setState({checkDealVersions: true})
    }
    
    okValidationAlert = () => {
        this.setState({showAlert: false});
    }

    render() {
        var screen = [];
        if (this.state.clickedRow) {
            screen.push(getScreen(ActionName.ShowDealInfo, this.state.dealHeaderId, this.handleBackLink, null, null, null, "Deal Search"));
        } else {
            if (this.state.checkDealVersions) {
                return <DealVersionsScreen dealID = {this.state.objDealNo} backFromDealVersions={this.handleFromDealVersions.bind(this)}/>
            }
            screen.push(
                <div>
                    <h3 className="uk-heading-divider" style={style.titleStyle}>Deal Search</h3>
                    <DealForm searchDealsGrid={this.searchDealsGrid.bind(this)} clearGrid={this.clearGrid.bind(this)} dealID = {this.state.objDealNo} checkDealVersions = {this.handleDealVersions.bind(this)} />
                    <hr/>
                    <div style={{ visibility: this.state.isVisible }}>
                        <NewDynamicTable 
                            formats={[{ header: 'BuyAmount', formatFunction: formatToCurrencyWithScale , formatCurrency: 'Buy' }, { header: 'SellAmount', formatFunction: formatToCurrencyWithScale, formatCurrency:'Sell' }]}
                            key="dealSearch-screen" data={this.state.customers}
                            radioButtonFunction={this.handleSelectClick} 
                            useRadioButton={true} 
                            enableClick={true} 
                            enableSortByHeader={true}
                            clickFunction={this.handleRowClick.bind(this)}
                            newSearch={true} 
                            useDeleteButton={false}
                            usePaginator={true}
                            changePage={this.changePage.bind(this)}
                            numberOfPages={this.state.totalPages}
                            pageNumber = {this.state.totalCriteria.Page}
                            numberPerPage = {getDefaultPagingNumberOfItems()}
                            useFilter={true} 
                            filterFunction={this.getAllDealsGrid}
                            useExportButton
                            tableName="deal_information"
                            />
                    </div>
                </div>
            );
        }
        
        return (
            <div>
                {screen}

                <AlertBox id="global-alert" 
                    open={this.state.showAlert} 
                    title={this.state.alertTitle} 
                    message={this.state.alertMessage}
                    type="Ok"
                    okClick={this.okValidationAlert}/>
            </div>
        );
    }
}