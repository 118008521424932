import React, { useEffect, useState } from 'react';

import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { DialogActions } from '@material-ui/core';

import { getDealHeaderInfo } from '../../../networking/NetworkingDeals';

import { formatToCurrencyWithScale, getScaleForCurrencyPair, roundAmountByCurrency } from '../../../helpers/FormatHelper';
import AlertBox from '../../shared/AlertBox';
import NumberInput from '../../shared/NumberInput';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const LinkToDeal = ({ open, onClose, onAcceptLinkDeal, buyCurrency, sellCurrency, dealHeaderIdToCover, sellAmount, buyAmount, coverDealId }) => {

    const [dealToSearchId, setDealToSearchId] = useState(0);
    const [searchDeal, setSearchDeal] = useState(false);
    const [selectedDealId, setSelectedDealId] = useState('');    
    const [selectedDealInfo, setSelectedDealInfo] = useState({});    
    const [enableAccept, setEnableAccept] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [showAlertOk, setShowAlertOk] = useState(false);
    const [alertTitleOk, setAlertTitleOk] = useState('');
    const [alertMessageOk, setAlertMessageOk] = useState('');

    useEffect(() => {        
        if (dealToSearchId !== undefined && dealToSearchId !== 0) {
            getDealHeaderInfo(dealToSearchId).then(response => {                
                if (Array.isArray(response.dealHeaderInfo)) {
                    let dealHeaderInfo = response.dealHeaderInfo[0] || {}                    
                    setSelectedDealInfo(dealHeaderInfo);
                    setSelectedDealId(dealToSearchId);                    
                }
                else {
                    setSelectedDealInfo({});
                    setSelectedDealId('');                    
                }
            })
        }
        else {
            setSelectedDealId('')
            setSelectedDealInfo({});
        }
    }, [searchDeal])

    useEffect(() => {        
        if (selectedDealId > 0 && open) {            
            validateSelectedDeal()
        }
    }, [selectedDealId, selectedDealInfo, buyCurrency, sellCurrency, open])

    const validateSelectedDeal = () => {

        if (selectedDealInfo === undefined) {
            return;
        }

        if (selectedDealInfo !== undefined && (selectedDealInfo.BuyCurrency !== buyCurrency || selectedDealInfo.SellCurrency !== sellCurrency)) {
            setEnableAccept(false)
            setShowAlertOk(true)
            setAlertMessageOk('The currency pair is invalid for a ' + buyCurrency + '/' + sellCurrency + ' swap')
            setAlertTitleOk('Error')
            return
        }

        let balance = Number(roundAmountByCurrency(selectedDealInfo.SellAmount - selectedDealInfo.ForwardSellTotalDisbursed, sellCurrency));
        let balance2 = Number(roundAmountByCurrency(selectedDealInfo.BuyAmount - selectedDealInfo.ForwardBuyTotalDisbursed, buyCurrency));

        console.log(sellAmount)
        console.log(buyAmount)
        console.log(balance)
        console.log(balance2)

        let noBalanceAvailable = false
        if (selectedDealInfo.UpdateBuyAmountField) {
            noBalanceAvailable = (sellAmount > balance)
        }
        else {
            noBalanceAvailable = (buyAmount > balance2)
        }

        if (selectedDealInfo.DealTypeID === 4 && noBalanceAvailable) {
            if (selectedDealInfo.UpdateBuyAmountField) {
                setAlertMessageOk('The Swap sell amount (' + sellAmount + ' ' + selectedDealInfo.SellCurrency +
                    ') exceeds the forward balance (' + balance + ' ' + selectedDealInfo.SellCurrency + ')')
            } else {
                setAlertMessageOk('The Swap buy amount (' + buyAmount + ' ' + selectedDealInfo.BuyCurrency +
                    ') exceeds the forward balance (' + balance2 + ' ' + selectedDealInfo.BuyCurrency + ')')
            }
            setEnableAccept(false)
            setShowAlertOk(true)
            setAlertTitleOk('Error')
            return;
        }

        setEnableAccept(true)
        setAlertMessageOk('')
    }

    const onDealToSearchIdUpdate = (event) => {
        var dealId = Number(event.target.value);        
        setDealToSearchId(dealId);
    }

    const onSearchDeal = () => {        
        setSearchDeal(!searchDeal)
    }

    const handleAcceptLinkDeal = () => {        
        onAcceptLinkDeal(selectedDealId)
        onClose()
    }


    const linkToCoverText = coverDealId > 0 ? 'Update Cover Swap' : 'Accept';
    const title = coverDealId > 0 ? 'Link to Cover Swap (covered by ' + (coverDealId) + ')' : 'Link to Deal';

    return (
        <>
            <Dialog open={open} onClose={onClose} maxWidth='xs'>
                <DialogTitle id="linktodeal-dialog-title" onClose={onClose}>{title}</DialogTitle>
                <DialogContent dividers style={{ paddingLeft: 15, paddingRight: 15, paddingTop: 0, paddingBottom: 5 }}>
                    <div uk-form-horizontal uk-grid='true' style={{ 'marginBottom': '5px', 'minWidth': '420px' }}>
                        <div className="uk-width-1-2" style={{ paddingBottom: '5px', marginTop: '5px' }}>
                            <div className="uk-margin">
                                <label className="uk-form-label" htmlFor="form-dealToLink">Deal To Link</label>
                                <div className="uk-form-controls">
                                    <NumberInput className="uk-input" id="form-dealToLink" type="Integer" value={dealToSearchId} onChange={onDealToSearchIdUpdate} />
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-1-2">
                            <div className="uk-margin">
                                <label className="uk-form-label" htmlFor="form-dealToLink">&nbsp;</label>
                                <div className="uk-form-controls">
                                    <button className="uk-button uk-button-green" onClick={onSearchDeal}>
                                        Search Deal
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>                    
                    <div hidden={selectedDealId === undefined || selectedDealId <= 0} display={selectedDealId !== undefined || selectedDealId > 0}>
                        <span style={{ 'display': 'inline-block', 'color': '#fff', 'background-color': '#f0506e', 'border-radius': '2px',  'width': '100%'}}>{alertMessageOk}</span>
                        <hr style={{ 'marginTop': '3px', marginBottom: '3px' }} />
                    </div>
                    <div className="uk-form-horizontal uk-grid" hidden={selectedDealId === undefined || selectedDealId <= 0}>                        
                        <div className="uk-width-1-2" >
                            <div className="uk-margin">
                                <label className="uk-form-label" htmlFor="form-deal-id">Deal ID</label>
                                <div>
                                    <input className="uk-input" id="form-deal-id" value={selectedDealInfo.DealHeaderID} type="text" disabled />
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-1-2" >
                            <div className="uk-margin">
                                <label className="uk-form-label" htmlFor="form-deal-status">Status</label>
                                <div className="">
                                    <input className="uk-input" id="form-deal-status" value={selectedDealInfo.Status} type="text" disabled />
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-1-2" >
                            <div className="uk-margin">
                                <label className="uk-form-label" htmlFor="form-deal-customer">Customer Name</label>
                                <div className="">
                                    <input className="uk-input" id="form-deal-customer" value={selectedDealInfo.Name} type="text" disabled />
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-1-2" >
                            <div className="uk-margin">
                                <label className="uk-form-label" htmlFor="form-deal-type">Deal Type</label>
                                <div className="">
                                    <input className="uk-input" id="form-deal-type" value={selectedDealInfo.DealTypeID === 4 ? (selectedDealInfo.dealTypeName + ' (' + selectedDealInfo.ForwardType + ')') : selectedDealInfo.dealTypeName}
                                        type="text" disabled />
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-1-2" >
                            <div className="uk-margin">
                                <label className="uk-form-label" htmlFor="form-deal-issue-date">Issue Date</label>
                                <div className="">
                                    <input className="uk-input" id="form-deal-issue-date" value={selectedDealInfo.IssueDate} type="text" disabled />
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-1-2">
                        </div>
                        <div className="uk-width-1-2" >
                            <div className="uk-margin">
                                <label className="uk-form-label" htmlFor="form-deal-value-date">Value Date</label>
                                <div className="">
                                    <input className="uk-input" id="form-deal-value-date" value={selectedDealInfo.ValueDate} type="text" disabled />
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-1-2" >
                            <div className="uk-margin">
                                <label className="uk-form-label" htmlFor="form-deal-maturity-date">Maturity Date</label>
                                <div className="">
                                    <input className="uk-input" id="form-deal-maturity-date" value={selectedDealInfo.MaturityDate} type="text" disabled />
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-1-2" >
                            <div className="uk-margin">
                                <label className="uk-form-label" htmlFor="form-deal-customer-rate">Customer Rate</label>
                                <div className="">                                    
                                    <NumberInput className="uk-input" id="form-deal-customer-rate" type="Rate" scale={getScaleForCurrencyPair(selectedDealInfo.BuyCurrency, selectedDealInfo.SellCurrency)} value={selectedDealInfo.CustomerRate} disabled={true}/>
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-1-2" >
                            <div className="uk-margin">
                                <label className="uk-form-label" htmlFor="form-deal-market-rate">Market Rate</label>
                                <div className="">                                    
                                    <NumberInput className="uk-input" id="form-deal-market-rate" type="Rate" scale={getScaleForCurrencyPair(selectedDealInfo.BuyCurrency, selectedDealInfo.SellCurrency)} value={selectedDealInfo.MarketRate} disabled={true}/>
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-1-2" >
                            <div className="uk-margin">
                                <label className="uk-form-label" htmlFor="form-deal-buy-amount">Buy Amount</label>
                                <div className="">
                                    <input className="uk-input" id="form-deal-buy-amount" value={formatToCurrencyWithScale(selectedDealInfo.BuyAmount, selectedDealInfo.BCur) + ' ' + selectedDealInfo.BCur} type="text" disabled />
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-1-2" >
                            <div className="uk-margin">
                                <label className="uk-form-label" htmlFor="form-deal-sell-amount">Sell Amount</label>
                                <div className="">
                                    <input className="uk-input" id="form-deal-sell-amount" value={formatToCurrencyWithScale(selectedDealInfo.SellAmount, selectedDealInfo.SCur) + ' ' + selectedDealInfo.SCur} type="text" disabled />
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-1-2" hidden={selectedDealInfo === undefined || selectedDealInfo.DealTypeID != 4}>
                            <div className="uk-margin">
                                <label className="uk-form-label" htmlFor="form-deal-fbuy-sellbalance">Buy Balance</label>
                                <div className="">
                                    <input className="uk-input" id="form-deal-fbuy-sellbalance"
                                        value={formatToCurrencyWithScale(selectedDealInfo.BuyAmount - selectedDealInfo.ForwardBuyTotalDisbursed, selectedDealInfo.BCur) + ' ' + selectedDealInfo.BCur}
                                        type="text" disabled />
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-1-2" hidden={selectedDealInfo === undefined || selectedDealInfo.DealTypeID != 4}>
                            <div className="uk-margin">
                                <label className="uk-form-label" htmlFor="form-deal-fsell-sellbalance">Sell Balance</label>
                                <div className="">
                                    <input className="uk-input" id="form-deal-fsell-sellbalance"
                                        value={formatToCurrencyWithScale(selectedDealInfo.SellAmount - selectedDealInfo.ForwardSellTotalDisbursed, selectedDealInfo.SCur) + ' ' + selectedDealInfo.SCur}
                                        type="text" disabled />
                                </div>
                            </div>
                        </div>                        
                    </div>
                </DialogContent>
                <DialogActions style={{ padding: '4px'}}>
                    <button className="uk-button uk-button-green" onClick={handleAcceptLinkDeal} disabled={!enableAccept} hidden={!enableAccept}>{linkToCoverText}</button>
                    <button className="uk-button uk-button-green" onClick={onClose}>
                        Close
                    </button>
                </DialogActions>
            </Dialog>
            <AlertBox
                id="linktodeal-confirmation-dialog-alert"
                key="linktodeal-confirmation-dialog-alert"
                open={showAlert}
                title={alertTitle}
                message={alertMessage}
                type="Yes/No"
                yesClick={() => { setShowAlert(false); /*onClickToCoverUpdate();*/ }}
                noClick={() => setShowAlert(false)}
            />
            <AlertBox id="linktodeal-ok-alert"
                open={showAlertOk}
                title={alertTitleOk}
                message={alertMessageOk}
                type="Ok"
                okClick={() => setShowAlertOk(false)} />
        </>
    );
}

export default LinkToDeal

