import { genericDeleteWithParameters, genericGetWithParameters, genericGetWithParametersNoEncrypted } from './Networking';

const apiURL = window.REACT_APP_API_URL;
const apiKey = window.REACT_APP_API_KEY;

export async function getAllRoles(applicationName,page,items){
    try {
        let params = {
            'ApplicationName': applicationName,
            'Page': page,
            'Items': items,
        }

        let response = await genericGetWithParameters('Role/GetRoles', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getUsersInRole(applicationName, roleName){
    try {
        let sessionID = await localStorage.getItem('SessionID');
        let params = {
            'ApplicationName': applicationName,
            'RolName': roleName
        }

        let query = Object.keys(params)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
            .join('&');
        
        let response = await fetch(apiURL + 'User/GetUsersInRol?' + query, {
            method: 'GET',
            headers: {
                SessionID: sessionID,
                'APIKey': apiKey,
            },
        });
        let responseJWT = await response.json();
        console.log(responseJWT);
        return responseJWT;
    } catch (error) {
        console.error(error);
    }
}

export async function addRole(applicationName,role,availableForOnlineUsers) {
    try {
        let sessionID = await localStorage.getItem('SessionID');
        let roleRequest = {
            ApplicationName: applicationName,
            role: role,
            availableForOnlineUsers: availableForOnlineUsers
        }
        let response = await fetch(apiURL + 'Role/AddRoles',{
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'APIKey': apiKey,                
                SessionID: sessionID
            },
            body: JSON.stringify(roleRequest),
        });
        if(response.status === 200){
            let responseJSON = await response.json();
            console.log(responseJSON);
            responseJSON.status = 200;
            return responseJSON;
        }else{
            return response;
        }
    } catch (error) {
        console.error(error);
    }
}

export async function editRole(applicationName, role, availableForOnlineUsers) {
    try {
        let sessionID = await localStorage.getItem('SessionID');
        let roleRequest = {
            ApplicationName: applicationName,
            role: role,
            availableForOnlineUsers: availableForOnlineUsers
        }
        let response = await fetch(apiURL + 'Role/UpdateRol', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'APIKey': apiKey,
                SessionID: sessionID
            },
            body: JSON.stringify(roleRequest),
        });
        if (response.status === 200) {
            let responseJSON = await response.json();
            console.log(responseJSON);
            responseJSON.status = 200;
            return responseJSON;
        } else {
            return response;
        }
    } catch (error) {
        console.error(error);
    }
}

export async function addUserToRole(addUserToRoleRequest) {
    try {
        console.log(addUserToRoleRequest);
        let sessionID = await localStorage.getItem('SessionID');
        /*let roleRequest = {
            ApplicationName: applicationName,
            role: role,
            availableForOnlineUsers: availableForOnlineUsers
        }*/
        let response = await fetch(apiURL + 'Role/AddUserToRol',{
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'APIKey': apiKey,                
                SessionID: sessionID
            },
            body: JSON.stringify(addUserToRoleRequest),
        });
        let responseJson = await response.json();
        console.log(responseJson);
        return responseJson;
    } catch (error) {
        console.error(error);
    }
}

export async function UnregisterUserInRole(unregisterUserInRoleRequest) {
    try {
        let sessionID = await localStorage.getItem('SessionID');
        /*let roleRequest = {
            ApplicationName: applicationName,
            role: role,
            availableForOnlineUsers: availableForOnlineUsers
        }*/
        let response = await fetch(apiURL + 'Role/AddUserToRol',{
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'APIKey': apiKey,                
                SessionID: sessionID
            },
            body: JSON.stringify(unregisterUserInRoleRequest),
        });
        let responseJson = await response.json();
        console.log(responseJson);
        return responseJson;
    } catch (error) {
        console.error(error);
    }
}

//Role/DeleteRole
export async function deleteRole(appName,roleName) {
    try{
        let params = {
            'ApplicationName':appName,
            'roleName': roleName
        };
        let response = await genericDeleteWithParameters('Role/DeleteRole',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getRolesByUsername(appName, username) {
    try {
        let sessionID = await localStorage.getItem('SessionID');
        console.log(sessionID);
        let params = {
            'ApplicationName': appName,
            'UserName': username
        };

        let query = Object.keys(params)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
            .join('&');
        
        let response = await fetch(apiURL + 'Role/GetRolesByUserName?' + query, {
            method: 'GET',
            headers: {
                SessionID: sessionID,
                'APIKey': apiKey,
            },
        });
        let responseJWT = await response.json();
        return responseJWT;
    } catch (error) {
        console.error(error);
    }
}

export async function getRolesByCustomerAndContact(customerId, contactId){
    let params = {
        "applicationName": 'ForeignExchange',
        "customerId": customerId,
        "contactId": contactId
    }
        
    try{
        let response = await genericGetWithParametersNoEncrypted('Role/GetRolesByCustomerAndContact', params)
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const checkUserIsInRole = async (userName, roleName) => {
    const params = {
        userName: userName,
        roleName: roleName
    };
    try {
        const response = await genericGetWithParameters('Role/CheckUserIsInRole', params);
        return response;
    } catch (error) {
        console.error(error);
    }
};