export const BoardName = {
    CustomerGenericBoard :"CustomerGenericBoard",
    DealsGenericBoard : "DealsGenericBoard",
    BeneficiaryGenericBoard : "BeneficiaryGenericBoard",
    PaymentBoard : "PaymentBoard",
    DepositBoard : "DepositBoard",
    WorkAssignmentBoard : "WorkAssignmentBoard",
    MakerBoard : "MakerBoard",
    CountryComplianceBoard : "CountryComplianceBoard",
    CheckerBoard : "CheckerBoard",
    ApprovalBoard : "ApprovalBoard",
    HeldFundsBoard : "HeldFundsBoard",
    SwiftPaymentsBoard : "SwiftPaymentsBoard",
    ComplaintBoard: "ComplaintBoard"
}
