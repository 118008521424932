import { PlusOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Form, Input, InputNumber, Popconfirm, Space, Table, Typography } from 'antd';
import React, { useEffect, useState, useRef } from 'react';
import { getRiskFactors, updateRiskFactor } from '../../../networking/NetworkingRiskFactors';
import AlertBox from '../../shared/AlertBox';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

const RiskFactorsMaintenanceScreen = () => {
    const [form] = Form.useForm();
    const [allRiskFactors, setAllRiskFactors] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [showAlert, setShowAlert] = useState(false);

    const [updating, setUpdating] = useState(false);
    const [editingKey, setEditingKey] = useState('');
    const [currentEditingID, setCurrentEditingID] = useState(0);
    // const [currentUpdateToken, setCurrentUpdateToken] = useState('');

    //Search
    const [filteringSomething, setFilteringSomething] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [searchArray, setSearchArray] = useState({});
    const searchInput = useRef();

    const isEditing = (record) => record.ID === editingKey;

    useEffect(() => {
        const loadRiskFactors = async () => {
            const json = await getRiskFactors(0,0);
            if (json != null && json.riskfactors != null) {
                const _riskFactors = json.riskfactors.map(rf => ({ ...rf, key: `RiskFactor-${rf.ID}`}));
                setAllRiskFactors(_riskFactors);
            }
        }
        loadRiskFactors();
    }, [updating]);

    const save = async (record) => {
        try {
            const row = await form.validateFields();
            console.log('Model');
            console.log(row);
            const model = {
                riskFactorID: currentEditingID,
                riskImportance: row.RiskImportance
            }
            const json = await updateRiskFactor(model);
            if (json != null) {
                if (json.httpStatusCode === 200) {
                    // setCurrentUpdateToken('');
                    setShowAlert(true);
                    setAlertTitle('Success');
                    setAlertMessage('Risk Factor successfully saved.');
                    setEditingKey(0);
                    setCurrentEditingID(0);
                    setUpdating(!updating);
                    form.resetFields();
                } else {
                    setShowAlert(true);
                    setAlertTitle('Error');
                    setAlertMessage(json.Message);
                }
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const edit = (record) => {
        if (allRiskFactors[0].ID === 0) {
            let auxRiskFactors = [...allRiskFactors];
            auxRiskFactors.shift();
            setAllRiskFactors(auxRiskFactors);
        }

        form.setFieldsValue({
            UpdateToken: '',
            ...record
        });
        setEditingKey(record.ID);
        setCurrentEditingID(record.ID);
        // setCurrentUpdateToken(record.UpdateToken);
    };

    const cancel = (page) => {
        setEditingKey('');
        if (typeof page === 'number') {
            setCurrentPage(page);
        }

        if (allRiskFactors[0].ID === 0) {
            let auxRiskFactors = [...allRiskFactors];
            auxRiskFactors.shift();
            setAllRiskFactors(auxRiskFactors);
        }
        form.resetFields();
        setCurrentEditingID(0);
        // setCurrentUpdateToken('');
    };

    const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
        let inputNode = <Input disabled={editingKey !== 0} style={{ textAlign: 'center' }} />;
        if (inputType === 'number') {
            inputNode = (
                <InputNumber min={0} max={100} style={{ width: '100%', textAlignLast: 'center' }} />
            );
        }
        let styleEdit = { margin: 0 };
        if ((record !== undefined && record.isNew !== undefined && record.isNew === true) || editing) {
            styleEdit = {
                paddingBottom: 10,
                paddingTop: 10,
                margin: 0
            };
        }

        const getCustomRules = (columnDataIndex) => {
            switch (columnDataIndex) {
                case 'RiskImportance': {
                    return [
                        {
                            min: 0,
                            max: 100,
                            type: 'number',
                            required: true,
                            message: 'Risk Importance must be between 0 and 100!'
                        }
                    ];
                }
                default:
                    return [
                        {
                            required: true,
                            message: `Please Input a ${title}!`
                        }
                    ];
            }
        };
        const rules = getCustomRules(dataIndex);

        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item name={dataIndex} style={styleEdit} rules={rules}>
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
        setFilteringSomething(true);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
        setFilteringSomething(false);
    };

    const settingSearchStateValues = (value, col) => {
        setSearchText(value);
        setSearchedColumn(col);
        setSearchArray(...(searchArray[col] = value));
        setFilteringSomething(true);
    };
    //


    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        searchInput.current = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : []);
                    }}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        width: 188,
                        marginBottom: 8,
                        display: 'block'
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => {
                            handleReset(clearFilters);
                        }}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            settingSearchStateValues(selectedKeys[0], dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => (record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : ''),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current.select(), 100);
            }
        },
        render: (text) => (
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (text)
        )
    });

    const columns = [
        {
            title: 'Risk Factor',
            dataIndex: 'Risk Factor',
            width: '10%',
            editable: true,
            align: 'center',
            sorter: (a, b) => {
                return a['Risk Factor'].localeCompare(b['Risk Factor']);
            },
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('Risk Factor')
        },
        {
            title: 'Risk Importance',
            dataIndex: 'RiskImportance',
            width: '10%',
            editable: true,
            align: 'center',
            sorter: (a, b) => {
                return a.RiskImportance - b.RiskImportance;
            },
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('RiskImportance')
        },
        {
            title: 'Actions',
            dataIndex: 'Actions',
            align: 'center',
            width: '15%',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <a onClick={() => save(record)} style={{ marginRight: 8 }}>
                            Save
                        </a>
                        <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                            <a>Cancel</a>
                        </Popconfirm>
                    </span>
                ) : (
                    <div>
                        <Space size="middle">
                            <Typography.Link disabled={editingKey !== '' || allRiskFactors[0].ID === 0} onClick={() => edit(record)}>
                                Edit
                            </Typography.Link>
                        </Space>
                    </div>
                );
            }
        }
    ].map((col) => {
        if (!col.editable) {
            return col;
        }
        let type = 'text';
        if (col.dataIndex === 'RiskImportance') {
            type = 'number';
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: type,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record)
            })
        };
    });

    const closeAlert = () => {
        setShowAlert(false);
    };

    function itemRender(current, type, originalElement) {
        if (type === 'prev') {
            return <a>Previous</a>;
        }
        if (type === 'next') {
            return <a>Next</a>;
        }
        return originalElement;
    }

    const addNewRiskFactor = () => {
        let actual = [...allRiskFactors];
        if (actual[0] != null && actual[0].ID === 0) {
            return;
        } else {
            actual.unshift({
                'key': 'RiskFactor-new',
                'ID': 0,
                'Risk Factor': '',
                'RiskImportance': 0,
                UpdateToken: '',
                isNew: true
            });
            setAllRiskFactors(actual);
            setCurrentPage(1);
            form.setFieldsValue({
                'ID': 0,
                'Risk Factor': '',
                'RiskImportance': 0,
                ...actual
            });
            // setCurrentUpdateToken('');
            setEditingKey(0);
            setCurrentEditingID(0);
        }
    };

    return (
        <div>
            <div className="breadcrumb-div">
                <Breadcrumb separator=">">
                    <Breadcrumb.Item className="breadcrumb-item-bold">Risk Factor Maintenance</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <Form form={form}>
                <Table dataSource={allRiskFactors} columns={columns} size="small" components={{ body: { cell: EditableCell } }}
                    pagination={{
                        onChange: cancel,
                        showQuickJumper: true,
                        itemRender: itemRender,
                        current: currentPage,
                        defaultCurrent: 1,
                        size: 'default'
                    }}
                    footer={() => (
                        <Button
                            type="dashed"
                            onClick={addNewRiskFactor}
                            block
                            icon={<PlusOutlined />}
                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            disabled={editingKey !== '' || (allRiskFactors[0] !== undefined && allRiskFactors[0].ID === 0)}
                        >
                            Add a Risk Factor
                        </Button>
                    )} />
            </Form>
            <AlertBox open={showAlert} onClose={closeAlert} title={alertTitle} message={alertMessage} type="Ok" okClick={closeAlert} />
        </div>
    );
}

export default RiskFactorsMaintenanceScreen;