import React from 'react';
import NumberInput from '../../shared/NumberInput';
import AlertBox from '../../shared/AlertBox';

import { getSupportingRates } from '../../../networking/NetworkingRates';

import { formatDate, roundAmountByCurrencyPair } from '../../../helpers/FormatHelper';

import { GlobalSystemParametersNames } from '../../../constants/GlobalSystemParametersNames';
import { Divider } from '@material-ui/core';
import { getCurrencyPairLabel } from '../../../helpers/AmountsHelper';

export default class WizardSupportingRates extends React.Component {
    state = {
        baseCurrency: '',
        ccy1: '',
        ccy2: '',
        enableControlUpdates: true,
        fwdPoints1: 0,
        fwdPoints2:0,
        marketRate1: 0,
        marketRate2: 0,
        originalSpotRate1: 0,
        originalSpotRate2: 0,
        ratePrecision1: 8,
        ratePrecision2: 8,
        rateTerms1: '',
        rateTerms2: '',
        dateText: '',
        disableControls: true,
        forwardPointsScale: 8,
        farLegMarketRate1: 1,
        farLegMarketRate2: 1,
        farLegFwdPoints1: 0,
        farLegFwdPoints2: 0,
        farFwdLegMarketRate1: 1,
        farFwdLegMarketRate2: 1,
        //valid rates limits
        originalMarketRate1: 0,
        originalMarketRate2: 0,
        originalSpotRate1FromAPI: 0,
        originalSpotRate2FromAPI: 0,
        oldMarketRate1: 0,
        oldMarketRate2: 0,
        oldSpotRate1FromAPI: 0,
        oldSpotRate2FromAPI: 0,
        showAlert: false,
        alertTitle: '',
        alertMessage:'',
        currencyPairs: [],
        showForwardColumns: false
    }
    
    componentDidMount(){
        let forwardPointsScale = localStorage.getItem(GlobalSystemParametersNames.ForwardPointsScale)

        this.setState({
            baseCurrency: localStorage.getItem('BaseCurrency'),
            enableControlUpdates: false, 
            forwardPointsScale: forwardPointsScale
        });
					
        //Get supporting rates by rate quote ID
        var maturityDate = null;
        if (this.props.maturityDate != null && this.props.maturityDate !== '') {
            maturityDate = formatDate(this.props.maturityDate);
        }
        var disableControls = false;
        if(this.props.disableControls != undefined && this.props.disableControls != null){
            disableControls = this.props.disableControls;
        }

        let dateText = this.getDateText();
        let currencyPairs = JSON.parse(localStorage.getItem('CurrencyPairs'));
        //alert(maturityDate);
        getSupportingRates(this.props.rateQuoteID, this.props.dealType, this.props.sellCurrency, this.props.buyCurrency, formatDate(this.props.valueDate), maturityDate).then(
            (response) => {
                this.setState({
                    ccy1: response.CCY1,
                    ccy2: response.CCY2,
                    originalSpotRate1: response.OriginalSpotRate1,
                    originalSpotRate2: response.OriginalSpotRate2,
                    marketRate1: response.MarketRate1,
                    marketRate2: response.MarketRate2,
                    fwdPoints1: response.FwdPoints1,
                    fwdPoints2: response.FwdPoints2,
                    ratePrecision1: response.RatePrecision1,
                    ratePrecision2: response.RatePrecision2,
                    rateTerms1: response.RateTerms1,
                    rateTerms2: response.RateTerms2,
                    dateText: dateText,
                    disableControls: disableControls,
                    originalMarketRate1: response.MarketRate1,
                    originalMarketRate2: response.MarketRate2,
                    originalSpotRate1FromAPI: response.OriginalSpotRate1,
                    originalSpotRate2FromAPI: response.OriginalSpotRate2,
                    currencyPairs: currencyPairs,
                    farLegMarketRate1: response.FarLegMarketRate1,
                    farLegMarketRate2: response.FarLegMarketRate2,                    
                    farLegFwdPoints1: response.FarLegFwdPoints1,
                    farLegFwdPoints2: response.FarLegFwdPoints2,
                    farFwdLegMarketRate1: response.FarFwdLegMarketRate1,
                    farFwdLegMarketRate2: response.FarFwdLegMarketRate2,
                },
                    () => this.setState({ enableControlUpdates: true }));
            }
        );
    }

    getDateText() {
        let dateText = formatDate(this.props.valueDate);
        if (this.props.maturityDate != null && this.props.maturityDate !== '') {
            dateText = formatDate(this.props.maturityDate);
        }
        return dateText;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.valueDate != this.props.valueDate || prevProps.maturityDate != this.props.maturityDate) {
            let dateText = this.getDateText();
            this.setState({ dateText: dateText });
        }
        if(prevProps.disableControls != this.props.disableControls){
            if(this.props.disableControls){
                var maturityDate = null;
                if (this.props.maturityDate != null && this.props.maturityDate !== '') {
                    maturityDate = formatDate(this.props.maturityDate);
                }
                getSupportingRates(this.props.rateQuoteID, this.props.dealType, this.props.sellCurrency, this.props.buyCurrency, formatDate(this.props.valueDate), maturityDate).then(
                    (response) => {
                        this.setState({
                            ccy1: response.CCY1,
                            ccy2: response.CCY2,
                            originalSpotRate1: response.OriginalSpotRate1,
                            originalSpotRate2: response.OriginalSpotRate2,
                            marketRate1: response.MarketRate1,
                            marketRate2: response.MarketRate2,
                            fwdPoints1: response.FwdPoints1,
                            fwdPoints2: response.FwdPoints2,
                            ratePrecision1: response.RatePrecision1,
                            ratePrecision2: response.RatePrecision2,
                            rateTerms1: response.RateTerms1,
                            rateTerms2: response.RateTerms2,
                            originalMarketRate1: response.MarketRate1,
                            originalMarketRate2: response.MarketRate2,
                            originalSpotRate1FromAPI: response.OriginalSpotRate1,
                            originalSpotRate2FromAPI: response.OriginalSpotRate2,
                            farLegMarketRate1: response.FarLegMarketRate1,
                            farLegMarketRate2: response.FarLegMarketRate2,
                            farLegFwdPoints1: response.FarLegFwdPoints1,
                            farLegFwdPoints2: response.FarLegFwdPoints2,
                            farFwdLegMarketRate1: response.FarFwdLegMarketRate1,
                            farFwdLegMarketRate2: response.FarFwdLegMarketRate2,
                        });
                    }
                );
            }
            this.setState({ disableControls: this.props.disableControls });
        }
    }

    getRateTerm = (currencyCode, outCurrencyCode) => {
        let pair = this.state.currencyPairs.find(c => c.CurrencyCode === currencyCode && c.OutCurrencyCode === outCurrencyCode);
        return pair !== undefined ? pair.RateTerms : '';
    }

    getNewSupportingRateGenerically = (mainPairRate, topPairRate, buttonPairRate,
        mainPairRateTerm, topPairRateTerm, buttonPairRateTerm, calculateTopRate) => {
        
        let newRate = 1;
        if (calculateTopRate) {
            if (mainPairRateTerm === 'D' && topPairRateTerm === 'D' && buttonPairRateTerm === 'D') {
                //1 MainPair/BottomPair                
                newRate = mainPairRate / buttonPairRate;
            }
            if (mainPairRateTerm === 'D' && topPairRateTerm === 'D' && buttonPairRateTerm === 'I') {
                //2 BottomPair*MainPair
                newRate = buttonPairRate * mainPairRate;
            }

            if (mainPairRateTerm === 'D' && topPairRateTerm === 'I' && buttonPairRateTerm === 'D') {
                //3 BottomPair*MainPair
                newRate = buttonPairRate * mainPairRate;
            }

            if (mainPairRateTerm === 'D' && topPairRateTerm === 'I' && buttonPairRateTerm === 'I') {
                //4 MainPair*BotttomPair
                newRate = mainPairRate * buttonPairRate;
            }
            //Main pair Indirect
            if (mainPairRateTerm === 'I' && topPairRateTerm === 'D' && buttonPairRateTerm === 'D') {
                //5 MainPair*BottomPair
                newRate = 1 / (mainPairRate * buttonPairRate);
            }
            if (mainPairRateTerm === 'I' && topPairRateTerm === 'D' && buttonPairRateTerm === 'I') {
                //6 BottomPair/MainPair
                newRate = buttonPairRate / mainPairRate;
            }
            if (mainPairRateTerm === 'I' && topPairRateTerm === 'I' && buttonPairRateTerm === 'D') {
                //7 MainPair*BotttomPair
                newRate = mainPairRate * buttonPairRate;
            }
            if (mainPairRateTerm === 'I' && topPairRateTerm === 'I' && buttonPairRateTerm === 'I') {
                //8 MainPair/BottomPair
                newRate = mainPairRate / buttonPairRate;
            }
        }
        else {
            if (mainPairRateTerm === 'D' && topPairRateTerm === 'D' && buttonPairRateTerm === 'D') {
                //1 MainPair/TopPair
                newRate = mainPairRate / topPairRate;
            }
            if (mainPairRateTerm === 'D' && topPairRateTerm === 'D' && buttonPairRateTerm === 'I') {
                //2 TopPair/MainPair
                newRate = topPairRate / mainPairRate;
            }
            if (mainPairRateTerm === 'D' && topPairRateTerm === 'I' && buttonPairRateTerm === 'D') {
                //3 TopPair/MainPair                
                newRate = topPairRate / mainPairRate;
            }
            if (mainPairRateTerm === 'D' && topPairRateTerm === 'I' && buttonPairRateTerm === 'I') {
                //4 MainPair*TopPair
                newRate = mainPairRate * topPairRate;
            }

            //Main pair Indirect
            if (mainPairRateTerm === 'I' && topPairRateTerm === 'D' && buttonPairRateTerm === 'D') {
                //5 MainPair*TopPair
                newRate = 1 / mainPairRate * topPairRate;
            }
            if (mainPairRateTerm === 'I' && topPairRateTerm === 'D' && buttonPairRateTerm === 'I') {
                //6 MainPair*TopPair                
                newRate = mainPairRate * topPairRate;
            }
            if (mainPairRateTerm === 'I' && topPairRateTerm === 'I' && buttonPairRateTerm === 'D') {
                //7 MainPair/TopPair
                newRate = mainPairRate / topPairRate;
            }
            if (mainPairRateTerm === 'I' && topPairRateTerm === 'I' && buttonPairRateTerm === 'I') {
                //8 MainPair/TopPair
                newRate = mainPairRate / topPairRate;
            }
        }
        return newRate;
    }

    handleFwdPoints1Update = (event) => {
        
        if (this.state.enableControlUpdates) {
            let fwdPoints1 = Number(event.target.value);
            let originalSpotRate1 = this.state.originalSpotRate1;
            let originalSpotRate2 = this.state.originalSpotRate2;
            let marketRate1 = (originalSpotRate1 + (fwdPoints1 / 10000)).toFixed(this.state.ratePrecision1);

            if (this.props.dealType != 6) {
                //Recalculate other rates
                let oldMarketRate1 = this.state.marketRate1;
                var marketRate2 = this.state.marketRate2;
                let referencialMarketRate = oldMarketRate1 * marketRate2;
                marketRate2 = referencialMarketRate / marketRate1;
                let fwdPoints2 = ((marketRate2 - originalSpotRate2) * 10000).toFixed(localStorage.getItem(GlobalSystemParametersNames.ForwardPointsScale));

                this.setState({
                    enableControlUpdates: false,
                    fwdPoints1: fwdPoints1,
                    fwdPoints2: fwdPoints2,
                    marketRate1: marketRate1,
                    marketRate2: marketRate2,
                }, () => {
                    this.setState({ enableControlUpdates: true });
                });
            }
            else {
                //Recalculate other rates
                let farLegMarketRate = this.props.farLegMarketRate;
                let marketRate2 = farLegMarketRate / marketRate1
                let fwdPoints2 = ((marketRate2 - originalSpotRate2) * 10000).toFixed(this.state.ratePrecision2);

                this.setState({
                    enableControlUpdates: false,
                    fwdPoints1: fwdPoints1,
                    fwdPoints2: fwdPoints2,
                    marketRate1: marketRate1,
                    marketRate2: marketRate2,
                }, () => {
                    this.setState({ enableControlUpdates: true });
                });

            }
        }
    }

    handleFwdPoints2Update = (event) => {
        
        if(this.state.enableControlUpdates){
            let fwdPoints2 = Number(event.target.value).toFixed(localStorage.getItem(GlobalSystemParametersNames.ForwardPointsScale));
            let originalSpotRate1 = this.state.originalSpotRate1;
            let originalSpotRate2 = this.state.originalSpotRate2;
            let marketRate2 = (originalSpotRate2 + (fwdPoints2 / 10000)).toFixed(this.state.ratePrecision2);

            if(this.props.dealType != 6)
            {
            //Recalculate other rates
            let oldMarketRate2 = this.state.marketRate2;
            var marketRate1 = this.state.marketRate1;
            let referencialMarketRate = oldMarketRate2 * marketRate1; 
            marketRate1 = roundAmountByCurrencyPair(referencialMarketRate / marketRate2, this.props.buyCurrency, this.props.sellCurrency);
            let fwdPoints1 = ((marketRate1 - originalSpotRate1) * 10000).toFixed(localStorage.getItem(GlobalSystemParametersNames.ForwardPointsScale));
            this.setState({enableControlUpdates: false,
                fwdPoints1: fwdPoints1,
                fwdPoints2: fwdPoints2,
                marketRate1: marketRate1,
                marketRate2: marketRate2,
            }, () => {
                this.setState({enableControlUpdates: true});
            });
            }
            else
            {
                //Recalculate other rates
                let farLegMarketRate =  this.props.farLegMarketRate;
                let marketRate1 = roundAmountByCurrencyPair(farLegMarketRate/marketRate2, this.props.buyCurrency, this.props.sellCurrency);
                let fwdPoints1 = ((marketRate1 - originalSpotRate1) * 10000).toFixed(this.state.ratePrecision1);
                
                this.setState({enableControlUpdates: false,
                    fwdPoints1: fwdPoints1,
                    fwdPoints2: fwdPoints2,
                    marketRate1: marketRate1,
                    marketRate2: marketRate2,
                }, () => {
                    this.setState({enableControlUpdates: true});
                });
            }
        }
    }

    handleMarketRate1Update = (event) => {
        
        if(this.state.enableControlUpdates){
            let marketRate1 = Number(event.target.value)//roundAmountByCurrencyPair(Number(event.target.value), this.props.buyCurrency, this.props.sellCurrency);
           
            let oldMarketRate2 =  this.state.marketRate2;
            let originalSpotRate1 = this.state.originalSpotRate1;
            let originalSpotRate2 = this.state.originalSpotRate2;
            let fwdPoints1 = ((marketRate1 - originalSpotRate1) * 10000).toFixed(localStorage.getItem(GlobalSystemParametersNames.ForwardPointsScale));
            
            if(this.props.dealType != 6)
            {
            //Recalculate other rates
            let oldMarketRate1 = (this.props.dealType == 6) ? this.props.nearLegMarketRate:this.state.marketRate1;
            let marketRate2 =this.state.marketRate2
            let referencialMarketRate = oldMarketRate1 * marketRate2;
            marketRate2 = roundAmountByCurrencyPair(referencialMarketRate / marketRate1, this.props.buyCurrency, this.props.sellCurrency);                        
            let fwdPoints2 = ((marketRate2 - originalSpotRate2) * 10000).toFixed(localStorage.getItem(GlobalSystemParametersNames.ForwardPointsScale));

            this.setState({enableControlUpdates: false,
                fwdPoints1: fwdPoints1,
                fwdPoints2: fwdPoints2,
                marketRate1: marketRate1,
                marketRate2: marketRate2,
                oldMarketRate1: oldMarketRate1,
                oldMarketRate2: oldMarketRate2
            }, () => {
                this.setState({enableControlUpdates: true});
            });
            }else
            {
                let oldMarketRate1 = this.state.marketRate1;
                //Recalculate other rates
                let farLegMarketRate =  this.props.farLegMarketRate;
                let marketRate2 = roundAmountByCurrencyPair(farLegMarketRate/marketRate1, this.props.buyCurrency, this.props.sellCurrency);
                let fwdPoints2 = ((marketRate2 - originalSpotRate2) * 10000).toFixed(this.state.ratePrecision2);
                
                this.setState({enableControlUpdates: false,
                    fwdPoints1: fwdPoints1,
                    fwdPoints2: fwdPoints2,
                    marketRate1: marketRate1,
                    marketRate2: marketRate2,
                    oldMarketRate1: oldMarketRate1,
                    oldMarketRate2: oldMarketRate2
                }, () => {
                    this.setState({enableControlUpdates: true});
                });
            }
        }
    }

    handleMarketRate1Validate = (event) => {
        let marketRate1 = this.state.marketRate1;
        let oldMarketRate1 = this.state.oldMarketRate1;
        var validRate = this.validateRateLimits(this.state.originalMarketRate1, marketRate1, this.state.baseCurrency, this.state.ccy2);
        /* if(!validRate)
         {
             this.setState({marketRate1: oldMarketRate1});
         }*/
    }

    handleMarketRate2Update = (event) => {
        
        if(this.state.enableControlUpdates){
            let marketRate2 = Number(event.target.value);
            let originalSpotRate1 = this.state.originalSpotRate1;
            let originalSpotRate2 = this.state.originalSpotRate2;
            let fwdPoints2 = ((marketRate2 - originalSpotRate2) * 10000).toFixed(localStorage.getItem(GlobalSystemParametersNames.ForwardPointsScale));

            let oldMarketRate2 = this.state.marketRate2;
            let oldMarketRate1 = this.state.marketRate1;

            if(this.props.dealType != 6)
            {
            //Recalculate other rates
            
            var marketRate1 = this.state.marketRate1;
            let referencialMarketRate = oldMarketRate2 * marketRate1;
            marketRate1 = roundAmountByCurrencyPair(referencialMarketRate / marketRate2, this.props.buyCurrency, this.props.sellCurrency);
            let fwdPoints1 = ((marketRate1 - originalSpotRate1) * 10000).toFixed(localStorage.getItem(GlobalSystemParametersNames.ForwardPointsScale));

            this.setState({enableControlUpdates: false,
                fwdPoints1: fwdPoints1,
                fwdPoints2: fwdPoints2,
                marketRate1: marketRate1, 
                marketRate2: marketRate2,
                oldMarketRate1: oldMarketRate1,
                oldMarketRate2: oldMarketRate2
            }, () => {
                this.setState({enableControlUpdates: true});
            });
            }
            else
                {
                    //Recalculate other rates
                    let farLegMarketRate =  this.props.farLegMarketRate;
                    let marketRate1 = farLegMarketRate/marketRate2
                    let fwdPoints1 = ((marketRate1 - originalSpotRate1) * 10000).toFixed(this.state.ratePrecision1);
                    
                    this.setState({enableControlUpdates: false,
                        fwdPoints1: fwdPoints1,
                        fwdPoints2: fwdPoints2,
                        marketRate1: marketRate1,
                        marketRate2: marketRate2,
                        oldMarketRate1: oldMarketRate1,
                        oldMarketRate2: oldMarketRate2
                    }, () => {
                        this.setState({enableControlUpdates: true});
                    });
                }
        }
    }

    handleMarketRate2Validate = (event) => {
        let marketRate2 = this.state.marketRate2;
        let oldMarketRate2 = this.state.oldMarketRate2;

        var validRate = this.validateRateLimits(this.state.originalMarketRate2, marketRate2, this.state.ccy1, this.state.baseCurrency);     
    }

    handleOriginalSpotRate1Update = (event) => {

        if (this.state.enableControlUpdates && Number(event.target.value) > 0) {
            let originalSpotRate1 = Number(event.target.value);
            let fwdPoints1 = 0;
            let marketRate1 = (originalSpotRate1 + (fwdPoints1 / 10000)).toFixed(this.state.ratePrecision1);
            let oldOriginalSpotRate1 = this.state.originalSpotRate1;
            let oldOriginalSpotRate2 = this.state.originalSpotRate2;

            if (this.props.dealType != 6) {

                //Recalculate other rates
                let originalSpotRate2 = this.getNewSupportingRateGenerically(this.props.customerRate, originalSpotRate1, this.state.originalSpotRate2,                    
                    this.getRateTerm(this.props.sellCurrency, this.props.buyCurrency),                    
                    this.getRateTerm(this.state.baseCurrency, this.props.buyCurrency),                    
                    this.getRateTerm(this.props.sellCurrency, this.state.baseCurrency),
                    false)

                let marketRate2 = this.getNewSupportingRateGenerically(this.props.marketRate, marketRate1, this.state.marketRate2,                    
                    this.getRateTerm(this.props.sellCurrency, this.props.buyCurrency),                    
                    this.getRateTerm(this.state.baseCurrency, this.props.buyCurrency),                    
                    this.getRateTerm(this.props.sellCurrency, this.state.baseCurrency),
                    false)

                let fwdPoints2 = ((marketRate2 - originalSpotRate2) * 10000).toFixed(this.state.ratePrecision2);

                this.setState({
                    enableControlUpdates: false,
                    fwdPoints1: fwdPoints1,
                    fwdPoints2: fwdPoints2,
                    marketRate1: marketRate1,
                    marketRate2: marketRate2,
                    originalSpotRate1: originalSpotRate1,
                    originalSpotRate2: originalSpotRate2,
                    oldOriginalSpotRate1: oldOriginalSpotRate1,
                    oldOriginalSpotRate2: oldOriginalSpotRate2
                }, () => {
                    this.setState({ enableControlUpdates: true });
                });
            }
            else {
                let originalSpotRate2 = this.getNewSupportingRateGenerically(this.props.nearLegCustomerRate, originalSpotRate1, this.state.originalSpotRate2,
                    this.getRateTerm(this.props.buyCurrency, this.props.sellCurrency),
                    this.getRateTerm(this.props.buyCurrency, this.state.baseCurrency),
                    this.getRateTerm(this.state.baseCurrency, this.props.sellCurrency), false)

                let marketRate2 = this.getNewSupportingRateGenerically(this.props.nearLegMarketRate, marketRate1, this.state.marketRate2,
                    this.getRateTerm(this.props.buyCurrency, this.props.sellCurrency),
                    this.getRateTerm(this.props.buyCurrency, this.state.baseCurrency),
                    this.getRateTerm(this.state.baseCurrency, this.props.sellCurrency), false)

                let fwdPoints2 = ((marketRate2 - originalSpotRate2) * 10000).toFixed(this.state.ratePrecision2);

                this.setState({
                    enableControlUpdates: false,
                    fwdPoints2: fwdPoints2,
                    marketRate1: marketRate1,
                    marketRate2: marketRate2,
                    originalSpotRate1: originalSpotRate1,
                    originalSpotRate2: originalSpotRate2,
                    oldOriginalSpotRate1: oldOriginalSpotRate1,
                    oldOriginalSpotRate2: oldOriginalSpotRate2
                }, () => {
                    this.setState({ enableControlUpdates: true });
                });
            }
        }
    }

    handleOriginalSpotRate1Validate = (event) => {
        let originalSpotRate1 = this.state.originalSpotRate1;
        let oldOriginalSpotRate1 = this.state.oldOriginalSpotRate1;

        var validRate = this.validateRateLimits(this.state.originalSpotRate1FromAPI, originalSpotRate1, this.state.baseCurrency, this.state.ccy2);
        /* if(!validRate)
         {
             this.setState({originalSpotRate1: oldOriginalSpotRate1});
         }*/
    }

    handleOriginalSpotRate2Update = (event) => {
        
        if (this.state.enableControlUpdates && Number(event.target.value) > 0) {
            let originalSpotRate2 = Number(event.target.value);
            let fwdPoints2 = 0;
            let marketRate2 = (originalSpotRate2 + (fwdPoints2 / 10000)).toFixed(this.state.ratePrecision2);
            let oldOriginalSpotRate1 = this.state.originalSpotRate1;
            let oldOriginalSpotRate2 = this.state.originalSpotRate2;

            if (this.props.dealType != 6) {
                //Recalculate other rates
                let originalSpotRate1 = this.getNewSupportingRateGenerically(this.props.marketRate, this.state.originalSpotRate1, originalSpotRate2,                    
                    this.getRateTerm(this.props.sellCurrency, this.props.buyCurrency),                    
                    this.getRateTerm(this.state.baseCurrency, this.props.buyCurrency),                    
                    this.getRateTerm(this.props.sellCurrency, this.state.baseCurrency),
                    true);
                let marketRate1 = this.getNewSupportingRateGenerically(this.props.marketRate, this.state.marketRate1, marketRate2,                    
                    this.getRateTerm(this.props.sellCurrency, this.props.buyCurrency),                    
                    this.getRateTerm(this.state.baseCurrency, this.props.buyCurrency),                    
                    this.getRateTerm(this.props.sellCurrency, this.state.baseCurrency),
                    true);

                let fwdPoints1 = ((marketRate1 - originalSpotRate1) * 10000).toFixed(this.state.ratePrecision1);

                this.setState({
                    enableControlUpdates: false,
                    fwdPoints1: fwdPoints1,
                    fwdPoints2: fwdPoints2,
                    marketRate1: marketRate1,
                    marketRate2: marketRate2,
                    originalSpotRate1: originalSpotRate1,
                    originalSpotRate2: originalSpotRate2,
                    oldOriginalSpotRate1: oldOriginalSpotRate1,
                    oldOriginalSpotRate2: oldOriginalSpotRate2
                }, () => {
                    this.setState({ enableControlUpdates: true });
                });
            }
            else {
                //Recalculate other rates
                let originalSpotRate1 = this.getNewSupportingRateGenerically(this.props.nearLegMarketRate, this.state.originalSpotRate1, originalSpotRate2,
                    this.getRateTerm(this.props.buyCurrency, this.props.sellCurrency),
                    this.getRateTerm(this.props.buyCurrency, this.state.baseCurrency),
                    this.getRateTerm(this.state.baseCurrency, this.props.sellCurrency), true)

                let marketRate1 = this.getNewSupportingRateGenerically(this.props.nearLegMarketRate, this.state.marketRate1, marketRate2,
                    this.getRateTerm(this.props.buyCurrency, this.props.sellCurrency),
                    this.getRateTerm(this.props.buyCurrency, this.state.baseCurrency),
                    this.getRateTerm(this.state.baseCurrency, this.props.sellCurrency), true)

                let fwdPoints1 = ((marketRate1 - originalSpotRate1) * 10000).toFixed(this.state.ratePrecision2);

                this.setState({
                    enableControlUpdates: false,
                    fwdPoints1: fwdPoints1,
                    marketRate1: marketRate1,
                    marketRate2: marketRate2,
                    originalSpotRate1: originalSpotRate1,
                    originalSpotRate2: originalSpotRate2,
                    oldOriginalSpotRate1: oldOriginalSpotRate1,
                    oldOriginalSpotRate2: oldOriginalSpotRate2
                }, () => {
                    this.setState({ enableControlUpdates: true });
                });
            }
        }
    }

    handleOriginalSpotRate2Validate = (event) => {
        let originalSpotRate2 = this.state.originalSpotRate2;
        let oldOriginalSpotRate2 = this.state.oldOriginalSpotRate2;

        var validRate = this.validateRateLimits(this.state.originalSpotRate2FromAPI, originalSpotRate2, this.state.ccy1, this.state.baseCurrency);
        /* if(!validRate)
         {
             this.setState({originalSpotRate2: oldOriginalSpotRate2});
         }*/

    }

    handleOriginalFarLegMarketRate1Update = (event) => {
        if (this.state.enableControlUpdates && Number(event.target.value) > 0) {
            let newValue = Number(event.target.value);

            let farLegMarketRate2 = this.getNewSupportingRateGenerically(this.props.farLegCustomerRate, newValue, this.state.farLegMarketRate2,
                this.getRateTerm(this.props.buyCurrency, this.props.sellCurrency),
                this.getRateTerm(this.props.buyCurrency, this.state.baseCurrency),
                this.getRateTerm(this.state.baseCurrency, this.props.sellCurrency), false)

            let farFwdLegMarketRate2 = this.getNewSupportingRateGenerically(this.props.farLegMarketRate, newValue, this.state.farFwdLegMarketRate2,
                this.getRateTerm(this.props.buyCurrency, this.props.sellCurrency),
                this.getRateTerm(this.props.buyCurrency, this.state.baseCurrency),
                this.getRateTerm(this.state.baseCurrency, this.props.sellCurrency), false)

            let fwdPoints2 = ((farFwdLegMarketRate2 - farLegMarketRate2) * 10000).toFixed(this.state.ratePrecision2);

            this.setState({
                enableControlUpdates: false,
                farLegMarketRate1: newValue,
                farLegFwdPoints1: 0,
                farFwdLegMarketRate1: newValue,
                farLegMarketRate2: farLegMarketRate2,
                farFwdLegMarketRate2: farFwdLegMarketRate2,
                farLegFwdPoints2: fwdPoints2
            }, () => {
                this.setState({ enableControlUpdates: true });
                let tempSr = this.props.supportingRates                
                this.props.updateLocalSupportingRates({
                    ...tempSr, ...{
                        FarLegMarketRate1: newValue, FarLegFwdPoints1: 0, FarFwdLegMarketRate1: newValue,
                        FarLegMarketRate2: farLegMarketRate2, fwdPoints2: fwdPoints2, FarFwdLegMarketRate2: farFwdLegMarketRate2
                    }
                })
            });
        }
    }

    handleOriginalFarLegMarketRate2Update = (event) => {
        if (this.state.enableControlUpdates && Number(event.target.value) > 0) {
            let newValue = Number(event.target.value);

            let farLegMarketRate1 = this.getNewSupportingRateGenerically(this.props.farLegMarketRate, this.state.farLegMarketRate2, newValue,
                this.getRateTerm(this.props.sellCurrency, this.state.baseCurrency),
                this.getRateTerm(this.props.buyCurrency, this.state.baseCurrency),
                this.getRateTerm(this.state.baseCurrency, this.props.sellCurrency), true)

            let farFwdLegMarketRate1 = this.getNewSupportingRateGenerically(this.props.farLegMarketRate, this.state.farFwdLegMarketRate2, newValue,
                this.getRateTerm(this.props.sellCurrency, this.state.baseCurrency),
                this.getRateTerm(this.props.buyCurrency, this.state.baseCurrency),
                this.getRateTerm(this.state.baseCurrency, this.props.sellCurrency), true)

            let fwdPoints1 = ((farFwdLegMarketRate1 - farLegMarketRate1) * 10000).toFixed(this.state.ratePrecision2);

            this.setState({
                enableControlUpdates: false,
                farLegMarketRate1: farLegMarketRate1,
                farLegFwdPoints1: fwdPoints1,
                farFwdLegMarketRate1: farFwdLegMarketRate1,
                farLegMarketRate2: newValue,
                farLegFwdPoints2: 0,
                farFwdLegMarketRate2: newValue
            }, () => {
                this.setState({ enableControlUpdates: true });
                let tempSr = this.props.supportingRates                
                this.props.updateLocalSupportingRates({
                    ...tempSr, ...{
                        FarLegMarketRate1: farLegMarketRate1, FarLegFwdPoints1: fwdPoints1, FarFwdLegMarketRate1: farFwdLegMarketRate1,
                        FarLegMarketRate2: newValue, fwdPoints2: 0, FarFwdLegMarketRate2: newValue
                    }
                })
            });
        }
    }

    validateRateLimits = (originalRate, rate, sellCcy, buyCcy) =>
    {
        //Get rate precision
       
        var ratePrecision = 8;
        var marginErrorPair = 0;
        if(this.props.currencyPairs[buyCcy+sellCcy] !== undefined){
            ratePrecision = this.props.currencyPairs[buyCcy+sellCcy].RatePrecision;
            marginErrorPair = this.props.currencyPairs[buyCcy+sellCcy].MarginError;
        }

       let marginError = marginErrorPair/100;
       let limit = Number((originalRate *marginError).toFixed(ratePrecision));
       let upperLimit = Number((originalRate + limit).toFixed(ratePrecision));
       let lowerLimit = Number((originalRate - limit).toFixed(ratePrecision));

        if (rate > upperLimit || rate < lowerLimit) {
            var alertMessage = 'Rate input is out of range. Rate has to be between ' + lowerLimit + ' and ' + upperLimit;
            var alertTitle = 'Warning';
            this.setState({ alertTitle: alertTitle, alertMessage: alertMessage, showAlert: true });
            return false;
        }
        else {
            return true;
        }
    }

    onCloseClick = () => {
        if(this.props.onCloseClick !== undefined){
            this.props.onCloseClick();
        }
    }

    onSaveClick = () => {
        if (this.props.onSaveClick !== undefined) {
            var maturityDate = null;
            if (this.props.maturityDate !== '' && this.props.maturityDate != null) {
                maturityDate = formatDate(this.props.maturityDate);
            }
            var objSupportingRates = {
                BuyCurrency: this.props.buyCurrency,
                FwdPoints1: this.state.fwdPoints1,
                FwdPoints2: this.state.fwdPoints2,
                MarketRate1: this.state.marketRate1,
                MarketRate2: this.state.marketRate2,
                MaturityDate: maturityDate,
                OriginalSpotRate1: this.state.originalSpotRate1,
                OriginalSpotRate2: this.state.originalSpotRate2,
                RateQuoteID: this.props.rateQuoteID,
                SellCurrency: this.props.sellCurrency,
                ValueDate: formatDate(this.props.valueDate),
                DealType: this.props.dealType,
                FarLegMarketRate1: this.state.farLegMarketRate1,
                FarLegMarketRate2: this.state.farLegMarketRate2,
                FarLegFwdPoints1: this.state.farLegFwdPoints1,
                FarLegFwdPoints2: this.state.farLegFwdPoints2,
                FarFwdLegMarketRate1: this.state.farFwdLegMarketRate1,
                FarFwdLegMarketRate2: this.state.farFwdLegMarketRate2
            };
            this.props.onSaveClick(objSupportingRates);
        }
    }

    okGlobalAlert = () => {
        this.setState({showAlert: false});
    } 

    render(){

        let showForwardColumns = this.state.showForwardColumns; 

        return(
            <div className="uk-form-stacked">
                <div className="uk-margin-small-top">
                    <div uk-grid="true">
                        <div className="uk-width-1-6">
                            <i onClick={this.onCloseClick} className="fa fa-arrow-left" style={{ fontSize: '1.75em', verticalAlign: 'middle'  }}></i>
                        </div>
                        <div className="uk-width-5-6">
                            <h4>Supporting Rates</h4>
                        </div>
                    </div>
                </div>
                {showForwardColumns && 
                          
                <div className="uk-margin-small-top">
                    <div uk-grid="true">
                        <div className="uk-width-1-6"></div>
                        <div className="uk-width-1-6">{this.props.dealType == 6 ?'':'Value Date'}</div>
                        <div className="uk-width-1-6">Market Rate</div>
                          
                        <div className="uk-width-1-6">Fwd Points</div>
                        <div className="uk-width-1-6">Fwd Market Rate</div>
                        
                        
                        <div className="uk-width-1-6"></div>
                    </div>
                </div>
                }
                {this.props.dealType == 6 &&
                <div className="uk-margin-small-top">
                    <div uk-grid="true">
                        <div className="uk-width-1-6"></div>
                        <div className="uk-width-1-4" ><h4>Near Leg</h4></div>
                        <div className="uk-width-1-6"></div>
                        <div className="uk-width-1-4"><h4>Far Leg</h4></div>
                    </div>
                    <div uk-grid="true">
                        <div className="uk-width-1-6">Value Date</div>
                        <div className="uk-width-1-4">{formatDate(this.props.valueDate)}</div>
                        <div className="uk-width-1-6">Value Date</div>
                        <div className="uk-width-1-4">{formatDate(this.props.maturityDate)}</div>
                    </div>
                </div>
                }

                <div className="uk-margin-small-top">
                    <div uk-grid="true">
                        <div className="uk-width-1-6">
                            {getCurrencyPairLabel(this.state.ccy1, this.state.baseCurrency, this.state.currencyPairs)}{/*this.state.ccy1 + '/' + this.state.baseCurrency*/}
                        </div>
                        <div className="uk-width-1-4">
                            <NumberInput id="originalSpotRate1" type="Rate" scale={this.state.ratePrecision1} className="uk-input" disabled={this.state.disableControls} value={this.state.originalSpotRate1} onChange={this.handleOriginalSpotRate1Update} onBlur={this.handleOriginalSpotRate1Validate}/>
                        </div>
                        {showForwardColumns && 
                            <>
                                <div className="uk-width-1-6">
                                    <NumberInput id="fwdPoints1" type="Rate" scale={this.state.forwardPointsScale} className="uk-input" disabled={this.state.disableControls} value={this.state.fwdPoints1} onChange={this.handleFwdPoints1Update} negative={true}/>
                                </div>
                                <div className="uk-width-1-6">
                                    <NumberInput id="marketRate1" type="Rate" scale={this.state.ratePrecision1} className="uk-input" disabled={this.state.disableControls} value={this.state.marketRate1} onChange={this.handleMarketRate1Update} onBlur={this.handleMarketRate1Validate}/>
                                </div>
                            </>
                        }

                        {/**SWAP Farleg  */}
                        {this.props.dealType === 6 &&
                        
                        <>
                            <div className="uk-width-1-6">
                            {getCurrencyPairLabel(this.state.baseCurrency,this.state.ccy2, this.state.currencyPairs)}{/*this.state.baseCurrency + '/' + this.state.ccy2*/}
                            </div>
                            <div className="uk-width-1-4">
                                <NumberInput id="originalFarLegMarketRate1" type="Rate" scale={this.state.ratePrecision2} className="uk-input" disabled={this.state.disableControls} value={this.state.farLegMarketRate1} onChange={this.handleOriginalFarLegMarketRate1Update} /*onBlur={this.handleOriginalFarLegMarketRate1Validate}*//>
                            </div>
                            {showForwardColumns && 
                                <>
                                    <div className="uk-width-1-6">
                                        <NumberInput id="fwdPoints2" type="Rate" scale={this.state.forwardPointsScale} className="uk-input" disabled={this.state.disableControls} value={this.state.farLegFwdPoints1} negative={true}/>
                                    </div>
                                    <div className="uk-width-1-6">
                                        <NumberInput id="marketRate2" type="Rate" scale={this.state.ratePrecision2} className="uk-input" disabled={this.state.disableControls} value={this.state.farFwdLegMarketRate1}/>
                                    </div>
                                    </>
                            }
                        </>}
                        
                            
                    </div>
                </div>

                <div className="uk-margin-small-top">
                    <div uk-grid="true">
                        <div className="uk-width-1-6">
                        {getCurrencyPairLabel(this.state.baseCurrency,this.state.ccy2, this.state.currencyPairs)}{/*this.state.baseCurrency + '/' + this.state.ccy2*/}
                        </div>
                        <div className="uk-width-1-4">
                            <NumberInput id="originalSpotRate2" type="Rate" scale={this.state.ratePrecision2} className="uk-input" disabled={this.state.disableControls} value={this.state.originalSpotRate2} onChange={this.handleOriginalSpotRate2Update} onBlur={this.handleOriginalSpotRate2Validate}/>
                        </div>
                        {showForwardColumns && 
                            <>
                                <div className="uk-width-1-6">
                                    <NumberInput id="fwdPoints2" type="Rate" scale={this.state.forwardPointsScale} className="uk-input" disabled={this.state.disableControls} value={this.state.fwdPoints2} onChange={this.handleFwdPoints2Update} negative={true}/>
                                </div>
                                <div className="uk-width-1-6">
                                    <NumberInput id="marketRate2" type="Rate" scale={this.state.ratePrecision2} className="uk-input" disabled={this.state.disableControls} value={this.state.marketRate2} onChange={this.handleMarketRate2Update} onBlur={this.handleMarketRate2Validate}/>
                                </div>
                            </>
                        }
                        {/**SWAP Farleg */}
                        {this.props.dealType == 6 &&
                        <>
                            <div className="uk-width-1-6">
                                {getCurrencyPairLabel(this.state.ccy1, this.state.baseCurrency, this.state.currencyPairs)}{/*this.state.ccy1 + '/' + this.state.baseCurrency*/}
                            </div>
                            <div className="uk-width-1-4">
                                <NumberInput id="originalFarLegMarketRate2" type="Rate" scale={this.state.ratePrecision1} className="uk-input" disabled={this.state.disableControls} value={this.state.farLegMarketRate2} onChange={this.handleOriginalFarLegMarketRate2Update} /*onBlur={this.handleOriginalFarLegMarketRate2Validate}*//>
                            </div>
                            {showForwardColumns && 
                                <>
                                    <div className="uk-width-1-6">
                                        <NumberInput id="fwdPoints1" type="Rate" scale={this.state.forwardPointsScale} className="uk-input" disabled={this.state.disableControls} value={this.state.farLegFwdPoints2} negative={true}/>
                                    </div>
                                    <div className="uk-width-1-6">
                                        <NumberInput id="marketRate1" type="Rate" scale={this.state.ratePrecision1} className="uk-input" disabled={this.state.disableControls} value={this.state.farFwdLegMarketRate2} />
                                    </div>
                                    </>
                            }
                        </>}
                            
                    </div>
                </div>

                
                <div className="uk-margin-small-top">
                    <div uk-grid="true">
                        <div className="uk-width-4-6"></div>
                        <div className="uk-width-2-6">
                            <button className="uk-button uk-button-green" disabled={this.state.disableControls} onClick={this.onSaveClick}>Save</button>
                        </div>
                    </div>
                </div>
                <AlertBox id="global-alert" 
                    open={this.state.showAlert} 
                    title={this.state.alertTitle}
                    message={this.state.alertMessage}
                    type="Ok"
                    okClick={this.okGlobalAlert}/>
            </div>
        );
    }
}