import React from 'react';
import { getDefaultPagingNumberOfItems } from '../../../helpers/PackageJsonHelper';
import { newSearchCustomers, searchCustomersSalesforce } from '../../../networking/Networking';
import { getStyle } from '../../../styles/styles';
import NewDynamicTable from '../../shared/NewDynamicTable';

export default class WizardSearchCustomerForm extends React.Component {
    state = {
        searchTerm: '',
        pages: 1,
        totalPages: 0,
        customerList: [],
        newSearch: false,
        filter: false
    }

    componentDidMount() {
        this.loadCustomerSalesforce();   
    }

    loadCustomerSalesforce = async () => {
        let queryString = window.location.search;
        let urlParams = new URLSearchParams(queryString);        
        let sfaccount = urlParams.get('sfaccount');
        if(sfaccount!==undefined && sfaccount!=='' && sfaccount!==null){
            const value = await searchCustomersSalesforce(sfaccount);
            if (value !== undefined) {                
                this.props.onCustomerSelected(value, 2);
            }            
        } 
    }

    handleUpdateSearchTerm = (event) => {
        this.setState({ searchTerm: event.target.value });
    }

    loadCustomers = async () => {
        const itemsPerPage = getDefaultPagingNumberOfItems();
        const value = await newSearchCustomers(this.state.searchTerm, 0, 0);
        if (value.customers !== undefined) {
            const totalCount = value.totalCount;
            const totalPages = Math.ceil(totalCount / itemsPerPage);
            this.setState({
                totalPages,
                newSearch: true,
                filter: true,
                customerList: value.customers
            });
        }
    }

    changePage = (value) => {
        this.setState({ pages: value });
    }

    handleSearchClick = async (event) => {
        if (event != null) {
            event.preventDefault();
        }
        await this.loadCustomers();
    }

    handleRecordClick = (objCustomer) => {
        this.props.onCustomerSelected(objCustomer, 2);
    }

    getAllCustomers = async () => {
        const value = await newSearchCustomers('', 0, 0);
        if (value.customers !== undefined) {
            return value.customers;
        } else {
            return [];
        }
    }

    handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.handleSearchClick();
        }
    };

    render() {
        return (
            <div>
                <form className="uk-grid">
                    <div className="uk-width-1-2">
                        <div className="uk-margin" style={{ ...getStyle().inputStyle, marginTop: '5px' }}>
                            <input
                                className="uk-input"
                                id="searchTerm"
                                type="text"
                                value={this.state.searchTerm}
                                onChange={this.handleUpdateSearchTerm}
                                onKeyPress={this.handleKeyPress}
                                autoFocus={true} />
                        </div>
                    </div>
                    <div className="uk-width-1-2">
                        <div className="uk-margin" style={getStyle().inputStyle}>
                            <button className="uk-button uk-button-green" onClick={this.handleSearchClick}>Search</button>
                        </div>
                    </div>
                </form>
                <NewDynamicTable data={this.state.customerList}
                    enableClick={true}
                    clickFunction={this.handleRecordClick}
                    newSearch={this.state.newSearch}
                    useDeleteButton={false}
                    hiddenHeaders={['FeeStructureID', 'WireFeeSide', 'RelatedWireFeeID', 'DefaultBuyCurrencyID', 'DefaultSellCurrencyID']}
                    usePaginator={true}
                    changePage={this.changePage}
                    numberOfPages={this.state.totalPages}
                    pageNumber={this.state.pages}
                    numberPerPage={getDefaultPagingNumberOfItems()}
                    enableSortByHeader={true}
                    useFilter={this.state.filter}
                    filterFunction={this.getAllCustomers}
                />
            </div>
        )
    }
}