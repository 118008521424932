import React from 'react';
import {genericGetWithParameters} from '../../../networking/Networking';
import NewDynamicTable from '../../shared/NewDynamicTable';
import Paginator from '../../shared/Paginator';
import { getDefaultPagingNumberOfItems } from '../../../helpers/PackageJsonHelper'
export default class WizardSelectContactForm extends React.Component {
    state = {
        contactList: [],
        pages:0,
        newSearch:false,
    }

    componentDidMount(){
        this.loadContacts(1, true);
    }

    changePage(page){
        this.loadContacts(page, false);
    }

    loadContacts(page, newSearch){
        let itemsPerPage = getDefaultPagingNumberOfItems();
        let params = {"CustomerID":this.props.objCustomer["Customer ID"],
            "Page":page,
            "Items":itemsPerPage}
        genericGetWithParameters('Contact/GetContactsByCustomer', params).then(
            (value) => {
                var totalCount = value.totalCount;
                var pages = Math.ceil(totalCount/itemsPerPage);
                var helper = [];
                if(totalCount >0){
                    helper = value.contacts;
                }
                this.setState({contactList: helper,
                    pages: pages,
                    newSearch: newSearch});
            }
        );
    }

    handleRecordClick(objContact){
        this.props.onContactSelected(objContact);
    }

    handleBackClick(event){
        this.props.onBackClick();
    }

    render(){
        return(
            <form>
                {/* <div className="uk-width-1-1">
                    <h1>Contacts for customer {this.props.objCustomer["Customer Name"]}</h1>
                </div> */}
                <div className="uk-width-1-1">
                    <NewDynamicTable data={this.state.contactList}
                        enableClick={true}
                        clickFunction={this.handleRecordClick.bind(this)}
                        newSearch={this.state.newSearch} 
                        useDeleteButton={false}/>
                    <Paginator changePage={this.changePage.bind(this)} 
                        numberOfPages={this.state.pages} 
                        newSearch={this.state.newSearch}/>                        
                </div>
            </form>
        )
    }
}