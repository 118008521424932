import React from 'react';
import { DateTimeDataType, NumericDataType, TextDataType } from '../../constants/DataTypeNames';
import { GlobalSystemParametersNames } from '../../constants/GlobalSystemParametersNames';
import { EntityPropertyLinkType } from '../../constants/LinkTypeNames';
import MinusIcon from '../../css/custom-icons/minus.svg';
import PlusIcon from '../../css/custom-icons/plus.svg';
import { getScaleByCurrency, titleCase } from '../../helpers/FormatHelper';
import { deleteEntityProperty, getEntityProperties, saveEntityProperty } from '../../networking/NetworkingEntityProperties';
import { saveOutgoingFundsEntityProperties } from '../../networking/NetworkingFunds';
import { getLookUpTableByID, getLookUpTablesDescription } from '../../networking/NetworkingLookUpTables';
import { getStyle, notFilledInputClass, regularInputClass, regularSelectClass } from '../../styles/styles';
import AttachmentsTable from './AttachmentsTable';
import DatePicker from './DatePicker';
import DynamicSelect from './DynamicSelect';
import LoadingBox from './LoadingBox';
import NumberInput from './NumberInput';





var style = getStyle();
var counter = 1;

let listMandatory = [];

class TextProperty extends React.Component {
    state = {
        id: '',
        value: '',
        toggleAttachments: '',
        idAttachments: '',
        countAttachments: 0,
        style: regularInputClass,
        styleSelect: regularSelectClass,
        selectData: [],
        forceLoad: false,
        amount:0
    }

    componentDidMount() {
        // console.groupCollapsed('TextProperty - ' + this.props.property.Description);
        // console.table(this.props.property);
        // console.groupEnd();
        var description = this.props.property.Description;
        var newId = 'property-' + description.split(' ').join('-');
        this.setState({ id: newId });
        var idAttachments = newId + '-attachments';
        var toggle = 'target: #' + idAttachments + '; animation: uk-animation-slide-top-small';
        this.setState({ idAttachments: idAttachments, toggleAttachments: toggle });
        if(this.props.amount != null)
        {
            this.setState({ amount:this.props.amount });
        }
       
        if (this.props.property.LookUpTableID) {
            if(this.props.property.LookupTableDetails)
            {
                let lookUpTableDetails = JSON.parse(this.props.property.LookupTableDetails);
                var helper = [];
                for (var i = 0; i < lookUpTableDetails.length; i++) {
                    lookUpTableDetails[i].value = lookUpTableDetails[i].LookUpTableDetail
                    lookUpTableDetails[i].name = lookUpTableDetails[i].LookUpTableDetail
                    helper.push(lookUpTableDetails[i]);
                    //helper.push({value: lookUpTableDetails[i].LookUpTableDetail, name: lookUpTableDetails[i].LookUpTableDetail})
                }
                this.setState({ selectData: helper });
            }
            else
            {
            getLookUpTableByID(this.props.property.LookUpTableID).then(lookUp => {
                getLookUpTablesDescription(lookUp.Description).then(response => {
                    let lookUpTableDetails = response.lookUpTables[0].lookUpTableDetails;
                    var helper = [];
                    for (var i = 0; i < lookUpTableDetails.length; i++) {
                        lookUpTableDetails[i].value = lookUpTableDetails[i].LookUpTableDetail
                        lookUpTableDetails[i].name = lookUpTableDetails[i].LookUpTableDetail
                        helper.push(lookUpTableDetails[i]);
                        //helper.push({value: lookUpTableDetails[i].LookUpTableDetail, name: lookUpTableDetails[i].LookUpTableDetail})
                    }
                    this.setState({ selectData: helper });
                    //////debugger;
                })
            });
        }
        }
        if (this.props.property.TextValue !== null) {
            this.setState({ value: this.props.property.TextValue }, () => { this.inputStyle(); });
        } else {
            this.setState({ value: ''}, () => { this.inputStyle(); });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.property.EntityPropertyID !== this.props.property.EntityPropertyID) {

            if (this.props.property.TextValue !== null) {
                this.setState({ value: this.props.property.TextValue }, () => { this.inputStyle(); });
            } else {
                this.setState({ value: '' });
            }

            if (this.props.property.TextValue !== null) {
                this.setState({ value: this.props.property.TextValue }, () => { this.inputStyle(); });
            }
            this.inputStyle();
        }

        if(prevProps.amount !=  this.props.amount)
        {
            this.setState({amount:this.props.amount}, () => { this.inputStyle(); });
        }

        if (prevProps.forceLoad !== this.props.forceLoad) {
            this.setState({ forceLoad: this.props.forceLoad, value: this.props.property.TextValue });
        }
    }

    onChange(event) {
        this.setState({ value: event.target.value });
        var newProperty = this.props.property;
        newProperty.TextValue = event.target.value;
        this.inputStyle();
        this.props.getProperty(newProperty);
    }

    getSelect = (objValue) => {
        if (objValue !== "select") {
            this.setState({ value: objValue.value });
            var newProperty = this.props.property;
            newProperty.TextValue = objValue.value;
            this.props.getProperty(newProperty);
        }
    }

    onDelete() {
        if (this.props.property.EntityPropertyID !== null && this.props.property.EntityPropertyID !== undefined) {
            if (!JSON.parse(localStorage.getItem('HasComplianceAccess'))) {
                deleteEntityProperty(this.props.property.EntityPropertyID).then((value) => {
                    this.props.onDelete();
                })
            }
        }
    }

    inputStyle() {
        if(this.props.property.LookUpTableID != null && this.props.property.LookUpTableID != '')
            {
                this.selectStyle();
            }
            else
            {
        if(((this.props.property.AmountLimit > 0 && this.state.amount > this.props.property.AmountLimit)
            || this.props.property.Mandatory)
            && ((this.props.property.TextValue === null || this.props.property.TextValue === '')))
        {
            
                    this.setState({ style: notFilledInputClass });
                    if(this.props.onBlankMandatory)
                    {
                        this.props.onBlankMandatory(this.props.property);
                    }
                } else {
                    this.setState({ style: regularInputClass });
                    if(this.props.onValidMandatory)
                    {
                        this.props.onValidMandatory(this.props.property);
                    }
                }            
       
    }
    
}

    selectStyle() {
            if (this.props.property.LookUpTableID != null && this.props.property.LookUpTableID != '')
            {
                if(((this.props.property.AmountLimit > 0 && this.state.amount > this.props.property.AmountLimit)
                    || this.props.property.Mandatory)
                    && (this.props.property.TextValue === null || this.props.property.TextValue === ''))
                {
                        this.setState({ styleSelect: notFilledInputClass });
                        if(this.props.onBlankMandatory)
                        {
                            this.props.onBlankMandatory(this.props.property);
                        }
                    } else {
                        this.setState({ styleSelect: regularSelectClass });
                        if(this.props.onValidMandatory)
                        {
                            this.props.onValidMandatory(this.props.property);
                        }
                    }
                }
        }
            
        onSaveAttachmentWithoutLink = (id, fileName, fileDescription) => //for outgoings
        {
            var newProperty = this.props.property;
            if(newProperty.AttachmentsDetails != undefined)
            {
                var AttachmentsList = JSON.parse(newProperty.AttachmentsDetails);

                AttachmentsList.push({ ID: id});

                newProperty.Attachments = AttachmentsList.length;
                newProperty.AttachmentsDetails = JSON.stringify(AttachmentsList);
            }
            else
            {
                var attachments = [];
                attachments.push({ ID: id});

                newProperty.Attachments = attachments.length;
                newProperty.AttachmentsDetails = JSON.stringify(attachments);
            }
            this.props.getProperty(newProperty);        
        }
        
    render() {
        var attachmentsColor = "#666";
        var readOnly = this.props.property.ReadOnly;
        //var attachmentsText = "No Attachments";
        if(this.props.outgoingFunds != null)
        {
            if(((this.props.property.AmountLimit > 0  && this.state.amount > this.props.property.AmountLimit) || this.props.property.Mandatory) && this.props.property.AttachmentsRequired
                && (this.props.property.Attachments == 0))
            {
                attachmentsColor = 'red';
            }
        }

        var notificationAttachmentsBadge = (<span></span>);
        if (this.props.property.Attachments > 0) {
            /*attachmentsText = this.props.property.Attachments + " Attachment";
            if(this.props.property.Attachments > 1){
                attachmentsText = this.props.property.Attachments + " Attachments";
            }*/
            notificationAttachmentsBadge = (<span className="badge">{this.props.property.Attachments}</span>);
        }
        var input = [];
        if (readOnly) {
            input.push(<input key={this.state.id} style={{ background: this.props.backgroundColor }} className={this.state.style} id={this.state.id} type="text" placeholder="" value={this.state.value} readOnly />)
        } else {
            if (this.props.property.LookUpTableID) {
                input.push(<DynamicSelect className={this.state.styleSelect} getValue={this.getSelect} listHasPlaceholder={true} data={this.state.selectData} id={this.state.id} objValue={{ value: this.state.value, LookUpTableDetail: this.state.value }} />);
            } else {
                input.push(<input key={this.state.id} className={this.state.style} style={{ background: this.props.backgroundColor }} id={this.state.id} type="text" placeholder="" value={this.state.value} onChange={this.onChange.bind(this)} />);
            }
        }
        return (
            <div className="uk-grid  property-box" style={{ background: this.props.backgroundColor }}>

                <div className="uk-width-11-12">
                    <div className="uk-margin">
                        <label className="uk-form-label-properties-task" htmlFor={this.state.id} >{this.props.property.Description}</label>
                        <div className="uk-form-controls">
                            {input}
                        </div>
                    </div>
                </div>
                <div className="uk-width-1-12 uk-grid icons-container-properties">
                    <div className="uk-width-1-2 icon-container-properties" >
                        <i className="fa fa-fw fa-paperclip uk-align-center" title={'Attachments'} uk-toggle={this.state.toggleAttachments} style={{ fontSize: '1.50em', verticalAlign: 'middle', cursor: 'pointer', color: attachmentsColor }}>{notificationAttachmentsBadge}</i>
                    </div>
                    <div className="uk-width-1-2 icon-container-properties" hidden={this.props.outgoingFunds != null ? this.props.outgoingFunds : false}>
                        <i className="fa fa-fw fa-eraser uk-align-center" title={'Delete'} onClick={this.onDelete.bind(this)} style={{ fontSize: '1.50em', verticalAlign: 'middle', cursor: 'pointer' }} />
                    </div>
                </div>
                <div className="uk-width-1-1" id={this.state.idAttachments} style={{ marginTop: '10px' }} hidden>
                    <AttachmentsTable linkType={EntityPropertyLinkType} linkID={this.props.property.EntityPropertyID} onNewAttachment={this.props.onNewAttachment} 
                        attachmentsDetails = {this.props.property.AttachmentsDetails}
                    forceLoad={this.state.forceLoad} onSaveAttachmentWithoutLink={this.onSaveAttachmentWithoutLink} />
                </div>
            </div>
        );
    }

}

class DateProperty extends React.Component {
    state = {
        id: '',
        value: '',
        toggleAttachments: '',
        idAttachments: '',
        countAttachments: 0,
        style: '',
        forceLoad: false,
        amount:0
    }

    componentDidMount() {
        // console.groupCollapsed('DateProperty - ' + this.props.property.Description);
        // console.table(this.props.property);
        // console.groupEnd();
        var description = this.props.property.Description;
        var newId = 'property-' + description.split(' ').join('-');
        this.setState({ id: newId });
        var idAttachments = newId + '-attachments';
        var toggle = 'target: #' + idAttachments + '; animation: uk-animation-slide-top-small';
        this.setState({ idAttachments: idAttachments, toggleAttachments: toggle });
        if(this.props.amount != null)
            {
                this.setState({ amount:this.props.amount });
            }
        if (this.props.property.DateValue !== null) {
            this.setState({ value: this.props.property.DateValue });
        } else {
            this.setState({ value: '' });
        }
        this.inputStyle();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.property.EntityPropertyID !== this.props.property.EntityPropertyID) {
            if(prevProps.amount !== this.props.amount)
            {
                this.setState({ amount:this.props.amount });
            }
            if (this.props.property.DateValue !== null) {
                this.setState({ value: this.props.property.DateValue });
            } else {
                this.setState({ value: '' });
            }
            this.inputStyle();
        }
        if(prevProps.amount !=  this.props.amount)
        {
            this.setState({amount:this.props.amount}, () => { this.inputStyle(); });
        }
        if (prevProps.forceLoad !== this.props.forceLoad) {
            this.setState({ forceLoad: this.props.forceLoad, value: this.props.property.DateValue });
        }
    }

    onChange(value) {
        this.setState({ value: value });
        var newProperty = this.props.property;
        newProperty.DateValue = value;
        this.inputStyle();
        this.props.getProperty(newProperty);
    }

    onDelete() {
        if (this.props.property.EntityPropertyID !== null && this.props.property.EntityPropertyID !== undefined) {
            deleteEntityProperty(this.props.property.EntityPropertyID).then((value) => {
                this.props.onDelete();
            })
        }
    }

    inputStyle() {
        if(((this.props.property.AmountLimit > 0 && this.state.amount > this.props.property.AmountLimit)
            || this.props.property.Mandatory)
            && (this.props.property.DateValue === null || this.props.property.DateValue === ''))
        {
             this.setState({ style: notFilledInputClass });
                if(this.props.onBlankMandatory)
                {
                    this.props.onBlankMandatory(this.props.property);
                }
            } else {
                this.setState({ style: regularInputClass });
                if(this.props.onValidMandatory)
                {
                    this.props.onValidMandatory(this.props.property);
                }
            }
        
    
    }

    onSaveAttachmentWithoutLink = (id, fileName, fileDescription) => //for outgoings
        {
            var newProperty = this.props.property;
            if(newProperty.AttachmentsDetails != undefined)
            {
                var AttachmentsList = JSON.parse(newProperty.AttachmentsDetails);

                AttachmentsList.push({ ID: id});

                newProperty.Attachments = AttachmentsList.length;
                newProperty.AttachmentsDetails = JSON.stringify(AttachmentsList);
            }
            else
            {
                var attachments = [];
                attachments.push({ ID: id});

                newProperty.Attachments = attachments.length;
                newProperty.AttachmentsDetails = JSON.stringify(attachments);
            }
            this.props.getProperty(newProperty);        
        }

    render() {
        var attachmentsColor = "#666";
        var readOnly = this.props.property.ReadOnly;
        if(this.props.outgoingFunds != null)
        {
            if(((this.props.property.AmountLimit > 0  && this.state.amount > this.props.property.AmountLimit) || this.props.property.Mandatory) && this.props.property.AttachmentsRequired
                && this.props.property.Attachments == 0)
            {
                attachmentsColor = 'red';
            }
        }
        //var attachmentsText = "No Attachments";
        var notificationAttachmentsBadge = (<span></span>);
        if (this.props.property.Attachments > 0) {
            /*attachmentsText = this.props.property.Attachments + " Attachment";
            if(this.props.property.Attachments > 1){
                attachmentsText = this.props.property.Attachments + " Attachments";
            }*/
            notificationAttachmentsBadge = (<span className="badge">{this.props.property.Attachments}</span>);
        }
        var input = [];
        if (readOnly) {
            input.push(<input key={this.state.id} style={{ background: this.props.backgroundColor }} className={this.state.style} id={this.state.id} type="text" placeholder="" value={this.state.value} readOnly />)
        } else {
            input.push(<DatePicker key={this.state.id} style={{ background: this.props.backgroundColor }} className={this.state.style} value={this.state.value} onDayChange={this.onChange.bind(this)} />);
        }
        return (
            <div className="uk-grid  property-box" style={{ background: this.props.backgroundColor }}>
                <div className="uk-width-11-12" >
                    <div className="uk-margin">
                        <label className="uk-form-label-properties-task" htmlFor={this.state.id} >{this.props.property.Description}</label>
                        <div className="uk-form-controls">
                            {input}
                        </div>
                    </div>
                </div>
                <div className="uk-width-1-12 uk-grid icons-container-properties">
                    <div className="uk-width-1-2 icon-container-properties">
                        <i className="fa fa-fw fa-paperclip uk-align-center" title={'Attachments'} uk-toggle={this.state.toggleAttachments} style={{ fontSize: '1.50em', verticalAlign: 'middle', cursor: 'pointer', color: attachmentsColor }}>{notificationAttachmentsBadge}</i>
                    </div>
                    <div className="uk-width-1-2 icon-container-properties" hidden={this.props.outgoingFunds != null ? this.props.outgoingFunds : false}>
                        <i className="fa fa-fw fa-eraser uk-align-center" title={'Delete'} onClick={this.onDelete.bind(this)} style={{ fontSize: '1.50em', verticalAlign: 'middle', cursor: 'pointer' }} />
                    </div>
                </div>
                <div className="uk-width-1-1" id={this.state.idAttachments} hidden>
                    <AttachmentsTable linkType={EntityPropertyLinkType} linkID={this.props.property.EntityPropertyID} 
                    attachmentsDetails = {this.props.property.AttachmentsDetails}
                    onNewAttachment={this.props.onNewAttachment} onSaveAttachmentWithoutLink={this.onSaveAttachmentWithoutLink} />
                </div>
            </div>
        );
    }
}

class NumericProperty extends React.Component {
    state = {
        id: '',
        value: '',
        toggleAttachments: '',
        idAttachments: '',
        countAttachments: 0,
        style: '',
        forceLoad: false,
        baseCurrency : localStorage.getItem(GlobalSystemParametersNames.BaseCurrency),
        amount:0
    }

    componentDidMount() {
        // console.groupCollapsed('NumericProperty - ' + this.props.property.Description);
        // console.table(this.props.property);
        // console.groupEnd();
        var description = this.props.property.Description;
        var newId = 'property-' + description.split(' ').join('-');
        this.setState({ id: newId });
        var idAttachments = newId + '-attachments';
        var toggle = 'target: #' + idAttachments + '; animation: uk-animation-slide-top-small';
        this.setState({ idAttachments: idAttachments, toggleAttachments: toggle });
        if (this.props.property.NumValue !== null) {
            this.setState({ value: this.props.property.NumValue });
        } else {
            this.setState({ value: '' });
        }
        this.inputStyle();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.property.EntityPropertyID !== this.props.property.EntityPropertyID) {
            if(prevProps.amount !== this.props.amount)
            {
                this.setState({ amount:this.props.amount });
            }
            if (this.props.property.NumValue !== null) {
                this.setState({ value: this.props.property.NumValue });
            } else {
                this.setState({ value: '' });
            }
            this.inputStyle();
        }

        if(prevProps.amount !=  this.props.amount)
        {
            this.setState({amount:this.props.amount}, () => { this.inputStyle(); });
        }
        if (prevProps.forceLoad !== this.props.forceLoad) {
            this.setState({ forceLoad: this.props.forceLoad, value: this.props.property.NumValue });
        }
    }

    onChange(event) {
        this.setState({ value: event.target.value });
        var newProperty = this.props.property;
        newProperty.NumValue = event.target.value;
        this.inputStyle();
        this.props.getProperty(newProperty);
    }

    onDelete() {
        if (this.props.property.EntityPropertyID !== null && this.props.property.EntityPropertyID !== undefined) {
            deleteEntityProperty(this.props.property.EntityPropertyID).then((value) => {
                this.props.onDelete();
            })
        }
    }

    onSaveAttachmentWithoutLink = (id, fileName, fileDescription) => //for outgoings
        {
            var newProperty = this.props.property;
            if(newProperty.AttachmentsDetails != undefined)
            {
                var AttachmentsList = JSON.parse(newProperty.AttachmentsDetails);

                AttachmentsList.push({ ID: id});

                newProperty.Attachments = AttachmentsList.length;
                newProperty.AttachmentsDetails = JSON.stringify(AttachmentsList);
            }
            else
            {
                var attachments = [];
                attachments.push({ ID: id});

                newProperty.Attachments = attachments.length;
                newProperty.AttachmentsDetails = JSON.stringify(attachments);
            }
            this.props.getProperty(newProperty);         
        }

    inputStyle() {
        if(((this.props.property.AmountLimit > 0 && this.state.amount > this.props.property.AmountLimit)
            || this.props.property.Mandatory)
            && ((this.props.property.NumValue === null || this.props.property.NumValue === '' || this.props.property.NumValue.value === 0)))
        {
                this.setState({ style: notFilledInputClass });
                if(this.props.onBlankMandatory)
                {
                    this.props.onBlankMandatory(this.props.property);
                }
            } else {
                this.setState({ style: regularInputClass });
                if(this.props.onValidMandatory)
                {
                    this.props.onValidMandatory(this.props.property);
                }
            }
   
    
    }    

    render() {
        var attachmentsColor = "#666";
        var readOnly = this.props.property.ReadOnly;

        if(this.props.outgoingFunds != null)
        {
            if(((this.props.property.AmountLimit > 0 && this.state.amount > this.props.property.AmountLimit) || this.props.property.Mandatory) && this.props.property.AttachmentsRequired
                && this.props.property.Attachments == 0)
            {
                attachmentsColor = 'red';
            }
        }
        //var attachmentsText = "No Attachments";
        var notificationAttachmentsBadge = (<span></span>);
        if (this.props.property.Attachments > 0) {
            /*attachmentsText = this.props.property.Attachments + " Attachment";
            if(this.props.property.Attachments > 1){
                attachmentsText = this.props.property.Attachments + " Attachments";
            }*/
            notificationAttachmentsBadge = (<span className="badge">{this.props.property.Attachments}</span>);
        }
        var input = [];
        if (readOnly) {
            input.push(<input key={this.state.id} style={{ background: this.props.backgroundColor }} className={this.state.style} id={this.state.id} type="number" placeholder="" value={this.state.value} readOnly />)
        } else {
            input.push(<NumberInput key={this.state.id} id={this.state.id} style={{ background: this.props.backgroundColor }} type="Currency" className={this.state.style}
            scale={getScaleByCurrency(this.state.baseCurrency)}    
            value={this.state.value}
                onChange={this.onChange.bind(this)}
            />);
        }
        return (
            <div className="uk-grid property-box" style={{ background: this.props.backgroundColor }}>
                <div className="uk-width-11-12" >
                    <div className="uk-margin">
                        <label className="uk-form-label-properties-task" htmlFor={this.state.id} >{this.props.property.Description}</label>
                        <div className="uk-form-controls">
                            {input}
                            {/*<input className="uk-input" id={this.state.id} type="number" placeholder="" value={this.state.value} onChange={this.onChange.bind(this)}/>*/}
                        </div>
                    </div>
                </div>
                <div className="uk-width-1-12 uk-grid icons-container-properties">
                    <div className="uk-width-1-2 icon-container-properties">
                        <i className="fa fa-fw fa-paperclip uk-align-center" title={'Attachments'} uk-toggle={this.state.toggleAttachments} style={{ fontSize: '1.50em', verticalAlign: 'middle', cursor: 'pointer',color: attachmentsColor }}>{notificationAttachmentsBadge}</i>
                        {/*<button className="uk-button uk-button-default" uk-tooltip={attachmentsText} type="button" uk-toggle={this.state.toggleAttachments}>Attachments</button>*/}
                    </div>
                    <div className="uk-width-1-2 icon-container-properties" hidden={this.props.outgoingFunds != null ? this.props.outgoingFunds : false}>
                        <i className="fa fa-fw fa-eraser uk-align-center" title={'Delete'} onClick={this.onDelete.bind(this)} style={{ fontSize: '1.50em', verticalAlign: 'middle', cursor: 'pointer' }} />
                    </div>
                </div>
                <div className="uk-width-1-1" id={this.state.idAttachments} hidden>
                    <AttachmentsTable linkType={EntityPropertyLinkType} linkID={this.props.property.EntityPropertyID} 
                    attachmentsDetails = {this.props.property.AttachmentsDetails}
                    onNewAttachment={this.props.onNewAttachment} onSaveAttachmentWithoutLink={this.onSaveAttachmentWithoutLink} />
                </div>
            </div>
        );
    }
}

//PROPS:
//largeScreen = {true, false} (true, uses self save button. false, needs a function to save)
//isSaving = {state.variable} (only when largeScreen is false)
//entityType = {} (from constants/EntityTypeNames)
//entityID = {} (int/string)
export default class EntityProperties extends React.Component {
    state = {
        listEntityProperties: [],
        filteredListEntityProperties: [],
        listCategories: [],
        filteredCategories: [],
        finalListEntityProperties: [],
        filterScreen: false,
        loading: false,
        filtering: false,
        nameFilter: '',
        contentFilter: '',
        //listMandatory: [],
        forceLoad: false,
        mandatoryProperty:[],
        amount:0
    }

    componentDidMount() {
        this.getProperties();
    }

    componentDidUpdate = async(prevProps)=> {
        if (prevProps.entityID !== this.props.entityID) {
            await this.getProperties();
        }

        if(prevProps.amount != this.props.amount)
        {
            this.setState({amount:this.props.amount});
        }

        if (prevProps.runSaveProcess !== this.props.runSaveProcess && this.props.runSaveProcess) {
            if(this.props.outgoingFunds)
            {
                this.savePropertiesOutgoing();
            }
            else
            {
                this.saveProperties();
            }
        }
        if (prevProps.forceLoad !== this.props.forceLoad) {
            await this.getProperties(true);
            this.setState({ forceLoad: this.props.forceLoad });
        }
    }

    onDelete() {
        this.getProperties();
    }

    saveProperties = async () => {
        let invalidProperties = this.getInvalidProperties()
        if (invalidProperties.length === 0) {
            var listOfProperties = this.state.finalListEntityProperties;
            let userID = localStorage.getItem('UserID');
            counter = 1;
            let concurrencyError = false;
            let arrayOfConcurrencyProperties = [];
            let propertiesToSave = listOfProperties.filter(x => {
                let valueHasChange = false
                switch (x.Type) {
                    case "Text":
                        valueHasChange = x.OriginalValue !== x.TextValue
                        break;
                    case "Numeric":
                        valueHasChange = x.OriginalValue !== x.NumValue
                        break;
                    case "DateTime":
                        valueHasChange = x.OriginalValue !== x.DateValue
                        break;
                    default:
                        break;
                }
                return valueHasChange
            }) || []

            //console.log(propertiesToSave)
            for (var i = 0; i < propertiesToSave.length; i++) {
                let value = '';
                switch (propertiesToSave[i].Type) {
                    case TextDataType: value = propertiesToSave[i].TextValue || ''; break;
                    case DateTimeDataType: value = propertiesToSave[i].DateValue; break;
                    case NumericDataType: value = propertiesToSave[i].NumValue || 0; break;
                    default: value = ''; break;
                }

                let model = {
                    EntityId: this.props.entityID,
                    EntityType: this.props.entityType,
                    PropertyDescription: propertiesToSave[i].Description,
                    Value: value || '',
                    CreatedBy: userID,
                    UpdateToken: propertiesToSave[i].UpdateToken,
                }

                let response = await saveEntityProperty(model);

                if (response.httpStatusCode === 409) {
                    concurrencyError = true;
                    arrayOfConcurrencyProperties.push(model.PropertyDescription);
                }
            }
            if (concurrencyError) {
                let message = `These properties have been updated by someone else: ${arrayOfConcurrencyProperties.join(', ')}. Please reload the information and try again.`
                this.props.finishSaveProcess(false, message, []);
            } else {
                this.props.finishSaveProcess(true, "Success", []);
            }
            this.getProperties();
        }
        /*else{
            this.props.finishSaveProcess(false,"Please fill all mandatory fields:",listMandatory)
        }*/
        if (invalidProperties.length > 0) {
            this.props.finishSaveProcess(false, "Please fill all mandatory properties:", invalidProperties);
        }
    }

    savePropertiesOutgoing = async () => {

        //debugger
        if (this.validatePropertiesOutgoings()) {
            var listOfProperties = this.state.finalListEntityProperties;
            let userID = localStorage.getItem('UserID');
            var propertiesToSave = listOfProperties.filter(x => {
                let valueHasChange = false
                switch (x.Type) {
                    case "Text":
                        valueHasChange = x.OriginalValue !== x.TextValue
                        break;
                    case "Numeric":
                        valueHasChange = x.OriginalValue !== x.NumValue
                        break;
                    case "DateTime":
                        valueHasChange = x.OriginalValue !== x.DateValue
                        break;
                    default:
                        break;
                }
                if(!valueHasChange)
                {
                    valueHasChange = x.Attachments > 0
                }
                return valueHasChange
            }) || [];

            propertiesToSave.forEach(p => {
                
                if(p.AttachmentsDetails != null && p.Attachments > 0)
                {
                    var attachments = [];
                    var attDetails = JSON.parse(p.AttachmentsDetails);

                    attDetails.forEach( a => {
                        attachments.push({"ID": a.ID});
                    }
                    );

                    p.Attachments = attachments;
                }
            });
            
            const model = {
                OutgoingFundsID: this.props.entityID,
                EntityPropertiesJson: JSON.stringify(propertiesToSave),
                UpdatedBy: userID
            };
            const json = await saveOutgoingFundsEntityProperties(model);
            if (json != null) {
                switch (Number(json.httpStatusCode)) {
                    case 200: {
                        this.setState({ showAlert: true, alertMessage: 'Required Information successfully saved', alertTitle: 'Success' });
                        this.props.finishSaveProcess(true, "Success", []);
                        break;
                    }
                    default: {
                        this.setState({ showAlert: true, alertMessage: json.httpErrorMessage, alertTitle: 'Error' });
                        this.props.finishSaveProcess(false, json.httpErrorMessage, []);
                        break;
                    }
                }
                this.getProperties();
            }
        }
    };

    sleep = (milliseconds) => {
        const date = Date.now();
        let currentDate = null;
        do {
            currentDate = Date.now();
        } while (currentDate - date < milliseconds);
    }

    validateProperties = () => {
        let helper = [];
        let listOfProperties = this.state.finalListEntityProperties;
        var isValid = true;
        debugger;
        for (var i = 0; i < listOfProperties.length; i++) {
            var property = listOfProperties[i];
            if (property.Mandatory === true) {
                switch (property.Type) {
                    case TextDataType:
                        if (property.TextValue === null || property.TextValue === '') {
                            isValid = false;
                            helper.push(property.Description + ', the value is required');
                        }
                        break;
                    case NumericDataType:
                        if (property.NumValue === null || property.NumValue === '') {
                            isValid = false;
                            helper.push(property.Description + ', the value is required');
                        }
                        break;
                    case DateTimeDataType:
                        if (property.DateValue === null || property.DateValue === '') {
                            isValid = false;
                            helper.push(property.Description + ', the value is required');
                        }
                        break;
                    default:
                        break;
                }
            }
            if (property.AttachmentsRequired === true && property.Attachments === 0) {
                helper.push(property.Description + ', attachments required');
            }
        }
        console.log(helper)
        return helper.length === 0;
    }

    //old version
    validatePropertiesOutgoings = () => {
        listMandatory = [];
        var helper = [];
        var listOfProperties = this.state.finalListEntityProperties;
        var isValid = true;
        for (var i = 0; i < listOfProperties.length; i++) {
            var property = listOfProperties[i];
            if (property.Mandatory === true) {
                switch (property.Type) {
                    case TextDataType:
                        if (property.TextValue === null || property.TextValue === '') {
                            isValid = false;
                            helper.push(property.Description);
                        }
                        break;
                    case NumericDataType:
                        if (property.NumValue === null || property.NumValue === '') {
                            isValid = false;
                            helper.push(property.Description);
                        }
                        break;
                    case DateTimeDataType:
                        if (property.DateValue === null || property.DateValue === '') {
                            isValid = false;
                            helper.push(property.Description);
                        }
                        break;
                    default:
                        break;
                }
            }
        }
        listMandatory = helper;
        return true;
    }

    getInvalidProperties = () => {
        let helper = [];
        let listOfProperties = this.state.finalListEntityProperties;
        debugger;
        for (var i = 0; i < listOfProperties.length; i++) {
            var property = listOfProperties[i];
            if (property.Mandatory === true) {
                switch (property.Type) {
                    case TextDataType:
                        if (property.TextValue === null || property.TextValue === '') {
                            helper.push(property.Description + ', the value is required');
                        }
                        break;
                    case NumericDataType:
                        if (property.NumValue === null || property.NumValue === '') {
                            helper.push(property.Description + ', the value is required');
                        }
                        break;
                    case DateTimeDataType:
                        if (property.DateValue === null || property.DateValue === '') {
                            helper.push(property.Description + ', the value is required');
                        }
                        break;
                    default:
                        break;
                }
            }
            if (property.AttachmentsRequired === true && property.Attachments === 0) {
                helper.push(property.Description + ', attachments required');
            }
        }
        return helper;
    }

    onNewAttachment = () => {
        /*if(!this.props.outgoingFunds)
        {
            this.getProperties();
        }
        else
        {*/
            this.refreshAttachments();
        //}
    }

    refreshAttachments(forceLoad = false) {
        this.setState({ loading: true }, () => {
            getEntityProperties(this.props.entityID, this.props.entityType).then(
                (value) => {

                    if (value.entityProperties) {
                        var entityProperties = [];
                        entityProperties = value.entityProperties;
                    
                        let properties = this.state.filteredListEntityProperties;
                        properties.forEach(x => {
                            var prop = entityProperties.find(p => p.EntityPropertyID == x.EntityPropertyID);
                            if(prop != null)
                            {
                                x.Attachments = prop.Attachments;
                                x.AttachmentsDetails = prop.AttachmentsDetails;
                            }
                        })
                        this.setState({
                            listEntityProperties: properties,
                            finalListEntityProperties: properties,
                            filteredListEntityProperties: properties,
                            forceLoad: forceLoad,
                            loading: false
                        }, () => {
                            if (forceLoad) {
                                this.setState({ forceLoad: false });
                            }
                            if(this.props.onValidMandatory)
                            {
                                this.props.onValidMandatory(properties);
                            }
                        });
                        this.getCategories(properties);
                    }
                    else
                    {
                        if(this.props.onValidMandatory)
                            {
                                this.props.onValidMandatory(null);
                            }
                    }
                }
            );
        })
    }

    getProperties = async(forceLoad = false) => {
        this.setState({ loading: true }, async () => {
            //debugger;
            var value = await getEntityProperties(this.props.entityID, this.props.entityType);
               
                    if (value.entityProperties) {
                        var entityProperties = [];
                        entityProperties = value.entityProperties;
                        let properties = entityProperties;
                        properties.forEach(x => {
                            switch (x.Type) {
                                case "Text":
                                    x.OriginalValue = x.TextValue
                                    break;
                                case "Numeric":
                                    x.OriginalValue = x.NumValue
                                    break;
                                case "DateTime":
                                    x.OriginalValue = x.DateValue
                                    break;
                                default:
                                    x.OriginalValue = x.TextValue
                                    break;
                            };
                            x['Group By'] = (x['Group By'] === undefined || x['Group By'] === null || x['Group By'] === '') ? 'Without group' : x['Group By']
                        })
                        this.setState({
                            listEntityProperties: properties,
                            finalListEntityProperties: properties,
                            filteredListEntityProperties: properties,
                            forceLoad: forceLoad,
                            loading: false
                        }, async () => {
                            if (forceLoad) {
                                this.setState({ forceLoad: false });
                            }
                            if(this.props.onValidMandatory)
                            {
                                await this.props.onValidMandatory(properties);
                            }
                        });
                        this.getCategories(properties);
                    }
                }
            );
        
    }

    getCategories(json) {
        var helper = [];
        for (var i = 0; i < json.length; i++) {
            if (!helper.includes(json[i]['Group By'])) {
                helper.push(json[i]['Group By']);
            }
        }
        this.setState({ listCategories: helper, filteredCategories: helper, loading: false });
    }

    getProperty(value) {
        var listOfProperties = this.state.finalListEntityProperties;
        for (var i = 0; i < listOfProperties.length; i++) {
            if (listOfProperties[i].UserDefinedPropertyID === value.UserDefinedPropertyID) {
                listOfProperties[i] = value;
            }
        }
        this.setState({ finalListEntityProperties: listOfProperties });

        if(this.props.onValidMandatory)
        {
            this.props.onValidMandatory(listOfProperties);
        }
    }

    isOdd = (number) => {
        return number % 2;
    }

    getController(property, number) {
        let backgroundColor = '#f8f8f8';
        if (this.isOdd(number) === 1) {
            backgroundColor = '#f8f8f8'
        }
        var key = this.props.entityID + '-' + this.props.entityType + '-' + property.Description.replace(' ', '-');
        switch (property.Type) {
            case TextDataType:
                return (
                    <div>
                        <TextProperty key={key} property={property} onNewAttachment={this.onNewAttachment} getProperty={this.getProperty.bind(this)} onDelete={this.onDelete.bind(this)} backgroundColor={backgroundColor} forceLoad={this.state.forceLoad} outgoingFunds={this.props.outgoingFunds} 
                            amount = {this.state.amount} />
                    </div>
                );
            case NumericDataType:
                return (
                    <div>
                        <NumericProperty key={key} property={property} onNewAttachment={this.onNewAttachment} getProperty={this.getProperty.bind(this)} onDelete={this.onDelete.bind(this)} backgroundColor={backgroundColor} forceLoad={this.state.forceLoad} outgoingFunds={this.props.outgoingFunds} 
                        amount = {this.state.amount}/>
                    </div>
                );
            case DateTimeDataType:
                return (
                    <div>
                        <DateProperty key={key} property={property} onNewAttachment={this.onNewAttachment} getProperty={this.getProperty.bind(this)} onDelete={this.onDelete.bind(this)} backgroundColor={backgroundColor} forceLoad={this.state.forceLoad} outgoingFunds={this.props.outgoingFunds}
                        amount = {this.state.amount}/>
                    </div>
                );
            default:
                break;
        }

        return (
            <div>

            </div>
        );
    }

    onChangeNameFilter = (event) => {
        this.setState({ nameFilter: event.target.value }, () => {
            this.runFilter();
        });
    }

    onChangeContentFilter = (event) => {
        this.setState({ contentFilter: event.target.value }, () => {
            this.runFilter();
        });
    }

    runFilter = () => {
        let newList = [];
        let filterList = this.state.listEntityProperties;
        let name = this.state.nameFilter;
        let content = this.state.contentFilter;

        if (name === '' && content === '') {
            this.setState({ filteredListEntityProperties: this.state.listEntityProperties });
            this.getCategories(this.state.listEntityProperties);
            return;
        }

        filterList.forEach(element => {
            switch (element.Type) {
                case TextDataType:
                    if (element.TextValue) {
                        if (element.Description.toLowerCase().includes(name.toLowerCase()) && (element.TextValue.toLowerCase().includes(content.toLowerCase()) || content === '')) {
                            newList.push(element);
                        }
                    }
                    break;
                case NumericDataType:
                    if (element.NumValue) {
                        if (element.Description.toLowerCase().includes(name.toLowerCase()) && (element.NumValue.toString().toLowerCase().includes(content.toLowerCase()) || content === '')) {
                            newList.push(element);
                        }
                    }
                    break;
                case DateTimeDataType:
                    if (element.DateValue) {
                        if (element.Description.toLowerCase().includes(name.toLowerCase()) && (element.DateValue.toString().toLowerCase().includes(content.toLowerCase()) || content === '')) {
                            newList.push(element);
                        }
                    }
                    break;
                default:
                    break;
            }

            if (!element.TextValue && !element.NumValue && !element.DateValue && content === '') {
                if (element.Description.toLowerCase().includes(name.toLowerCase())) {
                    newList.push(element);
                }
            }
        });
        this.setState({ filteredListEntityProperties: newList });
        this.getCategories(newList);
    }

    onFilterButtonClick = () => {
        if (this.state.filtering) {
            this.setState({ filtering: false, nameFilter: '', contentFilter: '' }, () => { this.runFilter() });
        } else {
            this.setState({ filtering: true });
            this.runFilter();
        }
    }

    render() {
        var fullScreen = false;
        var filterIcon = "fa fa-fw fa-filter uk-align-right";
        if (this.state.filtering) {
            filterIcon = "fa fa-fw fa-ban uk-align-right";
        }

        if (this.props.fullScreen !== undefined) {
            fullScreen = this.props.fullScreen;
        }

        var properties = [];
        var id = "";
        if (this.props.id !== undefined) {
            id = "-" + this.props.id;
        }

        var idForEP = "entity-properties" + id;
        var idForFilter = "entity-properties-filter" + id;
        var toogle = "target: #" + idForEP + "; animation: uk-animation-slide-top-medium";
        var toggleFilter = "target: #" + idForFilter + ";";

        //console.group("Entity Properties");
        let setCategories = new Set(this.state.filteredCategories);
        let uniqueCategories = [...setCategories];
        for (var j = 0; j < uniqueCategories.length; j++) {
            //console.group(uniqueCategories[j]);
            var idForGroup = 'entity-group-' + j;
            var toogleForGroup = "target : #" + idForGroup + ";"

            var listProp = [];
            var showProperties = false;

            for (var k = 0; k < this.state.filteredListEntityProperties.length; k++) {
                if (this.state.filteredListEntityProperties[k]['Group By'] === uniqueCategories[j]) {

                    var prop = this.state.filteredListEntityProperties[k];
                    if((prop.AmountLimit > 0 || prop.Mandatory) && prop.AttachmentsRequired
                        && prop.Attachments == 0)
                    {
                        showProperties = true;
                    }
                    else
                    {
                    if((prop.AmountLimit > 0 || prop.Mandatory) && ((prop.Type == 'Text' && (prop.TextValue == null || prop.TextValue == ''))
                        || (prop.Type == 'Numeric' && (prop.NumValue == null || prop.NumValue == '' || prop.NumValue.value == 0))
                        || (prop.Type == 'DateTime' && (prop.DateValue === null || prop.DateValue === '') ) ) )
                    {
                        showProperties = true;
                    }
                    }

                    listProp.push(this.getController(this.state.filteredListEntityProperties[k], k + 1));
                    //console.groupCollapsed(this.state.filteredListEntityProperties[k].Type + "Property - " + this.state.filteredListEntityProperties[k].Description);
                    //console.table(this.state.filteredListEntityProperties[k]);
                    //console.groupEnd();
                }
            }

            properties.push(
                <h5
                    key={j}
                    className="uk-heading-divider propoerties-tasks-category-title"
                    uk-toggle={toogleForGroup}
                    style={{
                        ...style.tasktitleStyle,
                        cursor: 'pointer'
                    }}>{titleCase(uniqueCategories[j])}
                    {!showProperties && 
                    <img
                        id={idForGroup}
                        src={PlusIcon}
                        //className="fa fa-fw fa-plus" 
                        style={{
                            position: 'absolute',
                            paddingTop: '5px',
                            right: '60px', color: 'black'
                        }}
                    />}
                    {!showProperties && 
                    <img
                        id={idForGroup}
                        src={MinusIcon}
                        //className="fa fa-fw fa-minus" 
                        style={{
                            position: 'absolute',
                            paddingTop: '5px',
                            right: '60px', color: 'black'
                        }}
                        hidden
                    /> }
                    {showProperties && 
                    <img
                        id={idForGroup}
                        src={PlusIcon}
                        //className="fa fa-fw fa-plus" 
                        style={{
                            position: 'absolute',
                            paddingTop: '5px',
                            right: '60px', color: 'black'
                        }}
                        hidden
                    /> }
                    {showProperties && 
                    <img
                        id={idForGroup}
                        src={MinusIcon}
                        //className="fa fa-fw fa-minus" 
                        style={{
                            position: 'absolute',
                            paddingTop: '5px',
                            right: '60px', color: 'black'
                        }}
                    /> }
                </h5>);
            

            if(showProperties){
            properties.push(<div key={idForGroup} id={idForGroup} style={{ paddingLeft: '50px', paddingRight: '50px' }} className="properties-div">{listProp}</div>);
            }
            else
            {
            properties.push(<div key={idForGroup} id={idForGroup} hidden style={{ paddingLeft: '50px', paddingRight: '50px' }} className="properties-div">{listProp}</div>);
            }
            //console.groupEnd();
        }
        //console.groupEnd();

        if (fullScreen) {
            return (
                <div>
                    <div className="uk-grid container-filter-properties" id={idForFilter} >
                        <div className="uk-width-2-12 filter-label">
                            <form className="uk-form-horizontal">
                                <label className="uk-form-label-properties-task-filter" htmlFor="property-name-filter-input">Filter</label>
                                <div className="uk-form-controls-filter"><input className="uk-input" id="property-name-filter-input" type="text" placeholder="Property Name" value={this.state.nameFilter} onChange={this.onChangeNameFilter} /></div>
                            </form>
                        </div>
                        <div className="uk-width-3-12">
                            <input className="uk-input" type="text" placeholder="Content" value={this.state.contentFilter} onChange={this.onChangeContentFilter} />
                        </div>
                        {this.props.children && (
                            <div className="uk-width-3-12" style={{ marginTop: -5 }}>
                                {this.props.children}
                            </div>
                        )}
                    </div>
                    <div id={idForEP} className="uk-margin">
                        <form className="uk-form-horizontal">
                            {properties}
                        </form>
                    </div>
                    <LoadingBox loading={this.state.loading} />
                </div>
            );
        } else {
            return (
                <div>
                    <button className="uk-button uk-button-default" type="button" uk-toggle={toogle}>Additional Properties</button>
                    <div id={idForEP} className="uk-margin" hidden>

                        <div className="uk-grid container-filter-properties" id={idForFilter}>
                            <div className="uk-width-2-12 filter-label">
                                <form className="uk-form-horizontal">
                                    <label className="uk-form-label-properties-task-filter" htmlFor="property-name-filter-input">Filter</label>
                                    <div className="uk-form-controls-filter"><input className="uk-input" id="property-name-filter-input" type="text" placeholder="Property Name" value={this.state.nameFilter} onChange={this.onChangeNameFilter} /></div>
                                </form>
                            </div>
                            <div className="uk-width-3-12">
                                <input className="uk-input" type="text" placeholder="Content" value={this.state.contentFilter} onChange={this.onChangeContentFilter} />
                            </div>
                        </div>
                        <form className="uk-form-horizontal">
                            {properties}
                        </form>
                    </div>
                    <LoadingBox loading={this.state.loading} />
                </div>
            );
        }
    }
}