const pjson = require('../../package.json');
const preregURL = window.REACT_APP_REGISTRATIONFORM_URL;

export function getCurrentVersionNumber(){
    return pjson.version;
}

export function getCurrentCompanyName(){
    return window.REACT_APP_CUSTOMER_NAME;
}

export function getDefaultPagingNumberOfItems(){
    return pjson.defaultPagingNumberOfItems;
}

export function getEODParameters(){
    return window.REACT_APP_EOD_PARAMETERS;
}

export function getLogoURL(){
    return window.REACT_APP_IMAGES_URL + "logoid.jpg";
}

export function getPreRegURL() {
    return preregURL;
}