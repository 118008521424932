import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { Breadcrumb } from 'antd';
import * as mime from 'mime-types';
import React from 'react';
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { GlobalSystemParametersNames } from '../../../constants/GlobalSystemParametersNames';
import { formatDate, roundAmountByCurrency } from '../../../helpers/FormatHelper';
import { getBeneficiariesByCustomer } from '../../../networking/NetworkingBeneficiaries';
import { getCurrenciesByCustomer, getCurrenciesByStatus } from '../../../networking/NetworkingCurrencies';
import { getAllCurrencyPairs } from '../../../networking/NetworkingCurrencyPairs';
import { getBanksForOneClickCover } from '../../../networking/NetworkingCustomers';
import { checkCustomerCreditLimitMessate, getConfirmation, getDealHeaderInfo, getFullDealHeader, 
    getInwardConfirmation, saveDealHeader, sendDealReportByEmail, validateEntityForReferral, updateDealHeaderStatus, updateForwardMaturityDate, validateCustomerAndContactLimits } from '../../../networking/NetworkingDeals';
import { getParametersByName } from '../../../networking/NetworkingGlobalSystemParameters';
import { getTradingAndCurrencyHolidays } from '../../../networking/NetworkingHolidays';
import { getInstrumentsByCustomer } from '../../../networking/NetworkingInstruments';
import { getPendingEntityTasks } from '../../../networking/NetworkingTasksDefinition';
import { getProfit } from '../../../networking/NetworkingRates';
import DealSearchScreen from '../../deal/DealSearchScreen';
import AlertBox from '../../shared/AlertBox';
import LoadingBox from '../../shared/LoadingBox';
import WizardConfirmation from './WizardConfirmation';
import WizardOutgoingFunds from './WizardOutgoingFunds';
import WizardSearchCustomerForm from './WizardSearchCustomerForm';
import WizardSelectContactForm from './WizardSelectContactForm';
import WizardSettlementForm from './WizardSettlementForm';
import WizardTradeScreen from './WizardTradeScreen';
import WizardUncoveredDeals from './WizardUncoveredDeals';




const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

export const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});
export default class WizardScreen extends React.Component {

    state = {
        alertMessage: '',
        alertTitle: '',
        baseCurrency: localStorage.getItem('BaseCurrency'),
        bankCustomers: [],
        beneficiaryDictionary: {},
        beneficiaryList: [],
        beneficiaryListForSettlement: [],
        currencyDictionary: {},
        currencyPairs: {},
        customerCurrencyDictionary: {},
        dealsToCover: [],
        disbursements: [],
        holidays: [],
        incomingInstrumentList: [],
        loading: false,
        objCustomer: {},
        objContact: {},
        objDealHeader: {},
        outgoingInstrumentList: [],
        paymentCurrencies: [],
        settlementCurrencies: [],
        incomingFunds: [], //these are entered by the user in the pop up window of the settlement screen
        showAlert: false,
        saveDealSuccess: false,
        step: 1,
        systemDate: new Date(),
        searchList: [],

        //FileView
        openFile: false,
        currentFileName: '',
        currentFileURList: [],
        fullWidthFile: true,
        maxWidthFile: 'lg',

        //breadcrumb
        dealFromDealSearch: false,
        maxForwardWindow: 90,

        //Funds for referral validation
        fundsForReferralMessage: '',
        showFundsForReferralAlert: false,

        showCustomerPendingTasksMessage: true,

        //limit validation
        showWarningLimitValidation: false,
        limitValidationMessage: '',
        postSaveFuction: undefined,
        dealHeaderAfterValidation: {},
        isBookingNow: false,
        showWarningLimitValidationPending: false,

    }

    componentDidMount() {
        this.setState({ systemDate: new Date(localStorage.getItem('SystemDate')) });
        //Load currency pairs
        getAllCurrencyPairs().then(
            (json) => {
                if (json !== undefined) {
                    let currencyPairs = json.currencyPairs;
                    var helper = {};
                    for (var j = 0; j < currencyPairs.length; j++) {
                        helper[currencyPairs[j].CurrencyCode + currencyPairs[j].OutCurrencyCode] = {};
                        helper[currencyPairs[j].CurrencyCode + currencyPairs[j].OutCurrencyCode].SpotDays = currencyPairs[j].SpotDays;
                        helper[currencyPairs[j].CurrencyCode + currencyPairs[j].OutCurrencyCode].RatePrecision = currencyPairs[j].RatePrecision;
                        helper[currencyPairs[j].CurrencyCode + currencyPairs[j].OutCurrencyCode].MarginError = currencyPairs[j].MarginError;
                    }
                    this.setState({ currencyPairs: helper });
                }
            }
        );

        //Load holidays
        let startDate = new Date();
        var endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + 90);
        getTradingAndCurrencyHolidays(startDate.toISOString(), endDate.toISOString()).then(
            (json) => {
                let holidays = json.holidays;
                if (holidays !== undefined) {
                    this.setState({ holidays: holidays });
                }
            }
        );

        //Load all currencies
        getCurrenciesByStatus('').then(
            (json) => {
                var currencyDictionary = {};
                let currencies = json.currencies;
                for (var i = 0; i < currencies.length; i++) {
                    currencyDictionary[currencies[i].CurrencyID] = currencies[i];
                }
                this.setState({ currencyDictionary: currencyDictionary });
            }
        );

        //Banks for one click cover
        getBanksForOneClickCover().then(
            (result) => {
                //alert(JSON.stringify(result));
                var bankCustomers = [];
                if (result != undefined && result.Customers != undefined) {
                    bankCustomers = result.Customers;
                    bankCustomers.forEach(element => {
                        element.value = element.CustomerID;
                        element.name = element.Name;
                    });
                }
                this.setState({ bankCustomers: bankCustomers });
            }
        );
        getParametersByName(GlobalSystemParametersNames.MaximumOpenForwardWindow).then(
            (response) => {
                console.log(response)
                if (response != null && response.parameters != null && response.parameters.length > 0) {
                    this.setState({ maxForwardWindow: parseInt(response.parameters[0].Value) });
                }
            }
        );
    }

    onClickStepTab = (value) => {
        switch (value) {
            case 1:
                this.setState({ step: value, showCustomerPendingTasksMessage: true });
                break;
            case 2:
                //Customers and contacts
                this.setState({ step: value });
                break;
            case 3:
                //Trade
                if ([4, 5].includes(Number(this.state.step))) {
                    this.setState({ step: value });
                }
                break;
            case 4:
                //Disbursements
                if (Number(this.state.step) === 5) {
                    this.setState({ step: value });
                }
                break;
            default:
                this.setState({ step: value });
                break;
        }
    }

    ///Customer screen
    handleCustomerSelection = async (obj, nextStep, loadCurrenciesCallback) => {
        //alert(JSON.stringify(obj));
        //Show status warning
        if (obj.Status !== 'Authorized to deal') {
            nextStep = 1;
            this.setState({
                alertMessage: 'Customer is in ' + obj.Status + ' status. Deals cannot be created or edited under this customer.',
                alertTitle: 'Warning',
                showAlert: true,
            });
        }

        //Verify pending entity tasks over the deadline
        if (obj.Status === 'Authorized to deal' && this.state.showCustomerPendingTasksMessage) {
            let applicationDate = await localStorage.getItem('SystemDate');
            getPendingEntityTasks('Customer', obj['Customer ID'], applicationDate, true).then(
                (response) => {
                    if (response.tasks !== undefined) {
                        var tasks = '';
                        response.tasks.forEach(element => {
                            tasks = element.Title + ', ';
                        });
                        if (tasks !== '') {
                            tasks = tasks.substring(0, tasks.length - 2);
                        }
                        this.setState({
                            alertTitle: 'Warning',
                            alertMessage: 'The selected customer has pending mandatory tasks (' + tasks + '). New deals will be created in Pending status.',
                            showAlert: true,
                        });
                    }
                }
            );
        }


        //Get customer instruments
        getInstrumentsByCustomer(obj['Customer ID']).then(
            (json) => {
                let jsonInstruments = json.instruments;
                if (jsonInstruments !== undefined) {
                    var incomingHelper = [];
                    var outgoingHelper = [];
                    for (var i = 0; i < jsonInstruments.length; i++) {
                        var instrumentElement = {
                            value: jsonInstruments[i].Instrument,
                            name: jsonInstruments[i].Instrument
                        };
                        //alert(JSON.stringify(jsonInstruments));
                        instrumentElement.deliveryMethods = jsonInstruments[i].DeliveryMethods;
                        if (jsonInstruments[i].InstrumentType === 'Incoming') {
                            incomingHelper.push(instrumentElement);
                        }
                        else {
                            instrumentElement.accountsOnly = jsonInstruments[i].AccountsOnly;
                            outgoingHelper.push(instrumentElement);
                        }
                    }
                    this.setState({
                        incomingInstrumentList: incomingHelper,
                        outgoingInstrumentList: outgoingHelper,
                    });
                }
            }
        );

        //Get customer currencies
        getCurrenciesByCustomer(obj['Customer ID']).then(
            (json) => {
                let jsonCurrencies = json.currencies;
                var paymentHelper = [];
                var settlementHelper = [];
                var customerCurrencyDictionary = {};
                if (jsonCurrencies !== undefined) {
                    for (var i = 0; i < jsonCurrencies.length; i++) {
                        var currencyElement = {
                            value: jsonCurrencies[i].CurrencyID,
                            name: jsonCurrencies[i].CurrencyIDName,
                            numberOfDecimals: jsonCurrencies[i].NumberOfDecimals,
                        };
                        switch (jsonCurrencies[i].Type) {
                            case 'Payment':
                                paymentHelper.push(currencyElement);
                                break;
                            case 'Settlement':
                                settlementHelper.push(currencyElement);
                                break;
                            default:
                                break;
                        }
                        var dictionaryElement = { numberOfDecimals: jsonCurrencies[i].NumberOfDecimals };
                        customerCurrencyDictionary[jsonCurrencies[i].CurrencyID] = dictionaryElement;
                    }
                }

                this.setState({
                    paymentCurrencies: paymentHelper,
                    settlementCurrencies: settlementHelper,
                    customerCurrencyDictionary: customerCurrencyDictionary,
                });

                if (loadCurrenciesCallback !== undefined) {
                    loadCurrenciesCallback();
                }
            }
        );

        //Get beneficiaries by customer
        getBeneficiariesByCustomer(obj["Customer ID"], 0).then(
            (json) => {
                var helper = [];
                var helperDictionary = {};
                var helperForSettlement = [];

                var tbaRecord = { value: -2, name: 'TBA', BeneficiaryID: -2 };
                helper.push(tbaRecord);
                helperDictionary[-2] = tbaRecord;

                var customerRecord = { value: -1, name: obj["Customer Name"], BeneficiaryID: -1 };
                helper.push(customerRecord);
                helperDictionary[-1] = customerRecord;

                if (json.beneficiaries !== undefined) {
                    for (var i = 0; i < json.beneficiaries.length; i++) {
                        json.beneficiaries[i].value = json.beneficiaries[i].BeneficiaryID;
                        json.beneficiaries[i].name = json.beneficiaries[i].Description;
                        helper.push(json.beneficiaries[i]);
                        helperDictionary[json.beneficiaries[i].BeneficiaryID] = json.beneficiaries[i];

                        if (json.beneficiaries[i].PaymentFlow !== undefined) {
                            if (json.beneficiaries[i].PaymentFlow !== 'Pays to' && json.beneficiaries[i].PaymentFlow !== null && json.beneficiaries[i].PaymentFlow !== '') {
                                helperForSettlement.push(json.beneficiaries[i]);
                            }
                        }
                    }
                }

                this.setState({
                    beneficiaryList: helper,
                    beneficiaryDictionary: helperDictionary,
                    beneficiaryListForSettlement: helperForSettlement,
                });
            }
        );

        if (nextStep === 2) {
            this.setState({
                step: nextStep,
                objCustomer: obj,
                objDealHeader: {},
                disbursements: [],
                incomingFunds: []
            });
        }
    }

    //Contacts screen
    handleContactSelection = (obj) => {
        this.setState({
            step: 3,
            objContact: obj,
            objDealHeader: {},
        });
    }

    //Update deal header object
    handleDealHeaderUpdate(obj, callback) {
        var disbursements = this.state.disbursements;
        if (obj.OutgoingFunds !== undefined) {
            let outgoingFundsListAux = [];
            obj.OutgoingFunds.forEach(outgoing => {
                if (outgoing.Status !== 'Void') {
                    outgoingFundsListAux.push(outgoing);
                }
            });
            disbursements = outgoingFundsListAux;
        }

        let incomingFundsTemp = (obj.IncomingFunds || [])//this.state.incomingFunds
        if (obj.DealHeaderID !== this.state.objDealHeader.DealHeaderID) {
            incomingFundsTemp = []
        }

        this.setState({
            objDealHeader: obj,
            disbursements: disbursements,
            incomingFunds: incomingFundsTemp
        }, () => {
            if (callback !== undefined) {
                callback();
            }
        });
    }

    //Get confirmation
    getConfirmation = (dealHeaderID) => {
        this.setState({ loading: true });
        getConfirmation(dealHeaderID).then((value) => {
            if (value !== undefined) {
                let fileName = 'Deal Confirmation ' + dealHeaderID + '.pdf';
                var byteCharacters = atob(value.data);
                var byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                var bytes = new Uint8Array(byteNumbers);
                var blob = new Blob([bytes], { type: mime.lookup(fileName) });

                /*var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = fileName;
                link.click();*/

                let fileList = [{
                    uri: window.URL.createObjectURL(blob),
                    fileType: 'pdf',
                    fileName: fileName
                }];

                this.setState({
                    currentFileName: fileName,
                    currentFileURList: fileList,
                    openFile: true,
                });
            }
            this.setState({ loading: false });
        });
    }

    //Get Inward confirmation
    getInwardConfirmation = (dealHeaderID) => {
        this.setState({ loading: true });
        getInwardConfirmation(dealHeaderID).then((value) => {
            if (value !== undefined) {
                let fileName = 'Inward Funds Confirmation ' + dealHeaderID + '.pdf';
                var byteCharacters = atob(value.data);
                var byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                var bytes = new Uint8Array(byteNumbers);
                var blob = new Blob([bytes], { type: mime.lookup(fileName) });

                let fileList = [{
                    uri: window.URL.createObjectURL(blob),
                    fileType: 'pdf',
                    fileName: fileName
                }];

                this.setState({
                    currentFileName: fileName,
                    currentFileURList: fileList,
                    openFile: true,
                });
            }
            this.setState({ loading: false });
        });
    }

    updateForwardDate = (objDealHeader,maturityDate, fromBookNow= true) => {
        let model = {
            DealHeaderID: objDealHeader.DealHeaderID,
            FarDate: maturityDate,
            UpdatedBy: localStorage.getItem('UserID'),
            UpdateToken: objDealHeader.UpdateToken,
            RateQuoteID: objDealHeader.RateQuoteID
        }
        updateForwardMaturityDate(model).then(
            (json) => {
                var alertMessage = '', alertTitle = '';
                switch (json.httpStatusCode) {
                    case 200:
                        {
                            let showAlert = false;
                            if (fromBookNow)
                            {
                            alertTitle = 'Success';
                            alertMessage = 'Deal ' + objDealHeader.DealHeaderID + ' has been saved';
                            showAlert = true;
                            }
                            objDealHeader.UpdateToken = json.updateToken;
                            objDealHeader.MaturityDate = maturityDate;
                            
                            this.setState({
                                alertTitle: alertTitle,
                                alertMessage: alertMessage,
                                showAlert: showAlert,
                                loading: false,
                            }, () => {
                                this.handleDealHeaderUpdate(objDealHeader);
                            });
                            break;
                        }
                    default:
                        {
                            alertMessage = 'Deal not saved! Please try again or contact administrator.';
                            alertTitle = 'Error';

                             if (json.Message !== undefined) {
                                 switch (json.Message) {
                                     case 'concurrency':
                                         {
                                             alertMessage = 'Please reload the information and try again. Otherwise contact your supervisor';
                                             break;
                                         }
                                     default:
                                         {
                                             alertMessage = alertMessage + ' (' + json.Message + ')';
                                             break;
                                         }
                                 }
                             } else {
                                 if (json.httpErrorMessage !== undefined) {
                                     alertTitle = 'Error';
                                     alertMessage = json.httpErrorMessage;
                                 }
                             }
                             this.setState({
                                alertTitle: alertTitle,
                                alertMessage: alertMessage,
                                showAlert: true,
                                loading: false,
                            });
                             break;
                         }
                 }
            }
        );
    }


    handleNextInTradeScreen = (obj) => {
        var step = 4;
        switch (obj.DealType) {
            case 1:
            case 2:
                step = 4;//Disbursements
                break;
            case 4:
            case 6:
                if (obj.DealType === 4 && obj.ForwardType === 'Closed') {
                    step = 4;//Disbursements
                }
                else {
                    step = 5;//Settlement
                }

                break;
            default:
                step = 4;
                break;
        }
        this.setState({ step: step, objDealHeader: obj });
    }

    handleBackInDisbursementScreen = (disbursements) => {
        let objDealHeader = this.updateDealFees(disbursements);
        this.setState({
            objDealHeader: objDealHeader,
            disbursements: disbursements,
            step: 3,
        });
    }

    handleNextInDisbursementScreen = (disbursements) => {
        let objDealHeader = this.updateDealFees(disbursements);
        this.setState({
            objDealHeader: objDealHeader,
            disbursements: disbursements,
            step: 5,
        });
    }

    clearDisbursements = () => {
        this.setState({ disbursements: [] });
    }

    updateDealFees(disbursements) {
        let relatedWireFeeID = this.state.objCustomer['RelatedWireFeeID$'];
        var objDealHeader = this.state.objDealHeader;
        var dealFees = this.state.objDealHeader.DealFees;
        var totalFees = this.state.objDealHeader.TotalFees;

        var wireFeeSide = 'Buy';
        if (this.state.objCustomer['WireFeeSide$'] !== null) {
            wireFeeSide = this.state.objCustomer['WireFeeSide$'];
        }

        //Calculate total sell side fees
        var totalWireFess = 0;
        if (wireFeeSide === 'Sell') {
            for (var j = 0; j < disbursements.length; j++) {
                totalWireFess += disbursements[j].WireItemFee;
            }
        }
        
        if (this.state.objCustomer.FeeStructureID !== null && relatedWireFeeID !== null) {
            totalFees = 0;
            for (var i = 0; i < dealFees.length; i++) {
                if (Number(dealFees[i].FeeStructureID) === Number(relatedWireFeeID)) {
                    var totalDisbursementsFees = 0;
                    for (let j = 0; j < disbursements.length; j++) {
                        totalDisbursementsFees += disbursements[j].WireItemFee;
                    }
                    dealFees[i].FeeAmount = totalDisbursementsFees;
                }
                totalFees += dealFees[i].FeeAmount;
            }
        }
        else {
            //It is not necessary for the client to have a FeeStructureID assigned, the trader can enter a value
            totalFees = 0;
            let totalDisbursementsFees = 0;
            for (let j = 0; j < disbursements.length; j++) {
                totalDisbursementsFees += disbursements[j].WireItemFee;
            }
            totalFees = totalDisbursementsFees
        }
        objDealHeader.DealFees = dealFees;
        objDealHeader.TotalFees = roundAmountByCurrency(totalFees, objDealHeader.BuyCurrency);
        objDealHeader.SellSideFess = roundAmountByCurrency(totalWireFess, objDealHeader.SellCurrency);
        return objDealHeader;
    }

    handleBackInSettlementScreen = () => {
        var step = 4;
        switch (this.state.objDealHeader.DealType) {
            case 1:
            case 2:
                step = 4;//Disbursements
                break;
            case 6:
            case 4:
                step = 3;//Trade
                break;
            default:
                step = 4;
                break;
        }
        this.setState({ step: step });
    }

    handleSelectCustomerTab = () => {
        this.setState({
            step: 1,
            objDealHeader: {},
            disbursements: [],
            incomingFunds: []
        });
    }

    handleSelectContactTab = () => {
        this.setState({
            step: 2,
            objDealHeader: {},
            disbursements: [],
            incomingFunds: []
        });
    }

    dealStatusWasUpdated = (status) => {
        var objDealHeader = this.state.objDealHeader;
        objDealHeader.Status = status;
        this.setState({ objDealHeader: objDealHeader });
    }

    handleNewDeal = () => {
        this.setState({
            step: 3,
            showCustomerPendingTasksMessage: true,
            objDealHeader: {},
            disbursements: [],
            incomingFunds: []
        });
    }

    sendConfirmationMail = (dealHeaderID) => {
        getDealHeaderInfo(dealHeaderID).then(
            (json) => {
                let emails = json.dealHeaderInfo[0].Email.split(';');

                let sendDealReportByEmailRequest = {
                    DealHeaderID: dealHeaderID,
                    Language: json.dealHeaderInfo[0].PreferredLanguage,
                    SendTrader: true
                }

                let DealMailInfo = [];

                emails.forEach((value) => {
                    DealMailInfo.push({
                        MainInfo: value,
                        DocType: 'Confirmation',
                        MobilePhone: this.state.objCustomer['Mobile Phone']
                    });
                });

                sendDealReportByEmailRequest.DealMailInfo = DealMailInfo;
                sendDealReportByEmail(sendDealReportByEmailRequest);
            }
        );
    }

    handleSaveTradeInSettlementScreen = async(obj, isBookingNow, postFuction) => {
        this.setState({ loading: true, showCustomerPendingTasksMessage: false, showAlert:false });
        var objDealHeader = this.state.objDealHeader;

        if (isBookingNow) {
            objDealHeader = obj;
        }

        if(objDealHeader.CoveredDeals !== undefined && objDealHeader.CoveredDeals !== "")
        {
            this.saveTradeInSettlementScreen(obj, isBookingNow, postFuction);
            return;
        }


        const json =validateCustomerAndContactLimits(objDealHeader.DealType, objDealHeader.BuyAmount, objDealHeader.BuyCurrency, this.state.objCustomer['Customer ID'], this.state.objContact['Contact ID']).then(
            (json) => {
                var alertMessage = '', alertTitle = '';
                if (json.httpStatusCode) {
                            let showAlert = false;
                            if(json.ValidationMessage === '')
                            {
                                this.saveTradeInSettlementScreen(obj, isBookingNow, postFuction);
                            }
                            else
                            {
                                if (json.LimitValidationStatus.toLowerCase() === 'warning')
                                {
                                    alertTitle = 'Warning';
                                    alertMessage = json.ValidationMessage;

                                    this.setState({
                                        limitValidationMessage: json.ValidationMessage,
                                        showWarningLimitValidation: true,
                                        loading: false,
                                        postSaveFuction: postFuction,
                                        dealHeaderAfterValidation: obj,
                                        isBookingNow: isBookingNow
                                    });
                                } 
                                else
                                {
                                    showAlert = true;
                                    this.setState({
                                        alertTitle: 'Error',
                                        alertMessage: json.ValidationMessage,
                                        showAlert: showAlert,
                                        loading: false,
                                    });
                                }
                            }
                        }
                    else
                        {
                            alertMessage = 'Deal not saved! Please try again or contact administrator.';
                            alertTitle = 'Error';

                             if (json.Message !== undefined) {
                                 switch (json.Message) {
                                     case 'concurrency':
                                         {
                                             alertMessage = 'Please reload the information and try again. Otherwise contact your supervisor';
                                            
                                         }
                                     default:
                                         {
                                             alertMessage = alertMessage + ' (' + json.Message + ')';
                                            
                                         }
                                 }
                             } else {
                                 if (json.httpErrorMessage !== undefined) {
                                     alertTitle = 'Error';
                                     alertMessage = json.httpErrorMessage;
                                 }
                             }
                             this.setState({
                                alertTitle: alertTitle,
                                alertMessage: alertMessage,
                                showAlert: true,
                                loading: false,
                            });
                         
                 }
            }
        );   
    }

    getProfit = async (rateQuoteId, dealType, buyCurrency, sellCurrency, buyAmount, sellAmount, valueDate, maturityDate, totalFees, customerRate, marketRate) => {
        let profitAmount = 0;
        await getProfit(rateQuoteId,
            dealType,
            sellCurrency,
            buyCurrency,
            sellAmount,
            buyAmount,
            [2, 4].includes(dealType) ? maturityDate : valueDate,
            totalFees,
            customerRate,
            marketRate).then(
                (json) => {
                    profitAmount = json.Profit;
                })
        return profitAmount
    }


    saveTradeInSettlementScreen = async(obj, isBookingNow, postFuction) => {

        let objDealHeader = this.state.objDealHeader;
        if (isBookingNow) {
            objDealHeader = obj;
        }

        objDealHeader.BuyAmount = obj.BuyAmount;
        objDealHeader.ConfirmedByClient = obj.ConfirmedByClient;
        objDealHeader.CreatedBy = localStorage.getItem('UserID');
        objDealHeader.CustomerRate = obj.CustomerRate;
        objDealHeader.CustomerRefNo = obj.CustomerRefNo;
        objDealHeader.DepositID = obj.DepositID;
        objDealHeader.EntityProperties = obj.EntityProperties;
        objDealHeader.EntityPropertiesJson = obj.EntityPropertiesJson;
        objDealHeader.ExternalComments = obj.ExternalComments;
        objDealHeader.ExternalInterface = obj.ExternalInterface;
        objDealHeader.ExternalInterfaceType = obj.ExternalInterfaceType;
        //objDealHeader.IncomingFunds = [];
        objDealHeader.InternalComments = obj.InternalComments;
        objDealHeader.LockedSide = obj.LockedSide;
        objDealHeader.MarketRate = obj.MarketRate;
        objDealHeader.NostroAccountID = obj.NostroAccountID;
        objDealHeader.NostroDescription = obj.NostroDescription;
        objDealHeader.OutgoingFunds = this.state.disbursements;
        objDealHeader.ProfitAmount = obj.ProfitAmount;
        objDealHeader.RateQuoteID = obj.RateQuoteID;
        objDealHeader.SellAmount = obj.SellAmount;
        objDealHeader.Status = obj.Status;
        if (objDealHeader.SwapNearLegDealHeader !== undefined
            && objDealHeader.SwapNearLegDealHeader !== null
            && objDealHeader.SwapNearLegDealHeader.ValueDate !== undefined) {
            objDealHeader.SwapNearLegDealHeader.ValueDate = formatDate(objDealHeader.SwapNearLegDealHeader.ValueDate);
        }
        if (objDealHeader.SwapFarLegDealHeader !== undefined
            && objDealHeader.SwapFarLegDealHeader !== null
            && objDealHeader.SwapFarLegDealHeader.ValueDate !== undefined) {
            objDealHeader.SwapFarLegDealHeader.ValueDate = formatDate(objDealHeader.SwapFarLegDealHeader.ValueDate);
        }
        objDealHeader.UpdatedBy = localStorage.getItem('UserID');        
        objDealHeader.BankReferenceForCoverDeal = obj.BankReferenceForCoverDeal;

        if (obj.SettlementInstrument !== undefined && obj.SettlementInstrument !== '' )
        {
            objDealHeader.SettlementInstrument = obj.SettlementInstrument;
            objDealHeader.SettlementDeliveryMethods = obj.SettlementDeliveryMethod;
        }

        //incoming funds for new deal
        let allowAdditionalSettlements = localStorage.getItem(GlobalSystemParametersNames.TradeScreenAllowAdditionalSettlements) === 'true'
        //TODO: deal edition

        if (obj.SettlementInstrument !== undefined && obj.SettlementInstrument !== '' && Number(objDealHeader.DealHeaderID) === 0) {   
            //just Direct Debit / ACH 
            if(obj.SettlementInstrument === 'Direct Debit' && obj.SettlementDeliveryMethod === 'ACH')
            {
                objDealHeader.IncomingFunds.push({
                    Amount: obj.BuyAmount,
                    BankAccountID: obj.BankAccountID,
                    BankAccountDescription: obj.BankAccountDescription,
                    BeneficiaryID: obj.BeneficiaryID,
                    BeneficiaryName: obj.BeneficiaryName,
                    IncomingFundsID: 0,
                    NostroAccount: obj.NostroAccountID,
                    SettlementDeliveryMethod: obj.SettlementDeliveryMethod,
                    SettlementInstrument: obj.SettlementInstrument,
                    ThirdPartyBeneficiaryPayment: obj.ThirdPartyBeneficiaryPayment,
                });
            }
            else
            {
            if (this.state.incomingFunds.length > 0) { //this are added in 'Additional Settlements Pop up' 
                this.state.incomingFunds.forEach(x => {
                    objDealHeader.IncomingFunds.push({
                        Amount: x.Amount,
                        BankAccountID: obj.BankAccountID,
                        BankAccountDescription: obj.BankAccountDescription,
                        BeneficiaryID: obj.BeneficiaryID,
                        BeneficiaryName: obj.BeneficiaryName,
                        IncomingFundsID: x.IncomingFundsID,
                        NostroAccount: x.NostroAccount,
                        SettlementDeliveryMethod: obj.SettlementDeliveryMethod,
                        SettlementInstrument: obj.SettlementInstrument,
                        ThirdPartyBeneficiaryPayment: obj.ThirdPartyBeneficiaryPayment,
                    });
                })
                if (objDealHeader.TotalFees > 0) {
                    objDealHeader.IncomingFunds.push({
                        Amount: Number(objDealHeader.TotalFees),
                        BankAccountID: obj.BankAccountID,
                        BankAccountDescription: obj.BankAccountDescription,
                        BeneficiaryID: obj.BeneficiaryID,
                        BeneficiaryName: obj.BeneficiaryName,
                        IncomingFundsID: obj.IncomingFundsID,
                        SettlementDeliveryMethod: obj.SettlementDeliveryMethod,
                        SettlementInstrument: obj.SettlementInstrument,
                        ThirdPartyBeneficiaryPayment: obj.ThirdPartyBeneficiaryPayment,
                    });
                }
            }
            else {
                //add the incoming just if it is in the default settlement mode
                if (!allowAdditionalSettlements) {
                    objDealHeader.IncomingFunds.push({
                        Amount: roundAmountByCurrency((Number(objDealHeader.BuyAmount) + Number(objDealHeader.TotalFees)), objDealHeader.BuyCurrency),
                        BankAccountID: obj.BankAccountID,
                        BankAccountDescription: obj.BankAccountDescription,
                        BeneficiaryID: obj.BeneficiaryID,
                        BeneficiaryName: obj.BeneficiaryName,
                        IncomingFundsID: obj.IncomingFundsID,
                        SettlementDeliveryMethod: obj.SettlementDeliveryMethod,
                        SettlementInstrument: obj.SettlementInstrument,
                        ThirdPartyBeneficiaryPayment: obj.ThirdPartyBeneficiaryPayment,
                    });
                }
            }
        }
        } else if (obj.SettlementInstrument !== undefined && obj.SettlementInstrument !== '' && Number(objDealHeader.DealHeaderID) > 0) { //edition            
            let incomingFundsLocal = this.state.incomingFunds || []            
            if (incomingFundsLocal.length === 0) {
                let defaultIncoming = {
                    Amount: roundAmountByCurrency((Number(objDealHeader.BuyAmount) + Number(objDealHeader.TotalFees)), objDealHeader.BuyCurrency),
                    BankAccountID: obj.BankAccountID,
                    BankAccountDescription: obj.BankAccountDescription,
                    BeneficiaryID: obj.BeneficiaryID,
                    BeneficiaryName: obj.BeneficiaryName,
                    IncomingFundsID: 0,
                    SettlementDeliveryMethod: obj.SettlementDeliveryMethod,
                    SettlementInstrument: obj.SettlementInstrument,
                    ThirdPartyBeneficiaryPayment: obj.ThirdPartyBeneficiaryPayment,
                }
                incomingFundsLocal.push(defaultIncoming)
            }
            else if (incomingFundsLocal.length === 1) {
                incomingFundsLocal[0].Amount = obj.BuyAmount
                incomingFundsLocal[0].BankAccountID = obj.BankAccountID
                incomingFundsLocal[0].BankAccountDescription = obj.BankAccountDescription
                incomingFundsLocal[0].BeneficiaryID = obj.BeneficiaryID
                incomingFundsLocal[0].BeneficiaryName = obj.BeneficiaryName
                incomingFundsLocal[0].SettlementDeliveryMethod = obj.SettlementDeliveryMethod
                incomingFundsLocal[0].SettlementInstrument = obj.SettlementInstrument
                incomingFundsLocal[0].ThirdPartyBeneficiaryPayment = obj.ThirdPartyBeneficiaryPayment
            }//TODO: for more than one incoming user has to edit in the Additional Settlements
            else {
                let totalIncomingAmount = incomingFundsLocal.reduce((x, y) => x + y.Amount, 0)
                if (Number(totalIncomingAmount !== Number(obj.BuyAmount))) {
                    this.setState({
                        loading: false,
                        alertTitle: 'Error',
                        alertMessage: 'Please check the total settlement amount: ' + totalIncomingAmount + ', it does not match the buy amount:' + obj.BuyAmount,
                        showAlert: true,
                    })
                    return
                }
                //new ones 
                let newIncomings = incomingFundsLocal.filter(x => (x.IncomingFundsID || 0) === 0) || []
                newIncomings.forEach(x => {
                    x.BankAccountID = obj.BankAccountID
                    x.BankAccountDescription = obj.BankAccountDescription
                    x.BeneficiaryID = obj.BeneficiaryID
                    x.BeneficiaryName = obj.BeneficiaryName
                    x.IncomingFundsID = 0
                    x.SettlementDeliveryMethod = obj.SettlementDeliveryMethod
                    x.SettlementInstrument = obj.SettlementInstrument
                    x.ThirdPartyBeneficiaryPayment = obj.ThirdPartyBeneficiaryPayment
                })

            }
            objDealHeader.IncomingFunds = incomingFundsLocal
        }

        if ([2, 4].includes(Number(objDealHeader.DealType))) {
            objDealHeader.MaturityDate = formatDate(objDealHeader.MaturityDate);
        }
        objDealHeader.ValueDate = formatDate(objDealHeader.ValueDate);

        //let newJson = await saveDealHeader(objDealHeader);

        if (isBookingNow === null) {
            objDealHeader.AdjustmentDealID = null;
        }

        let objDealHeaderForAcceptedReferral = objDealHeader;
        
        let rProfitAmount = await this.getProfit(objDealHeader.RateQuoteID, objDealHeader.DealType, objDealHeader.BuyCurrency, objDealHeader.SellCurrency, 
            objDealHeader.BuyAmount, objDealHeader.SellAmount, objDealHeader.ValueDate, objDealHeader.MaturityDate, 
            objDealHeader.TotalFees, objDealHeader.CustomerRate, objDealHeader.MarketRate);
        
        rProfitAmount = Number(roundAmountByCurrency(rProfitAmount, this.state.baseCurrency))                
        objDealHeader.ProfitNotification = ''
        let profitMessageOverriden = ''
        if (rProfitAmount !== Number(objDealHeader.ProfitAmount) && [1, 2, 4].includes(objDealHeader.DealType)) {
            profitMessageOverriden = 'Please note that the profit has been updated from ' + objDealHeader.ProfitAmount + ' to ' + rProfitAmount
            objDealHeader.ProfitAmount = rProfitAmount
            this.setState({
                showAdditionalAlert: true,
                additionalAlertTitle: 'Profit Notification',
                additionalAlertMessage: profitMessageOverriden
            })
            objDealHeader.ProfitNotification = profitMessageOverriden + '</br> customer rate: ' + objDealHeader.CustomerRate + '</br> market rate: ' + objDealHeader.MarketRate + '</br> quote id: ' + objDealHeader.RateQuoteID
        }

        saveDealHeader(objDealHeader).then(
            (json) => {
            let alertMessage = '', alertTitle = '', additionalMessage = '';
                switch (json.httpStatusCode) {
                    case 200:
                        {
                            //External interface parameters
                            if (json.ExternalInterfaceTradeID !== null) {
                                additionalMessage = objDealHeader.ExternalInterface + ' transaction details: ID -> ' + json.ExternalInterfaceTradeID + '. Ref Number -> ' + json.ExternalInterfaceTradeNumber;
                            }
                            objDealHeader.ProfitNotification = ''
                            objDealHeader.DealHeaderID = json.DealHeaderID;
                            objDealHeader.Status = json.Status;
                            if (objDealHeader.Status === 'Accepted') {
                                alertMessage = 'Deal ' + json.DealHeaderID + ' was committed!';
                                
                                if(objDealHeader.JsonProperties && objDealHeader.JsonProperties !== '')
                                {                                  

                                    let properties = JSON.parse(objDealHeader.JsonProperties);
                                    if(properties)
                                    {
                                        if(properties['nium_hold_expiry_at'])
                                        {
                                            alertMessage = alertMessage+ '. Nium Rate expires on '+ properties['nium_hold_expiry_at']
                                        }
                                    }
                                }
                                if(json.CoverDealID > 0)
                                {
                                    let coverDealMessage = `. Also the Cover Deal with number ${json.CoverDealID} was automatically created.`;

                                    alertMessage = alertMessage+ ' '+ coverDealMessage
                                }

                                if (json.ExternalInterfaceTradeID !== null) {
                                    alertMessage = alertMessage + ' ' + objDealHeader.ExternalInterface + ', transaction details: ID -> ' + json.ExternalInterfaceTradeID + '. Ref Number -> ' + json.ExternalInterfaceTradeNumber;
                                }
                                
                                let zeptoMessage = json.ZeptoErrorMessage || '';
                                if (zeptoMessage.length > 0) {
                                    alertMessage = alertMessage + ", Zepto API Message : " + zeptoMessage                                        
                                }                                

                            } else {
                                var messagePending = '';
                                if (json.MessagePending != undefined && json.MessagePending != '') {
                                    messagePending = json.MessagePending;
                                }
                                alertMessage = 'Deal ' + json.DealHeaderID + ' has been saved in ' + json.Status + ' status! ' + messagePending;

                                if (json.ComplianceRules !== undefined && json.ComplianceRules !== null
                                    && Array.isArray(json.ComplianceRules) && json.ComplianceRules.length > 0) {
                                    alertMessage = alertMessage + ", reasons: " + json.ComplianceRules.join()
                                }                                
                            }
                            
                            var checkCreditLimit = false;
                            var nextStep = 6;
                            if (isBookingNow) {
                                nextStep = this.state.step;
                            }
                            if (isBookingNow) {
                                let objDealHeader = { DealNo: json.DealHeaderID };
                                this.loadDeal(objDealHeader);
                            }
                            if (postFuction !== undefined) {
                                postFuction();
                            }

                            /*if (obj.ConfirmedByClient) {
                                this.sendConfirmationMail(json.DealHeaderID);
                            }*/

                            if (json != undefined && json.TasksOverDealLine === "yes") {
                                
                                //task pending, so stop here
                                let message = "Customer has pending mandatory tasks. Deal was saved in Pending status"
                                if (isBookingNow === null) {
                                    this.setState({ loading: false, showPendingTasksAlert: false });
                                } else if (isBookingNow) {
                                    this.setState({
                                        loading: false,
                                        alertTitle: 'Success',
                                        alertMessage: message,
                                        showAlert: true,
                                        successAlert: true,
                                        showPendingTasksAlert: false
                                    });
                                }
                                else {
                                    this.setState({
                                        objDealHeader: objDealHeader,
                                        step: nextStep,
                                        loading: false,
                                        alertTitle: 'Success',
                                        alertMessage: message,
                                        showAlert: true,
                                        showPendingTasksAlert: false
                                    });
                                }
                            }
                            else if (json != undefined && (json.LimitProfileValidationMessage || '') !== '') {
                                //this is a logic for the acceptance of the deal. Reusing for Compliance                                
                                let tempMessage = 'The Profile Limit has been violated. ' + json.LimitProfileValidationMessage
                                let questionMessage = 'Do you want to save the deal in Accepted status?'
                                var newFundForReferralMessage = <span> {tempMessage} <br /> {questionMessage} </span>
                                this.setState({
                                    alertMessage: alertMessage,
                                    alertTitle: 'Success',
                                    loading: false,
                                    fundsForReferralMessage: newFundForReferralMessage,
                                    showFundsForReferralAlert: true,
                                    showAlert: false,
                                    objDealHeaderForAcceptedReferral: objDealHeaderForAcceptedReferral,
                                    nextStep: nextStep,
                                    isBookingNow: isBookingNow
                                });
                            }
                            else if (json != undefined && json.ComplianceSoftRules && Array.isArray(json.ComplianceSoftRules) && json.ComplianceSoftRules.length > 0) {
                                
                                let tempMessage = 'Your Deal needs an additional revision from Compliance.' + ", reasons: " + json.ComplianceSoftRules.join()
                                let questionMessage = 'Do you want to save the deal in Accepted status?'
                                var newFundForReferralMessage = <span> {tempMessage} <br /> {questionMessage} </span>
                                this.setState({
                                    alertMessage: alertMessage,
                                    alertTitle: 'Success',
                                    loading: false,
                                    fundsForReferralMessage: newFundForReferralMessage,
                                    showFundsForReferralAlert: true,
                                    showAlert: false,
                                    objDealHeaderForAcceptedReferral: objDealHeaderForAcceptedReferral,
                                    nextStep: nextStep,
                                    isBookingNow: isBookingNow
                                });
                            }
                            else
                            {                   
                                getParametersByName(GlobalSystemParametersNames.EnforceCreditLimits).then(
                                    (response) => {
                                        if (typeof response !== undefined && typeof response.parameters !== undefined && response.parameters.length > 0) {
                                            checkCreditLimit = response.parameters[0].Value;
                                        }
                                        if (checkCreditLimit) {
                                            checkCustomerCreditLimitMessate(objDealHeader.DealHeaderID).then(
                                                (json) => {
                                                    if (json !== undefined && json.httpStatusCode !== undefined && json.httpStatusCode === 200) {
                                                        alertMessage += '\n' + json.response;
                                                    }
                                                    if (isBookingNow === null) {
                                                        
                                                        this.setState({ loading: false });
                                                    } else if (isBookingNow) {
                                                        
                                                        this.setState({
                                                            loading: false,
                                                            alertTitle: 'Success',
                                                            alertMessage: alertMessage,
                                                            showAlert: true,
                                                            successAlert: true
                                                        });
                                                    }
                                                    else {
                                                        
                                                        this.setState({
                                                            objDealHeader: objDealHeader,
                                                            step: nextStep,
                                                            loading: false,
                                                            alertTitle: 'Success',
                                                            alertMessage: alertMessage,
                                                            showAlert: true
                                                        });
                                                    }
                                                }
                                            );
                                        } else {
                                            if (isBookingNow === null) {
                                                
                                                this.setState({ loading: false });                                                
                                            } else if (isBookingNow) {
                                                
                                                this.setState({
                                                    loading: false,
                                                    alertTitle: 'Success',
                                                    alertMessage: alertMessage,
                                                    showAlert: true,
                                                    successAlert: true
                                                });
                                            }
                                            else {
                                                
                                                this.setState({
                                                    objDealHeader: objDealHeader,
                                                    step: nextStep,
                                                    loading: false,
                                                    alertTitle: 'Success',
                                                    alertMessage: alertMessage,
                                                    showAlert: true,
                                                });
                                            }
                                        }
                                    }
                                );
                            }
                            break;
                        }
                    default:
                        {
                            //Additional message
                            if (json.ExternalInterfaceTradeID !== undefined && json.ExternalInterfaceTradeID !== null) {
                                additionalMessage = 'Please notice that the cover deal was accepted by ' + objDealHeader.ExternalInterface + ' with this ID ' + json.ExternalInterfaceTradeID + ' and reference number ' + json.ExternalInterfaceTradeNumber + '. Try saving the deal again but DO NOT select the “Use interface” option. You will have to update the cover deal properties with the reference number mentioned.';
                            }

                            alertMessage = 'Deal not saved! Please try again or contact administrator.';
                            alertTitle = 'Error';

                            if (json.ValidationMessage !== undefined) {
                                alertTitle = 'Validation';
                                alertMessage = json.ValidationMessage;
                            }
                            else {
                                if (json.Message !== undefined) {
                                    switch (json.Message) {
                                        case 'concurrency':
                                            {
                                                alertMessage = 'Please reload the information and try again. Otherwise contact your supervisor';
                                                break;
                                            }
                                        case 'SentToInterface':
                                            {
                                                alertMessage = "PLEASE NOTE: The deal Deal with partner was committed with them, but the cover deal was not created in our platform. In the information below if the customer deal was created in Pending status, you can accept it using the Trade screen. Use the Partner’s deal number to update the “Customer reference number”, the “InterfaceTradeNumber” and “InterfaceTradeID” propertiesy for the Cover Deal. You will also have to link the customer and cover deals using the “Cover Deal Application” screen.";
                                                break;
                                            }
                                        default:
                                            {
                                                alertMessage = alertMessage + ' (' + json.Message + ')';
                                                break;
                                            }
                                    }
                                } else {
                                    if (json.httpErrorMessage !== undefined) {
                                        alertTitle = 'Error';
                                        alertMessage = json.httpErrorMessage;
                                    }
                                }
                            }

                            alertMessage += ' ' + additionalMessage;
                            this.setState({
                                alertTitle: alertTitle,
                                alertMessage: alertMessage,
                                showAlert: true,
                                loading: false,
                            });
                            break;
                        }
                }

            }
        );

        
    }


    onUncoveredDealsClick = () => {
        this.setState({ step: 8 });
    }

    okValidationAlert = () => {
        this.setState({ showAlert: false }, () => {
            if (this.state.successAlert) {
                this.setState({ saveDealSuccess: true })
            }
        });
    }

    closeSaveDealSuccess = () => {
        this.setState({ saveDealSuccess: false });
    }
    changeSearchDealList = (newlist) => {
        this.setState({
            searchList: newlist
        });
    }

    loadDeal = (objDealHeader, fromSearch) => {
        //alert(JSON.stringify(objDealHeader));
        this.setState({ loading: true });
        getFullDealHeader(objDealHeader.DealNo).then(
            (result) => {
                //alert(result.CoverDealID);
                console.log(result)
                if (result !== undefined) {
                    let objDealHeader = result;
                    objDealHeader.ValueDate = new Date(objDealHeader.ValueDate);
                    if (objDealHeader.MaturityDate !== null) {
                        objDealHeader.MaturityDate = new Date(objDealHeader.MaturityDate);
                    }
                    if (objDealHeader.OutgoingFunds === null) {
                        objDealHeader.OutgoingFunds = [];
                    }
                    if (objDealHeader.IncomingFunds === null) {
                        objDealHeader.IncomingFunds = [];
                    }

                    let incomingFundsTmp = []
                    objDealHeader.IncomingFunds.forEach(x => {
                        incomingFundsTmp.push({ incomingFundsId: x.IncomingFundsID, amount: x.Amount, nostroAccountId: x.NostroAccount })
                    });
                    //Set swap information
                    if (Number(objDealHeader.DealType) === 6) {
                        objDealHeader.SwapNearLegDealHeader = {
                            CustomerRate: Number(objDealHeader.CustomerRate),
                            ForwardPoints: Number(objDealHeader.ForwardPoints),
                            MarketRate: Number(objDealHeader.MarketRate),
                            OriginalMarketSpotRate: Number(objDealHeader.OriginalMarketSpotRate),
                            OriginalCustomerRate: Number(objDealHeader.OriginalCustomerRate),
                            ProfitAmount: Number(objDealHeader.ProfitAmount),
                            Spread: Number(roundAmountByCurrency((Number(objDealHeader.CustomerRate) - Number(objDealHeader.MarketRate)) * 10000),objDealHeader.SellCurrency),
                        };

                        if (objDealHeader.FarLeg !== undefined && objDealHeader.FarLeg !== null && objDealHeader.FarLeg.length > 0) {
                            let farLeg = objDealHeader.FarLeg[0]
                            objDealHeader.SwapFarLegDealHeader = {
                                BuyAmount: Number(farLeg.BuyAmount),
                                CustomerRate: Number(farLeg.CustomerRate),
                                DealHeaderID: Number(farLeg.DealHeaderID),
                                ForwardPoints: Number(farLeg.ForwardPoints),
                                MarketRate: Number(farLeg.MarketRate),
                                OriginalMarketSpotRate: Number(farLeg.OriginalMarketSpotRate),
                                OriginalCustomerRate: Number(farLeg.OriginalCustomerRate),
                                ProfitAmount: Number(farLeg.ProfitAmount),
                                SellAmount: Number(farLeg.SellAmount),
                                Spread: Number(roundAmountByCurrency((Number(farLeg.CustomerRate) - Number(farLeg.MarketRate)) * 10000),objDealHeader.SellCurrency),
                                UpdateToken: Number(farLeg.UpdateToken),
                                ValueDate: new Date(farLeg.ValueDate),
                            }
                        }
						
                    }

                    this.handleDealHeaderUpdate(objDealHeader, () => {                        
                        this.handleCustomerSelection(objDealHeader.Customer, 3, () => {
                            this.setState({
                                objContact: objDealHeader.Contact,
                                objCustomer: objDealHeader.Customer,
                                incomingFunds: incomingFundsTmp,
                            }, () => {
                                this.setState({ step: 3, loading: false });
                                if (fromSearch) {
                                    this.setState({ dealFromDealSearch: fromSearch })
                                }
                            });
                        });
                    });
                }
            }
        );
    }

    uncoveredDealsOnExit = () => {
        this.setState({ step: 3 });
    }

    uncoveredDealsOnSelectDeals = (uncoveredDeals, currencyPair, coverTotalSellAmount, coverTotalBuyAmount, dealTypeText, valueDateText) => {
        //alert(JSON.stringify(uncoveredDeals));
        let valueDate = new Date(valueDateText);
        let days = Math.round((valueDate - this.state.systemDate) / (1000 * 60 * 60 * 24));
        var dealType = 0;
        switch (dealTypeText) {
            case 'Spot':
            case 'Drawdown':
            default:
                dealType = 1;
                break;
            case 'Forward':
                dealType = 4;
                break;
            case 'Swap':
                if (days > 3) {
                    dealType = 4;
                }
                else {
                    dealType = 1;
                }
                break;
        }

        var objDealHeader = this.state.objDealHeader;
        var currencies = currencyPair.split('/');
        var buyAmount = 0, sellAmount = 0;
        var lockedSide = '';

        if (currencies[1] === this.state.baseCurrency) {
            sellAmount = Number(coverTotalBuyAmount);
            lockedSide = 'Sell';
        }
        else {
            buyAmount = Number(coverTotalSellAmount);
            lockedSide = 'Buy';
        }
        objDealHeader.BuyAmount = buyAmount;
        objDealHeader.BuyCurrency = currencies[1];
        objDealHeader.DealsToCover = uncoveredDeals;
        objDealHeader.DealType = dealType;
        objDealHeader.LockedSide = lockedSide;
        objDealHeader.SellAmount = sellAmount;
        objDealHeader.SellCurrency = currencies[0];
        this.setState({
            objDealHeader: objDealHeader,
            step: 3,
            refreshSpot: true
        });
    }

    updateIncomingFunds = (newIncomingFunds) => {
        this.setState({ incomingFunds: newIncomingFunds })
    }

    onCloseFile = () => {
        this.setState({
            openFile: false,
            fullWidthFile: true,
            currentFileName: '',
            currentFileURList: [],
        })
    }
    updatedRefreshSpot = () => {
        this.setState({ refreshSpot: null })
    }
    
    yesFundsForReferralAlert = () => {

        let objDealHeader;
        let objDealHeaderForAcceptedReferral = this.state.objDealHeaderForAcceptedReferral
        objDealHeaderForAcceptedReferral.OutgoingFunds = [] //just to accepted dealheader        
        objDealHeaderForAcceptedReferral.IncomingFunds = null //just to accepted dealheader        
        objDealHeaderForAcceptedReferral.ComplianceSoftRulesAcceptedByUser = true;

        this.setState({ showFundsForReferralAlert: false, loading: true, isBookingNow: this.state.isBookingNow }, () => {            
            getFullDealHeader(objDealHeaderForAcceptedReferral.DealHeaderID).then(
                (result) => {                    
                    if (result !== undefined) {
                        objDealHeader = result;                        
                    }
                }).then(() => {                  
                    objDealHeaderForAcceptedReferral.Status = "Accepted";
                    objDealHeaderForAcceptedReferral.UpdateToken = objDealHeader.UpdateToken

                    let deposits = objDealHeader.Deposits
                    if (objDealHeader.DealType === 4 && deposits !== undefined && deposits != null && Array.isArray(deposits) && deposits.length === 1) {
                        console.log(deposits)
                        objDealHeaderForAcceptedReferral.DepositID = deposits[0].DepositID
                    }

                    saveDealHeader(objDealHeaderForAcceptedReferral).then(
                        (json) => {
                            let alertMessage = '', alertTitle = '', additionalMessage = '';
                            switch (json.httpStatusCode) {
                                case 200:
                                    {
                                        objDealHeader.DealHeaderID = json.DealHeaderID;
                                        objDealHeader.Status = json.Status;
                                        objDealHeader.CustomerRefNo = objDealHeaderForAcceptedReferral.CustomerRefNo
                                        objDealHeader.ExternalComments = objDealHeaderForAcceptedReferral.ExternalComments
                                        objDealHeader.InternalComments = objDealHeaderForAcceptedReferral.InternalComments
                                        objDealHeader.NostroDescription = objDealHeaderForAcceptedReferral.NostroDescription
                                        objDealHeader.BankReferenceForCoverDeal = objDealHeaderForAcceptedReferral.BankReferenceForCoverDeal
                                        objDealHeader.UpdateToken = json.UpdateToken
                                        
                                        if (objDealHeader.Status === 'Accepted') {
                                            alertMessage = 'Deal ' + json.DealHeaderID + ' was Accepted!';
                                        }
                                        else {
                                            alertMessage = 'Deal ' + json.DealHeaderID + ' was Saved!';
                                        }
                                        alertMessage = alertMessage + ' Your deal has been referred for further review.'

                                        let additionalMessage
                                        if (json.ExternalInterfaceTradeID !== null) {
                                            additionalMessage = objDealHeaderForAcceptedReferral.ExternalInterface + ' transaction details: ID -> ' + json.ExternalInterfaceTradeID + '. Ref Number -> ' + json.ExternalInterfaceTradeNumber;
                                            alertMessage = <span> {alertMessage} <br /> {additionalMessage} </span>
                                        }

                                        this.setState({
                                            objDealHeader: objDealHeader,
                                            loading: false,
                                            alertTitle: 'Success',
                                            alertMessage: alertMessage,
                                            showAlert: true,
                                            successAlert: true,
                                            step: this.state.nextStep
                                        });
                                        break;
                                    }
                                default:
                                    alertMessage = 'Deal not saved! Please try again or contact administrator.';
                                    alertTitle = 'Error';

                                    if (json.ValidationMessage !== undefined) {
                                        alertTitle = 'Validation';
                                        alertMessage = json.ValidationMessage;
                                    }
                                    else {
                                        if (json.Message !== undefined) {
                                            switch (json.Message) {
                                                case 'concurrency':
                                                    {
                                                        alertMessage = 'Please reload the information and try again. Otherwise contact your supervisor';
                                                        break;
                                                    }
                                                case 'SentToInterface':
                                                    {
                                                        alertMessage = "PLEASE NOTE: The deal Deal with partner was committed with them, but the cover deal was not created in our platform. In the information below if the customer deal was created in Pending status, you can accept it using the Trade screen. Use the Partner’s deal number to update the “Customer reference number”, the “InterfaceTradeNumber” and “InterfaceTradeID” propertiesy for the Cover Deal. You will also have to link the customer and cover deals using the “Cover Deal Application” screen.";
                                                        break;
                                                    }
                                                default:
                                                    {
                                                        alertMessage = alertMessage + ' (' + json.Message + ')';
                                                        break;
                                                    }
                                            }
                                        } else {
                                            if (json.httpErrorMessage !== undefined) {
                                                alertTitle = 'Error';
                                                alertMessage = json.httpErrorMessage;
                                            }
                                        }
                                    }

                                    alertMessage += ' ' + additionalMessage;
                                    this.setState({
                                        alertTitle: alertTitle,
                                        alertMessage: alertMessage,
                                        showAlert: true,
                                        loading: false,
                                    });
                                    break;
                            }

                        }
                    );
                });

        });

    }

    closeFundsForReferralAlert = () =>{
        this.setState({ showFundsForReferralAlert: false }, () => { 
            let message = "Deal  " + this.state.objDealHeader.DealHeaderID + " was saved in Pending status and has been referred for further review."
            this.setState({
                loading: false,
                alertTitle: 'Success',
                alertMessage: message,
                showAlert: true,
                successAlert: true,
                step: this.state.nextStep
            }); 
        });        
    }

    closeLimitValidationAlert = () =>{
        this.setState({ showWarningLimitValidationPending: true, showWarningLimitValidation: false });        
    }

    closeLimitValidationPendingAlert = () =>{
        this.setState({ showWarningLimitValidationPending: false });        
    }

    yesLimitValidationAlert = () =>{

        let objDealHeader = this.state.dealHeaderAfterValidation;
        objDealHeader.Status = 'Accepted';

        this.saveTradeInSettlementScreen(objDealHeader, this.state.isBookingNow, this.state.postSaveFuction);
        this.setState({ showWarningLimitValidation: false });  
    }

    yesLimitValidationPendingAlert = () =>{

        let objDealHeader = this.state.dealHeaderAfterValidation;
        objDealHeader.Status = 'Pending';

        this.saveTradeInSettlementScreen(objDealHeader, this.state.isBookingNow, this.state.postSaveFuction);
        this.setState({ showWarningLimitValidationPending: false });  
    }

    ValidateEntityForReferral(alertMessage, dealHeaderId){
        validateEntityForReferral(dealHeaderId, 0, localStorage.getItem('UserID')).then(
            (json) => {
                var showReferralAlert = false
                var showRegularAlert = true
                if(!json.Result){
                    showReferralAlert = true;
                    showRegularAlert = false;
                }
                let tempMessage = 'The Profile Limit has been violated. ' + json.Reasons
                let questionMessage = 'Do you want to save the deal in Accepted status?'
                var newFundForReferralMessage = <span> {tempMessage} <br /> {questionMessage} </span>
                this.setState({
                    alertMessage: alertMessage,
                    alertTitle: 'Success',
                    loading: false,
                    //fundsForReferralMessage: 'The limit has been violated. ' + json.Reasons + ' Do you want to save the deal in Accepted status?',
                    fundsForReferralMessage: newFundForReferralMessage,
                    showFundsForReferralAlert: showReferralAlert,
                    showAlert: showRegularAlert,
                });
            }
        )
    }

    UpdateDealStatus = (newStatus) => {
        updateDealHeaderStatus(this.state.objDealHeader.DealHeaderID, newStatus).then(
            (json) => {
                if(json.Result){
                    this.setState({ alertMessage: this.state.alertMessage + ' Your deal has been referred for further review.', showAlert: true});
                }
            }
        )
    }

    render() {
        var stepScreen = null;

        var classNameStep1 = '', classNameStep2 = '', classNameStep3 = '',
            classNameStep4 = '', classNameStep5 = '', classNameStep6 = '',
            classNameStep7 = '', classNameSearchContainer = 'uk-invisible';

        var breadcrumb = [];

        //Deal label
        let objDealHeader = this.state.objDealHeader;

        var dealIDLabel;
        /* if (Number(objDealHeader.DealHeaderID) > 0 &&
            !objDealHeader.ForDrawdownCreation && this.state.step === 3) {
            dealIDLabel = (<h3 className="uk-align-right">Deal ID: {objDealHeader.DealHeaderID}</h3>);
        } */

        var forwardIDLabel;
        if (objDealHeader.ForDrawdownCreation ||
            (objDealHeader.DealHeaderID === 0 &&
                objDealHeader.ParentDealHeaderID > 0) && this.state.step === 3) {
            forwardIDLabel = (<h3 className="uk-align-right">Creating drawdown for forward {objDealHeader.ParentDealHeaderID}</h3>);
        }

        if(this.props.parentScreen){
            breadcrumb.push(
                <Breadcrumb.Item className="breadcrumb-item" onClick={this.props.returnToParent}>{this.props.parentScreen}</Breadcrumb.Item>
            );
        }

        switch (this.state.step) {
            case 1:
            default:
                classNameStep1 = 'uk-active';
                stepScreen = (<WizardSearchCustomerForm id="comp_customer_form"
                    onCustomerSelected={this.handleCustomerSelection} />);
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item-bold" onClick={() => this.onClickStepTab(1)}>Trade</Breadcrumb.Item>
                );
                break;
            case 2:
                classNameStep2 = 'uk-active';
                stepScreen = (<WizardSelectContactForm id="comp_contact_form"
                    onContactSelected={this.handleContactSelection}
                    objCustomer={this.state.objCustomer} />);
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item" onClick={() => this.onClickStepTab(1)}>Trade</Breadcrumb.Item>
                );
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item-bold" onClick={() => this.onClickStepTab(2)}>{this.state.objCustomer["Customer Name"]} - Select Contact</Breadcrumb.Item>
                );
                break;
            case 3:
                classNameStep3 = 'uk-active';
                stepScreen = (<WizardTradeScreen id="comp_trade_screen"
                    bankCustomers={this.state.bankCustomers}
                    objCustomer={this.state.objCustomer}
                    objContact={this.state.objContact}
                    objDealHeader={this.state.objDealHeader}
                    paymentCurrencies={this.state.paymentCurrencies}
                    settlementCurrencies={this.state.settlementCurrencies}
                    currencyPairs={this.state.currencyPairs}
                    customerCurrencyDictionary={this.state.customerCurrencyDictionary}
                    holidays={this.state.holidays}
                    currencyDictionary={this.state.currencyDictionary}
                    systemDate={this.state.systemDate}
                    dealsToCover={this.state.objDealHeader.DealsToCover}
                    refreshSpot={this.state.refreshSpot}
                    updatedRefreshSpot={this.updatedRefreshSpot}
                    //For show popup by Additional Information
                    showAlert={this.state.saveDealSuccess}
                    closeSaveDealSuccess={this.closeSaveDealSuccess}
                    maxForwardWindow={this.state.maxForwardWindow}

                    onNextStepClicked={this.handleNextInTradeScreen}
                    onUpdateDealHeader={this.handleDealHeaderUpdate.bind(this)}
                    onClearDisbursements={this.clearDisbursements}
                    getConfirmation={this.getConfirmation}
                    handleSelectCustomerTab={this.handleSelectCustomerTab}
                    handleSelectContactTab={this.handleSelectContactTab}
                    dealStatusWasUpdated={this.dealStatusWasUpdated}
                    onBookNowClicked={this.handleSaveTradeInSettlementScreen}
                    onUpdateForwardDate={this.updateForwardDate}
                    onUncoveredDealsClick={this.onUncoveredDealsClick} 
                    disbursements ={this.state.disbursements}
                    />);
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item" onClick={() => this.onClickStepTab(1)}>Trade</Breadcrumb.Item>
                );
                if (!this.state.dealFromDealSearch) {
                    breadcrumb.push(
                        <Breadcrumb.Item className="breadcrumb-item" onClick={() => this.onClickStepTab(2)}>Select Contact</Breadcrumb.Item>
                    );
                }

                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item-bold">Trade Screen</Breadcrumb.Item>
                );
                break;
            case 4:
                classNameStep4 = 'uk-active';
                stepScreen = (<WizardOutgoingFunds
                    objCustomer={this.state.objCustomer}
                    objContact={this.state.objContact}
                    objDealHeader={this.state.objDealHeader}
                    disbursements={this.state.disbursements}
                    beneficiaryList={this.state.beneficiaryList}
                    beneficiaryDictionary={this.state.beneficiaryDictionary}
                    outgoingInstrumentList={this.state.outgoingInstrumentList}
                    onBackClicked={this.handleBackInDisbursementScreen}
                    onNextStepClicked={this.handleNextInDisbursementScreen} />);
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item" onClick={() => this.onClickStepTab(1)}>Trade</Breadcrumb.Item>
                );
                if (!this.state.dealFromDealSearch) {
                    breadcrumb.push(
                        <Breadcrumb.Item className="breadcrumb-item" onClick={() => this.onClickStepTab(2)}>Select Contact</Breadcrumb.Item>
                    );
                }
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item" onClick={() => this.onClickStepTab(3)}>Trade Screen</Breadcrumb.Item>
                );
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item-bold">Beneficiaries</Breadcrumb.Item>
                );
                break;
            case 5:
                classNameStep5 = 'uk-active';
                stepScreen = (<WizardSettlementForm
                    objCustomer={this.state.objCustomer}
                    objContact={this.state.objContact}
                    objDealHeader={this.state.objDealHeader}
                    disbursements={this.state.disbursements}
                    incomingFunds={this.state.incomingFunds}
                    incomingInstrumentList={this.state.incomingInstrumentList}
                    beneficiaryList={this.state.beneficiaryListForSettlement}
                    bankCustomers={this.state.bankCustomers}
                    onBackClicked={this.handleBackInSettlementScreen}
                    onSaveClicked={this.handleSaveTradeInSettlementScreen}
                    updateIncomingFunds={this.updateIncomingFunds} />);
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item" onClick={() => this.onClickStepTab(1)}>Trade</Breadcrumb.Item>
                );
                if (!this.state.dealFromDealSearch) {
                    breadcrumb.push(
                        <Breadcrumb.Item className="breadcrumb-item" onClick={() => this.onClickStepTab(2)}>Select Contact</Breadcrumb.Item>
                    );
                }
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item" onClick={() => this.onClickStepTab(3)}>Trade Screen</Breadcrumb.Item>
                );
                if (Number(objDealHeader.DealType) !== 6) 
                {
                  breadcrumb.push(
                      <Breadcrumb.Item className="breadcrumb-item" onClick={() => this.onClickStepTab(4)}>Beneficiaries</Breadcrumb.Item>
                  );
                }
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item-bold">Settlement</Breadcrumb.Item>
                );
                break;
            case 6:
                classNameStep6 = 'uk-active';
                stepScreen = (<WizardConfirmation
                    objCustomer={this.state.objCustomer}
                    objContact={this.state.objContact}
                    objDealHeader={this.state.objDealHeader}
                    disbursements={this.state.disbursements}
                    getConfirmation={this.getConfirmation}
                    handleSelectCustomerTab={this.handleSelectCustomerTab}
                    handleNewDeal={this.handleNewDeal}
                    getInwardConfirmation={this.getInwardConfirmation} />);
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item" onClick={() => this.onClickStepTab(1)}>Trade</Breadcrumb.Item>
                );
                if (!this.state.dealFromDealSearch) {
                    breadcrumb.push(
                        <Breadcrumb.Item className="breadcrumb-item" onClick={() => this.onClickStepTab(2)}>Select Contact</Breadcrumb.Item>
                    );
                }
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item" onClick={() => this.onClickStepTab(3)}>Trade Screen</Breadcrumb.Item>
                );
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item" onClick={() => this.onClickStepTab(4)}>Beneficiaries</Breadcrumb.Item>
                );
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item">Settlement</Breadcrumb.Item>
                );
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item-bold">Confirmation</Breadcrumb.Item>
                );
                break;
            case 7:
                classNameStep7 = 'uk-active';
                classNameSearchContainer = '';
                stepScreen = (<div className={classNameSearchContainer}>
                    <DealSearchScreen
                        onDealClick={this.loadDeal}
                        listSearchDeal={this.state.searchList}
                        onListSearchChange={this.changeSearchDealList}
                    />
                </div>
                );
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item-bold">Trade</Breadcrumb.Item>
                );
                break;
            case 8:
                stepScreen = (<WizardUncoveredDeals id="comp_uncovered_deals"
                    onExitClick={this.uncoveredDealsOnExit}
                    onSelectUncoveredDeals={this.uncoveredDealsOnSelectDeals}
                    isBreadcrumb={true}
                />);
                
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item" onClick={() => this.onClickStepTab(1)}>Trade</Breadcrumb.Item>
                );
                if (!this.state.dealFromDealSearch) {
                    breadcrumb.push(
                        <Breadcrumb.Item className="breadcrumb-item" onClick={() => this.onClickStepTab(2)}>Select Contact</Breadcrumb.Item>
                    );
                }
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item" onClick={this.uncoveredDealsOnExit}>Trade Screen</Breadcrumb.Item>
                );
                breadcrumb.push(
                    <Breadcrumb.Item className="breadcrumb-item">Uncovered Deals</Breadcrumb.Item>
                );
                break;
        }



        var tab;
        if (this.state.step === 1 || this.state.step === 7) {
            tab = (
                <ul className="uk-tab">
                    <li key="step-1" className={classNameStep1}><a onClick={() => this.onClickStepTab(1)}>Search Customer</a></li>
                    <li key="step-7" className={classNameStep7}><a onClick={() => this.onClickStepTab(7)}>Deal Search</a></li>
                    {/*<li key="step-2" className={classNameStep2}><a onClick={() => this.onClickStepTab(2)}>Select Contact</a></li>
                    <li key="step-3" className={classNameStep3}><a>Trade</a></li>
                    <li key="step-4" className={classNameStep4}><a>Beneficiaries</a></li>
                    <li key="step-5" className={classNameStep5}><a>Settlement</a></li>
                    <li key="step-6" className={classNameStep6}><a>Confirmation</a></li>*/}
                </ul>
            );
        }

        return (
            <div>
                <div className="breadcrumb-div">
                    <Breadcrumb separator=">">
                        {breadcrumb}
                    </Breadcrumb>
                </div>
                <div uk-grid="true">
                    <div className="uk-width-1-2">
                        {/*<h3 className="uk-heading-divider" style={getStyle().titleStyle}>Trade</h3>*/}
                    </div>
                    <div className="uk-width-1-2">
                        {dealIDLabel}
                        {forwardIDLabel}
                    </div>
                </div>


                {tab}



                {/*<ul className="uk-tab">
                    <li key="step-1" className={classNameStep1}><a onClick={() => this.onClickStepTab(1)}>Search Customer</a></li>
                    <li key="step-7" className={classNameStep7}><a onClick={() => this.onClickStepTab(7)}>Deal Search</a></li>
                    <li key="step-2" className={classNameStep2}><a onClick={() => this.onClickStepTab(2)}>Select Contact</a></li>
                    <li key="step-3" className={classNameStep3}><a onClick={() => this.onClickStepTab(3)}>Trade</a></li>
                    <li key="step-4" className={classNameStep4}><a onClick={() => this.onClickStepTab(4)}>Beneficiaries</a></li>
                    <li key="step-5" className={classNameStep5}><a>Settlement</a></li>
                    <li key="step-6" className={classNameStep6}><a>Confirmation</a></li>
                </ul>*/}


                {stepScreen}

                <Dialog
                    open={this.state.openFile}
                    onClose={this.onCloseFile}
                    fullWidth={this.state.fullWidthFile}
                    maxWidth={this.state.maxWidthFile}
                >
                    <DialogTitle id="current-File-dialog-title" onClose={this.onCloseFile}>
                        {this.state.currentFileName}
                    </DialogTitle>
                    <DialogContent dividers>
                        <div>
                            <DocViewer
                                pluginRenderers={DocViewerRenderers}
                                documents={this.state.currentFileURList}
                                config={{
                                    header: {
                                        disableHeader: true,
                                        disableFileName: true,
                                        retainURLParams: false
                                    }
                                }}
                            />
                        </div>
                    </DialogContent>
                </Dialog>

                <LoadingBox loading={this.state.loading} />

                <AlertBox id="validation-alert"
                    open={this.state.showAlert}
                    title={this.state.alertTitle}
                    message={this.state.alertMessage}
                    type="Ok"
                    okClick={this.okValidationAlert}
                />

                <AlertBox id="additonal-alert"
                    open={this.state.showAdditionalAlert}
                    title={this.state.additionalAlertTitle}
                    message={this.state.additionalAlertMessage}
                    type="Ok"
                    okClick={() => {
                        this.setState({
                            showAdditionalAlert: false,
                            additionalAlertTitle: '',
                            additionalAlertMessage: ''
                        })
                    }}
                />

                <AlertBox open={this.state.showFundsForReferralAlert} 
                    onClose={this.closeFundsForReferralAlert.bind(this)} 
                    title={"Confirm"} 
                    message={this.state.fundsForReferralMessage} 
                    type="Yes/No" 
                    yesClick={this.yesFundsForReferralAlert} 
                    noClick={this.closeFundsForReferralAlert.bind(this)}/>

                <AlertBox open={this.state.showWarningLimitValidation} 
                    onClose={this.closeLimitValidationAlert} 
                    title={"Warning"} 
                    message={this.state.limitValidationMessage} 
                    type="Yes/No" 
                    yesClick={this.yesLimitValidationAlert} 
                    noClick={this.closeLimitValidationAlert}/>
                <AlertBox open={this.state.showWarningLimitValidationPending} 
                    onClose={this.closeLimitValidationPendingAlert} 
                    title={"Confirm"} 
                    message={"Do you want to save this deal in Pending status?."} 
                    type="Yes/No" 
                    yesClick={this.yesLimitValidationPendingAlert} 
                    noClick={this.closeLimitValidationPendingAlert}/>
            </div>
        );
    }
}