import { SearchOutlined } from '@ant-design/icons';
import { Button, Form, Input, Popconfirm, Select, Space, Table, Tooltip, Typography } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import TheCSVButton from './TheCSVButton';
import Text from 'antd/lib/typography/Text';
import { CheckOutlined, DeleteOutlined, EditOutlined, RollbackOutlined }  from '@ant-design/icons';

/**
 * @component
 * @param {array} data - Table data. Required.
 * @param {array} columnDetails - Extra info for customizing table settings.
 * Options have the form option:value
 * Multiple options separated by commas.
 * Current options are:
 * id:boolean - Required for edit. Only one property should have this.
 * width:value% - Total should add up to 100%. Actions currently use 15% width if enabled
 * type:string|boolean|select
 * editable:boolean
 * required: boolean - Used for validation of edit fields.
 * title:value - No commas.
 * options:option1;option2;option3 - Used with type select - Needs optionsFrom:fixed to work - Sets filter values.
 * optionsFrom:fixed|dynamic - Describes if select options come from options property (fixed) or from the dynamicOptions prop (dynamic)
 * Check usp_react_Emails_GetEmails in Reactbox for an example.
 * @param {boolean} enableClick - Enables row click.
 * @function clickFunction - Row click function. Used with enableClick.
 * @param {array} dynamicActions - Array in the form [{ name: 'Branches', function: handleBranchesClick, useIcon: boolean, icon: <JSX icon from Ant or MaterialUI> }]. Enables Actions column.
 * @param {boolean} useDeleteButton - Enables delete function.
 * @function deleteFunction - Delete function. Used with useDeleteButton.
 * @param {boolean} useExportButton - Show export button.
 * @param {string} tableName - Csv file name prefix. Used with useExportButton.
 * @param {boolean} isEditable - Enables Actions column. Enables Edit button.
 * @function saveFunction - Save function. Used with isEditable.
 * @param {object} dynamicOptions - Needs optionsFrom:dynamic in stored procedure. Enables loading data from a custom object with form { [ColumnName]: data }, with data being a list with form { name: 'string', value: 'string'}.
 * @param {boolean} useIcons - Show icons instead of text in Actions column.
 * @example
 * <NewEditableGrid
        data={emails}
        columnDetails={columnDetail}
        isEditable={true}
        saveFunction={save}
        useIcons={true}
        dynamicActions={[{name: 'View', function: onRowSelected, isIcon: true, icon: <SearchOutlined /> }]} />
 */
const NewEditableGrid = ({
    data,
    columnDetails,
    enableClick,
    clickFunction,
    dynamicActions,
    useDeleteButton,
    deleteFunction,
    useExportButton,
    tableName,
    isEditable,
    saveFunction,
    dynamicOptions,
    useIcons,
    rowClassName = '',
    pageRowsSize=10
}) => {

    const [columns, setColumns] = useState([]);
    const [form] = Form.useForm();
    const [filteringSomething, setFilteringSomething] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [searchArray, setSearchArray] = useState({});
    const searchInput = useRef();
    const [currentPage, setCurrentPage] = useState(1);

    const [idKey, setIDKey] = useState(null);

    const [editingKey, setEditingKey] = useState(null);

    const isEditing = useCallback((record) =>  (idKey != null && record[idKey] === editingKey), [editingKey, idKey]);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
        if (selectedKeys[0] != null) {
            setFilteringSomething(true);
        }
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
        setFilteringSomething(false);
    };

    const settingSearchStateValues = useCallback((value, col) => {
        if (value != null) {
            setSearchText(value);
            setSearchedColumn(col);
            setSearchArray(...(searchArray[col] = value));
            setFilteringSomething(true);
        }
    }, [searchArray]);

    const getColumnSearchProps = useCallback((dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        searchInput.current = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : []);
                    }}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block'
                    }}
                />
                <Space>
                    <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{ width: 90 }}>
                        Search
                    </Button>
                    <Button
                        onClick={() => {
                            handleReset(clearFilters);
                        }}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            settingSearchStateValues(selectedKeys[0], dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => (record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : ''),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            )
    }), [searchText, searchedColumn, settingSearchStateValues]);

    const cancel = useCallback((page) => {
        setEditingKey(null);
        if (typeof page === 'number') {
            setCurrentPage(page);
        }

        form.resetFields();
    }, [form])

    const save = useCallback(async (record, event) => {
        event.stopPropagation();
        try {
            const row = await form.validateFields();
            // console.log(row);
            const newData = [...data];
            const index = newData.findIndex((item) => record[idKey] === item[idKey]);
            if (index > -1) {
                if (saveFunction != null) {
                    saveFunction(record, row);
                    cancel();
                }
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    }, [cancel, data, form, idKey, saveFunction]);

    const edit = useCallback((record, event) => {
        event.stopPropagation();
        form.setFieldsValue({
            UpdateToken: '',
            ...record,
        });
        // console.log(record)
        // console.log(idKey)
        setEditingKey(record[idKey]);
    }, [form, idKey]);

    const EditableCell = useCallback(({ editing, dataIndex, title, inputType, record, index, dropDownType, children, ...restProps }) => {
        const getOptions = (dataIndex) => {
            const _columnDetail = columnDetails[dataIndex];
            if (_columnDetail != null) {
                const allOptions = _columnDetail.split(',');
                const option = allOptions.find(option => option.includes('options:'));
                if (option != null) {
                    const [, _optionValue] = option.split(':');
                    return _optionValue.split(';').map(filter => ({ text: filter, value: filter}));
                }
            }
            return [];
        }

        let inputNode = null;
        switch (inputType) {
            case 'select': {
                inputNode = (
                    <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Select an Option..."
                        optionFilterProp="children"
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                        {dropDownType === 'fixed' ?
                            getOptions(dataIndex).map((option) => (
                                <Select.Option key={`${dataIndex}-option-${option.value}`} value={option.value}>
                                    {option.name}
                                </Select.Option>
                            )) :
                            dynamicOptions != null && dynamicOptions[dataIndex] != null && dynamicOptions[dataIndex].map(option => (
                                <Select.Option key={`${dataIndex}-option-${option.value}`} value={option.value}>
                                    {option.name}
                                </Select.Option>
                            ))
                        }
                    </Select>
                );
                break;
            }
            default: {
                inputNode = <Input style={{ textAlign: 'center' }} />;
                break;
            }
        }

        let styleEdit = { margin: 0 };
        if ((record !== undefined && record.isNew !== undefined && record.isNew === true) || editing) {
            styleEdit = {
                paddingBottom: 10,
                paddingTop: 10,
                margin: 0
            };
        }

        const getCustomRules = (columnDataIndex) => {
            // switch (columnDataIndex) {
            //     case 'Email Receiver': {
            //         return [
            //             {
            //                 type: 'email'
            //             }
            //         ];
            //     }
            //     case 'EmailId': {
            //         return [
            //             {
            //                 type: 'string',
            //                 pattern: /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
            //             }
            //         ];
            //     }
            //     default: {
            //         return [
            //             {
            //                 required: true,
            //                 message: `Please Input ${title}!`
            //             }
            //         ];
            //     }
            // }
            let _required = false;
            let _type = 'string';
            if (columnDetails != null) {
                const _columnDetail = columnDetails[dataIndex];
                // console.log(`${dataIndex}: ${_columnDetail}`);
                if (_columnDetail != null) {
                    const options = _columnDetail.split(',');
                    options.forEach(option => {
                        const [_optionName, _optionValue] = option.split(':');
                        switch (_optionName) {
                            case 'type': {
                                _type = _optionValue;
                                break;
                            }
                            case 'required': {
                                if (_optionValue === 'true') {
                                    _required = true;
                                }
                                break;
                            }
                            default: break;
                        }
                    }
                )}
            }
            return [
                {
                    type: _type,
                    required: _required,
                    message: `Please Input ${title}!`
                }
            ]


        };
        const rules = getCustomRules(dataIndex);

        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item name={dataIndex} style={styleEdit} rules={rules}>
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    }, [columnDetails, dynamicOptions]);

    useEffect(() => {
        if (data != null && data.length > 0) {
            let _columns =  Object.keys(data[0]).filter(key => !key.includes('§H')).map((key) => {
                let _type = 'string';
                let _title = key;
                let _width = '15%';
                let _filters = [];
                let _onFilter = null;
                let _render = null;
                let _editable = false;
                let _optionsFrom = 'fixed';
                let _hidden = false;
          
                if (columnDetails != null) {
                    const _columnDetail = columnDetails[key];
                    // console.log(`${key}: ${_columnDetail}`);
                    if (_columnDetail != null) {
                        const options = _columnDetail.split(',');
                        options.forEach(option => {
                            const [_optionName, _optionValue] = option.split(':');
                            // console.log(`${_optionName}-> ${_optionValue}`);
                            if (_optionName != null && _optionValue != null) {
                                switch (_optionName) {
                                    case 'id': {
                                        // console.log(_optionValue)
                                        if (_optionValue === 'true') {
                                            // console.log(key)
                                            setIDKey(key);
                                        }
                                        break;
                                    }
                                    case 'title': {
                                        _title = _optionValue;
                                        // console.log(`title --> ${_title}`)
                                        break;
                                    }
                                    case 'width': {
                                        _width = _optionValue;
                                        break;
                                    }
                                    case 'type': {
                                        _type = _optionValue;
                                        if (_optionValue === 'boolean') {
                                            _filters = [{ text: 'Yes', value: 'Yes' }, { text: 'No', value: 'No'}]
                                            _onFilter = (value, record) => (record[key] ? 'Yes' : 'No') === value;
                                            _render = (_, record) => <Text>{record[key] ? 'Yes' : 'No'}</Text>
                                        }
                                        break;
                                    }
                                    case 'options': {
                                        _filters = _optionValue.split(';').map(filter => ({ text: filter, value: filter}))
                                        _onFilter = (value, record) => record[key].startsWith(value);
                                        break;
                                    }
                                    case 'editable': {
                                        if (_optionValue === 'true') {
                                            _editable = true;
                                        }
                                        break;
                                    }
                                    case 'optionsFrom': {
                                        _optionsFrom = _optionValue;
                                        break;
                                    }
                                    case 'hidden': {
                                        if (_optionValue === 'true') {
                                            _hidden = true;
                                        }
                                        break;
                                    }
                                    default: break;
                                }
                            }
                        });
                    }
                }
                if (_filters.length > 0) {
                    _type = 'select';
                }
                let column = {
                    title: _title,
                    width: _width,
                    dataIndex: key,
                    type: _type,
                    editable: _editable,
                    align: 'center',
                    dropDownType: _optionsFrom,
                    sortDirections: ['descend', 'ascend'],
                    hidden: _hidden,
                    sorter: (a, b) => {
                        if (typeof key === 'number') {
                            return a[key] - b[key];
                        }
                        else {
                            if (a[key] == null) {
                                return -1;
                            }
                            if (b[key] == null) {
                                return 1;
                            }
                            return a[key]?.toString().localeCompare(b[key]);
                        }
                    }
                }
                if (_filters.length > 0) {
                    column.filters = _filters;
                    column.onFilter = _onFilter;
                }
                if (_render != null) {
                    column.render = _render;
                }
                if ((_type === 'string' || _type === 'select') && _filters.length === 0) {
                    column = { ...column, ...getColumnSearchProps(key)}
                }
                return column;
            }).filter(d => d.dataIndex !== 'key')
            .map((col) => {
                if (!col.editable) {
                    return col;
                }
                return {
                    ...col,
                    onCell: (record) => ({
                        record,
                        inputType: col.type,
                        dataIndex: col.dataIndex,
                        title: col.title,
                        editing: isEditing(record),
                        dropDownType: col.dropDownType,
                        onClick: event => {
                            event.stopPropagation();
                        }
                    })
                };
            });;
             
            _columns = _columns.filter(c => !c.hidden);

            if (dynamicActions != null || isEditable || useDeleteButton) {
                _columns.push({
                    title: 'Actions',
                    dataIndex: 'Actions',
                    align: 'center',
                    width: '15%',
                    onCell: () => {
                        return {
                            onClick: event => {
                                event.stopPropagation();
                            }
                        }
                    },
                    render: (_, record) => {
                        const editable = isEditing(record);
                        // const editable = false;
                        return editable ? (
                            useIcons ? (
                                <Space size="middle">
                                    {/* <a onClick={(event) => save(record, event)} style={{ marginRight: 8 }}>
                                        Save
                                    </a> */}
                                    <Tooltip title="Save">
                                        <Typography.Link onClick={(event) => save(record, event)}>
                                            <CheckOutlined />
                                        </Typography.Link>
                                    </Tooltip>
                                    <Popconfirm title="Sure to cancel?" onConfirm={(event) => {event.stopPropagation(); cancel()}}>
                                            <Tooltip title="Cancel">
                                            <Typography.Link>
                                                <RollbackOutlined />
                                            </Typography.Link>
                                        </Tooltip>
                                    </Popconfirm>
                                </Space>
                            ) : (
                                <Space size="middle">
                                    <Typography.Link onClick={(event) => save(record, event)}>
                                    Save
                                    </Typography.Link>
                                    <Popconfirm title="Sure to cancel?" onConfirm={(event) => {event.stopPropagation(); cancel()}}>
                                        <a>Cancel</a>
                                    </Popconfirm>
                                </Space>
                                )
                        ) : (
                            <div>
                                <Space size="middle">
                                    {dynamicActions != null && dynamicActions.map((action) => (
                                        action.isIcon ? (
                                            <Tooltip title={action.name}>
                                                <Typography.Link onClick={(event) => {
                                                        if (clickFunction != null) {
                                                            event.stopPropagation();
                                                        }
                                                        action.function(record);
                                                    }}>
                                                    {action.icon}
                                                </Typography.Link>
                                            </Tooltip>
                                        ) : (
                                            <Typography.Link onClick={(event) => {
                                                if (clickFunction != null) {
                                                    event.stopPropagation();
                                                }
                                                action.function(record);
                                            }}>
                                                {action.getName ? action.getName(record): action.name}
                                            </Typography.Link>)
                                        )
                                    )}
                                    {isEditable && (
                                            useIcons ? (
                                                <Tooltip title="Edit">
                                                    <Typography.Link disabled={editingKey !== null || (idKey != null && data[0][idKey] === '')} onClick={(event) => edit(record, event)}>
                                                        <EditOutlined />
                                                    </Typography.Link>
                                                </Tooltip>
                                            ) : (
                                                <Typography.Link disabled={editingKey !== null || (idKey != null && data[0][idKey] === '')} onClick={(event) => edit(record, event)}>
                                                    Edit
                                                </Typography.Link>
                                            )
                                        )
                                    }
                                    {useDeleteButton && deleteFunction && (
                                            useIcons ? (
                                                <Tooltip title="Delete">
                                                    <Typography.Link onClick={(event) => {
                                                            if (clickFunction != null) {
                                                                event.stopPropagation();
                                                            }
                                                            deleteFunction(record);
                                                        }}>
                                                        <DeleteOutlined />
                                                    </Typography.Link>
                                                </Tooltip>
                                            ) : (
                                            <Typography.Link onClick={(event) => {
                                                    if (clickFunction != null) {
                                                        event.stopPropagation();
                                                    }
                                                    deleteFunction(record);
                                                }}>
                                            Delete
                                            </Typography.Link>
                                            )
                                        )
                                    }
                                </Space>
                            </div>
                        );
                    }
                })
            }
            setColumns(_columns);
        }
    }, [dynamicActions, data, getColumnSearchProps, columnDetails, useDeleteButton, deleteFunction, clickFunction, idKey, isEditable, isEditing, editingKey, cancel, edit, save, useIcons]);

    return (
        <div>
            <Form form={form}>
                {useExportButton && (
                    <div className="uk-overflow-auto">
                        <TheCSVButton board={tableName} data={data} useIcon={true}/>
                    </div>
                )}
                <Table 
                    // key = {(id) ? id : 'table'}
                    key='table'
                    rowKey = {(record, rowIndex) => {
                            return rowIndex;
                        }
                    }
                    className="uk-table-hover"
                    rowClassName={rowClassName}
                    dataSource  = {[...data]} 
                    columns     = {columns} 
                    components={{ body: { cell: EditableCell } }}
                    onRow       = {(record) => {
                        return {
                            onClick: () => {
                                if(enableClick && clickFunction){
                                    clickFunction(record);
                                }
                            }
                        };
                    }}
                    pagination={{
                        onChange: cancel,
                        showQuickJumper: true,
                        itemRender: (current, type, originalElement) => {
                            if (type === 'prev') {
                                return <a>Previous</a>;
                            }
                            if (type === 'next') {
                                return <a>Next</a>;
                            }
                            return originalElement;
                        },
                        current: currentPage,
                        defaultPageSize: pageRowsSize,
                        defaultCurrent: 1,
                        size: 'default'
                    }}
                    size        = 'small'
                    scroll      = {{x: true}}
                />
            </Form>
        </div>
    );
}

export default NewEditableGrid;
