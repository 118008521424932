import React from 'react';
import { GlobalSystemParametersNames } from '../../../constants/GlobalSystemParametersNames';
import { formatToCurrencyWithScale, roundAmountByCurrency } from '../../../helpers/FormatHelper';
import { getFundOnHoldByCustomerAndCurrency } from '../../../networking/NetworkingCustomers';
import EmailConfirmationDialog from '../../deal/EmailConfirmationDialog';
import EntityPropertiesContainer from '../../shared/EntityPropertiesContainer';
import NewDynamicTable from '../../shared/NewDynamicTable';
import WizardDealSummary from './WizardDealSummary';

export default class WizardConfirmation extends React.Component {
    state = {
        bankAccountDescription: '',
        beneficiaryName: '',
        customerRefNo: '',
        externalComments: '',
        heldFundsBalance: 0,
        internalComments: '',
        nostroDescription: '',
        selectedDeliveryMethod: '',
        selectedInstrument: '',
        thirdPartyBeneficiaryPayment: false,
        allowAdditionalSettlements: false,
        lookUpTables: [],
        showDealConfirmation: false
    }

    componentDidMount(){
        let objDealHeader = this.props.objDealHeader;
        var customerRefNo = objDealHeader.CustomerRefNo;
        var internalComments = objDealHeader.InternalComments;
        var externalComments = objDealHeader.ExternalComments;
        let nostroDescription = objDealHeader.NostroDescription;

        //Incoming fund info

        var bankAccountDescription = '';
        var beneficiaryName = '';
        var selectedInstrument = '';
        var selectedDeliveryMethod = '';
        var thirdPartyBeneficiaryPayment = false;
        
        let allowAdditionalSettlements = localStorage.getItem(GlobalSystemParametersNames.TradeScreenAllowAdditionalSettlements) === 'true'
        
        if(objDealHeader.IncomingFunds !== undefined && objDealHeader.IncomingFunds!== null && objDealHeader.IncomingFunds.length > 0)
        {
            let incomingFund = objDealHeader.IncomingFunds[0];
        
            bankAccountDescription = incomingFund.BankAccountDescription;
            beneficiaryName = incomingFund.BeneficiaryName;
            selectedInstrument = incomingFund.SettlementInstrument;
            selectedDeliveryMethod = incomingFund.SettlementDeliveryMethod;
            thirdPartyBeneficiaryPayment = incomingFund.ThirdPartyBeneficiaryPayment;
        }

        this.setState({
            bankAccountDescription: bankAccountDescription,
            beneficiaryName: beneficiaryName,
            customerRefNo: customerRefNo,
            externalComments: externalComments,
            internalComments: internalComments,
            nostroDescription: nostroDescription,
            selectedDeliveryMethod: selectedDeliveryMethod,
            selectedInstrument: selectedInstrument,
            thirdPartyBeneficiaryPayment: thirdPartyBeneficiaryPayment,
            allowAdditionalSettlements: allowAdditionalSettlements
        });

        //Load held fund balance
        getFundOnHoldByCustomerAndCurrency(this.props.objCustomer["Customer ID"],
            this.props.objDealHeader.BuyCurrency).then(
            (json) => {
                var balance = 0;
                if(json.balances !== undefined){
                    balance = json.balances[0].Amount;
                }
                this.setState({heldFundsBalance: balance});
            }
        );
    }

    handlePrintPDFClick()
    {
        let dealHeaderID = this.props.objDealHeader.DealHeaderID;
        this.props.getConfirmation(dealHeaderID);
    }
    
    handlePrintInwardClick()
    {
        let dealHeaderID = this.props.objDealHeader.DealHeaderID;
        this.props.getInwardConfirmation(dealHeaderID);
    }

    handleNewDealClick(){
        this.props.handleNewDeal();
    }

    handleSelectCustomerClick(){
        this.props.handleSelectCustomerTab();
    }

    handleUpdateAccount(obj){
        if(obj !== undefined){
            this.setState({selectedAccountID: obj.value});
        }
    }

    handleUpdateBeneficiaryID(obj){
        if(obj !== undefined){
            this.setState({selectedBeneficiaryID: obj.value});
        }
    }

    handleShowEmailConfirmationClick = () => {
        this.setState({ showDealConfirmation: true });
    };

    closeDealConfirmation = () => {
        this.setState({ showDealConfirmation: false });
    };

    render() {
        let objDealHeader = this.props.objDealHeader;

        //Disbursements section
        var disbursementsSection;
        if(Number(objDealHeader.DealType) !== 4){
            var disbursements = [];
            for(var i=0;i<this.props.disbursements.length;i++){
                var name = this.props.disbursements[i].Name;
                switch(this.props.disbursements[i].BeneficiaryType){
                    case 1:
                        name = this.props.objCustomer["Customer Name"];
                        break;
                    case 2:
                        name = 'TBA';
                        break;
                    default:
                        break;
                }
                
                disbursements.push({"Name":name, 
                    "Amount":formatToCurrencyWithScale(this.props.disbursements[i].PaymentAmount, objDealHeader.SellCurrency), 
                    "Country":this.props.disbursements[i].CountryName,
                });
            }

            disbursementsSection = (
                <tr>
                    <td colSpan="3">
                        <label className="uk-form-label">Beneficiaries</label>
                        <NewDynamicTable 
                            data={disbursements}
                            enableClick={false}
                            useDeleteButton={false}/>
                    </td>
                </tr>
            );
        }
        
        //Instrument and delivery method controls
        var instrumentControl, deliveryMethodControl, nostroAccountControl;
        if(Number(objDealHeader.DealType) !== 4 ||
            Number(objDealHeader.DepositAmount) > 0 ||
            (Number(objDealHeader.DealType) === 4 && objDealHeader.ForwardType === 'Closed')){
            instrumentControl = (
                <div>
                    <label className="uk-form-label">Settlement instrument</label>
                    <div>
                        {this.state.selectedInstrument}
                    </div>
                </div>
            );

            deliveryMethodControl = (
                <div>
                    <label className="uk-form-label">Delivery method</label>
                    <div>
                        {this.state.selectedDeliveryMethod}
                    </div>
                </div>
            );

            nostroAccountControl = (
                <div>
                    <label className="uk-form-label">Nostro account</label>
                    <div>
                        {this.state.nostroDescription}
                    </div>
                </div>
            );
        }

        //Third party payment control
        var thirdPartyPaymentControl;
        var thirdPartyBeneficiaryPaymentText = "No";
        if(this.state.thirdPartyBeneficiaryPayment){
            thirdPartyBeneficiaryPaymentText = "Yes";
        }
        if(this.state.selectedInstrument === 'Electronic Funds Transfer' ||
            this.state.selectedInstrument === 'Direct Debit'){
            thirdPartyPaymentControl = (
            <div>
                <label className="uk-form-label">Third-party beneficiary payment?</label>
                <div>
                    {thirdPartyBeneficiaryPaymentText}
                </div>
            </div>);
        }

        //Customer bank account control
        var customerBankAccountControl;
        if(this.state.selectedInstrument === 'Direct Debit'){
            customerBankAccountControl = (
                <div>
                    <label className="uk-form-label">Bank Account</label>
                    <div>
                        {this.state.bankAccountDescription}
                    </div>
                </div>
            );
        }

        //Third party payor control
        var thirdPartyPayorControl;
        if(this.state.thirdPartyBeneficiaryPayment){
            thirdPartyPayorControl = (
                <div>
                    <label className="uk-form-label">Third-party payor</label>
                    <div>
                        {this.state.beneficiaryName}
                    </div>
                </div>
            );
        }

        //Total owned by customer
        var totalOwnedByCustomer = roundAmountByCurrency(Number(objDealHeader.TotalFees) + Number(objDealHeader.BuyAmount), objDealHeader.BuyCurrency);

        //Deal labels
        /*var dealHeaderIDLabel;
        if(objDealHeader.DealHeaderID !== undefined &&
            Number(objDealHeader.DealHeaderID) !== 0){
            dealHeaderIDLabel = (<h3 className="uk-align-right">Deal Header ID: {objDealHeader.DealHeaderID}</h3>);
        }*/

        //Deal summary
        var dealSummary;
        var dealIDLabel;
        if (objDealHeader.DealHeaderID !== undefined &&
            objDealHeader.DealHeaderID !== 0) {
            dealIDLabel = (<div style={{ textAlign: "right", marginRight: '10px' }}><h3 className="" style={{ fontSize: '1.5em', verticalAlign: 'middle' }}>Deal ID: {objDealHeader.DealHeaderID}</h3></div>);
            dealSummary = (//<div className="input-number" >
                <WizardDealSummary objDealHeader={objDealHeader} />);
        }
        /* var statusLabel;
        if(objDealHeader.Status !== undefined &&
            objDealHeader.Status !== ''){
            statusLabel = (<h3 className="uk-align-right">Status: {objDealHeader.Status}</h3>);
        } */

        //Fees section
        
        //Total sell side fees
        var sellSideFees = '';
        if(objDealHeader.SellSideFess){
            sellSideFees = formatToCurrencyWithScale(objDealHeader.SellSideFess, this.props.objDealHeader.SellCurrency) + ' ' + this.props.objDealHeader.SellCurrency;
        }

        var feeSection;
        if(Number(objDealHeader.DealType) !== 4){
            feeSection = (
                <tr>
                    <td><label className="uk-form-label">Total Fees</label></td>
                    <td align="right">{formatToCurrencyWithScale(objDealHeader.TotalFees, objDealHeader.BuyCurrency) + ' ' + objDealHeader.BuyCurrency}</td>
                    <td>{sellSideFees}</td>
                </tr>
            );
        }

        //Deposit amount section
        var depositAmountSection;
        if(Number(objDealHeader.DealType) === 4 &&
            Number(objDealHeader.DepositAmount) !== 0){
            depositAmountSection = (
                <tr>
                    <td><label className="uk-form-label">Deposit Amount</label></td>
                    <td align="right">{formatToCurrencyWithScale(objDealHeader.DepositAmount, this.props.objDealHeader.BuyCurrency) + ' ' + this.props.objDealHeader.BuyCurrency}</td>
                </tr>
            );
        }

        return (
            <div className="uk-form-stacked">
                <div>
                    <div uk-grid="true">
                        <div className="uk-width-2-5">
                            <h3>Customer: {this.props.objCustomer["Customer Name"]}</h3>
                            <h3>Contact: {this.props.objContact.Name}</h3>
                        </div>
                        <div className="uk-width-3-5" style={{ display: 'inline-flex', justifyContent: 'flex-end' }}>
                            {/* {statusLabel} */}                            
                            {/*dealIDLabel*/}
                            {dealSummary}
                        </div>
                    </div>
                </div>

                <hr className="uk-hr"/>

                <div uk-grid="true">
                    <div className="uk-width-1-2">
                        <div>
                            <table cellPadding="5" cellSpacing="5" width="100%">
                                <tbody>
                                    <tr>
                                        <td><label className="uk-form-label">We Sell</label></td>
                                        <td align="right">{formatToCurrencyWithScale(this.props.objDealHeader.SellAmount, this.props.objDealHeader.SellCurrency) + ' ' + this.props.objDealHeader.SellCurrency}</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td width="30%"><label className="uk-form-label">We Buy</label></td>
                                        <td width="30%" align="right">{formatToCurrencyWithScale(this.props.objDealHeader.BuyAmount, this.props.objDealHeader.BuyCurrency) + ' ' + this.props.objDealHeader.BuyCurrency}</td>
                                    </tr>                                    
                                    {feeSection}
                                    <tr>
                                        <td><label className="uk-form-label">Total owed by customer</label></td>
                                        <td align="right">{formatToCurrencyWithScale(totalOwnedByCustomer, this.props.objDealHeader.BuyCurrency) + ' ' + this.props.objDealHeader.BuyCurrency}</td>
                                    </tr>
                                    {depositAmountSection}
                                    {disbursementsSection}
                                </tbody>
                            </table>

                            

                            
                        </div>
                    </div>
                
                    <div className="uk-width-1-2">
                        <div>
                            <div uk-grid="true">
                                <div className="uk-width-1-2">
                                    <label className="uk-form-label">Funds on hold balance</label>
                                </div>
                                <div className="uk-width-1-2">
                                    {formatToCurrencyWithScale(this.state.heldFundsBalance, this.props.objDealHeader.BuyCurrency) + ' ' + this.props.objDealHeader.BuyCurrency}
                                </div>
                            </div>
                        </div>
                        {instrumentControl}
                        {deliveryMethodControl}
                        {nostroAccountControl}
                        {customerBankAccountControl}
                        {thirdPartyPaymentControl}
                        {thirdPartyPayorControl}
                        <div>
                            <label className="uk-form-label">Customer reference number</label>
                            {this.state.customerRefNo}
                        </div>
                        <div>
                            <label className="uk-form-label">Internal comments</label>
                            <div>
                                <textarea className="uk-textarea"
                                    value={this.state.internalComments}
                                    readOnly={true}></textarea>
                            </div>
                        </div>
                        <div>
                            <label className="uk-form-label">External comments</label>
                            <div>
                                <textarea className="uk-textarea"
                                    value={this.state.externalComments}
                                    readOnly={true}></textarea>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="uk-width-1-1">
                    <EntityPropertiesContainer
                        entityProperties        = {this.props.objDealHeader.EntityProperties}
                        readOnly                = {true}
                        showLikeCollapsable     = {true}
                        isConfirmationFather    = {true}
                    />
                </div>

                <hr className="uk-hr" />

                <div>
                    <button className="uk-button uk-button-green" onClick={this.handlePrintPDFClick.bind(this)}>Print PDF</button>
                    {this.props.objDealHeader.Status === 'Accepted' &&
                        <button className="uk-button uk-button-green" onClick={this.handleShowEmailConfirmationClick}>Email Confirmation</button>
                    }
                    <button hidden={!this.state.allowAdditionalSettlements} className="uk-button uk-button-green" onClick={this.handlePrintInwardClick.bind(this)}>Print Inward Confirmation</button>
                    <button className="uk-button uk-button-green" onClick={this.handleNewDealClick.bind(this)}>New Deal</button>
                    <button className="uk-button uk-button-green" onClick={this.handleSelectCustomerClick.bind(this)}>New Customer</button>
                </div>
                <EmailConfirmationDialog
                    open            = {this.state.showDealConfirmation}
                    onClose         = {this.closeDealConfirmation}
                    dealHeaderId    = {this.props.objDealHeader.DealHeaderID}
                    customerId      = {this.props.objCustomer['Customer ID']}
                />
            </div>
        );
    }
}